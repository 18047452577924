import { Button, Alert } from '@ph-react-ui/core';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSetPassword } from '../../../hooks/auth/useSetPassword';
import { Input } from '../../../utils/components/hoc-components';
import {
  getCommonValidators,
  VALIDATION as V,
} from '../../../utils/inputValidators';
import { PasswordRequirements } from '../../admission/complete-profile/common/PasswordRequirements';

interface FormData {
  password: string;
}

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export function SetPassword() {
  let query = useQuery();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const methods = useForm({
    defaultValues: {
      password: '',
    },
  });

  const setPassword = useSetPassword({
    onError: () => {
      methods.reset();
    },
  });

  const onSubmit = async (data: FormData) => {
    await setPassword.mutateAsync({
      requestId: query.get('token') ?? '',
      newPassword: data.password,
    });
    navigate('/auth');
  };

  const password = methods.watch('password');

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <h2 className="auth__window__title">{t('AUTH.TITLE.LOGIN')}</h2>
        <div>
          {setPassword.isSuccess && (
            <Alert type="success">{t('AUTH.ALERT.PASSWORD_SET')}</Alert>
          )}
          {setPassword.isError && (
            <Alert type="danger">{t('AUTH.SOMETHING_WENT_WRONG')}</Alert>
          )}
        </div>
        <div className="auth__window__inputs">
          <Input
            rules={getCommonValidators([V.REQUIRED, V.PASSWORD_PATTERN])}
            name="password"
            inputType="password"
            placeholder={t('AUTH.NEW_PASSWORD') ?? ''}
          />
        </div>
        <PasswordRequirements password={password} />
        <Button
          type="submit"
          loading={setPassword.isPending}
          className="auth__window__button"
          color="success"
        >
          {t('AUTH.ACTIONS.SET_PASSWORD')}
        </Button>
      </form>
    </FormProvider>
  );
}
