import { useMutation } from '@tanstack/react-query';
import type { BoricaParametersDto } from '../../models/payments/borica-parameters.dto';
import type { FoodParametersDto } from '../../models/payments/food-parameters.dto';
import apiRequest from '../../services/apiWrapper';
import { FOOD_PAYMENTS } from '../../utils/constants/urlConstants';

async function sendFoodParameters(data: FoodParametersDto) {
  const res = await apiRequest.post<FoodParametersDto, BoricaParametersDto>(
    `${FOOD_PAYMENTS}/students`,
    data
  );

  return res.data;
}

export function useSendFoodParameters() {
  return useMutation({
    mutationFn: (data: FoodParametersDto) => sendFoodParameters(data),
  });
}
