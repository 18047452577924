import { Button, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { useCommentTermReportSection } from '../../../hooks/term-reports/admin/report-sections/useCommentTermReportSection';
import { useModal } from '../../../hooks/useModal';
import type { TermReportDenyFormDto } from '../../../models/term-reports/term-report-deny.dto';
import { Textarea } from '../../../utils/components/hoc-components';
import { COMMENT_TERM_REPORT_SECTION } from '../../../utils/constants/term-reports/modals';
import {
  getCommonValidators,
  VALIDATION as V,
} from '../../../utils/inputValidators';
import { Dialog } from '../../shared/components/Dialog';

export const CommentTermReportSectionModal = () => {
  const [searchParams] = useSearchParams();
  const sectionId = searchParams.get('sectionId');
  const { id } = useParams();
  const { t } = useTranslation();
  const notification = useNotifications();
  const queryClient = useQueryClient();
  const { close: closeModal } = useModal(COMMENT_TERM_REPORT_SECTION);

  const commentTermReportSection = useCommentTermReportSection();

  const methods = useForm({
    defaultValues: {
      comment: '',
    },
  });

  const onSubmit = (data: TermReportDenyFormDto) => {
    commentTermReportSection
      .mutateAsync({
        id: sectionId!,
        comment: data.comment,
      })
      .then(() => {
        notification.success(
          t('TERM_REPORTS.MESSAGES.SUCCESSFULLY_SENT_COMMENT')
        );
        queryClient.invalidateQueries({
          queryKey: ['term-report-section', sectionId],
        });
        queryClient.invalidateQueries({
          queryKey: ['term-report', id],
        });
        closeModal();
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  return (
    <Dialog
      dialogHeader={t('TERM_REPORTS.MODALS.LEAVE_COMMENT')}
      onClose={() => {
        closeModal();
      }}
      actionButton={
        <Button onClick={methods.handleSubmit((data) => onSubmit(data))}>
          {t('ACTIONS.CONTINUE')}
        </Button>
      }
    >
      <FormProvider {...methods}>
        <form>
          <div className="dialog__confirm__content">
            <Textarea
              name="comment"
              className="dialog__confirm__content__textarea"
              label={t('TERM_REPORTS.MODALS.COMMENT') ?? ''}
              placeholder={t('TERM_REPORTS.MODALS.COMMENT') ?? ''}
              rules={getCommonValidators([V.MAX_LENGTH_2000, V.REQUIRED])}
            />
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};
