import { useMutation } from '@tanstack/react-query';
import apiRequest from '../../../services/apiWrapper';
import { TERM_REPORTS_URL } from '../../../utils/constants/urlConstants';

async function approveTermReportById(id: string) {
  return apiRequest.put(`${TERM_REPORTS_URL}/${id}/approve`, {});
}

export function useApproveTermReportById() {
  return useMutation({
    mutationFn: (id: string) => approveTermReportById(id),
  });
}
