import { useMutation } from '@tanstack/react-query';
import apiRequest from '../../../../services/apiWrapper';
import { TERM_REPORT_SECTIONS_URL } from '../../../../utils/constants/urlConstants';

async function approveTermReportSectionById(id: string) {
  return apiRequest.put(`${TERM_REPORT_SECTIONS_URL}/${id}/approve`, {});
}

export function useApproveTermReportSectionById() {
  return useMutation({
    mutationFn: (id: string) => approveTermReportSectionById(id),
  });
}
