import { useQuery } from '@tanstack/react-query';
import type { AvailableGroupsForEvaluationPlanDto } from '../../models/evaluation-plans/available-groups-for-evaluation-plan.dto';
import apiRequest from '../../services/apiWrapper';
import { EVALUATION_PLANS_URL } from '../../utils/constants/urlConstants';

async function getAvailableGroupsForEvaluationPlanById(id: string | null) {
  const response = await apiRequest.get<AvailableGroupsForEvaluationPlanDto[]>(
    `${EVALUATION_PLANS_URL}/${id}/groups/available`
  );

  return response.data;
}

export function useGetAvailableGroupsForEvaluatinoPlanById(id: string | null) {
  return useQuery({
    queryKey: ['available-groups-for-evaluation-plan', id],
    queryFn: () => getAvailableGroupsForEvaluationPlanById(id),
    enabled: Boolean(id),
  });
}
