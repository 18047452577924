import { Information } from '@carbon/icons-react';
import { Button, Table, Tag, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { usePaginatedMyEvaluationPlans } from '../../../hooks/evaluation-plans/usePaginatedMyEvaluationPlans';
import { useSubmitEvaluationPlanForApprovalById } from '../../../hooks/evaluation-plans/useSubmitEvaluationPlanForApprovalById';
import { useModal } from '../../../hooks/useModal';
import { EVALUATION_PLANS_STATUS } from '../../../utils/constants/evaluation-plans/evaluation-plans-status';
import {
  ARCHIVE_PLAN,
  DENY_EVALUATION_PLAN,
  DUPLICATE_PLAN,
} from '../../../utils/constants/evaluation-plans/modals';
import { EvaluationPlanStatus } from '../../../utils/enums/evaluation-plan-status.enum';
import { getTagType } from '../../../utils/getTagType';
import { ActionMenuHorizontal } from '../../shared/components/ActionMenuHorizontal';
import { Pagination } from '../../shared/components/Pagination';
import { TableSkeleton } from '../../shared/components/skeletons/TableSkeleton';

export function MyEvaluationPlans() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const notifications = useNotifications();
  const queryClient = useQueryClient();
  const { open: openDenyEvaluationPlanModal } = useModal(DENY_EVALUATION_PLAN);
  const { open: openArchiveEvaluationPlanModal } = useModal(ARCHIVE_PLAN);
  const { open: openDuplicateEvaluationPlanModal } = useModal(DUPLICATE_PLAN);

  const { data: evaluationPlans, isSuccess } =
    usePaginatedMyEvaluationPlans(searchParams);

  const onNavigateToCreateNewCriteriaMatrix = () => {
    navigate('../create-edit');
  };

  const onOpenDenyEvaluationPlanModal = useCallback(
    (id: string) => {
      openDenyEvaluationPlanModal({ id });
    },
    [openDenyEvaluationPlanModal]
  );

  const onOpenArchiveEvaluationPlan = useCallback(
    (id: string, assignedGroups: number) => {
      openArchiveEvaluationPlanModal({
        id,
        active: assignedGroups > 0 ? 'true' : 'false',
      });
    },
    [openArchiveEvaluationPlanModal]
  );

  const onOpenDuplicateEvaluationPlan = useCallback(
    (id: string) => {
      openDuplicateEvaluationPlanModal({ id });
    },
    [openDuplicateEvaluationPlanModal]
  );

  const submitForApproval = useSubmitEvaluationPlanForApprovalById();

  const onSubmitForApproval = useCallback(
    (id: string) => {
      submitForApproval
        .mutateAsync(id)
        .then(() => {
          notifications.success(
            t('EVALUATION_PLANS.MESSAGES.SUBMIT_FOR_APPROVAL_SUCCESS')
          );
          queryClient.invalidateQueries({ queryKey: ['my-evaluation-plans'] });
          queryClient.invalidateQueries({ queryKey: ['evaluation-plans'] });
        })
        .catch((resError) => {
          notifications.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
        });
    },
    [notifications, queryClient, submitForApproval, t]
  );

  const headers = [
    t('EVALUATION_PLANS.TABLE.HEADERS.NAME'),
    t('EVALUATION_PLANS.TABLE.HEADERS.CREATED_ON'),
    t('EVALUATION_PLANS.TABLE.HEADERS.UPDATED_ON'),
    t('EVALUATION_PLANS.TABLE.HEADERS.STATUS'),
    t('EVALUATION_PLANS.TABLE.HEADERS.ASSIGNED_GROUPS'),
    t('EVALUATION_PLANS.TABLE.HEADERS.ACTIONS'),
  ];

  const tableChildren = useMemo(() => {
    return evaluationPlans?.result.reduce((acc: any, evaluationPlan) => {
      let userArray = [
        evaluationPlan.name,
        format(new Date(evaluationPlan.createdAt), 'dd.MM.yyyy'),
        format(new Date(evaluationPlan.lastUpdatedAt), 'dd.MM.yyyy'),
        <div className="deny-tag">
          <Tag type={getTagType(evaluationPlan.status)}>
            {t(
              `EVALUATION_PLANS.STATUS.${
                EVALUATION_PLANS_STATUS[evaluationPlan.status]
              }`
            )}
          </Tag>
          {evaluationPlan.status === EvaluationPlanStatus.Denied &&
            evaluationPlan.deniedReason && (
              <Information
                size={20}
                onClick={() => onOpenDenyEvaluationPlanModal(evaluationPlan.id)}
              />
            )}
        </div>,
        evaluationPlan.assignedGroups.map((group) => group.name).join(', '),
        <ActionMenuHorizontal
          options={[
            {
              label: t('ACTIONS.EDIT'),
              hidden:
                evaluationPlan.status === EvaluationPlanStatus.Approved ||
                evaluationPlan.status === EvaluationPlanStatus.PendingApproval,
              onClick: () => navigate(`../create-edit/${evaluationPlan.id}`),
            },
            {
              label: t('EVALUATION_PLANS.ACTIONS.REVIEW'),
              onClick: () => navigate(`../${evaluationPlan.id}`),
            },
            {
              label: t('ACTIONS.SUBMIT_FOR_APPROVAL'),
              onClick: () => onSubmitForApproval(evaluationPlan.id),
              hidden:
                evaluationPlan.status === EvaluationPlanStatus.Approved ||
                evaluationPlan.status === EvaluationPlanStatus.PendingApproval,
            },
            {
              label: t('EVALUATION_PLANS.ACTIONS.DUPLICATE'),
              onClick: () => {
                onOpenDuplicateEvaluationPlan(evaluationPlan.id);
              },
            },
            {
              label: t('ACTIONS.ARCHIVE'),
              onClick: () => {
                onOpenArchiveEvaluationPlan(
                  evaluationPlan.id,
                  evaluationPlan.assignedGroups.length
                );
              },
            },
          ]}
        ></ActionMenuHorizontal>,
      ];

      return [...acc, userArray];
    }, []);
  }, [
    onOpenDenyEvaluationPlanModal,
    onOpenDuplicateEvaluationPlan,
    onOpenArchiveEvaluationPlan,
    evaluationPlans,
    t,
    navigate,
    onSubmitForApproval,
  ]);

  return (
    <div className="evaluation-plans">
      <section className="evaluation-plans__actions">
        <Button
          compact
          variant="outlined"
          onClick={onNavigateToCreateNewCriteriaMatrix}
        >
          {t('EVALUATION_PLANS.ACTIONS.CREATE_NEW_EVALUATION_PLAN')}
        </Button>
      </section>
      {isSuccess && evaluationPlans ? (
        <>
          {evaluationPlans.result.length > 0 ? (
            <Table headers={headers} rows={tableChildren} />
          ) : (
            t('EVALUATION_PLANS.TITLES.NO_EVALUATION_PLANS')
          )}
          <div className="users__pagination">
            <Pagination data={evaluationPlans} />
          </div>
        </>
      ) : (
        <TableSkeleton headers={headers} />
      )}
    </div>
  );
}
