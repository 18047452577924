import { Pagination as PaginationBase } from '@ph-react-ui/core';
import { useSearchParams } from 'react-router-dom';
import type { PaginatedList } from '../../../models/common/paginated-list.model';
interface PaginationProps<Type> {
  data: PaginatedList<Type>;
}
export function Pagination<Type>({ data }: PaginationProps<Type>) {
  const [searchParams, setSearchParams] = useSearchParams();

  const handlePageChange = (page: number) => {
    searchParams.set('page', page.toString());
    setSearchParams(searchParams);
  };
  return (
    <PaginationBase
      page={data.page}
      totalItems={data.totalCount}
      pageSize={data.size}
      onChange={handlePageChange}
    />
  );
}
