import { Button } from '@ph-react-ui/core';
import { useTranslation } from 'react-i18next';
import { useDeleteFile } from '../../../../../hooks/files/useDeleteFile';
import { Dialog } from '../../../../shared/components/Dialog';

interface DialogDeleteFileProps {
  fileId: string;
  closeModal: () => void;
  removeFile: (index?: number | number[]) => void;
  invitationId?: string;
}

export const DialogDeleteFile = ({
  fileId,
  closeModal,
  removeFile,
  invitationId,
}: DialogDeleteFileProps) => {
  const { t } = useTranslation();
  const deleteFile = useDeleteFile(invitationId);

  const onDeleteFile = async (fileId: string) => {
    await deleteFile.mutateAsync(fileId);
    closeModal();
    removeFile(+fileId);
  };

  return (
    <Dialog
      onClose={closeModal}
      dialogHeader={t('UPLOAD_FILES.TITLES.UPLOAD_DOCUMENT')}
      actionButton={
        <Button onClick={() => onDeleteFile(fileId)}>
          {t('ACTIONS.DELETE')}
        </Button>
      }
    >
      <h2>{t('UPLOAD_FILES.MESSAGES.DELETE_FILE')}</h2>
    </Dialog>
  );
};
