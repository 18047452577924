import { useTranslation } from 'react-i18next';
import { Radio, RadioGroup } from '../../../utils/components/hoc-components';
import { TransportationUsage } from '../../../utils/enums/transportation-usage.enum';

interface TravelDirectionRadioGroupProps {
  travelDirection: string | number;
}

export const TravelDirectionRadioGroup = ({
  travelDirection,
}: TravelDirectionRadioGroupProps) => {
  const { t } = useTranslation();
  return (
    <RadioGroup name="travelDirection" value={travelDirection?.toString()}>
      <Radio
        name="travelDirection"
        value={TransportationUsage.OneWay.toString()}
      >
        {t('TRANSPORTATION_MANAGEMENT.DRAWER.LABELS.ONE_WAY_USAGE')}
      </Radio>
      <Radio
        name="travelDirection"
        value={TransportationUsage.TwoWay.toString()}
      >
        {t('TRANSPORTATION_MANAGEMENT.DRAWER.LABELS.TWO_WAY_USAGE')}
      </Radio>
      <Radio
        name="travelDirection"
        value={TransportationUsage.NoUsage.toString()}
      >
        {t('TRANSPORTATION_MANAGEMENT.DRAWER.LABELS.NO_USAGE')}
      </Radio>
    </RadioGroup>
  );
};
