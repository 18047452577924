import { useMutation } from '@tanstack/react-query';
import type { EmployeeNonActiveUpdateDto } from '../../../models/users/employees/employee-non-active-update.dto';
import apiRequest from '../../../services/apiWrapper';

async function updateNonActiveEmployeeById(
  id: string | null,
  data: EmployeeNonActiveUpdateDto
) {
  return apiRequest.put(`/api/users/employees/${id}/non-active`, data);
}

export function useUpdateNonActiveEmployeeById(id: string | null) {
  return useMutation({
    mutationFn: (data: EmployeeNonActiveUpdateDto) =>
      updateNonActiveEmployeeById(id, data),
  });
}
