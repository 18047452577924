import { useQuery } from '@tanstack/react-query';
import type { StudentsAssignedToBusStopDto } from '../../../models/transportation/students/students-assigned-to-bus-stop.dto';
import apiRequest from '../../../services/apiWrapper';
import { TRANSPORTATION_BUS_STOPS_URL } from '../../../utils/constants/urlConstants';
import type { BusStopType } from '../../../utils/enums/bus-stop-type.enum';

async function getAllStudentsAssignedToBusStop(
  busStopId: string,
  busStopType: BusStopType
) {
  const response = await apiRequest.get<StudentsAssignedToBusStopDto>(
    `${TRANSPORTATION_BUS_STOPS_URL}/${busStopId}/students?busStopType=${busStopType}`
  );

  return response.data;
}

export function useGetAllStudentsAssignedToBusStop({
  busStopId,
  busStopType,
}: {
  busStopId: string;
  busStopType: BusStopType;
}) {
  return useQuery({
    queryKey: ['students-on-bus-stop', busStopId, busStopType],
    queryFn: () => getAllStudentsAssignedToBusStop(busStopId, busStopType),
  });
}
