import { Button } from '@ph-react-ui/core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export function NotFound() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onGoToMain = () => {
    navigate('');
  };
  return (
    <div className="ph-light error">
      <div className="error__header">{t('ERROR.MESSAGES.404')}</div>
      <div className="error__message">{t('ERROR.MESSAGES.PAGE_NOT_FOUND')}</div>
      <div className="error__actions">
        <Button color="primary" variant="filled" onClick={onGoToMain}>
          {t('ERROR.ACTIONS.GO_TO_MAIN')}
        </Button>
      </div>
    </div>
  );
}
