import { useTranslation } from 'react-i18next';
import { Input } from '../../../../../utils/components/hoc-components';
import type { ParentSectionType } from '../../../../../utils/enums/parent-type.enum';
import {
  getCommonValidators,
  VALIDATION as V,
} from '../../../../../utils/inputValidators';

interface BasicInfoSectionProps {
  prefix?: string;
  type?: ParentSectionType | '';
}

export function BasicInfoSection({
  prefix = '',
  type = '',
}: BasicInfoSectionProps) {
  const { t } = useTranslation();
  return (
    <>
      <div className="mb-6">
        <Input
          className="drawer__users__form__input"
          label={t(`USERS.DRAWER.LABELS.${type}NAME`)!}
          placeholder={t(`USERS.DRAWER.PLACEHOLDERS.${type}NAME`) ?? ''}
          name={`${prefix}name`}
          rules={getCommonValidators([V.REQUIRED, V.MAX_LENGTH])}
        />
      </div>

      <div className="mb-6">
        <Input
          name={`${prefix}email`}
          rules={getCommonValidators([V.REQUIRED, V.EMAIL_PATTERN])}
          label={t(`USERS.DRAWER.LABELS.${type}EMAIL`)!}
          className="drawer__users__form__input"
          placeholder={t(`USERS.DRAWER.PLACEHOLDERS.${type}EMAIL`) ?? ''}
        />
      </div>
    </>
  );
}
