import { useMutation } from '@tanstack/react-query';
import type { EmployeeCreateDto } from '../../../models/users/employees/employee-create.dto';
import apiRequest from '../../../services/apiWrapper';

interface CreateEmployeeWithInviteDto {
  data: EmployeeCreateDto;
  shouldInvite: boolean;
}

async function createEmployee(data: CreateEmployeeWithInviteDto) {
  return apiRequest.post<CreateEmployeeWithInviteDto, void>(
    '/api/users/employees',
    data
  );
}

export function useCreateEmployee() {
  return useMutation({
    mutationFn: (data: CreateEmployeeWithInviteDto) => createEmployee(data),
  });
}
