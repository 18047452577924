import { Select, Table, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useSearchParams } from 'react-router-dom';
import { useApproveStudentTransportationRequestById } from '../../../hooks/transportation/students/useApproveStudentTransportationRequest';
import { usePaginatedStudentsTransportationRequests } from '../../../hooks/transportation/students/usePaginatedStudentsTransportationRequests';
import { useModal } from '../../../hooks/useModal';
import type { StudentTransportationRequestsDto } from '../../../models/transportation/students/student-transportation-requests.dto';
import {
  TRANSPORTATION_EDIT_REQUEST,
  TRANSPORTATION_REQUEST_DENY,
} from '../../../utils/constants/transportation/modals';
import {
  BUS_STOP_STATUS,
  BUS_STOP_STATUS_OPTIONS,
  getBusStopStatus,
} from '../../../utils/constants/transportation/student-bus-stop-status';
import { TRAVEL_DIRECTION } from '../../../utils/constants/transportation/travel-direction';
import { StudentBusStopsRequestStatus } from '../../../utils/enums/student-bus-stop-request-status.enum';
import { TransportationUserManagementOrdering } from '../../../utils/enums/transportation-user-management-ordering.enum';
import { ActionMenuHorizontal } from '../../shared/components/ActionMenuHorizontal';
import { Pagination } from '../../shared/components/Pagination';
import { SearchInput } from '../../shared/components/SearchByNameInput';
import { TableSkeleton } from '../../shared/components/skeletons/TableSkeleton';
import { ThSortItem } from '../../shared/components/tables/ThSortItem';

export function StudentsTransportationPendingRequests() {
  const pathMatch = useMatch('/dashboard/transportation/students');
  const { t } = useTranslation();
  const notification = useNotifications();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const { open: openDenyTransportationRequestModal } = useModal(
    TRANSPORTATION_REQUEST_DENY
  );
  const { open: openEditTransportationRequestModal } = useModal(
    TRANSPORTATION_EDIT_REQUEST
  );
  const { data, isSuccess, isPending, isError } =
    usePaginatedStudentsTransportationRequests(searchParams);
  const approveTransportationRequest =
    useApproveStudentTransportationRequestById();

  const headers = [
    <div className="users__table__select-all">
      <ThSortItem
        title={t('USERS.TABLE.HEADERS.NAME')}
        ordering={TransportationUserManagementOrdering.Name}
      />
    </div>,
    t('TRANSPORTATION_MANAGEMENT.TABLE.HEADERS.TYPE'),
    <ThSortItem
      title={t('TRANSPORTATION_MANAGEMENT.TABLE.HEADERS.ROUTE')}
      ordering={TransportationUserManagementOrdering.Route}
    />,
    t('TRANSPORTATION_MANAGEMENT.TABLE.HEADERS.PICK_UP'),
    t('TRANSPORTATION_MANAGEMENT.TABLE.HEADERS.DROP_OFF'),
    t('TRANSPORTATION_MANAGEMENT.TABLE.HEADERS.STATUS'),
    <ThSortItem
      title={t('TRANSPORTATION_MANAGEMENT.TABLE.HEADERS.REQUESTED_ON')}
      ordering={TransportationUserManagementOrdering.RequestedAt}
    />,
    t('TRANSPORTATION_MANAGEMENT.TABLE.HEADERS.ACTION'),
  ];

  const getFullBusStopName = (
    transportation: StudentTransportationRequestsDto
  ) => {
    let fullBusName = '';
    if (transportation.routeName) {
      fullBusName += transportation.routeName;
    }
    if (transportation.busNumber) {
      fullBusName += ', ' + transportation.busNumber;
    }
    return fullBusName;
  };

  const onFilterByStatusChanged = (
    value: string | number | (string | number)[] | null
  ) => {
    if (value) {
      searchParams.set(
        'status',
        typeof value === 'string' ? value : value?.toString()
      );
      searchParams.set('page', '1');
      setSearchParams(searchParams);
    } else {
      searchParams.delete('status');
      setSearchParams(searchParams);
    }
  };

  const onApproveTransportationRequest = useCallback(
    (id: string) => {
      approveTransportationRequest
        .mutateAsync(id)
        .then(() => {
          notification.success(
            t('TRANSPORTATION_MANAGEMENT.MESSAGES.SUCCESSFULLY_APPROVED')
          );
          queryClient.invalidateQueries({
            queryKey: ['transportation-requests'],
          });
        })
        .catch((resError) => {
          notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
        });
    },
    [approveTransportationRequest, notification, queryClient, t]
  );

  const onDenyTransportationRequest = useCallback(
    (id: string) => {
      openDenyTransportationRequestModal({
        id,
      });
    },
    [openDenyTransportationRequestModal]
  );

  const onEditTransportationRequest = useCallback(
    (id: string) => {
      openEditTransportationRequestModal({
        requestId: id,
      });
    },
    [openEditTransportationRequestModal]
  );

  const tableChildren = useMemo(() => {
    return data?.result.reduce((acc: any, transportationUsage) => {
      let userArray = [
        transportationUsage.student.name,
        transportationUsage.travelDirection
          ? t(
              `TRANSPORTATION_MANAGEMENT.TABLE.LABELS.${
                TRAVEL_DIRECTION[transportationUsage.travelDirection]
              }`
            )
          : '',
        getFullBusStopName(transportationUsage),
        transportationUsage.pickUpAddress,
        transportationUsage.dropOffAddress,
        getBusStopStatus(BUS_STOP_STATUS[transportationUsage.status]),
        transportationUsage.requestedAt
          ? format(new Date(transportationUsage.requestedAt), 'dd.MM.yyyy')
          : '',
        <div className="taxes__food__table__actions">
          {transportationUsage.status !==
            StudentBusStopsRequestStatus.NoTransportation && (
            <ActionMenuHorizontal
              options={[
                {
                  label: t('ACTIONS.EDIT'),
                  onClick: () => {
                    onEditTransportationRequest(transportationUsage.requestId);
                  },
                },
                {
                  label: t('ACTIONS.APPROVE'),
                  onClick: () => {
                    onApproveTransportationRequest(
                      transportationUsage.requestId
                    );
                  },
                },
                {
                  label: t('ACTIONS.DENY'),
                  onClick: () => {
                    onDenyTransportationRequest(transportationUsage.requestId);
                  },
                },
              ]}
            />
          )}
        </div>,
      ];

      return [...acc, userArray];
    }, []);
  }, [
    data?.result,
    t,
    onEditTransportationRequest,
    onApproveTransportationRequest,
    onDenyTransportationRequest,
  ]);

  return (
    <div className="transportation-management__users">
      <div className="taxes__food__filter">
        <SearchInput
          pathMatch={pathMatch}
          placeholder={t('TAXES.FILTERS.SEARCH_BY_NAME')}
        />
        <Select
          compact
          options={BUS_STOP_STATUS_OPTIONS}
          clearable
          placeholder={
            t('TRANSPORTATION_MANAGEMENT.TABLE.FILTERS.FILTER_BY_STATUS') ?? ''
          }
          onChange={onFilterByStatusChanged}
          value={searchParams.get('status') ?? ''}
        />
      </div>

      {isSuccess && data.result && (
        <>
          <Table headers={headers} rows={tableChildren} />
          <div className="users__pagination">
            <Pagination data={data} />
          </div>
        </>
      )}
      {isError && t('TAXES.MESSAGES.NO_FOOD_TAXES')}
      {isPending && <TableSkeleton headers={headers} />}
    </div>
  );
}
