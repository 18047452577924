import { useMutation } from '@tanstack/react-query';
import type { EvaluationPlansCreateWithSubmitForApprovalDto } from '../../models/evaluation-plans/evaluation-plans-create.dto';
import apiRequest from '../../services/apiWrapper';
import { EVALUATION_PLANS_URL } from '../../utils/constants/urlConstants';

async function createEvaluationPlan({
  data,
  submitForApproval,
}: EvaluationPlansCreateWithSubmitForApprovalDto) {
  const queryParam = submitForApproval ? 'shouldSubmit=true' : '';
  return apiRequest.post(`${EVALUATION_PLANS_URL}?${queryParam}`, data);
}

export function useCreateEvaluationPlan() {
  return useMutation({
    mutationFn: ({
      data,
      submitForApproval,
    }: EvaluationPlansCreateWithSubmitForApprovalDto) =>
      createEvaluationPlan({ data, submitForApproval }),
  });
}
