import { Edit } from '@carbon/icons-react';
import { Button, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEditBusRouteMap } from '../../../hooks/transportation/routes/useAddBusRoutesMap';
import { useGetBusRoutes } from '../../../hooks/transportation/routes/useGetBusRoutes';
import { useGetBusRoutesMap } from '../../../hooks/transportation/routes/useGetBusRoutesMap';
import { useModal } from '../../../hooks/useModal';
import type { BusStopDTO } from '../../../models/transportation/bus-stop.dto';
import type { BusRouteMapDTO } from '../../../models/transportation/routes/bus-routes-map';
import { formatTimeWithoutSeconds } from '../../../services/mappingsSimpleTime';
import { TRANSPORTATION_ROUTES_MAP_EXTENSIONS } from '../../../utils/constants/files/filesConstants';
import {
  CREATE_EDIT_ROUTE_MODAL,
  DELETE_ROUTE_MODAL,
  BUS_STOP_DRAWER,
} from '../../../utils/constants/transportation/modals';

import { BusStopType } from '../../../utils/enums/bus-stop-type.enum';
import { ActionMenuHorizontal } from '../../shared/components/ActionMenuHorizontal';
import { SimpleFileUploadModal } from '../../shared/components/SimpleFileUploadModal';

export function TransportationRoutesManagement() {
  const { t } = useTranslation();
  const notification = useNotifications();
  const queryClient = useQueryClient();
  const { open: openCreateEditRouteModal } = useModal(CREATE_EDIT_ROUTE_MODAL);
  const { open: openDeleteRouteModal } = useModal(DELETE_ROUTE_MODAL);
  const { open: openBusStopDrawer } = useModal(BUS_STOP_DRAWER);

  const [isFileModalOpened, setFileModalOpened] = useState(false);

  const { data: busRoutes } = useGetBusRoutes();
  const { data: busRoutesMap, isSuccess } = useGetBusRoutesMap();
  const updateBusRoutesMap = useEditBusRouteMap();

  const openCreateRouteDrawer = () => {
    openCreateEditRouteModal();
  };

  const uploadMap = () => {
    setFileModalOpened(true);
  };

  const onOpenRouteDetailsPage = useCallback(
    (routeId: string) => {
      openCreateEditRouteModal({
        routeId: routeId,
      });
    },
    [openCreateEditRouteModal]
  );

  const onOpenDeleteRouteModal = useCallback(
    (routeId: string) => {
      openDeleteRouteModal({
        routeId: routeId,
      });
    },
    [openDeleteRouteModal]
  );

  const onOpenBusStopDetailsDrawer = ({
    busStopId,
    busStopType,
  }: {
    busStopId: string;
    busStopType: BusStopType;
  }) => {
    openBusStopDrawer({ busStopId, busStopType: busStopType.toString() });
  };

  const handleMapUpload = async (fileId: string) => {
    const updateData = {
      fileId: fileId,
    } as BusRouteMapDTO;

    await updateBusRoutesMap
      .mutateAsync(updateData)
      .then(() => {
        notification.success(
          t('TRANSPORTATION_MANAGEMENT.MESSAGES.MAP_UPLOAD_SUCCESS')
        );
        queryClient.invalidateQueries({ queryKey: ['bus-routes-map'] });
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  return (
    <div className="transportation-management">
      <div className="transportation-management__actions">
        <Button compact variant="outlined" onClick={openCreateRouteDrawer}>
          {t('TRANSPORTATION_MANAGEMENT.ACTIONS.ADD_ROUTE')}
        </Button>
      </div>
      <div className="transportation-management__routes">
        {busRoutes?.result.map((route, index) => (
          <div className="transportation-management__routes__route" key={index}>
            <div className="transportation-management__routes__route__header">
              <div className="transportation-management__routes__route__header__name">
                <h3>
                  {route.name}, {t('TRANSPORTATION_MANAGEMENT.MESSAGES.BUS')}
                  &nbsp;
                  {route.busNumber}
                </h3>
              </div>
              <div className="transportation-management__route__header__actions">
                <ActionMenuHorizontal
                  options={[
                    {
                      label: t('ACTIONS.EDIT'),
                      onClick: () => onOpenRouteDetailsPage(route.id),
                    },
                    {
                      label: t('ACTIONS.DELETE'),
                      onClick: () => onOpenDeleteRouteModal(route.id),
                    },
                  ]}
                />
              </div>
            </div>
            {route?.busStops.map((busStop: BusStopDTO, index) => (
              <div
                className="transportation-management__routes__route__details"
                key={index}
              >
                <div className="transportation-management__routes__route__details__stop-name">
                  {busStop.address}
                </div>
                <div className="transportation-management__routes__route__details__stop-details">
                  <div className="transportation-management__routes__route__details__stop-details__pickup-time">
                    <h4>
                      {t('TRANSPORTATION_MANAGEMENT.DRAWER.LABELS.PICKUP_TIME')}
                      &nbsp;
                      {formatTimeWithoutSeconds(busStop.timeOfPickUp)}
                    </h4>
                    <div className="transportation-management__routes__route__details__stop-details__pickup-time__students">
                      <h4>
                        {busStop.pickUpStudentsCount}{' '}
                        {t('TRANSPORTATION_MANAGEMENT.LABELS.STUDENTS')}
                      </h4>
                      <Button
                        variant="link"
                        type="button"
                        onClick={() =>
                          onOpenBusStopDetailsDrawer({
                            busStopId: busStop.id,
                            busStopType: BusStopType.PickUp,
                          })
                        }
                      >
                        <Edit />
                      </Button>
                    </div>
                  </div>
                  <div className="transportation-management__routes__route__details__stop-details__dropoff-time">
                    <h4>
                      {t(
                        'TRANSPORTATION_MANAGEMENT.DRAWER.LABELS.DROPOFF_TIME'
                      )}
                      &nbsp;
                      {formatTimeWithoutSeconds(busStop.timeOfDropOff)}
                    </h4>
                    <div className="transportation-management__routes__route__details__stop-details__dropoff-time__students">
                      <h4>
                        {busStop.dropOffStudentsCount}{' '}
                        {t('TRANSPORTATION_MANAGEMENT.LABELS.STUDENTS')}
                      </h4>
                      <Button
                        variant="link"
                        type="button"
                        onClick={() =>
                          onOpenBusStopDetailsDrawer({
                            busStopId: busStop.id,
                            busStopType: BusStopType.DropOFF,
                          })
                        }
                      >
                        <Edit />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="transportation-management__map">
        <Button compact variant="outlined" onClick={uploadMap}>
          {t('TRANSPORTATION_MANAGEMENT.ACTIONS.ADD_MAP')}
        </Button>
        {isSuccess && busRoutesMap.uri && (
          <div className="transportation-management__map__image">
            <iframe title="transportation-plan" src={busRoutesMap.uri}></iframe>
          </div>
        )}
      </div>

      {isFileModalOpened && (
        <SimpleFileUploadModal
          allowedTypes=".pdf"
          header={t('TRANSPORTATION_MANAGEMENT.MESSAGES.MAP_UPLOAD')}
          onCloseModal={() => setFileModalOpened(false)}
          onUpload={handleMapUpload}
          withPreview={false}
          allowedTypesMessage={t('UPLOAD_FILES.MESSAGES.ALLOWED_TYPES_PDF')}
          fileMaxSize={t('UPLOAD_FILES.MESSAGES.MAX_FILE_SIZE')}
          validFileExtensions={TRANSPORTATION_ROUTES_MAP_EXTENSIONS}
        />
      )}
    </div>
  );
}
