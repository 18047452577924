import { useQuery } from '@tanstack/react-query';
import type { PaginatedList } from '../../models/common/paginated-list.model';
import type { GroupsDto } from '../../models/groups/groups.dto';
import apiRequest from '../../services/apiWrapper';
import { createPaginatedQueryKeys } from '../../services/createPaginatedQueryKeys';
import { GROUPS_URL } from '../../utils/constants/urlConstants';

async function fetchGroups(searchParams: URLSearchParams) {
  const response = await apiRequest.get<PaginatedList<GroupsDto>>(
    `${GROUPS_URL}?${searchParams.toString()}`
  );
  return response.data;
}

function createQueryKey(searchParams: URLSearchParams) {
  return {
    name: searchParams.get('name'),
    ...createPaginatedQueryKeys(searchParams),
  };
}

export function usePaginatedGroups(searchParams: URLSearchParams) {
  return useQuery({
    queryKey: ['groups', createQueryKey(searchParams)],
    queryFn: () => fetchGroups(searchParams),
  });
}
