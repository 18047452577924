import type { SelectOption } from '@ph-react-ui/core';
import { Button, Select } from '@ph-react-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetEvaluationPlansPerStudent } from '../../../hooks/evaluation-plans/useGetEvaluationPlansPerStudent';
import { NavigateBack } from '../../shared/components/NavigateBack';

export function SelectEvaluationPlan() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [evaluationPlans, setEvaluationPlans] = useState<
    SelectOption<string>[]
  >([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const studentId = searchParams.get('studentId') ?? '';
  const studentName = searchParams.get('name') ?? '';
  const evaluationPlanId = searchParams.get('epId') ?? null;

  const { data: evaluationPlansPerStudent, isSuccess } =
    useGetEvaluationPlansPerStudent(studentId!);

  const onSelectPlan = (value: string | null) => {
    if (value) {
      searchParams.set('epId', value.toString());
      setSearchParams(searchParams);
    }
  };

  const handleNextClick = () => {
    if (evaluationPlanId) {
      navigate(
        `./${evaluationPlanId}?name=${studentName}&studentId=${studentId}`
      );
    }
  };

  useEffect(() => {
    const evaluationPlans = (evaluationPlansPerStudent?.plans || []).map(
      (evaluationPlansPerStudent) => ({
        label: evaluationPlansPerStudent.name,
        value: evaluationPlansPerStudent.id,
      })
    );

    setEvaluationPlans(evaluationPlans);
  }, [evaluationPlansPerStudent, searchParams, setSearchParams]);

  return (
    <div className="select-evaluation-plan">
      <div className="select-evaluation-plan__actions">
        <NavigateBack
          onNavigate={() => navigate('../students')}
          text={t('EVALUATION_PLANS.MESSAGES.GO_BACK')}
        />
      </div>
      <div className="select-evaluation-plan__container">
        <div className="select-evaluation-plan__container__dropdown">
          {studentName && (
            <div className="select-evaluation-plan__container__dropdown__name">
              <h4>{`${t(
                'EVALUATION_PLANS.MESSAGES.EVALUATION_OF'
              )} ${studentName}`}</h4>
            </div>
          )}
          {isSuccess && evaluationPlans.length ? (
            <div className="select-evaluation-plan__container__dropdown__pick">
              <Select
                placeholder={
                  t('EVALUATION_PLANS.ACTIONS.SELECT_EVALUATION_PLAN') ?? ''
                }
                label={
                  t('EVALUATION_PLANS.ACTIONS.SELECT_EVALUATION_PLAN') ?? ''
                }
                compact
                options={evaluationPlans}
                multi={false}
                onChange={(value) => onSelectPlan(value)}
              />
            </div>
          ) : (
            <h4 className="select-evaluation-plan__container__dropdown__text">
              No assigned evaluation plans
            </h4>
          )}
        </div>
        <div className="select-evaluation-plan__container__next">
          <Button
            color="primary"
            variant="outlined"
            onClick={handleNextClick}
            disabled={!evaluationPlanId ? true : false}
          >
            {t('ACTIONS.NEXT')}
          </Button>
        </div>
      </div>
    </div>
  );
}
