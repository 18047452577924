import { useMutation } from '@tanstack/react-query';
import type { BusRouteDTO } from '../../../models/transportation/bus-route.dto';
import apiRequest from '../../../services/apiWrapper';
import { BUS_ROUTES_URL } from '../../../utils/constants/urlConstants';

async function createBusRoute(routeData: BusRouteDTO) {
  return apiRequest.post<BusRouteDTO, void>(BUS_ROUTES_URL, routeData);
}

export function useCreateBusRoute() {
  return useMutation({
    mutationFn: (routeData: BusRouteDTO) => createBusRoute(routeData),
  });
}
