import i18n from 'i18next';
import { DocumentType } from '../../enums/document-type.enum';

export function getDocumentType(key: string) {
  return i18n.t(key);
}

export const DOCUMENT_KEY = {
  [DocumentType.Any]: 'USERS.DRAWER.DOCUMENTS.TYPE.OTHER',
  [DocumentType.ProfilePicture]: 'USERS.DRAWER.DOCUMENTS.TYPE.PROFILE_PICTURE',
  [DocumentType.Diploma]: 'USERS.DRAWER.DOCUMENTS.TYPE.DIPLOMA',
  [DocumentType.MedicalCondition]:
    'USERS.DRAWER.DOCUMENTS.TYPE.MEDICAL_CONDITION',
  [DocumentType.BirthCertificate]:
    'USERS.DRAWER.DOCUMENTS.TYPE.BIRTH_CERTIFICATE',
  [DocumentType.Relocation]: 'USERS.DRAWER.DOCUMENTS.TYPE.RELOCATION',
  [DocumentType.MealMenu]: 'USERS.DRAWER.DOCUMENTS.TYPE.MEAL_MENU',
};
