import { useTranslation } from 'react-i18next';
import { Input } from '../../../../utils/components/hoc-components';
import {
  getCommonValidators,
  VALIDATION as V,
} from '../../../../utils/inputValidators';

interface PasswordSectionProps {
  passwordWatcher: string;
  prefix?: string;
}

export function PasswordSection({
  passwordWatcher,
  prefix = '',
}: PasswordSectionProps) {
  const { t } = useTranslation();
  return (
    <>
      <div className="mb-6">
        <Input
          name={`${prefix}newPassword`}
          rules={getCommonValidators([V.REQUIRED, V.PASSWORD_PATTERN])}
          inputType="password"
          label={t('ADMISSION.COMPLETE_PROFILE.LABELS.NEW_PASSWORD')!}
          placeholder={
            t('ADMISSION.COMPLETE_PROFILE.PLACEHOLDERS.NEW_PASSWORD') ?? ''
          }
          autocomplete="off"
        />
      </div>
      <div className="mb-6">
        <Input
          name={`${prefix}confirmationPassword`}
          rules={{
            ...getCommonValidators([V.REQUIRED]),
            validate: {
              value: (passwordConfirmation) => {
                return (
                  (passwordConfirmation === passwordWatcher ||
                    t(
                      'ADMISSION.COMPLETE_PROFILE.ERRORS.PASSWORDS_DO_NOT_MATCH'
                    )) ??
                  ''
                );
              },
            },
          }}
          inputType="password"
          placeholder={
            t(
              'ADMISSION.COMPLETE_PROFILE.PLACEHOLDERS.CONFIRMATION_PASSWORD'
            ) ?? ''
          }
          label={t('ADMISSION.COMPLETE_PROFILE.LABELS.CONFIRMATION_PASSWORD')!}
        />
      </div>
    </>
  );
}
