import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Bulgarian from './bg.json';
import English from './en.json';

export enum SupportedLanguage {
  EN = 'en',
  BG = 'bg',
}

i18n.use(initReactI18next).init({
  lng: import.meta.env.VITE_TRANSLATIONS_LANGUAGE,
  fallbackLng: 'en',
  resources: {
    [SupportedLanguage.EN]: {
      translation: English,
    },
    [SupportedLanguage.BG]: {
      translation: Bulgarian,
    },
  },
});

export default i18n;
