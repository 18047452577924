import { Button, Dialog as BaseDialog } from '@ph-react-ui/core';
import type { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

interface BaseDialogProps extends PropsWithChildren {
  dialogHeader?: string | null;
  className?: string;
  actionButton?: JSX.Element | null;
  onClose: () => void;
}

export const Dialog = ({
  dialogHeader = '',
  className = 'dialog__confirm',
  actionButton,
  children,
  onClose,
}: BaseDialogProps) => {
  const { t } = useTranslation();
  return (
    <BaseDialog className={className} onClose={onClose} header={dialogHeader}>
      <div className="dialog__confirm__content">{children}</div>
      <div
        className={`dialog__confirm__footer ${
          !actionButton ? 'align-right' : ''
        }`}
      >
        <Button type="button" variant="outlined" onClick={onClose}>
          {t('ACTIONS.CANCEL')}
        </Button>
        {actionButton && (
          <div className="dialog__confirm__footer__actions">{actionButton}</div>
        )}
      </div>
    </BaseDialog>
  );
};
