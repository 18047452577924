import { useMutation } from '@tanstack/react-query';
import type { TermReportDenyDto } from '../../../../models/term-reports/term-report-deny.dto';
import apiRequest from '../../../../services/apiWrapper';
import { TERM_REPORT_SECTIONS_URL } from '../../../../utils/constants/urlConstants';

async function commentTermReportSection(data: TermReportDenyDto) {
  return apiRequest.put(`${TERM_REPORT_SECTIONS_URL}/${data.id}/comment`, {
    comment: data.comment,
  });
}

export function useCommentTermReportSection() {
  return useMutation({
    mutationFn: (data: TermReportDenyDto) => commentTermReportSection(data),
  });
}
