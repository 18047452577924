import { Select, Table, type SelectOption } from '@ph-react-ui/core';
import { format } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useGetAuthenticatedParentProfile } from '../../../hooks/profile/parent/useGetAuthenticatedParentProfile';
import { usePaginatedStudentTermReportsById } from '../../../hooks/term-reports/usePaginatedStudentTermReportsById';
import { useModal } from '../../../hooks/useModal';
import { useGetStudentsForParent } from '../../../hooks/users/parents/useGetStudentsForParent';
import { VIEW_TERM_REPORT } from '../../../utils/term-reports/modals';
import { ActionMenuHorizontal } from '../../shared/components/ActionMenuHorizontal';
import { Pagination } from '../../shared/components/Pagination';
import { TableSkeleton } from '../../shared/components/skeletons/TableSkeleton';

export function ParentTermReports() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { open: openTermReportModal } = useModal(VIEW_TERM_REPORT);

  const [studentOptions, setStudentOptions] = useState<SelectOption<string>[]>(
    []
  );

  const studentIdParam =
    searchParams.get('studentId') ?? studentOptions?.[0]?.value;

  const { data: parent } = useGetAuthenticatedParentProfile();
  const { data: students } = useGetStudentsForParent({
    enabled: Boolean(parent),
  });

  const { data: termReports, isSuccess } = usePaginatedStudentTermReportsById(
    studentIdParam,
    searchParams
  );

  useEffect(() => {
    const studentOptions = (students || []).map((student) => ({
      label: student.name,
      value: student.id,
    }));

    setStudentOptions(studentOptions);
  }, [students, searchParams, setSearchParams]);

  const headers = [
    t('TERM_REPORTS.TABLE.HEADERS.NAME'),
    t('TERM_REPORTS.TABLE.HEADERS.ISSUE_DATE'),
    t('TERM_REPORTS.TABLE.HEADERS.ACTIONS'),
  ];

  const onOpenTermReportModal = useCallback(
    (id: string) => {
      openTermReportModal({ termReportId: id, studentId: studentIdParam });
    },
    [openTermReportModal, studentIdParam]
  );

  const tableChildren = useMemo(() => {
    return termReports?.result.reduce((acc: any, termReport) => {
      let termReportArray = [
        termReport.title,
        format(new Date(termReport?.approvedAt), 'dd.MM.yyyy'),
        <ActionMenuHorizontal
          options={[
            {
              label: t('TERM_REPORTS.TABLE.ACTIONS.VIEW'),
              onClick: () => onOpenTermReportModal(termReport.id),
            },
          ]}
        />,
      ];

      return [...acc, termReportArray];
    }, []);
  }, [termReports, t, onOpenTermReportModal]);

  const onSelectStudent = (
    value: string | number | (string | number)[] | null
  ) => {
    if (value) {
      searchParams.set('studentId', value.toString());
      setSearchParams(searchParams);
    }
  };

  return (
    <div className="term-reports">
      <div className="term-reports__actions">
        <Select
          placeholder={t('TERM_REPORTS.MESSAGES.CHOOSE_STUDENT') ?? ''}
          label={t('TERM_REPORTS.MESSAGES.CHOOSE_STUDENT') ?? ''}
          compact
          options={studentOptions}
          value={studentIdParam}
          onChange={(value) => onSelectStudent(value)}
        />
      </div>
      <div>
        {isSuccess && termReports ? (
          <>
            <Table headers={headers} rows={tableChildren} />
            <div className="users__pagination">
              <Pagination data={termReports} />
            </div>
          </>
        ) : (
          <TableSkeleton headers={headers} />
        )}
      </div>
    </div>
  );
}
