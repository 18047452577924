import { useMutation } from '@tanstack/react-query';
import type { GroupCreateEditDto } from '../../models/groups/group-create-edit.dto';
import apiRequest from '../../services/apiWrapper';
import { GROUPS_URL } from '../../utils/constants/urlConstants';

interface CreateGroupResponse {
  id: string;
}

async function createGroup(group: GroupCreateEditDto) {
  return apiRequest.post<GroupCreateEditDto, CreateGroupResponse>(
    GROUPS_URL,
    group
  );
}

export function useCreateGroup() {
  return useMutation({
    mutationFn: (group: GroupCreateEditDto) => createGroup(group),
  });
}
