import { useQuery } from '@tanstack/react-query';
import type { StudentTermReportDetails } from '../../models/term-reports/student-term-report-details.dto';
import apiRequest from '../../services/apiWrapper';

async function getStudentTermReportByIds(
  studentId: string | null,
  termReportId: string | null
) {
  const response = await apiRequest.get<StudentTermReportDetails>(
    `/api/students/${studentId}/term-reports/${termReportId}`
  );

  return response.data;
}
export function useGetStudentTermReportByIds({
  studentId,
  termReportId,
}: {
  studentId: string | null;
  termReportId: string | null;
}) {
  return useQuery({
    queryKey: ['student-term-report', studentId, termReportId],
    queryFn: () => getStudentTermReportByIds(studentId, termReportId),
    enabled: Boolean(studentId && termReportId),
  });
}
