import { useQuery } from '@tanstack/react-query';
import type { EvaluationPlansDetailsBaseDTO } from '../../models/evaluation-plans/evaluation-plans-details.dto';
import apiRequest from '../../services/apiWrapper';
import { EVALUATIONS_URL } from '../../utils/constants/urlConstants';

async function getEvaluationPlanByIdForEvaluation(id: string | undefined) {
  const response = await apiRequest.get<EvaluationPlansDetailsBaseDTO>(
    `${EVALUATIONS_URL}/plans/${id}`
  );

  return response.data;
}

export function useGetEvaluationPlanByIdForEvaluation(id: string | undefined) {
  return useQuery({
    queryKey: ['evaluation-plans-for-evaluation', id],
    queryFn: () => getEvaluationPlanByIdForEvaluation(id),
    enabled: Boolean(id),
  });
}
