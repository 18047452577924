import { useMutation } from '@tanstack/react-query';
import type { EmployeeAbsenceCreateDto } from '../../../models/meal-menus/admin/employee-absence-create.dto';
import apiRequest from '../../../services/apiWrapper';
import { EMPLOYEES_ABSENCE_URL } from '../../../utils/constants/urlConstants';

async function createGroup(data: EmployeeAbsenceCreateDto) {
  return apiRequest.post<EmployeeAbsenceCreateDto, {}>(
    EMPLOYEES_ABSENCE_URL,
    data
  );
}

export function useCreateEmployeeAbsence() {
  return useMutation({
    mutationFn: (data: EmployeeAbsenceCreateDto) => createGroup(data),
  });
}
