export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{12,64}$/; // 1 lower, 1 capital, 1 special symbol, 1 number, between 12 and 64 characters
export const NUMBER_ONLY_REGEX = /^(0|[1-9]\d*)(\.\d+)?$/;
export const IBAN_REGEX =
  /^[A-Za-z]{2}\s*[0-9]{2}\s*[A-Za-z]{4}\s*[0-9]{4}\s*[0-9]{2}[A-Za-z0-9]{2}\s*[A-Za-z0-9]{4}\s*[A-Za-z0-9]{2}$/;
export const LOWERCASE_REGEX = /[a-z]/g;
export const UPPERCASE_REGEX = /[A-Z]/g;
export const NUMBER_REGEX = /(0|[1-9])/g;
export const SPECIAL_CHAR_REGEX = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g;
