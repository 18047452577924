import { useQuery } from '@tanstack/react-query';
import type { PaginatedList } from '../../../../../models/common/paginated-list.model';
import type { HistoryOfSchoolTaxesPaymentsDto } from '../../../../../models/taxes/school/history-of-school-taxes-payments.dto';
import apiRequest from '../../../../../services/apiWrapper';
import { SCHOOL_TAX_PAYMENT_HISTORY_URL } from '../../../../../utils/constants/urlConstants';

export async function fetchInstallmentsByStudent(studentName: string) {
  const response = await apiRequest.get<
    PaginatedList<HistoryOfSchoolTaxesPaymentsDto>
  >(`${SCHOOL_TAX_PAYMENT_HISTORY_URL}?size=9999&name=${studentName}`);

  return response.data;
}

export function useGetInstallmentsByStudent(studentName: string) {
  return useQuery({
    queryKey: ['installments', studentName],
    queryFn: () => fetchInstallmentsByStudent(studentName),
    enabled: studentName !== '',
  });
}
