export const MAX_DOCUMENTS_FILE_SIZE = 4;
export const MAX_IMAGES_FILE_SIZE = 2;
export const DOCUMENTS_ALLOWED_EXTENSIONS = [
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
  'pdf',
  'txt',
  'jpeg',
  'jpg',
  'png',
];
export const IMAGES_ALLOWED_EXTENSIONS = ['jpeg', 'jpg', 'png'];

export const FOOD_MENU_EXTENSIONS = ['pdf'];
export const TRANSPORTATION_ROUTES_MAP_EXTENSIONS = ['pdf'];
