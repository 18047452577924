import { useMutation } from '@tanstack/react-query';
import apiRequest from '../../../services/apiWrapper';
import { BUS_ROUTES_URL } from '../../../utils/constants/urlConstants';

async function deleteBusRoute(routeId: string) {
  return apiRequest.delete(`${BUS_ROUTES_URL}/${routeId}`, {});
}

export function useDeleteBusRoute() {
  return useMutation({
    mutationFn: (routeId: string) => deleteBusRoute(routeId),
  });
}
