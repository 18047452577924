import { useMutation } from '@tanstack/react-query';
import apiRequest from '../../services/apiWrapper';
import { USERS_PROFILES_URL } from '../../utils/constants/urlConstants';

interface ArchiveUsersDto {
  userIds: string[];
  includeRelatives: boolean;
}

async function archiveUsers(userIds: string[], includeRelatives: boolean) {
  return apiRequest.put<ArchiveUsersDto, void>(
    `${USERS_PROFILES_URL}/archive`,
    {
      userIds,
      includeRelatives,
    }
  );
}

export function useArchiveUsers() {
  return useMutation({
    mutationFn: ({ userIds, includeRelatives }: ArchiveUsersDto) =>
      archiveUsers(userIds, includeRelatives),
  });
}
