import axios from 'axios';
import type { AxiosResponse, AxiosRequestConfig } from 'axios';
import type { ApiRequest } from '../models/auth/api-wrapper';
import { isTokenExpired } from '../utils/auth/tokenUtils';
import { LOGOUT_URL, REFRESH_TOKEN } from '../utils/constants/urlConstants';
import { clearLocalStorage } from '../utils/localStorage';
import { createAPIError } from './createApiError';
import { mapEmptyStringToNull } from './mapEmptyStringToNull';

export const axiosApiInstance = axios.create({
  baseURL: import.meta.env.VITE_REACT_APP_API,
});

axiosApiInstance.interceptors.request.use(
  async (config) => {
    let accessToken = localStorage.getItem('accessToken');
    let refreshToken = localStorage.getItem('refreshToken');
    let expires = Number(localStorage.getItem('expires') || 0);
    if (!accessToken || !refreshToken) {
      return config;
    }

    if (isTokenExpired(expires) && !config.url?.includes(LOGOUT_URL)) {
      try {
        const { data } = await axios.post(REFRESH_TOKEN, {
          accessToken,
          refreshToken,
        });
        accessToken = data.accessToken;
        refreshToken = data.refreshToken;
        expires = data.expires;
        localStorage.setItem('accessToken', data.accessToken);
        localStorage.setItem('refreshToken', data.refreshToken);
        localStorage.setItem('expires', data.expires.toString());
        localStorage.setItem('role', data.role.toString());
      } catch (error) {
        clearLocalStorage();
        return Promise.reject(error);
      }
    }
    config.headers.set(
      'Content-Type',
      config.headers['Content-Type'] ?? 'application/json'
    );
    config.headers.set('Authorization', `Bearer ${accessToken}`);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const apiRequest: ApiRequest = {
  get: async (url: string, options?: AxiosRequestConfig) => {
    try {
      return await axiosApiInstance.get(url, options);
    } catch (error) {
      throw createAPIError(error);
    }
  },
  post: async <TRequest, TResponse>(
    url: string,
    body: TRequest,
    options?: AxiosRequestConfig<any>
  ) => {
    body = body instanceof FormData ? body : mapEmptyStringToNull(body);

    try {
      return await axiosApiInstance.post<TRequest, AxiosResponse<TResponse>>(
        url,
        body,
        options
      );
    } catch (error: any) {
      throw createAPIError(error);
    }
  },
  put: async (url: string, body: any, options?: AxiosRequestConfig<any>) => {
    body = body instanceof FormData ? body : mapEmptyStringToNull(body);

    try {
      return await axiosApiInstance.put(url, body, options);
    } catch (error) {
      throw createAPIError(error);
    }
  },
  patch: async (url: string, body?: any, options?: AxiosRequestConfig<any>) => {
    body = body instanceof FormData ? body : mapEmptyStringToNull(body);

    try {
      return await axiosApiInstance.patch(url, body, options);
    } catch (error) {
      throw createAPIError(error);
    }
  },
  delete: async (url: string) => {
    try {
      return await axiosApiInstance.delete(url);
    } catch (error) {
      throw createAPIError(error);
    }
  },
};

export default apiRequest;
