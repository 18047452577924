import { useQuery } from '@tanstack/react-query';
import type { EmployeesDetailsDto } from '../../models/term-reports/term-reports-details.dto';
import apiRequest from '../../services/apiWrapper';
import { TERM_REPORTS_URL } from '../../utils/constants/urlConstants';

async function fetchAssignableEmployees() {
  const response = await apiRequest.get<EmployeesDetailsDto>(
    `${TERM_REPORTS_URL}/employees`
  );
  return response.data;
}

export function useGetAssignableEmployeesForTermReports() {
  return useQuery({
    queryKey: ['term-reports-employees'],
    queryFn: () => fetchAssignableEmployees(),
  });
}
