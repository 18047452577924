import { t } from 'i18next';
import { useSearchParams } from 'react-router-dom';
import { useGetStudentTermReportByIds } from '../../../../hooks/term-reports/useGetStudentTermReportByIds';
import { useModal } from '../../../../hooks/useModal';
import { VIEW_TERM_REPORT } from '../../../../utils/term-reports/modals';
import { Dialog } from '../../../shared/components/Dialog';

export function TermReportModal() {
  const [searchParams] = useSearchParams();
  const studentId = searchParams.get('studentId');
  const termReportId = searchParams.get('termReportId');
  const { data, isLoading } = useGetStudentTermReportByIds({
    studentId,
    termReportId,
  });
  const { close: onClose } = useModal(VIEW_TERM_REPORT);

  return (
    <Dialog
      dialogHeader={
        isLoading
          ? t('TERM_REPORTS.TABLE.LABELS.VIEW')
          : `${data?.studentName} | ${data?.title}`
      }
      onClose={onClose}
      className="dialog__term-report"
    >
      <div className="dialog__term-report__content">
        <div className="dialog__term-report__content__section">
          {data?.introContent}
        </div>
        {data?.sections.map((section, index) => (
          <div className="dialog__term-report__content__section" key={index}>
            {section.content}
          </div>
        ))}
        <div className="dialog__term-report__content__section">
          {data?.outroContent}
        </div>
      </div>
    </Dialog>
  );
}
