import { Skeleton } from '@ph-react-ui/core';

export function FormSkeleton() {
  return (
    <div className="skeletons__form">
      <div className="skeletons__form__row">
        <div className="skeletons__form__row__item">
          <Skeleton borderRadius="medium" height="20px" width="40%" />
          <Skeleton borderRadius="medium" height="16px" width="30%" />
          <Skeleton borderRadius="large" height="50px" width="90%" />
        </div>
        <div className="skeletons__form__row__item">
          <Skeleton borderRadius="medium" height="20px" width="40%" />
          <Skeleton borderRadius="medium" height="16px" width="30%" />
          <Skeleton borderRadius="large" height="50px" width="90%" />
        </div>
      </div>
      <div className="mt-16">
        <div className="skeletons__form__row__item">
          <Skeleton borderRadius="medium" height="20px" width="20%" />
          <Skeleton borderRadius="medium" height="16px" width="10%" />
          <Skeleton borderRadius="large" height="50px" width="40%" />
        </div>
      </div>
      <div className="mt-16">
        <div className="skeletons__form__row__item">
          <div className="skeletons__form__row__item-first">
            <Skeleton
              variant="circular"
              borderRadius="medium"
              height="50px"
              width="50px"
            />
            <Skeleton borderRadius="medium" height="30px" width="25%" />
          </div>
          <Skeleton borderRadius="medium" height="16px" width="15%" />
          <Skeleton borderRadius="large" height="50px" width="40%" />
        </div>
      </div>
    </div>
  );
}
