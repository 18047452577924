import { Button, useNotifications } from '@ph-react-ui/core';
import { useMemo, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCompleteRegistrationForEmployee } from '../../../../hooks/admission/employee/useCompleteRegistrationForEmployee';
import { useGetPublicEmployeeById } from '../../../../hooks/admission/employee/useGetPublicEmployeeById';
import type { EmployeeCompleteProfileFormDto } from '../../../../models/admission/employee/employee-complete-profile-form.dto';
import type { EmployeeCompleteProfileDto } from '../../../../models/admission/employee/employee-complete-profile.dto';
import { mapSimpleDateToString } from '../../../../services/mapSimpleDateToString';
import { FileListItem } from '../../../shared/components/FileListItem';
import { FileUpload } from '../../../shared/components/FileUpload';
import { FormSkeleton } from '../../../shared/components/skeletons/FormSkeleton';
import { BasicInfoSection } from '../../../users/create-edit/forms/common/BasicInfoSection';
import { DialogDeleteFile } from '../../../users/create-edit/forms/common/DialogDeleteFile';
import { ActiveEmployeeSection } from '../../../users/create-edit/forms/employee/ActiveEmployeeSection';
import { PedagogicalSection } from '../../../users/create-edit/forms/employee/PedagogicalSection';
import { PasswordRequirements } from '../common/PasswordRequirements';
import { PasswordSection } from '../common/PasswordSection';

interface CompleteEmployeeProfileForm extends EmployeeCompleteProfileFormDto {
  confirmationPassword: string;
}

export function CompleteProfileEmployee() {
  const { t } = useTranslation();
  const notification = useNotifications();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const invitationId = searchParams.get('invitationId');
  const [deleteFileId, setDeleteFileIdModal] = useState('');

  const { data: employee, isLoading } = useGetPublicEmployeeById(invitationId);
  const completeRegistration = useCompleteRegistrationForEmployee(invitationId);
  const methods = useForm<CompleteEmployeeProfileForm>({
    values: employee
      ? {
          ...employee,
          newPassword: '',
          confirmationPassword: '',
          diplomaNumber: employee.diplomaNumber || '',
          diplomaIssueDate: employee.diplomaIssueDate || null,
          diplomaSeries: employee.diplomaSeries || '',
          files: [],
        }
      : undefined,
  });

  const password = methods.watch('newPassword');

  const onSubmit = async (data: CompleteEmployeeProfileForm) => {
    const formData: EmployeeCompleteProfileDto = {
      ...data,
      birthDate: mapSimpleDateToString(data.birthDate),
      diplomaIssueDate: mapSimpleDateToString(data.diplomaIssueDate),
      fileIds: data.files.map((file) => file.id),
    };
    await completeRegistration
      .mutateAsync(formData)
      .then(() => {
        notification.success(t('ADMISSION.COMPLETE_PROFILE.MESSAGES.SUCCESS'));
        setTimeout(() => {
          navigate('/');
        }, 3000);
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  const {
    fields: fileFields,
    append: appendFileToList,
    remove: removeFileFromList,
  } = useFieldArray({
    control: methods.control,
    name: 'files',
    keyName: 'files',
  });

  const nonRequiredFiles = useMemo(() => {
    return fileFields.filter((file) => file.shouldDisplay !== false);
  }, [fileFields]);

  if (!invitationId) {
    navigate('/');
  }

  if (invitationId && isLoading) {
    return (
      <div className="admission">
        <FormSkeleton />
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <form
        id="complete-profile-form"
        className="admission__form"
        onSubmit={methods.handleSubmit((data: CompleteEmployeeProfileForm) =>
          onSubmit(data)
        )}
      >
        <BasicInfoSection />
        <PasswordSection passwordWatcher={methods.watch('newPassword')} />
        <PasswordRequirements
          className="password__requirements-expand"
          password={password}
        />
        <ActiveEmployeeSection
          isPedagogicalEmployee={employee?.canCreateStudentPlans}
        />

        {methods.getValues('canCreateStudentPlans') && (
          <PedagogicalSection
            canCreateStudentPlans={employee?.canCreateStudentPlans}
          />
        )}
        <div className="upload__file-documents">
          <FileUpload
            appendFile={appendFileToList}
            invitationId={invitationId!}
          />
          {nonRequiredFiles.length ? (
            <div className="upload__file__other">
              {nonRequiredFiles.map((file, index) => (
                <FileListItem
                  key={index}
                  file={file}
                  index={index}
                  removeFile={() => setDeleteFileIdModal(file.id)}
                />
              ))}
            </div>
          ) : null}
          {deleteFileId && (
            <DialogDeleteFile
              fileId={deleteFileId}
              removeFile={removeFileFromList}
              closeModal={() => setDeleteFileIdModal('')}
              invitationId={invitationId!}
            />
          )}
        </div>
      </form>
      <Button
        form="complete-profile-form"
        type="submit"
        disabled={completeRegistration.isPending}
        loading={completeRegistration.isPending}
      >
        {t('ACTIONS.CONTINUE')}
      </Button>
    </FormProvider>
  );
}
