import { useMutation } from '@tanstack/react-query';
import type { TransportationRequestDenyDto } from '../../../models/transportation/students/student-transportation-request-deny.dto';
import apiRequest from '../../../services/apiWrapper';
import { TRANSPORTATION_BUS_STOPS_URL } from '../../../utils/constants/urlConstants';

async function denyTransportationRequestById(
  data: TransportationRequestDenyDto
) {
  return apiRequest.put(
    `${TRANSPORTATION_BUS_STOPS_URL}/requests/${data.id}/deny`,
    {
      reason: data.reason,
    }
  );
}

export function useDenyTransportationRequestById() {
  return useMutation({
    mutationFn: (data: TransportationRequestDenyDto) =>
      denyTransportationRequestById(data),
  });
}
