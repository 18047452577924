import { useMutation } from '@tanstack/react-query';
import type { UnassignStudentsFromBusStopDto } from '../../../models/transportation/students/unassign-students-from-bus-stop.dto';
import apiRequest from '../../../services/apiWrapper';
import { TRANSPORTATION_BUS_STOPS_URL } from '../../../utils/constants/urlConstants';

async function unassignTransportationPlanForStudent(
  data: UnassignStudentsFromBusStopDto
) {
  return await apiRequest.put(`${TRANSPORTATION_BUS_STOPS_URL}/unassign`, data);
}

export function useUnassignTransportationPlanForStudent() {
  return useMutation({
    mutationFn: (data: UnassignStudentsFromBusStopDto) =>
      unassignTransportationPlanForStudent(data),
  });
}
