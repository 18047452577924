import { useQuery } from '@tanstack/react-query';
import { format, startOfTomorrow } from 'date-fns';
import type { EmployeeAbsenceAvailabilityDto } from '../../../models/meal-menus/admin/employee-absence-availability.dto';
import apiRequest from '../../../services/apiWrapper';
import { EMPLOYEES_ABSENCE_URL } from '../../../utils/constants/urlConstants';

async function getEmployeeAbsenceAvailabilityByDate() {
  const tomorrow = format(startOfTomorrow(), 'yyyy-MM-dd');
  const response = await apiRequest.get<EmployeeAbsenceAvailabilityDto>(
    `${EMPLOYEES_ABSENCE_URL}/availability?targetedAt=${tomorrow}`
  );

  return response.data;
}

export function useGetEmployeeAbsenceAvailabilityByDate() {
  return useQuery({
    queryKey: ['employee-absence-availability'],
    queryFn: () => getEmployeeAbsenceAvailabilityByDate(),
  });
}
