import type { SelectOption } from '@ph-react-ui/core';
import { Button, Select } from '@ph-react-ui/core';
import { useTranslation } from 'react-i18next';
import { useMatch, useSearchParams } from 'react-router-dom';
import { useMediaQuery } from '../../../hooks/common/useMediaQuery';
import { ModalType } from '../../../utils/enums/modal-type.enum';
import { Role } from '../../../utils/enums/role.enum';
import { SearchInput } from '../../shared/components/SearchByNameInput';

interface UserFilterProps {
  handleModalType: (modal: ModalType) => void;
  isDisabled: boolean;
}

export function UserFilter({ handleModalType, isDisabled }: UserFilterProps) {
  const { t } = useTranslation();
  const match = useMatch('/dashboard/users');

  return (
    <div className="users__filters">
      <UserFilterNavigation />
      <div className="users__filters__actions">
        <SearchInput
          pathMatch={match}
          placeholder={t('USERS.TABLE.FILTERS.SEARCH_USER')}
        />{' '}
        <Button
          type="button"
          onClick={() => handleModalType(ModalType.AddToGroup)}
          disabled={isDisabled}
        >
          {t('USERS.ACTIONS.ADD_TO_GROUP')}
        </Button>
        <Button
          type="button"
          onClick={() => handleModalType(ModalType.Archive)}
          disabled={isDisabled}
        >
          {t('USERS.ACTIONS.ARCHIVE')}
        </Button>
      </div>
    </div>
  );
}

export function UserFilterNavigation() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const role = searchParams.get('role') ?? '';
  const isOnMobile = useMediaQuery('(max-width: 768px)');
  const handleRoleChange = (
    role: string | number | (string | number)[] | null
  ) => {
    setSearchParams((prevParams) => ({ ...prevParams, role, page: 1 }));
  };

  const userFilterOptions: SelectOption<string | number>[] = [
    {
      label: t('USERS.TABLE.FILTERS.ALL'),
      value: '',
    },
    {
      label: t('USERS.TABLE.FILTERS.STUDENTS'),
      value: Role.Student,
    },
    {
      label: t('USERS.TABLE.FILTERS.EMPLOYEES'),
      value: Role.Employee,
    },
    {
      label: t('USERS.TABLE.FILTERS.PARENTS'),
      value: Role.Parent,
    },
    {
      label: t('USERS.TABLE.FILTERS.CANDIDATES'),
      value: Role.Candidate,
    },
  ];

  if (isOnMobile) {
    return (
      <Select
        label={t('USERS.TABLE.HEADERS.ROLE') ?? ''}
        placeholder={t('USERS.TABLE.FILTERS.BY_ROLE') ?? ''}
        compact
        options={userFilterOptions}
        onChange={handleRoleChange}
      />
    );
  }
  return (
    <div className="users__filters__item-header">
      <div>
        <span className="users__filters__item__title">
          {t('USERS.TABLE.FILTERS.TITLE')}
        </span>
      </div>
      <button
        type="button"
        className={`users__filters__item ${
          role === '' && ' users__filters__item-active'
        }`}
        onClick={() => handleRoleChange('')}
      >
        {t('USERS.TABLE.FILTERS.ALL')}
      </button>
      <button
        type="button"
        className={`users__filters__item ${
          +role === Role.Student && ' users__filters__item-active'
        }`}
        onClick={() => handleRoleChange(Role.Student)}
      >
        {t('USERS.TABLE.FILTERS.STUDENTS')}
      </button>
      <button
        type="button"
        className={`users__filters__item ${
          +role === Role.Employee && ' users__filters__item-active'
        }`}
        onClick={() => handleRoleChange(Role.Employee)}
      >
        {t('USERS.TABLE.FILTERS.EMPLOYEES')}
      </button>
      <button
        type="button"
        className={`users__filters__item ${
          +role === Role.Parent && ' users__filters__item-active'
        }`}
        onClick={() => handleRoleChange(Role.Parent)}
      >
        {t('USERS.TABLE.FILTERS.PARENTS')}
      </button>
      <button
        type="button"
        className={`users__filters__item ${
          +role === Role.Candidate && ' users__filters__item-active'
        }`}
        onClick={() => handleRoleChange(Role.Candidate)}
      >
        {t('USERS.TABLE.FILTERS.CANDIDATES')}
      </button>
    </div>
  );
}
