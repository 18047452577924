import { useMutation } from '@tanstack/react-query';
import type { EvaluationPlansUpdateWithSubmitForApprovalDto } from '../../models/evaluation-plans/evaluation-plans-update.dto';
import apiRequest from '../../services/apiWrapper';
import { EVALUATION_PLANS_URL } from '../../utils/constants/urlConstants';

async function updateEvaluationPlan({
  data,
  submitForApproval,
}: EvaluationPlansUpdateWithSubmitForApprovalDto) {
  const queryParam = submitForApproval ? 'shouldSubmit=true' : '';
  return apiRequest.put(
    `${EVALUATION_PLANS_URL}/${data.id}?${queryParam}`,
    data
  );
}

export function useUpdateEvaluationPlanById() {
  return useMutation({
    mutationFn: ({
      data,
      submitForApproval,
    }: EvaluationPlansUpdateWithSubmitForApprovalDto) =>
      updateEvaluationPlan({ data, submitForApproval }),
  });
}
