import { Button, Tooltip, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useCreateEvaluationPlan } from '../../../hooks/evaluation-plans/useCreateEvaluationPlan';
import { useGetEvaluationPlanById } from '../../../hooks/evaluation-plans/useGetEvaluationPlanById';
import { useUpdateEvaluationPlanById } from '../../../hooks/evaluation-plans/useUpdateEvaluationPlanById';
import type { EvaluationPlansCreateWithSubmitForApprovalDto } from '../../../models/evaluation-plans/evaluation-plans-create.dto';
import type { EvaluationPlansFormDto } from '../../../models/evaluation-plans/evaluation-plans-form.dto';
import type { EvaluationPlansUpdateWithSubmitForApprovalDto } from '../../../models/evaluation-plans/evaluation-plans-update.dto';
import { Input, Textarea } from '../../../utils/components/hoc-components';
import { EvaluationPlanStatus } from '../../../utils/enums/evaluation-plan-status.enum';
import {
  getCommonValidators,
  VALIDATION as V,
} from '../../../utils/inputValidators';
import { CancellationDialog } from '../../shared/components/CancellationDialog';
import { defaultCriteria } from './CriteriaFieldArray';
import { StrandFieldArray } from './StrandFieldArray';

export function CreateEditEvaluationPlans() {
  const navigate = useNavigate();
  const notification = useNotifications();
  const [isCancellationDialogOpen, setIsCancellationDialogOpen] =
    useState(false);
  const { id } = useParams();
  const queryClient = useQueryClient();

  const { data: evaluationPlan } = useGetEvaluationPlanById(id);

  const methods = useForm<EvaluationPlansFormDto>({
    defaultValues: {
      name: '',
      description: '',
      strands: [
        {
          name: '',
          index: 1,
          criterias: [defaultCriteria],
        },
      ],
    },
    values: evaluationPlan,
  });

  const {
    formState: { isDirty },
  } = methods;

  const createEvaluationPlan = useCreateEvaluationPlan();
  const updateEvaluationPlan = useUpdateEvaluationPlanById();

  const { t } = useTranslation();

  const onNavigateBack = () => {
    navigate('/dashboard/evaluation-plans/my');
  };

  const onDiscardChanges = () => {
    if (isDirty) {
      setIsCancellationDialogOpen(true);
    } else {
      onNavigateBack();
    }
  };

  const onSave = (data: EvaluationPlansFormDto) => {
    if (id) {
      updateEvaluationPlanData({
        data: { ...data, id: id! },
        submitForApproval: false,
      });
    } else {
      createEvaluationPlanData({ data, submitForApproval: false });
    }
  };

  const onSubmitForApproval = (data: EvaluationPlansFormDto) => {
    if (id) {
      updateEvaluationPlanData({
        data: { ...data, id: id! },
        submitForApproval: true,
      });
    } else {
      createEvaluationPlanData({ data, submitForApproval: true });
    }
  };

  const createEvaluationPlanData = ({
    data,
    submitForApproval,
  }: EvaluationPlansCreateWithSubmitForApprovalDto) => {
    createEvaluationPlan
      .mutateAsync({ data, submitForApproval })
      .then(() => {
        submitForApproval
          ? notification.success(
              t('EVALUATION_PLANS.MESSAGES.SUCCESSFULLY_CREATED_AND_SUBMITTED')
            )
          : notification.success(
              t('EVALUATION_PLANS.MESSAGES.SUCCESSFULLY_CREATED')
            );
        queryClient.invalidateQueries({
          queryKey: ['my-evaluation-plans'],
        });
        queryClient.invalidateQueries({
          queryKey: ['evaluation-plans'],
        });

        navigate('/dashboard/evaluation-plans/my');
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  const updateEvaluationPlanData = ({
    data,
    submitForApproval,
  }: EvaluationPlansUpdateWithSubmitForApprovalDto) => {
    updateEvaluationPlan
      .mutateAsync({ data, submitForApproval })
      .then(() => {
        submitForApproval
          ? notification.success(
              t('EVALUATION_PLANS.MESSAGES.SUCCESSFULLY_UPDATED_AND_SUBMITTED')
            )
          : notification.success(
              t('EVALUATION_PLANS.MESSAGES.SUCCESSFULLY_UPDATED')
            );
        queryClient.invalidateQueries({ queryKey: ['my-evaluation-plans'] });
        queryClient.invalidateQueries({ queryKey: ['evaluation-plans', id] });

        navigate('/dashboard/evaluation-plans/my');
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  const isDisabled =
    evaluationPlan?.status === EvaluationPlanStatus.Approved ||
    evaluationPlan?.status === EvaluationPlanStatus.PendingApproval;

  const SaveButton = (
    <Button disabled={isDisabled} variant="outlined" type="submit">
      {t('ACTIONS.SAVE')}
    </Button>
  );

  const SubmitButton = (
    <Button
      type="button"
      onClick={methods.handleSubmit((data) => onSubmitForApproval(data))}
      disabled={isDisabled}
    >
      {evaluationPlan?.status === EvaluationPlanStatus.New || !id
        ? t('ACTIONS.SAVE_AND_SUBMIT')
        : t('ACTIONS.RESUBMIT')}
    </Button>
  );

  const renderActionButton = (button: JSX.Element) => {
    return isDisabled ? (
      <Tooltip
        className="evaluation-plans__form__actions__tooltip"
        position="top"
        message={t('EVALUATION_PLANS.MESSAGES.DISABLED_BUTTON')}
      >
        {button}
      </Tooltip>
    ) : (
      button
    );
  };

  return (
    <FormProvider {...methods}>
      <form
        className="evaluation-plans"
        onSubmit={methods.handleSubmit(onSave)}
      >
        <div className="evaluation-plans__form">
          <section className="evaluation-plans__form__basic-info">
            <Input
              name="name"
              label={t('EVALUATION_PLANS.FORM.LABELS.NAME') ?? ''}
              placeholder={t('EVALUATION_PLANS.FORM.PLACEHOLDERS.NAME') ?? ''}
              rules={getCommonValidators([V.REQUIRED, V.MAX_LENGTH])}
            />
            <Textarea
              name="description"
              label={t('EVALUATION_PLANS.FORM.LABELS.DESCRIPTION') ?? ''}
              rows={5}
              rules={getCommonValidators([V.REQUIRED, V.MAX_LENGTH_3000])}
            />
          </section>
          <StrandFieldArray control={methods.control} />
        </div>
        <div className="evaluation-plans__form__actions">
          <Button variant="outlined" onClick={onDiscardChanges}>
            {t('ACTIONS.CANCEL')}
          </Button>
          {renderActionButton(SaveButton)}
          {renderActionButton(SubmitButton)}
        </div>
      </form>
      {isCancellationDialogOpen && (
        <CancellationDialog onDiscardChanges={onNavigateBack} />
      )}
    </FormProvider>
  );
}
