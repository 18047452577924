import type { SelectOption } from '@ph-react-ui/core';
import i18n from '../../../i18n';
import {
  TermReportStatus as Status,
  TermReportSectionStatus as SectionStatus,
} from '../../enums/term-report-status.enum';

export function getTermReportStatus(key: string) {
  return i18n.t(key);
}

export const TERM_REPORT_STATUS = {
  [Status.SentToParent]: 'TERM_REPORTS.STATUS.SENT_TO_PARENT',
  [Status.Submitted]: 'TERM_REPORTS.STATUS.SUBMITTED',
  [Status.Approved]: 'TERM_REPORTS.STATUS.APPROVED',
  [Status.Rejected]: 'TERM_REPORTS.STATUS.REJECTED',
  [Status.New]: 'TERM_REPORTS.STATUS.NEW',
};

export const TERM_REPORT_SECTION_STATUS = {
  [SectionStatus.New]: 'TERM_REPORTS.STATUS.NEW',
  [SectionStatus.Submitted]: 'TERM_REPORTS.STATUS.SUBMITTED',
  [SectionStatus.Approved]: 'TERM_REPORTS.STATUS.APPROVED',
  [SectionStatus.Rejected]: 'TERM_REPORTS.STATUS.REJECTED',
};

export const TERM_REPORT_STATUS_OPTIONS = Object.entries(
  TERM_REPORT_STATUS
).map((status) => {
  return {
    label: getTermReportStatus(status[1]),
    value: status[0],
  };
}) as SelectOption<string>[];

export const TERM_REPORT_SECTIONS_STATUS_OPTIONS = Object.entries(
  TERM_REPORT_SECTION_STATUS
).map((status) => {
  return {
    label: getTermReportStatus(status[1]),
    value: status[0],
  };
}) as SelectOption<string>[];
