import { useMutation } from '@tanstack/react-query';
import type { UseMutationOptions } from '@tanstack/react-query';
import axios from 'axios';
import type { AxiosError } from 'axios';
import type { UserCredentialsDto } from '../../models/auth/login.dto';
import { PASSWORD_URL } from '../../utils/constants/urlConstants';

async function forgotPassword(email: string) {
  const response = await axios.post(PASSWORD_URL, {
    email,
  });

  return response.data;
}

export function useForgotPassword(
  options?: UseMutationOptions<undefined, AxiosError, any>
) {
  return useMutation({
    mutationFn: ({ email }: UserCredentialsDto) =>
      forgotPassword(email as string),
    ...options,
  });
}
