import { useMutation } from '@tanstack/react-query';
import type { CompleteParentCandidateProfileFormDTO } from '../../../features/admission/complete-profile/parent-candidate/CompleteProfileParentCandidate';
import publicApiRequest from '../../../services/publicApiWrapper';
import { PUBLIC_PARENT_URL } from '../../../utils/constants/urlConstants';

async function completeParentRegistration(
  invitationId: string | null,
  parentData: CompleteParentCandidateProfileFormDTO
) {
  return publicApiRequest.post(
    `${PUBLIC_PARENT_URL}/complete-registration?invitationId=${invitationId}`,
    parentData
  );
}
export function useCompleteRegistrationForParent(invitationId: string | null) {
  return useMutation({
    mutationFn: (parentData: CompleteParentCandidateProfileFormDTO) =>
      completeParentRegistration(invitationId, parentData),
  });
}
