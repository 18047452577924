import { Button, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useDeleteGroupById } from '../../../hooks/groups/useDeleteGroup';
import { useModal } from '../../../hooks/useModal';
import { DELETE_GROUP } from '../../../utils/constants/groups/modals';
import { Dialog } from '../../shared/components/Dialog';

export const DeleteGroup = () => {
  const { t } = useTranslation();
  const notification = useNotifications();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('groupId');
  const { close: closeModal } = useModal(DELETE_GROUP);

  const deleteGroup = useDeleteGroupById();

  const handleDelete = () => {
    deleteGroup
      .mutateAsync(id as string)
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ['groups'] });
        closeModal();
        notification.success(t('GROUPS.DIALOG.MESSAGES.DELETE_SUCCESS'));
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  return (
    <Dialog
      onClose={() => closeModal()}
      dialogHeader={t('GROUPS.DIALOG.HEADERS.DELETE_GROUP')}
      actionButton={
        <Button onClick={handleDelete}>{t('ACTIONS.DELETE')}</Button>
      }
    >
      <h2>{t('GROUPS.DIALOG.CONTENT.DELETE_ARE_YOU_SURE')}</h2>
      <h4>{t('GROUPS.DIALOG.CONTENT.DELETE_WARNING')}</h4>
    </Dialog>
  );
};
