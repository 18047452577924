import { useMutation } from '@tanstack/react-query';
import type { ApplicationFormDto } from '../../models/admission/application-create';
import publicApiRequest from '../../services/publicApiWrapper';
import { USERS_URL } from '../../utils/constants/urlConstants';

async function sendApplication(application: ApplicationFormDto) {
  return publicApiRequest.post<ApplicationFormDto, void>(
    `${USERS_URL}/application`,
    application
  );
}

export function useSendApplication() {
  return useMutation({
    mutationFn: (application: ApplicationFormDto) =>
      sendApplication(application),
  });
}
