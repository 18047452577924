import { useQuery } from '@tanstack/react-query';
import type { BusRoutesWithBusStopsDetailsDto } from '../../../models/transportation/routes/bus-routes-with-bus-stops-details.dto';
import apiRequest from '../../../services/apiWrapper';
import { BUS_ROUTES_URL } from '../../../utils/constants/urlConstants';

async function getBusRoutesByStudentId(routeId?: string, studentId?: string) {
  const response = await apiRequest.get<BusRoutesWithBusStopsDetailsDto>(
    `${BUS_ROUTES_URL}/route-stops?routeId=${routeId}&studentId=${studentId}`
  );

  return response.data;
}

export function useGetBusRoutesByStudentId({
  routeId,
  studentId,
}: {
  routeId?: string;
  studentId?: string;
}) {
  return useQuery({
    queryKey: ['bus-routes-students', routeId, studentId],
    queryFn: () => getBusRoutesByStudentId(routeId, studentId),
  });
}
