import { Printer } from '@carbon/icons-react';
import { Button } from '@ph-react-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { useGetWeeklyReportById } from '../../../hooks/meal-menus/admin/useGetWeeklyReportById';
import { MONTH_NAMES } from '../../../utils/constants/dateContstants';

export const WeeklyMenuReports = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const week = searchParams.get('week') as string;
  const month = searchParams.get('month') as string;
  const year = searchParams.get('year');

  const { data: users } = useGetWeeklyReportById(id as string, week);

  return (
    <div className="food-management">
      <div className="food-management__actions">
        <Button variant="outlined" onClick={() => window.print()}>
          <Printer />
          {t('FOOD_MANAGEMENT.LABELS.PRINT_DAILY_REPORT')}
        </Button>
      </div>
      <h3 className="food-management__title">
        {t('FOOD_MANAGEMENT.LABELS.WEEK')} {week}, &nbsp;
        {t(`MONTHS.${MONTH_NAMES[+month]}`)} {year}
      </h3>
      <div className="food-management__daily-menu">
        <h3>
          {t('USERS.TABLE.FILTERS.STUDENTS')}:{' '}
          {t('FOOD_MANAGEMENT.LABELS.MEAT_MENU')}{' '}
          {`(${users?.studentMeatMeal.length})`}
        </h3>
        <div className="food-management__daily-menu__table">
          {users?.studentMeatMeal.map((user, index) => (
            <span key={index}>{user}</span>
          ))}
        </div>
      </div>
      <div className="food-management__daily-menu">
        <h3>
          {t('USERS.TABLE.FILTERS.STUDENTS')}:{' '}
          {t('FOOD_MANAGEMENT.LABELS.VEG_MENU')}{' '}
          {`(${users?.studentVegMeal.length})`}
        </h3>
        <div className="food-management__daily-menu__table">
          {users?.studentVegMeal.map((user, index) => (
            <span key={index}>{user}</span>
          ))}
        </div>
      </div>
    </div>
  );
};
