import { useMutation } from '@tanstack/react-query';
import apiRequest from '../../services/apiWrapper';
import { USERS_URL } from '../../utils/constants/urlConstants';

async function sendInvivation(id: string) {
  return apiRequest.post(
    `${USERS_URL}/invite/complete-registration?id=${id}`,
    null
  );
}

export function useInvitationById() {
  return useMutation({
    mutationFn: (id: string) => sendInvivation(id),
  });
}
