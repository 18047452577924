import { OverflowMenuHorizontal } from '@carbon/icons-react';
import { ActionMenu, Button } from '@ph-react-ui/core';

interface ActionMenuHorizontalProps {
  options: {
    label: string;
    onClick: () => void;
    hidden?: boolean;
  }[];
}

export const ActionMenuHorizontal = ({
  options,
}: ActionMenuHorizontalProps) => {
  return (
    <ActionMenu
      customClasses="table-last"
      position="left"
      options={options}
      toggleElement={
        <Button variant="link" type="button">
          <OverflowMenuHorizontal />
        </Button>
      }
    ></ActionMenu>
  );
};
