import { Textarea } from '@ph-react-ui/core';
import { useTranslation } from 'react-i18next';
import { useGetEvaluationPlanLegendDetails } from '../../../hooks/evaluation-plans/useGetEvaluationPlanLegendDetails';
import { useModal } from '../../../hooks/useModal';
import { EVALUATION_CRITERIA_LEGEND } from '../../../utils/constants/evaluation-plans/modals';
import { Dialog } from '../../shared/components/Dialog';

export const EvaluationCriteriaLegend = () => {
  const { t } = useTranslation();
  const { close: closeModal } = useModal(EVALUATION_CRITERIA_LEGEND);
  const { legendDetails, removeLegendDetails } =
    useGetEvaluationPlanLegendDetails();

  const handleClose = () => {
    removeLegendDetails();
    closeModal();
  };

  return (
    <Dialog
      onClose={handleClose}
      dialogHeader={t(
        'EVALUATION_PLANS.TITLES.EVALUATION_CRITERIA_DESCRIPTION'
      )}
    >
      <Textarea
        label={t('EVALUATION_PLANS.FORM.LABELS.EXCEEDS_EXPECTATIONS') ?? ''}
        rows={4}
        value={legendDetails?.exceedsExpectations}
        readonly
        className="mb-10"
      />
      <Textarea
        label={t('EVALUATION_PLANS.FORM.LABELS.MEETS_EXPECTATIONS') ?? ''}
        rows={4}
        value={legendDetails?.meetsExpectations}
        readonly
        className="mb-10"
      />
      <Textarea
        label={t('EVALUATION_PLANS.FORM.LABELS.APPROACHING_EXPECTATIONS') ?? ''}
        rows={4}
        value={legendDetails?.approachingExpectations}
        readonly
        className="mb-10"
      />
      <Textarea
        label={t('EVALUATION_PLANS.FORM.LABELS.BELOW_EXPECTATIONS') ?? ''}
        rows={4}
        value={legendDetails?.belowExpectations}
        readonly
        className="mb-10"
      />
    </Dialog>
  );
};
