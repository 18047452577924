import { Button, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { type MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useGetStudentMonthlyMealMenu } from '../../../hooks/meal-menus/student/useGetStudentMontlyMealMenu';
import { useLockStudentMonthlyMealMenu } from '../../../hooks/meal-menus/student/useLockStudentMonthlyMealMenu';
import { MONTH_NAMES } from '../../../utils/constants/dateContstants';
import { MealType } from '../../../utils/enums/meal-type.enum';

export function PreviewSaveMealChoice() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const notification = useNotifications();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const studentId = searchParams.get('studentId') ?? '';
  const monthParam = searchParams.get('month') ?? '';
  const yearParam = searchParams.get('year') ?? '';

  const { data: mealMenu } = useGetStudentMonthlyMealMenu({
    studentId,
    month: monthParam,
    year: yearParam,
  });

  const saveMonthlyMealChoice = useLockStudentMonthlyMealMenu();

  const onNavigateBack = () => {
    navigate(-1);
  };

  const handleSaveMealChoice = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const selectedOptions = mealMenu?.weeks?.filter((week) => week.isSelected);
    saveMonthlyMealChoice
      .mutateAsync({
        studentId,
        weeklySelections: selectedOptions?.map((option) => {
          return {
            mealMenuId: mealMenu?.mealMenuId || '',
            type: option.type,
            index: option.index,
            isLocked: true,
          };
        }),
      })
      .then(() => {
        notification.success(
          t('FOOD_MANAGEMENT.MESSAGES.MEAL_MENU_SAVED', {
            month: t(`MONTHS.${MONTH_NAMES[+monthParam]}`),
          })
        );
        navigate(-1);
      })
      .catch((error) => {
        notification.danger(t(`NETWORK_ERRORS.${error.errors[0]}`));
      });
  };

  const renderSelectedMealPlans = () => {
    if (studentId && monthParam && yearParam) {
      queryClient.invalidateQueries({ queryKey: ['student-meal-menu'] });
    }

    const selectedMealPlans = mealMenu?.weeks?.filter(
      (week) => week.isSelected
    );

    return (
      <div>
        <div className="payments__wrapper__flex"></div>
        <div className="payments__pre-payment__plans">
          <h4 className="payments__pre-payment__plans__title">
            {t('PAYMENTS.TITLES.MEAL_PLANS')}{' '}
            {t(`MONTHS.${MONTH_NAMES[+monthParam]}`)}
          </h4>
          <div className="payments__pre-payment__plans__weeks">
            {selectedMealPlans &&
              selectedMealPlans.map((week, index) => (
                <div
                  className="payments__pre-payment__plans__weeks__item"
                  key={index}
                >
                  <span className="payments__pre-payment__plans__weeks__item__label">
                    {t('PAYMENTS.LABELS.WEEK')} {week.index}
                  </span>
                  <span className="payments__pre-payment__plans__weeks__item__value">
                    {week.type === MealType.Meat
                      ? t('PAYMENTS.LABELS.MEAT')
                      : t('PAYMENTS.LABELS.VEG')}
                  </span>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="payments__pre-payment">
      <h3 className="payments__pre-payment__title">
        {t('PAYMENTS.TITLES.FOOD_ORDER')}
      </h3>
      {renderSelectedMealPlans()}
      <h4 className="payments__pre-payment__plans__info-text">
        {t('FOOD_MANAGEMENT.MESSAGES.CONFIRM_MONTHLY_MEAL_CHOICE')}
      </h4>
      <div className="payments__pre-payment__actions">
        <Button compact variant="outlined" onClick={onNavigateBack}>
          {t('ACTIONS.CANCEL')}
        </Button>
        <Button compact variant="filled" onClick={handleSaveMealChoice}>
          {t('ACTIONS.CONFIRM')}
        </Button>
      </div>
    </div>
  );
}
