import { Drawer } from '@ph-react-ui/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { AddEditRouteForm } from './forms/AddEditRouteForm';

export function CreateEditRoute() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('routeId');
  const { t } = useTranslation();
  const [footerElement, setFooterElement] = useState<HTMLDivElement | null>(
    null
  );

  return (
    <Drawer
      header={
        id
          ? t('TRANSPORTATION_MANAGEMENT.DRAWER.TITLES.EDIT_ROUTE')
          : t('TRANSPORTATION_MANAGEMENT.DRAWER.TITLES.ADD_ROUTE')
      }
      footer={
        <div
          className="drawer__transportation-management__form__actions"
          ref={(elem) => setFooterElement(elem)}
        ></div>
      }
    >
      <AddEditRouteForm footerElement={footerElement} />
    </Drawer>
  );
}
