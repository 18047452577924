import { Trans, useTranslation } from 'react-i18next';

export const TermsOfUse = () => {
  const { t } = useTranslation();

  return (
    <ol className="my-3">
      <li>1. {t(`ADMISSION.APPLICATION.TERMS_OF_USE.TERM1`)}</li>
      <li>2. {t(`ADMISSION.APPLICATION.TERMS_OF_USE.TERM2`)}</li>
      <li>3. {t(`ADMISSION.APPLICATION.TERMS_OF_USE.TERM3`)}</li>
      <li>
        4.{' '}
        <Trans
          i18nKey={t(`ADMISSION.APPLICATION.TERMS_OF_USE.TERM4`) ?? ''}
          components={[
            <a
              href="https://denisdiderot.bg/Portals/0/Privacy_policy_bg.pdf"
              target="_blank"
            >
              {}
            </a>,
          ]}
        />
      </li>
    </ol>
  );
};
