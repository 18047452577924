import { DatePicker, Select } from '@ph-react-ui/core';
import type { SelectOption, SimpleDate } from '@ph-react-ui/core';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import type { PathMatch } from 'react-router-dom';
import { useGetSchoolYearBaseSettings } from '../../../hooks/settings/useGetSchoolYearBaseSettings';
import { mapStringToSimpleDate } from '../../../services/mapStringToSimpleDate';
import { PaymentStatus } from '../../../utils/enums/payment-status.enum';
import { SearchInput } from './SearchByNameInput';

interface TaxesFiltersProps {
  pathMatch: PathMatch<string> | null;
}

export const TaxesFilters = ({ pathMatch }: TaxesFiltersProps) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const fromDateParam = searchParams.get('fromDate');
  const toDateParam = searchParams.get('toDate');

  const { data: schoolYearData } = useGetSchoolYearBaseSettings();

  const statusOptions: SelectOption<number | string>[] = [
    { label: t('TAXES.LABELS.ALL'), value: '' },
    { label: t('TAXES.LABELS.PAID'), value: PaymentStatus.Paid },
    { label: t('TAXES.LABELS.NOT_PAID'), value: PaymentStatus.NotPaid },
  ];

  const onSetToDate = (value: SimpleDate | null) => {
    if (value) {
      const date = new Date(value.year, value.month, value.date);
      searchParams.set('toDate', format(date, 'yyyy-MM-dd'));
      searchParams.set('page', '1');
    } else {
      searchParams.delete('toDate');
    }
    setSearchParams(searchParams);
  };

  const onSetFromDate = (value: SimpleDate | null) => {
    if (value) {
      const date = new Date(value.year, value.month, value.date);
      searchParams.set('fromDate', format(date, 'yyyy-MM-dd'));
      searchParams.set('page', '1');
    } else {
      searchParams.delete('fromDate');
    }
    setSearchParams(searchParams);
  };

  const handleStatusChange = (
    status: null | string | number | (string | number)[]
  ) => {
    if (status === null) {
      return;
    }
    const isPaid =
      status === PaymentStatus.Paid
        ? 'true'
        : status === PaymentStatus.NotPaid
        ? 'false'
        : '';
    searchParams.set('isPaid', isPaid);
    searchParams.set('page', '1');
    setSearchParams(searchParams);
  };

  return (
    <>
      <SearchInput
        pathMatch={pathMatch}
        placeholder={t('TAXES.FILTERS.SEARCH_BY_NAME')}
      />
      <div className="taxes__food__filter__dates">
        <DatePicker
          value={mapStringToSimpleDate(fromDateParam)}
          compact
          onChange={onSetFromDate}
          placeholder={t('TAXES.LABELS.FROM_DATE') ?? ''}
          maxDate={schoolYearData?.endOfSchoolYear}
        />
        <DatePicker
          value={mapStringToSimpleDate(toDateParam)}
          compact
          onChange={onSetToDate}
          placeholder={t('TAXES.LABELS.TO_DATE') ?? ''}
          maxDate={schoolYearData?.endOfSchoolYear}
        />
      </div>

      <div className="taxes__food__filter__select">
        <Select
          placeholder={t('TAXES.FILTERS.STATUS') ?? ''}
          compact
          options={statusOptions}
          onChange={(status) => handleStatusChange(status)}
        />
      </div>
    </>
  );
};
