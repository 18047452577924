import { useMutation } from '@tanstack/react-query';
import apiRequest from '../../services/apiWrapper';
import { GROUPS_URL } from '../../utils/constants/urlConstants';

async function deleteGroupById(id: string) {
  return apiRequest.delete(`${GROUPS_URL}/${id}`, null);
}

export function useDeleteGroupById() {
  return useMutation({
    mutationFn: (groupId: string) => deleteGroupById(groupId),
  });
}
