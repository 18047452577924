import { Search, UserFollow } from '@carbon/icons-react';
import {
  Button,
  Checkbox,
  Input,
  Table,
  useNotifications,
} from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import {
  type Dispatch,
  type SetStateAction,
  useMemo,
  useState,
  useRef,
  useEffect,
} from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from '../../../../../hooks/common/useDebounce';
import { useModal } from '../../../../../hooks/useModal';
import { useGetParentById } from '../../../../../hooks/users/parents/useGetParentById';
import { useAttachStudentsToFamily } from '../../../../../hooks/users/students/useAttachStudentsToFamily';
import { useGetUnmappedStudents } from '../../../../../hooks/users/students/useGetAllStudents';
import { useSelectAll } from '../../../../../hooks/useSelectAll';
import { ATTACH_USER_TYPE } from '../../../../../utils/constants/users/attach-user-type';
import { CREATE_EDIT_USER } from '../../../../../utils/constants/users/modals';
import { Role } from '../../../../../utils/enums/role.enum';
import { Status } from '../../../../../utils/enums/status.enum';
import { Dialog } from '../../../../shared/components/Dialog';

interface AddStudentsToParentModalProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export function AddStudentsToParentModal({
  setOpen,
}: AddStudentsToParentModalProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { open: openModal } = useModal(CREATE_EDIT_USER);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const parentId = searchParams.get('userId');
  const [isLockedOption, setIsLockedOption] = useState(false);
  const notification = useNotifications();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [searchInput, setSearchInput] = useState('');
  const debouncedInput = useDebounce(searchInput, 500);
  const [filteredStudents, setFilteredStudents] = useState<
    { name: string; id: string }[]
  >([]);
  const { data: allStudents } = useGetUnmappedStudents();
  const { data: family } = useGetParentById(parentId, Boolean(parentId));

  const attachStudentsToParent = useAttachStudentsToFamily(
    family?.familyInformation.id!
  );

  const { selectAll, handleSelectAll, control, getSelectedItems } =
    useSelectAll(allStudents);

  useEffect(() => {
    if (allStudents) {
      if (debouncedInput) {
        const filteredStudents = allStudents.filter((student) =>
          student.name.toLowerCase().includes(debouncedInput.toLowerCase())
        );
        setFilteredStudents(filteredStudents);
      } else {
        setFilteredStudents(allStudents);
      }
    }
  }, [allStudents, debouncedInput]);

  const headers = [
    <div className="users__table__select-all">
      <Checkbox
        onChange={(checked) => handleSelectAll(checked)}
        value={selectAll}
      />
      {t('USERS.TABLE.HEADERS.NAME')}
    </div>,
  ];

  const onSelectedOption = () => {
    if (selectedOption === ATTACH_USER_TYPE.NEW) {
      openModal({
        parentId: parentId!,
        userId: '',
        userRole: Role.Student.toString(),
        userStatus: Status.Active.toString(),
        navigateBack: 'true',
      });
      return;
    }

    setIsLockedOption(true);
  };

  const tableChildren = useMemo(() => {
    return filteredStudents?.reduce((acc: any, student, index) => {
      let studentArray = [
        <div className="users__table__checkbox">
          <Controller
            key={student.id}
            name={`items.${index}.value`}
            control={control}
            render={({ field }) => (
              <>
                <Checkbox className="users__table-first" {...field} />
              </>
            )}
          />
          {student.name}
        </div>,
      ];
      return [...acc, studentArray];
    }, []);
  }, [control, filteredStudents]);

  const onAttachStudents = () => {
    const selectedStudentsIds = getSelectedItems();
    attachStudentsToParent
      .mutateAsync({ existingStudents: selectedStudentsIds, newStudents: [] })
      .then(() => {
        notification.success(t('USERS.DRAWER.MESSAGES.ATTACH_COMPLETED'));
        queryClient.invalidateQueries({
          queryKey: ['parent-students', parentId!],
        });
        setOpen(false);
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  return (
    <Dialog
      onClose={() => setOpen(false)}
      dialogHeader={t('USERS.DRAWER.TITLES.ADD_MORE_STUDENTS_TO_PARENT')}
      actionButton={
        isLockedOption ? (
          <Button onClick={onAttachStudents}>
            {t('ACTIONS.ATTACH_STUDENT')}
          </Button>
        ) : (
          <Button disabled={!selectedOption} onClick={onSelectedOption}>
            {t('ACTIONS.CONTINUE')}
          </Button>
        )
      }
    >
      {isLockedOption ? (
        <div className="drawer__users__table">
          <Input
            className="mb-3"
            ref={inputRef}
            value={searchInput}
            onChange={setSearchInput}
            compact
            placeholder={t('USERS.TABLE.FILTERS.SEARCH_USER') ?? ''}
            prefix={
              <Search className="groups__actions__input__icon groups__actions__input__icon--action" />
            }
          />
          {filteredStudents && filteredStudents.length > 0 ? (
            <Table headers={headers} rows={tableChildren} />
          ) : (
            t('USERS.DRAWER.MESSAGES.NO_STUDENTS_TO_ATTACH')
          )}
        </div>
      ) : (
        <div className="dialog__confirm__content__payment-wrapper">
          <button
            onClick={() => setSelectedOption(ATTACH_USER_TYPE.NEW)}
            className={selectedOption === ATTACH_USER_TYPE.NEW ? 'active' : ''}
          >
            <UserFollow size={24} />
            <h3>{t('USERS.DRAWER.TITLES.NEW_STUDENT')}</h3>
          </button>
        </div>
      )}
    </Dialog>
  );
}
