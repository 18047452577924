import { Copy } from '@carbon/icons-react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useCopyToClipboard } from '../../../hooks/common/useCopyToClipboard';
import { useGetEvaluationScoreById } from '../../../hooks/evaluation-plans/useGetEvaluationScoreById';
import { useModal } from '../../../hooks/useModal';
import { EVALUATION_SCORES_VALUES } from '../../../utils/constants/evaluation-plans/evaluation-scores';
import { EVALUATION_SCORES } from '../../../utils/constants/evaluation-plans/modals';
import { Dialog } from '../../shared/components/Dialog';

export function EvaluationScoresDialog() {
  const { t } = useTranslation();
  const { close: closeModal } = useModal(EVALUATION_SCORES);
  const [searchParams] = useSearchParams();
  const templateName = searchParams.get('templateName');
  const date = searchParams.get('date');
  const evaluationId = searchParams.get('evaluationId');

  const { data: evaluationScoreDetails } = useGetEvaluationScoreById({
    evaluationId: evaluationId,
  });

  const { copy } = useCopyToClipboard();

  return (
    <Dialog
      dialogHeader={`${templateName} | ${
        date ? format(new Date(date), 'dd MMM yyyy') : ''
      }`}
      onClose={closeModal}
      className="dialog__evaluation-scores"
    >
      <div className="dialog__evaluation-scores__wrapper">
        {evaluationScoreDetails?.scores.map((strand, index) => (
          <div className="dialog__evaluation-scores__strand__item" key={index}>
            <div className="dialog__evaluation-scores__strand">
              <h3 className="dialog__evaluation-scores__strand__title">
                {index + 1} {strand.strandName}
              </h3>
              {strand.criterias.map((criteria, index) => (
                <div key={index}>
                  <div className="dialog__evaluation-scores__strand__criteria">
                    <div className="dialog__evaluation-scores__strand__criteria__title">
                      {criteria.name}
                    </div>
                    <div className="dialog__evaluation-scores__strand__criteria__score">
                      <h4 className="dialog__evaluation-scores__strand__criteria__score__title">
                        {t('EVALUATION_PLANS.LABELS.SCORE')}
                      </h4>
                      <span className="dialog__evaluation-scores__strand__criteria__score__value">
                        {criteria.score}
                      </span>
                      <span className="dialog__evaluation-scores__strand__criteria__score__description">
                        {t(
                          `EVALUATION_PLANS.EVALUATION_SCORES.${
                            EVALUATION_SCORES_VALUES[criteria.score]
                          }`
                        )}
                      </span>
                    </div>
                    <div className="dialog__evaluation-scores__strand__criteria__description">
                      {criteria.note}
                      <button
                        className="dialog__evaluation-scores__strand__criteria__description__button"
                        onClick={() => copy(criteria.note)}
                      >
                        <Copy className="dialog__evaluation-scores__strand__criteria__description__button__icon" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </Dialog>
  );
}
