import { useNotifications } from '@ph-react-ui/core';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export function useCopyToClipboard() {
  const notification = useNotifications();
  const { t } = useTranslation();

  const copy = useCallback(
    (note: string) => {
      if (note) {
        navigator.clipboard.writeText(note).then(() => {
          notification.success(t('COMMON.TEXT_COPIED'));
        });
      }
    },
    [notification, t]
  );

  return { copy };
}
