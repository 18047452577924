import { ChevronLeft, ChevronRight } from '@carbon/icons-react';
import type { SelectOption } from '@ph-react-ui/core';
import {
  Button,
  RadioGroup,
  Radio,
  useNotifications,
  Tooltip,
} from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetStudentMonthlyMealMenu } from '../../../hooks/meal-menus/student/useGetStudentMontlyMealMenu';
import { useSetStudentWeeklyMealMenu } from '../../../hooks/meal-menus/student/useSetStudentWeeklyMealMenu';
import { useGetAuthenticatedParentProfile } from '../../../hooks/profile/parent/useGetAuthenticatedParentProfile';
import { useGetStudentsForParent } from '../../../hooks/users/parents/useGetStudentsForParent';
import type { StudentMealMenuDetailsDto } from '../../../models/meal-menus/student/student-meal-menu-details.dto';
import type {
  StudentMealMenuFormDto,
  WeeklyOrder,
} from '../../../models/meal-menus/student/student-meal-menu-form.dto';
import { Scholarship } from '../../../models/users/students/scholarship-type';
import { Select } from '../../../utils/components/hoc-components';
import { MONTH_NAMES } from '../../../utils/constants/dateContstants';
import { MealType } from '../../../utils/enums/meal-type.enum';
import { FilePreview } from './FilePreview';

export function ParentFoodManagement() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [studentOptions, setStudentOptions] = useState<SelectOption<string>[]>(
    []
  );
  const notification = useNotifications();
  const [searchParams, setSearchParams] = useSearchParams();
  const monthParam = searchParams.get('month') ?? '';
  const yearParam = searchParams.get('year') ?? '';
  const weekParam = searchParams.get('week') ?? '1';
  const studentIdParam =
    searchParams.get('studentId') ?? studentOptions?.[0]?.value;

  const {
    data: mealMenu,
    isError,
    isSuccess,
  } = useGetStudentMonthlyMealMenu({
    studentId: studentIdParam,
    month: monthParam,
    year: yearParam,
  });

  const isMonthlyMenuSaved = !mealMenu?.weeks.filter((week) => !week.isLocked)
    ?.length;

  useEffect(() => {
    if (mealMenu) {
      queryClient.setQueryData(
        [
          'student-meal-menu',
          studentIdParam,
          mealMenu.month.toString(),
          mealMenu.year.toString(),
        ],
        mealMenu
      );
      searchParams.set('month', mealMenu.month.toString());
      searchParams.set('year', mealMenu.year.toString());
      searchParams.set('studentId', studentIdParam);
    }
  }, [
    mealMenu,
    queryClient,
    monthParam,
    yearParam,
    studentOptions,
    studentIdParam,
    searchParams,
    setSearchParams,
  ]);

  /** Api is returning two objects for each week with fileId and boolean isSelected
   * which we are mapping to one object which has mealFileId and vegFileId and
   * selected type is depending on isSelected
   */
  const mapMealMenuToFormData = (
    data: StudentMealMenuDetailsDto | undefined
  ): StudentMealMenuFormDto | undefined => {
    const combinedList = new Map<number, WeeklyOrder>();
    if (!data) {
      return undefined;
    }
    data.weeks.forEach((item) => {
      if (!combinedList.has(item.index)) {
        combinedList.set(item.index, {
          weekIndex: item.index,
          type: item.isSelected ? item.type.toString() : null,
          meatFileId: item.type === MealType.Meat ? item.fileId : '',
          vegFileId: item.type === MealType.Vegeterian ? item.fileId : '',
        });
      } else {
        if (item.type === MealType.Meat) {
          combinedList.get(item.index)!.meatFileId = item.fileId;
        } else {
          combinedList.get(item.index)!.vegFileId = item.fileId;
        }
        if (item.isSelected) {
          combinedList.get(item.index)!.type = item.type.toString();
        }
      }
    });

    return {
      ...data,
      weeks: Array.from(combinedList.values()).sort(
        (a, b) => a.weekIndex - b.weekIndex
      ),
    };
  };

  const methods = useForm<StudentMealMenuFormDto>({
    resetOptions: {
      keepValues: false,
    },
    defaultValues: {
      weeks: [],
      month: +monthParam,
      year: +yearParam,
    },
    values: mapMealMenuToFormData(mealMenu),
  });

  const { update } = useFieldArray({
    control: methods.control,
    name: 'weeks',
  });

  const { data: parent } = useGetAuthenticatedParentProfile();
  const { data: students } = useGetStudentsForParent({
    enabled: Boolean(parent),
    excludeArchived: true,
  });

  const updateMealMenu = useSetStudentWeeklyMealMenu();

  useEffect(() => {
    const studentOptions = students
      ? students.map((student) => ({ label: student.name, value: student.id }))
      : [];

    setStudentOptions(studentOptions);
  }, [students, searchParams, setSearchParams]);

  const onSelectStudent = (
    value: string | number | (string | number)[] | null
  ) => {
    if (value) {
      searchParams.set(
        'studentId',
        typeof value === 'string' ? value : value?.toString()
      );
      searchParams.delete('week');
      setSearchParams(searchParams);
    }
  };

  const goToPrevMonth = () => {
    if (+monthParam === 1) {
      const prevYear = (+yearParam - 1).toString();
      searchParams.set('month', '12');
      searchParams.set('year', prevYear);
    } else {
      const prevMonth = (+monthParam - 1).toString();
      searchParams.set('month', prevMonth);
      searchParams.set('year', yearParam.toString());
    }
  };

  const goToNextMonth = () => {
    if (+monthParam === 12) {
      const nextYear = (+yearParam + 1).toString();
      searchParams.set('month', '1');
      searchParams.set('year', nextYear);
    } else {
      const nextMonth = (+monthParam + 1).toString();
      searchParams.set('month', nextMonth);
      searchParams.set('year', yearParam.toString());
    }
  };

  /**
   * isError is true if there is no meal plan for selected month, in that case we don't want
   * to move week one by one, we just change month
   */
  const onPrevWeek = () => {
    if (isError) {
      goToPrevMonth();
      searchParams.set('week', '1');
      setSearchParams(searchParams);
      return;
    }
    if (weekParam === '1' || +weekParam === -weeks.length) {
      goToPrevMonth();
      searchParams.set('week', '-1');
      setSearchParams(searchParams);
    } else {
      const prevWeek = (+weekParam - 1).toString();
      searchParams.set('week', prevWeek);
      setSearchParams(searchParams);
    }
  };

  const onNextWeek = () => {
    if (isError) {
      goToNextMonth();
      searchParams.set('week', '1');
      setSearchParams(searchParams);
      return;
    }
    if (+weekParam === weeks.length || +weekParam === -1) {
      goToNextMonth();
      searchParams.set('week', '1');
      setSearchParams(searchParams);
    } else {
      const nextWeek = (+weekParam + 1).toString();
      searchParams.set('week', nextWeek);
      setSearchParams(searchParams);
    }
  };

  const onOpenLatestMealMenu = () => {
    searchParams.delete('week');
    searchParams.delete('year');
    searchParams.delete('month');
    setSearchParams(searchParams);
  };

  const onSubmit = () => {
    queryClient.invalidateQueries({ queryKey: ['food-price-plan'] });
    navigate(
      `/dashboard/payments/food?studentId=${studentIdParam}&month=${monthParam}&year=${yearParam}&withFallback=true`
    );
  };

  const weeks = methods.watch('weeks');

  const mealMenuId = methods.getValues('mealMenuId');

  const selectedStudent = students?.find(
    (student) => student.id === studentIdParam
  );

  const weeksSelected = useMemo(() => {
    const unselectedWeeks = weeks
      ? weeks.filter((week) => week.type === null)
      : [];
    return weeks.length - unselectedWeeks.length;
  }, [weeks]);

  /**
   * In order to return to last week of previous month we have weekParam as negative value
   */
  const weekIndexForChosenWeekParam = useMemo(
    () =>
      +weekParam > 0
        ? weeks?.at(+weekParam - 1)?.weekIndex
        : weeks?.at(+weekParam)?.weekIndex,
    [weekParam, weeks]
  );

  const isWeekOptionAvailableForField = (field: WeeklyOrder) => {
    return (
      (+weekParam > 0 &&
        field.weekIndex === weeks?.at(+weekParam - 1)?.weekIndex) ||
      (+weekParam < 0 && field.weekIndex === weeks?.at(+weekParam)?.weekIndex)
    );
  };

  const onMealChosen = ({
    type,
    weekIndex,
  }: {
    type: MealType;
    weekIndex: number;
  }) => {
    update(weekIndex - 1, {
      ...methods.getValues(`weeks.${weekIndex - 1}`),
      type: type.toString(),
    });
    updateMealMenu
      .mutateAsync({
        studentId: studentIdParam,
        mealItem: { type, index: weekIndex, mealMenuId },
      })
      .then((data) => {
        notification.success(t('FOOD_MANAGEMENT.MESSAGES.MEAL_MENU_UPDATED'));
        const { weeks: weeksResponse } = data;
        const filteredWeeks =
          mealMenu?.weeks.filter((week) => week.index !== weekIndex) ?? [];
        const newWeeks = [...filteredWeeks, ...weeksResponse].sort(
          (a, b) => a.index - b.index
        );
        queryClient.setQueryData(['student-meal-menu'], {
          ...mealMenu,
          weeks: newWeeks,
        });
      })
      .catch((error) => {
        notification.danger(t(`NETWORK_ERRORS.${error.errors[0]}`));
      });
  };

  const isLastWeekOfLatestPublishedMenu =
    (
      queryClient.getQueryData([
        'student-meal-menu',
        studentIdParam,
        '',
        '',
      ]) as StudentMealMenuDetailsDto
    )?.month === +monthParam && +weekParam === weeks.length;

  const onPreviewAndSaveMealChoice = () => {
    queryClient.invalidateQueries({ queryKey: ['food-price-plan'] });
    navigate(
      `/dashboard/food-management/meal-choice?studentId=${studentIdParam}&month=${monthParam}&year=${yearParam}&withFallback=true`
    );
  };

  const isOnFoodScholarship = useMemo(
    () => selectedStudent?.scholarshipType === Scholarship.FULL_SCHOLARSHIP,
    [selectedStudent?.scholarshipType]
  );

  return (
    <FormProvider {...methods}>
      <form
        className="food-management"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <div className="food-management__actions">
          <Select
            name="student"
            placeholder={t('FOOD_MANAGEMENT.LABELS.CHOOSE_STUDENT') ?? ''}
            label={t('FOOD_MANAGEMENT.LABELS.CHOOSE_STUDENT') ?? ''}
            compact
            options={studentOptions}
            value={studentIdParam}
            onChange={(value) => onSelectStudent(value)}
          />
          {monthParam && (
            <div className="month-pagination">
              {weeks?.length > 0 ? (
                <>
                  <button
                    type="button"
                    className="month-pagination__button"
                    onClick={() => onPrevWeek()}
                  >
                    <ChevronLeft />
                  </button>
                  <button
                    type="button"
                    className="month-pagination__button"
                    onClick={() => onNextWeek()}
                    disabled={isLastWeekOfLatestPublishedMenu}
                  >
                    <ChevronRight />
                  </button>

                  <span>
                    {t('FOOD_MANAGEMENT.LABELS.WEEK')}{' '}
                    {weekIndexForChosenWeekParam}
                    <span className="month-pagination__dash">-</span>
                    {t(`MONTHS.${MONTH_NAMES[+monthParam]}`)} {yearParam}
                  </span>
                </>
              ) : (
                <button
                  className="food-management__actions__button"
                  onClick={onOpenLatestMealMenu}
                >
                  {t('ACTIONS.VIEW_LATEST_MEAL_MENU')}
                </button>
              )}
            </div>
          )}
        </div>
        <div className="food-management__filter">
          <div className="food-management__filter__actions">
            {weeks?.length > 0 && (
              <>
                <span className="food-management__filter__actions__label">
                  {weeksSelected}/{weeks.length} &nbsp;
                  {t('FOOD_MANAGEMENT.LABELS.SELECTED')}
                </span>
                <span>
                  {isOnFoodScholarship
                    ? 0
                    : mealMenu &&
                      mealMenu.pricePerDay * mealMenu.numberOfDays}{' '}
                  {t('FOOD_MANAGEMENT.LABELS.BGN')}
                </span>
              </>
            )}
            <Tooltip
              position="bottom"
              message={
                isOnFoodScholarship
                  ? t('FOOD_MANAGEMENT.MESSAGES.ON_SCHOLARSHIP')
                  : mealMenu?.orderCompleted
                  ? t('FOOD_MANAGEMENT.MESSAGES.ALREADY_PAID')
                  : t('FOOD_MANAGEMENT.MESSAGES.PAY_NOW_NOTICE')
              }
            >
              <Button
                type="submit"
                compact
                disabled={
                  !mealMenu ||
                  mealMenu?.orderCompleted ||
                  isOnFoodScholarship ||
                  weeksSelected !== weeks.length
                }
              >
                {mealMenu?.orderCompleted
                  ? t('ACTIONS.ALREADY_PAID')
                  : t('ACTIONS.REVIEW_AND_PAY_NOW')}
              </Button>
            </Tooltip>
            <Tooltip
              position="bottom"
              className="food-management__filter__actions__tooltip"
              message={
                isMonthlyMenuSaved
                  ? t('FOOD_MANAGEMENT.MESSAGES.MEAL_CHOICES_SAVED')
                  : t('FOOD_MANAGEMENT.MESSAGES.PREVIEW_AND_SAVE_MEAL_CHOICE')
              }
            >
              <Button
                onClick={onPreviewAndSaveMealChoice}
                compact
                disabled={
                  !mealMenu ||
                  mealMenu?.orderCompleted ||
                  weeksSelected !== weeks.length ||
                  isMonthlyMenuSaved
                }
              >
                {t('ACTIONS.PREVIEW_AND_SAVE_MEAL_CHOICE')}
              </Button>
            </Tooltip>
          </div>
        </div>
        <div className="food-management__menu">
          {isError && t('FOOD_MANAGEMENT.MESSAGES.NO_MEAL_PLANS')}
          {isSuccess &&
            weeks?.map((field, index) => {
              if (isWeekOptionAvailableForField(field)) {
                return (
                  <RadioGroup
                    name={`weeks.${index}.type`}
                    value={weeks[index].type}
                    key={index}
                  >
                    <div
                      className={`food-management__menu__item ${
                        weeks.length > 0 &&
                        weeks[index].type &&
                        +weeks[index].type! === MealType.Meat
                          ? 'food-management__menu__item-selected'
                          : ''
                      }`}
                    >
                      <div className="mb-6">
                        <Radio
                          checked={
                            weeks[index].type === MealType.Meat.toString()
                          }
                          name={`weeks.${index}.type`}
                          value={MealType.Meat.toString()}
                          className="food-management__menu__item__radio"
                          disabled={
                            mealMenu.orderCompleted || isMonthlyMenuSaved
                          }
                          onChange={() =>
                            onMealChosen({
                              type: MealType.Meat,
                              weekIndex: weeks[index].weekIndex,
                            })
                          }
                        >
                          {t('FOOD_MANAGEMENT.LABELS.NON_VEGETARIAN')}
                        </Radio>
                      </div>
                      <FilePreview fileId={weeks[index].meatFileId} />
                    </div>
                    <div
                      className={`food-management__menu__item ${
                        weeks.length > 0 &&
                        weeks[index].type &&
                        +weeks[index].type! === MealType.Vegeterian
                          ? 'food-management__menu__item-selected'
                          : ''
                      }`}
                    >
                      <div className="mb-6">
                        <Radio
                          checked={
                            weeks[index].type === MealType.Vegeterian.toString()
                          }
                          name={`weeks.${index}.type`}
                          value={MealType.Vegeterian.toString()}
                          className="food-management__menu__item__radio"
                          disabled={
                            mealMenu.orderCompleted || isMonthlyMenuSaved
                          }
                          onChange={() =>
                            onMealChosen({
                              type: MealType.Vegeterian,
                              weekIndex: weeks[index].weekIndex,
                            })
                          }
                        >
                          {t('FOOD_MANAGEMENT.LABELS.VEGETARIAN')}
                        </Radio>
                      </div>
                      <FilePreview fileId={weeks[index].vegFileId} />
                    </div>
                  </RadioGroup>
                );
              }
            })}
        </div>
      </form>
    </FormProvider>
  );
}
