import { useMutation } from '@tanstack/react-query';
import type { TermReportFormDto } from '../../models/term-reports/term-report.dto';
import apiRequest from '../../services/apiWrapper';
import { TERM_REPORTS_URL } from '../../utils/constants/urlConstants';

async function updateTermReportById(
  id: string | null,
  updateTermReport: TermReportFormDto
) {
  return apiRequest.put(`${TERM_REPORTS_URL}/${id}`, updateTermReport);
}

export function useUpdateTermReportById(id: string | null) {
  return useMutation({
    mutationFn: (updateTermReport: TermReportFormDto) =>
      updateTermReportById(id, updateTermReport),
  });
}
