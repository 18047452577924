import { Button, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useDuplicateEvaluationPlanById } from '../../../hooks/evaluation-plans/useDuplicateEvaluationPlan';
import { useModal } from '../../../hooks/useModal';
import { DUPLICATE_PLAN } from '../../../utils/constants/evaluation-plans/modals';
import { Dialog } from '../../shared/components/Dialog';

export const DuplicateEvaluationPlan = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const { t } = useTranslation();
  const notification = useNotifications();
  const queryClient = useQueryClient();
  const { close: closeModal } = useModal(DUPLICATE_PLAN);
  const duplicateEvaluationPlan = useDuplicateEvaluationPlanById();

  const onDuplidateEvaluationPlan = () => {
    duplicateEvaluationPlan
      .mutateAsync(id!)
      .then(() => {
        notification.success(
          t('EVALUATION_PLANS.MESSAGES.SUCCESSFULLY_DUPLICATED')
        );
        queryClient.invalidateQueries({ queryKey: ['my-evaluation-plans'] });
        queryClient.invalidateQueries({ queryKey: ['evaluation-plans'] });

        closeModal();
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  return (
    <Dialog
      onClose={() => closeModal()}
      dialogHeader={t('EVALUATION_PLANS.TITLES.DUPLICATE_PLAN')}
      actionButton={
        <Button onClick={onDuplidateEvaluationPlan}>
          {t('ACTIONS.CONTINUE')}
        </Button>
      }
    >
      <h2>{t('EVALUATION_PLANS.MODALS.DUPLICATE')}</h2>
    </Dialog>
  );
};
