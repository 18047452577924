import { useQuery } from '@tanstack/react-query';
import type { FileDetailsResponseDto } from '../../models/files/file-details-response.dto';
import apiRequest from '../../services/apiWrapper';
import {
  FILES_URL,
  PUBLIC_FILES_URL,
} from '../../utils/constants/urlConstants';

async function getFile(
  fileId: string,
  invitationId?: string,
  shouldOpen?: boolean
) {
  const url = invitationId
    ? `${PUBLIC_FILES_URL}/${fileId}?invitationId=${invitationId}`
    : `${FILES_URL}/${fileId}?shouldOpen=${shouldOpen ? 'true' : 'false'}`;
  const response = await apiRequest.get<FileDetailsResponseDto>(url);
  return response.data;
}

export function useGetFile({
  fileId,
  invitationId,
  enabled,
  shouldOpen,
}: {
  fileId: string;
  invitationId?: string;
  enabled: boolean;
  shouldOpen?: boolean;
}) {
  return useQuery({
    queryKey: ['file', fileId],
    queryFn: () => getFile(fileId, invitationId, shouldOpen),
    enabled,
  });
}
