import { Skeleton } from '@ph-react-ui/core';

export const MonthPaginationSkeleton = () => {
  return (
    <div className="month-pagination-skeleton">
      <Skeleton width="1.5rem" height="1.5rem" />
      <Skeleton width="1.5rem" height="1.5rem" />
      <Skeleton width="10rem" height="1.5rem" />
    </div>
  );
};
