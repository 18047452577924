import axios from 'axios';
import type { AxiosError } from 'axios';
import type { ResponseError } from '../models/auth/api-wrapper';

export const createAPIError = (error: any): ResponseError => {
  if (axios.isAxiosError(error)) {
    const response = (error as AxiosError<ResponseError>).response;
    if (response) {
      if (Array.isArray(response.data.errors)) {
        return {
          errors: response.data.errors,
          status: response.status,
        };
      } else {
        return {
          errors: ['UNKNOWN_ERROR'],
          status: response.status,
        };
      }
    }
  }
  return {
    errors: Object.hasOwn(error, 'message') ? error.message : ['UNKNOWN_ERROR'],
    status: Object.hasOwn(error, 'status') ? error.status : 0,
  };
};
