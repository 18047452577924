import { useMutation } from '@tanstack/react-query';
import apiRequest from '../../../../services/apiWrapper';
import { TERM_REPORT_SECTIONS_URL } from '../../../../utils/constants/urlConstants';

interface TermReportSectionData {
  content: string;
}

async function updateTermReportSectionById(
  id: string | null,
  data: TermReportSectionData
) {
  return apiRequest.put(`${TERM_REPORT_SECTIONS_URL}/${id}`, data);
}

export function useUpdateTermReportSectionById(id: string | null) {
  return useMutation({
    mutationFn: (data: TermReportSectionData) =>
      updateTermReportSectionById(id, data),
  });
}
