import { DropPhoto, TrashCan } from '@carbon/icons-react';
import { Button } from '@ph-react-ui/core';
import { useState } from 'react';
import type { FieldArrayMethodProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { RenderArgs } from '../../../models/files/file-upload';
import type { FileDetailsDto } from '../../../models/users/files/file-details.dto';
import {
  DOCUMENTS_ALLOWED_EXTENSIONS,
  MAX_DOCUMENTS_FILE_SIZE,
} from '../../../utils/constants/files/filesConstants';
import type { DocumentType } from '../../../utils/enums/document-type.enum';
import { isValidFileExtension } from '../../../utils/files/isValidFileExtension';
import { isValidFileSize } from '../../../utils/files/isValidFileSize';
import { DialogFileType } from '../../users/create-edit/forms/common/DialogFileType';
import { BaseFileUpload } from './BaseFileUpload';

interface FileUploadProps {
  appendFile: (
    value: FileDetailsDto | FileDetailsDto[],
    options?: FieldArrayMethodProps | undefined
  ) => void;
  invitationId?: string;
}

export function FileUpload({ appendFile, invitationId }: FileUploadProps) {
  const { t } = useTranslation();
  const [fileType, setFileType] = useState<DocumentType | null>(null);

  const handleChangeFileType = (val: DocumentType | null) => {
    setFileType(val);
  };

  const renderHeader = ({ file, onRemoveFile }: RenderArgs) => {
    return (
      <>
        <h3 className="upload__file__title">
          {t('UPLOAD_FILES.TITLES.ATTACH_DOCUMENTS')}
        </h3>
        <DropPhoto className="upload__file__icon" />
        <div className="upload__file__text">
          {file ? (
            <div className="upload__file__text-selected">
              {file.name}
              <button
                className="upload__file__text__button"
                onClick={() => {
                  onRemoveFile();
                }}
              >
                <TrashCan />
              </button>
            </div>
          ) : (
            <div>{t('UPLOAD_FILES.MESSAGES.CHOOSE_FILE')}</div>
          )}
        </div>
      </>
    );
  };

  const renderFooter = ({
    file,
    onFileSelected,
    onRemoveFile,
    emitFileUpload,
    isUploading,
  }: RenderArgs) => {
    const canAddFileType =
      file &&
      isValidFileSize(file, MAX_DOCUMENTS_FILE_SIZE) &&
      isValidFileExtension(file, DOCUMENTS_ALLOWED_EXTENSIONS);

    const onFileUpload = async () => {
      if (file && fileType !== null) {
        const data = await emitFileUpload(file, fileType);
        if (data) {
          const { id } = data;
          onSuccessfulFileUpload(id, file, fileType);
        }
      }
    };

    const onSuccessfulFileUpload = (
      id: string,
      file: File,
      fileType: DocumentType
    ) => {
      appendFile({
        id,
        fileName: file.name,
        fileType: fileType,
        uploadDate: new Date().toString(),
        notAttached: true,
      });
      onRemoveFile();
    };

    return (
      <>
        <div className="upload__file__actions">
          {canAddFileType ? (
            <DialogFileType
              title={file ? file.name : ''}
              closeModal={onRemoveFile}
              fileType={fileType}
              setFileType={handleChangeFileType}
              fileUpload={onFileUpload}
            />
          ) : (
            <Button
              compact
              type="button"
              onClick={onFileSelected}
              disabled={isUploading}
            >
              {t('UPLOAD_FILES.ACTIONS.ATTACH')}
            </Button>
          )}
        </div>
        <div className="upload__file__text mt-5">
          <p className="mb-2">{t('UPLOAD_FILES.MESSAGES.ALLOWED_TYPES')}</p>
          <p>{t('UPLOAD_FILES.MESSAGES.MAX_FILE_SIZE')}</p>
        </div>
      </>
    );
  };

  return (
    <BaseFileUpload
      invitationId={invitationId}
      allowedTypes=".doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .pdf, .jpg, .jpeg, .png"
      renderHeader={renderHeader}
      renderFooter={renderFooter}
      validationFns={[
        (file: File) => isValidFileSize(file, MAX_DOCUMENTS_FILE_SIZE),
        (file: File) =>
          isValidFileExtension(file, DOCUMENTS_ALLOWED_EXTENSIONS),
      ]}
      validationErrors={['DOCUMENT_FILE_TOO_LARGE', 'INVALID_EXTENSION']}
    />
  );
}
