import { useQuery } from '@tanstack/react-query';
import type { AdminDetailsDto } from '../../../models/users/admins/admin-details.dto';
import apiRequest from '../../../services/apiWrapper';
import { ADMINS_URL } from '../../../utils/constants/urlConstants';

async function getAdminById(id: string | null) {
  const response = await apiRequest.get<AdminDetailsDto>(`${ADMINS_URL}/${id}`);

  return response.data;
}

export function useGetAdminById(id: string | null, enabled: boolean = true) {
  return useQuery({
    queryKey: ['admin', id],
    queryFn: () => getAdminById(id),
    enabled,
  });
}
