import { useMutation } from '@tanstack/react-query';
import type { EmployeeUpdateDto } from '../../../models/users/employees/employee-update.dto';
import apiRequest from '../../../services/apiWrapper';

async function updateEmployeeById(
  id: string | null,
  updateEmployeeData: EmployeeUpdateDto
) {
  return apiRequest.put(`/api/users/employees/${id}`, updateEmployeeData);
}

export function useUpdateEmployeeById(id: string | null) {
  return useMutation({
    mutationFn: (updateEmployeeData: EmployeeUpdateDto) =>
      updateEmployeeById(id, updateEmployeeData),
  });
}
