export const LOGIN_URL = '/api/auth/sign-in';
export const PASSWORD_URL = '/api/auth/password/reset';
export const REFRESH_TOKEN = '/api/auth/token/refresh';
export const LOGOUT_URL = '/api/auth/token/revoke';
export const USERS_URL = 'api/users';
export const USERS_PROFILES_URL = '/api/users/profiles';
export const PARENTS_URL = '/api/users/parents';
export const STUDENTS_URL = 'api/users/students';
export const STUDENT_URL = 'api/student';
export const EMPLOYEES_URL = 'api/users/employees';
export const ADMINS_URL = '/api/users/admins';
export const GROUPS_URL = '/api/users/groups';
export const PUBLIC_ADMIN_URL = '/api/public/users/admins';
export const PUBLIC_PARENT_URL = 'api/public/users/parents';
export const PUBLIC_CANDIDATE_URL = 'api/public/users/candidates';
export const PUBLIC_EMPLOYEE_URL = 'api/public/users/employees';
export const FILES_URL = '/api/files';
export const PUBLIC_FILES_URL = '/api/public/files';
export const SETTINGS_URL = '/api/global-settings';
export const PAYMENTS_URL = '/api/payments';
export const MEAL_MENUS_URL = '/api/meal-menus';
export const FOOD_PAYMENTS = '/api/food/payments';
export const SCHOOL_TAX_PAYMENTS = '/api/school-tax/payments';
export const BUS_ROUTES_URL = '/api/transportation/bus-routes';
export const TRANSPORT_PAYMENTS = '/api/transportation/payments';
export const TRANSPORTATION_URL = '/api/transportation';
export const TRANSPORTATION_BUS_STOPS_URL = '/api/transportation/bus-stops';
export const TRANSPORTATION_BUS_STOPS_URL_PARENT =
  '/api/transportation/parents/bus-stops';
export const SCHOOL_TAX_PAYMENT_HISTORY_URL =
  '/api/school-tax/payments/students/history';
export const SCHOOL_TAX_PREVIOUS_UNPAID_TAX =
  '/api/school-tax/payments/students/previous-unpaid-tax';
export const EVALUATION_PLANS_URL = '/api/evaluation-plans';
export const EVALUATIONS_URL = '/api/evaluations';
export const STUDENT_EVALUATIONS_URL = '/api/students';
export const TERM_REPORTS_URL = '/api/term-reports';
export const TERM_REPORT_SECTIONS_URL = '/api/term-report-sections';
export const STUDENT_ABSENCE_URL = '/api/student/absence';
export const EMPLOYEES_ABSENCE_URL = '/api/employees/absences';
export const FAMILY_URL = '/api/users/families';
