import type { SelectOption } from '@ph-react-ui/core';
import i18n from '../i18n';
import { EvaluationScores } from './enums/evaluation-scores.enum';

export function getFormattedEvaluationCriteriaOptions(): SelectOption<
  string | number
>[] {
  return [
    {
      label: i18n.t(
        'EVALUATION_PLANS.EVALUATION_SCORES.EXCEEDS_EXPECTATIONS_TEXT'
      ),
      value: EvaluationScores.ExceedsExpectations,
    },
    {
      label: i18n.t(
        'EVALUATION_PLANS.EVALUATION_SCORES.MEETS_EXPECTATIONS_TEXT'
      ),
      value: EvaluationScores.MeetsExpectations,
    },
    {
      label: i18n.t(
        'EVALUATION_PLANS.EVALUATION_SCORES.APPROACHING_EXPECTATIONS_TEXT'
      ),
      value: EvaluationScores.ApproachingExpectations,
    },
    {
      label: i18n.t(
        'EVALUATION_PLANS.EVALUATION_SCORES.BELOW_EXPECTATIONS_TEXT'
      ),
      value: EvaluationScores.BelowExpectations,
    },
  ];
}
