import { useMutation } from '@tanstack/react-query';
import type { UserCreateDto } from '../../../models/users/user-create.dto';
import apiRequest from '../../../services/apiWrapper';
import { ADMINS_URL } from '../../../utils/constants/urlConstants';

interface CreateAdminWithInviteDto {
  data: UserCreateDto;
  shouldInvite: boolean;
}

async function createAdmin(admin: CreateAdminWithInviteDto) {
  return apiRequest.post<CreateAdminWithInviteDto, void>(ADMINS_URL, admin);
}

export function useCreateAdmin() {
  return useMutation({
    mutationFn: (admin: CreateAdminWithInviteDto) => createAdmin(admin),
  });
}
