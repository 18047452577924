import { useTranslation } from 'react-i18next';
import { Input } from '../../../utils/components/hoc-components';
import {
  getCommonValidators,
  VALIDATION as V,
} from '../../../utils/inputValidators';

export const ParentsInformation = () => {
  const { t } = useTranslation();
  return (
    <div className="drawer__users__form__students__item">
      <div className="mb-6">
        <Input
          name={'parents.0.name'}
          rules={getCommonValidators([V.REQUIRED, V.MAX_LENGTH])}
          className="drawer__users__form__input"
          placeholder={t('ADMISSION.APPLICATION.LABELS.FULLNAME_MOTHER')!}
          label={t('ADMISSION.APPLICATION.LABELS.FULLNAME_MOTHER')!}
        />
      </div>
      <div className="mb-6">
        <Input
          name={'parents.0.email'}
          rules={getCommonValidators([V.REQUIRED, V.EMAIL_PATTERN])}
          className="drawer__users__form__input"
          placeholder={t('ADMISSION.APPLICATION.LABELS.EMAIL_MOTHER')!}
          label={t('ADMISSION.APPLICATION.LABELS.EMAIL_MOTHER')!}
        />
      </div>
      <div className="mb-6">
        <Input
          name={'parents.0.phoneNumber'}
          rules={getCommonValidators([V.REQUIRED, V.MAX_LENGTH])}
          className="drawer__users__form__input"
          placeholder={t('ADMISSION.APPLICATION.LABELS.PHONE_MOTHER')!}
          label={t('ADMISSION.APPLICATION.LABELS.PHONE_MOTHER')!}
        />
      </div>
      <div className="mb-6">
        <Input
          name={'parents.0.workAddress'}
          rules={getCommonValidators([V.REQUIRED, V.MAX_LENGTH])}
          className="drawer__users__form__input"
          placeholder={t('ADMISSION.APPLICATION.LABELS.WORKPLACE_MOTHER')!}
          label={t('ADMISSION.APPLICATION.LABELS.WORKPLACE_MOTHER')!}
        />
      </div>
      <div className="mb-6">
        <Input
          name={'parents.1.name'}
          rules={getCommonValidators([V.REQUIRED, V.MAX_LENGTH])}
          className="drawer__users__form__input"
          placeholder={t('ADMISSION.APPLICATION.LABELS.FULLNAME_FATHER')!}
          label={t('ADMISSION.APPLICATION.LABELS.FULLNAME_FATHER')!}
        />
      </div>
      <div className="mb-6">
        <Input
          name={'parents.1.email'}
          rules={getCommonValidators([V.REQUIRED, V.EMAIL_PATTERN])}
          className="drawer__users__form__input"
          placeholder={t('ADMISSION.APPLICATION.LABELS.EMAIL_FATHER')!}
          label={t('ADMISSION.APPLICATION.LABELS.EMAIL_FATHER')!}
        />
      </div>
      <div className="mb-6">
        <Input
          name={'parents.1.phoneNumber'}
          rules={getCommonValidators([V.REQUIRED, V.MAX_LENGTH])}
          className="drawer__users__form__input"
          placeholder={t('ADMISSION.APPLICATION.LABELS.PHONE_FATHER')!}
          label={t('ADMISSION.APPLICATION.LABELS.PHONE_FATHER')!}
        />
      </div>
      <div className="mb-6">
        <Input
          name={'parents.1.workAddress'}
          rules={getCommonValidators([V.REQUIRED, V.MAX_LENGTH])}
          className="drawer__users__form__input"
          placeholder={t('ADMISSION.APPLICATION.LABELS.WORKPLACE_FATHER')!}
          label={t('ADMISSION.APPLICATION.LABELS.WORKPLACE_FATHER')!}
        />
      </div>
    </div>
  );
};
