import { Download, TrashCan } from '@carbon/icons-react';
import { Button } from '@ph-react-ui/core';
import type { FieldArrayMethodProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import { useGetFile } from '../../../hooks/files/useGetFile';
import type { RenderArgs } from '../../../models/files/file-upload';
import {
  FOOD_MENU_EXTENSIONS,
  MAX_DOCUMENTS_FILE_SIZE,
} from '../../../utils/constants/files/filesConstants';
import { DocumentType } from '../../../utils/enums/document-type.enum';
import { isValidFileExtension } from '../../../utils/files/isValidFileExtension';
import { isValidFileSize } from '../../../utils/files/isValidFileSize';
import { BaseFileUpload } from './BaseFileUpload';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString();

interface PreviewFileUploadProps {
  appendFile: (id: string, options?: FieldArrayMethodProps | undefined) => void;
  id?: string;
}

export function PreviewFileUpload({ appendFile, id }: PreviewFileUploadProps) {
  const { t } = useTranslation();
  const { data: fileResponse } = useGetFile({
    fileId: id!,
    enabled: Boolean(id),
  });
  const renderHeader = ({ file }: RenderArgs) => {
    return (
      <>
        {file || fileResponse ? (
          <Document
            className="upload__file__preview"
            file={fileResponse ? fileResponse.uri : file}
          >
            <Page
              className="upload__file__preview__page"
              pageNumber={1}
              renderTextLayer={false}
              renderAnnotationLayer={false}
            ></Page>
            {file && (
              <h4 className="upload__file__preview__page__file__name">
                {file.name}
              </h4>
            )}
          </Document>
        ) : (
          <h3 className="upload__file__title upload__file__preview__title">
            {t('UPLOAD_FILES.MESSAGES.CHOOSE_FILE')}
          </h3>
        )}
      </>
    );
  };

  const renderFooter = ({
    error,
    file,
    filled,
    isUploading,
    onFileSelected,
    emitFileUpload,
    onRemoveFile,
    setFilled,
  }: RenderArgs) => {
    const onFileUpload = async () => {
      if (file) {
        const data = await emitFileUpload(file, DocumentType.MealMenu);
        if (data) {
          const { id } = data;
          appendFile(id);
          setFilled(true);
        }
      }
    };
    if (fileResponse) {
      return (
        <button
          type="button"
          className="upload__file__preview__zoom__button"
          onClick={() => window.open(fileResponse.uri)}
        >
          <Download className="upload__file__preview__zoom__button__icon" />
          {t('UPLOAD_FILES.ACTIONS.DOWNLOAD_FILE')}
        </button>
      );
    }
    return file || fileResponse ? (
      filled ? (
        <Button
          compact
          type="button"
          onClick={onFileSelected}
          disabled={isUploading}
          className="upload__file__preview__reattach"
        >
          {t('UPLOAD_FILES.ACTIONS.REATTACH')}
        </Button>
      ) : (
        <div className="upload__file__required__item__actions">
          <Button
            compact
            type="button"
            onClick={onFileUpload}
            disabled={isUploading || Boolean(error) || Boolean(fileResponse)}
          >
            {t('UPLOAD_FILES.ACTIONS.UPLOAD')}
          </Button>
          <button
            disabled={Boolean(fileResponse)}
            className={`upload__file__text__button 
                upload__file__required__item__icon 
                upload__file__required__item__icon-trash
                ${
                  Boolean(fileResponse)
                    ? 'upload__file__required__item__icon-disabled'
                    : ''
                }`}
            onClick={() => {
              onRemoveFile();
            }}
          >
            <TrashCan />
          </button>
        </div>
      )
    ) : (
      <Button
        compact
        type="button"
        variant="outlined"
        onClick={onFileSelected}
        disabled={isUploading}
        className="upload__file__preview__attach__button"
      >
        {t('UPLOAD_FILES.ACTIONS.ATTACH')}
      </Button>
    );
  };

  return (
    <BaseFileUpload
      className="upload__file__preview"
      allowedTypes=".pdf"
      renderHeader={renderHeader}
      renderFooter={renderFooter}
      validationFns={[
        (file: File) => isValidFileSize(file, MAX_DOCUMENTS_FILE_SIZE),
        (file: File) => isValidFileExtension(file, FOOD_MENU_EXTENSIONS),
      ]}
      validationErrors={['INVALID_EXTENSION']}
    />
  );
}
