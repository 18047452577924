import { useMutation } from '@tanstack/react-query';
import apiRequest from '../../services/apiWrapper';
import { TERM_REPORTS_URL } from '../../utils/constants/urlConstants';

async function submitTermReportForApprovalById(id: string | null) {
  return apiRequest.put(`${TERM_REPORTS_URL}/${id}/submit-review`, {});
}

export function useSubmitTermReportForApprovalById() {
  return useMutation({
    mutationFn: (id: string | null) => submitTermReportForApprovalById(id),
  });
}
