import { useMutation } from '@tanstack/react-query';
import type { WeeklyLockedSelection } from '../../../models/meal-menus/student/student-meal-menu-details.dto';
import apiRequest from '../../../services/apiWrapper';
import { MEAL_MENUS_URL } from '../../../utils/constants/urlConstants';

interface UpdateStudentMealMenuArgs {
  studentId: string;
  weeklySelections: WeeklyLockedSelection[] | undefined;
}

async function lockStudentMonthlyMealMenu({
  studentId,
  weeklySelections,
}: UpdateStudentMealMenuArgs) {
  const response = await apiRequest.post(
    `${MEAL_MENUS_URL}/students/${studentId}/lock`,
    weeklySelections
  );

  return response.data;
}

export function useLockStudentMonthlyMealMenu() {
  return useMutation({
    mutationFn: (data: UpdateStudentMealMenuArgs) =>
      lockStudentMonthlyMealMenu(data),
  });
}
