import type { SelectOption } from '@ph-react-ui/core';
import { useQuery } from '@tanstack/react-query';
import apiRequest from '../../../services/apiWrapper';
import { STUDENTS_URL } from '../../../utils/constants/urlConstants';

export interface StudentsDto {
  id: string;
  name: string;
}

async function fetchStudents() {
  const response = await apiRequest.get<StudentsDto[]>(STUDENTS_URL);

  return response.data;
}

const mapStudentsToSelectOption = (
  students: StudentsDto[]
): SelectOption<string>[] => {
  return students.map((student) => ({
    label: student.name,
    value: student.id,
  }));
};

export function useGetAllStudents() {
  return useQuery({
    queryKey: ['students'],
    queryFn: () => fetchStudents(),
    select: mapStudentsToSelectOption,
  });
}

export function useGetUnmappedStudents() {
  return useQuery({
    queryKey: ['unmapped-students'],
    queryFn: () => fetchStudents(),
  });
}
