import { Add, TrashCan } from '@carbon/icons-react';
import {
  Button,
  Checkbox,
  Drawer,
  Table,
  useNotifications,
} from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useGetBusStopById } from '../../../../hooks/transportation/routes/useGetBusStopById';
import { useGetAllStudentsAssignedToBusStop } from '../../../../hooks/transportation/students/useGetAllStudentsAssignedToBusStop';
import { useUnassignTransportationPlanForStudent } from '../../../../hooks/transportation/students/useUnassignTransportationPlanForStudent';
import { useModal } from '../../../../hooks/useModal';
import { useSelectAll } from '../../../../hooks/useSelectAll';
import type { UnassignStudentsFromBusStopDto } from '../../../../models/transportation/students/unassign-students-from-bus-stop.dto';
import { BUS_STOP_DRAWER } from '../../../../utils/constants/transportation/modals';
import { TableSkeleton } from '../../../shared/components/skeletons/TableSkeleton';
import { DeleteConfirmationDialog } from './DeleteConfirmationDialog';
import { StudentsAvailableForBusStopModal } from './StudentsAvailableForBusStopModal';

export function BusStopDrawer() {
  const notification = useNotifications();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [isStudentsModelOpen, setIsStudentsModelOpen] = useState(false);
  const [studentsToUnassign, setStudentsToUnassign] = useState<string[]>([]);
  const [searchParams] = useSearchParams();
  const busStopId = searchParams.get('busStopId') ?? '';
  const busStopType = searchParams.get('busStopType') ?? '';

  const { data, isSuccess } = useGetAllStudentsAssignedToBusStop({
    busStopId,
    busStopType: +busStopType,
  });

  const { data: busStop } = useGetBusStopById(busStopId);

  const unassignStudentFromBusStop = useUnassignTransportationPlanForStudent();

  const onOpenAddStudentModal = () => {
    setIsStudentsModelOpen(true);
  };

  const { control, handleSelectAll, selectAll, getSelectedItems } =
    useSelectAll(data?.students);

  const headers = [
    <div className="users__table__select-all">
      <Checkbox
        onChange={(checked) => handleSelectAll(checked)}
        value={selectAll}
      />
      {t('TRANSPORTATION_MANAGEMENT.TABLE.HEADERS.STUDENT')}
    </div>,
    t('TRANSPORTATION_MANAGEMENT.TABLE.HEADERS.ACTION'),
  ];

  const unassignStudents = useCallback(
    (data: UnassignStudentsFromBusStopDto) => {
      unassignStudentFromBusStop
        .mutateAsync(data)
        .then(() => {
          setStudentsToUnassign([]);
          queryClient.invalidateQueries({ queryKey: ['transportation-usage'] });
          queryClient.invalidateQueries({
            queryKey: ['students-on-bus-stop', busStopId],
          });
          queryClient.invalidateQueries({
            queryKey: ['available-students-bus-stop', busStopId, busStopType],
          });
          queryClient.invalidateQueries({ queryKey: ['bus-routes'] });
          notification.success(
            t(
              `TRANSPORTATION_MANAGEMENT.MESSAGES.STUDENT_UNASSIGNED_FROM_BUS_STOP_SUCCESSFULLY`
            )
          );
        })
        .catch((resError) => {
          notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
        });
    },
    [
      busStopId,
      busStopType,
      notification,
      queryClient,
      t,
      unassignStudentFromBusStop,
    ]
  );

  const onRemoveStudent = useCallback(
    (studentIds: string[]) => {
      const formData = {
        studentIds,
        busStopId: busStopId,
        busStopType: +busStopType,
      };
      unassignStudents(formData);
    },
    [busStopId, unassignStudents, busStopType]
  );

  const onOpenRemoveStudentDialog = useCallback((id: string | string[]) => {
    if (Array.isArray(id)) {
      setStudentsToUnassign(id);
    } else {
      setStudentsToUnassign([id]);
    }
  }, []);

  const tableChildren = useMemo(() => {
    return data?.students?.reduce((acc: any, student, index) => {
      let userArray = [
        <div className="users__table__checkbox">
          <Controller
            key={student.id}
            name={`items.${index}.value`}
            control={control}
            render={({ field }) => (
              <>
                <Checkbox className="users__table-first" {...field} />
              </>
            )}
          />
          {student.name}
        </div>,
        <div className="transportation-management__users__table__actions">
          <button
            className="transportation-management__users__table__button transportation-management__users__table__button-delete"
            onClick={() => onOpenRemoveStudentDialog(student.id)}
          >
            <TrashCan />
          </button>
        </div>,
      ];
      return [...acc, userArray];
    }, []);
  }, [control, data, onOpenRemoveStudentDialog]);

  const { close: closeModal } = useModal(BUS_STOP_DRAWER);
  const onCloseDrawer = () => {
    closeModal();
  };
  return (
    <Drawer
      header={busStop?.address ?? ''}
      footer={
        <div className="drawer__transportation-management__form__actions">
          <Button
            onClick={onCloseDrawer}
            type="button"
            className="drawer__transportation-management__form__actions__cancel"
            variant="outlined"
          >
            {t('ACTIONS.CANCEL')}
          </Button>
        </div>
      }
    >
      <div className="transportation-management__users">
        <div className="transportation-management__users__actions">
          {isSuccess && data.students.length > 0 && (
            <Button
              compact
              disabled={getSelectedItems()?.length === 0}
              onClick={() => onOpenRemoveStudentDialog(getSelectedItems())}
            >
              {t('ACTIONS.UNASSIGN')}
            </Button>
          )}
        </div>
        {isSuccess ? (
          data.students.length > 0 ? (
            <Table headers={headers} rows={tableChildren} />
          ) : (
            <p className="transportation-management__users__text">
              {t('TRANSPORTATION_MANAGEMENT.MESSAGES.NO_STUDENTS_ON_BUS_STOP')}
            </p>
          )
        ) : (
          <TableSkeleton headers={headers} />
        )}
        <div className="drawer__transportation-management__form__add-stop mt-4">
          <button
            type="button"
            className="drawer__transportation-management__form__add-stop__button"
            onClick={onOpenAddStudentModal}
          >
            <Add className="drawer__transportation-management__form__add-stop__button__icon" />
            <span className="ml-1">
              {t('TRANSPORTATION_MANAGEMENT.DRAWER.TITLES.ADD_MORE_STUDENTS')}
            </span>
          </button>
        </div>
      </div>
      {studentsToUnassign.length > 0 && (
        <DeleteConfirmationDialog
          title={t('TRANSPORTATION_MANAGEMENT.DRAWER.TITLES.DELETE_STUDENTS')}
          unassignAction={setStudentsToUnassign}
          handleRemove={() => onRemoveStudent(studentsToUnassign)}
          paragraphText={t(
            'TRANSPORTATION_MANAGEMENT.DRAWER.LABELS.DELETE_STUDENTS'
          )}
        />
      )}
      {isStudentsModelOpen && (
        <StudentsAvailableForBusStopModal
          setIsStudentsModelOpen={setIsStudentsModelOpen}
          busStopId={busStopId}
          busStopType={busStopType}
        />
      )}
    </Drawer>
  );
}
