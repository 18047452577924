import { useQuery } from '@tanstack/react-query';
import type { SchoolYearBaseSettingsDTO } from '../../models/settings/school-year-base-settings.dto';
import apiRequest from '../../services/apiWrapper';
import { mapStringToSimpleDate } from '../../services/mapStringToSimpleDate';
import { SETTINGS_URL } from '../../utils/constants/urlConstants';

async function getSchoolYearBaseSettings() {
  const res = await apiRequest.get<SchoolYearBaseSettingsDTO>(
    `${SETTINGS_URL}/general/school-year`
  );

  return res.data;
}

export function useGetSchoolYearBaseSettings() {
  return useQuery({
    queryKey: ['school-year-settings'],
    queryFn: () => getSchoolYearBaseSettings(),
    select: (data) => ({
      startOfSchoolYear: data?.startOfSchoolYear
        ? mapStringToSimpleDate(data?.startOfSchoolYear)
        : null,
      endOfSchoolYear: data?.startOfSchoolYear
        ? mapStringToSimpleDate(data?.endOfSchoolYear)
        : null,
    }),
  });
}
