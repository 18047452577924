import { useQuery } from '@tanstack/react-query';
import type { AdminDetailsDto } from '../../../models/users/admins/admin-details.dto';
import publicApiRequest from '../../../services/publicApiWrapper';
import { PUBLIC_ADMIN_URL } from '../../../utils/constants/urlConstants';

async function getAdminData(invitationId: string | null) {
  const response = await publicApiRequest.get<AdminDetailsDto>(
    `${PUBLIC_ADMIN_URL}?invitationId=${invitationId}`
  );

  return response.data;
}

export function useGetPublicAdminById(
  invitationId: string | null,
  enabled: boolean = true
) {
  return useQuery({
    queryKey: ['public-admin', invitationId],
    queryFn: () => getAdminData(invitationId),
    select: (data) => ({ name: data.name, email: data.email }),
    enabled,
  });
}
