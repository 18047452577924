import { Skeleton } from '@ph-react-ui/core';

export function SimpleSkeleton({ rows = 3 }) {
  const rowsArr = Array.from({ length: rows });
  return (
    <div className="mt-2">
      {rowsArr.map((_, index) => (
        <div key={index}>
          <Skeleton height="20px" className="mt-2" borderRadius="medium" />
        </div>
      ))}
    </div>
  );
}
