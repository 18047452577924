import { useQuery } from '@tanstack/react-query';
import type { PaginatedList } from '../../models/common/paginated-list.model';
import type { TermReportDetailsDto } from '../../models/term-reports/term-reports-details.dto';
import apiRequest from '../../services/apiWrapper';
import { createPaginatedQueryKeys } from '../../services/createPaginatedQueryKeys';
import { TERM_REPORTS_URL } from '../../utils/constants/urlConstants';

async function fetchTermReports(searchParams: URLSearchParams) {
  const response = await apiRequest.get<PaginatedList<TermReportDetailsDto>>(
    `${TERM_REPORTS_URL}/students?${searchParams.toString()}`
  );
  return response.data;
}

function createQueryKey(searchParams: URLSearchParams) {
  return {
    name: searchParams.get('name'),
    ...createPaginatedQueryKeys(searchParams),
  };
}

export function usePaginatedTermReports(searchParams: URLSearchParams) {
  return useQuery({
    queryKey: ['term-reports', createQueryKey(searchParams)],
    queryFn: () => fetchTermReports(searchParams),
  });
}
