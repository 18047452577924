import { Button, useNotifications } from '@ph-react-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCompleteRegistrationForAdmin } from '../../../../hooks/admission/admin/useCompleteRegistrationForAdmin';
import { useGetPublicAdminById } from '../../../../hooks/admission/admin/useGetPublicAdminById';
import { FormSkeleton } from '../../../shared/components/skeletons/FormSkeleton';
import { BasicInfoSection } from '../../../users/create-edit/forms/common/BasicInfoSection';
import { PasswordRequirements } from '../common/PasswordRequirements';
import { PasswordSection } from '../common/PasswordSection';

interface CompleteAdminProfileForm {
  name?: string | undefined;
  email?: string | undefined;
  newPassword: string;
  confirmationPassword: string;
}
export function CompleteProfileAdmin() {
  const { t } = useTranslation();
  const notification = useNotifications();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const invitationId = searchParams.get('invitationId');
  const { data: admin, isLoading } = useGetPublicAdminById(
    invitationId,
    Boolean(invitationId)
  );

  const completeRegistration = useCompleteRegistrationForAdmin(invitationId);
  const methods = useForm<CompleteAdminProfileForm>({
    defaultValues: {
      name: '',
      email: '',
      newPassword: '',
      confirmationPassword: '',
    },
    values: { ...admin, newPassword: '', confirmationPassword: '' },
  });

  const password = methods.watch('newPassword');

  const onSubmit = async (data: CompleteAdminProfileForm) => {
    await completeRegistration
      .mutateAsync({
        name: data.name!,
        email: data.email!,
        newPassword: data.newPassword,
      })
      .then(() => {
        notification.success(t('ADMISSION.COMPLETE_PROFILE.MESSAGES.SUCCESS'));
        setTimeout(() => {
          navigate('/');
        }, 3000);
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  if (!invitationId) {
    navigate('/');
  }

  if (isLoading && invitationId) {
    return (
      <div className="admission">
        <FormSkeleton />
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <form
        id="complete-profile-form"
        className="admission__form"
        onSubmit={methods.handleSubmit((data: CompleteAdminProfileForm) =>
          onSubmit(data)
        )}
      >
        <BasicInfoSection />
        <PasswordSection passwordWatcher={methods.watch('newPassword')} />
        <PasswordRequirements password={password} />
      </form>
      <Button
        form="complete-profile-form"
        type="submit"
        disabled={completeRegistration.isPending}
        loading={completeRegistration.isPending}
      >
        {t('ACTIONS.CONTINUE')}
      </Button>
    </FormProvider>
  );
}
