import { useQuery } from '@tanstack/react-query';
import type { PaginatedList } from '../../../models/common/paginated-list.model';
import type { GroupMemberDetailsDto } from '../../../models/groups/group-member-details.dto';
import apiRequest from '../../../services/apiWrapper';
import { createPaginatedQueryKeys } from '../../../services/createPaginatedQueryKeys';
import { GROUPS_URL } from '../../../utils/constants/urlConstants';

async function getGroupMembers(id: string, searchParams: URLSearchParams) {
  const response = await apiRequest.get<PaginatedList<GroupMemberDetailsDto>>(
    `${GROUPS_URL}/${id}/members?${searchParams.toString()}`
  );

  return response.data;
}

function createQueryKey(searchParams: URLSearchParams) {
  return {
    name: searchParams.get('name'),
    ...createPaginatedQueryKeys(searchParams),
  };
}

export function usePaginatedGroupMembers(
  id: string,
  searchParams: URLSearchParams
) {
  return useQuery({
    queryKey: ['members', id, createQueryKey(searchParams)],
    queryFn: () => getGroupMembers(id, searchParams),
    enabled: Boolean(id),
  });
}
