import { Button, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { createPortal } from 'react-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useCancellationDialog } from '../../../../hooks/useCancellationDialog';
import { useModal } from '../../../../hooks/useModal';
import { useCreateAdmin } from '../../../../hooks/users/admins/useCreateAdmin';
import { useGetAdminById } from '../../../../hooks/users/admins/useGetAdminById';
import { useUpdateAdminById } from '../../../../hooks/users/admins/useUpdateAdminById';
import { useInvitationById } from '../../../../hooks/users/useInvitationById';
import type { AdminCreateDto } from '../../../../models/users/admins/admin-create.dto';
import type { AdminUpdateDto } from '../../../../models/users/admins/admin-update.dto';
import type { CreateUserForm } from '../../../../models/users/create-user-form';
import { CREATE_EDIT_USER } from '../../../../utils/constants/users/modals';
import { Status } from '../../../../utils/enums/status.enum';
import { isArchivedUser } from '../../../../utils/isArchivedUser';
import { isNonActiveUser } from '../../../../utils/isNonActiveUser';
import { FormSkeleton } from '../../../shared/components/skeletons/FormSkeleton';
import { BasicInfoSection } from './common/BasicInfoSection';

export function AdminForm({ footerElement }: CreateUserForm) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { open: openCancellationDialog } = useCancellationDialog();
  const notification = useNotifications();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('userId');
  const status = searchParams.get('userStatus');
  const { close: closeModal } = useModal(CREATE_EDIT_USER);
  const { data: user, isLoading } = useGetAdminById(id, Boolean(id));

  const createAdmin = useCreateAdmin();
  const updateAdmin = useUpdateAdminById(id);
  const sendInvitation = useInvitationById();

  const methods = useForm({
    defaultValues: {
      name: '',
      email: '',
    },
    values: user,
  });

  const { isDirty } = methods.formState;

  const onSubmit = async (data: AdminCreateDto, shouldInvite?: boolean) => {
    if (id) {
      updateAdminData(data);
    } else {
      createNewAdmin(data, shouldInvite as boolean);
    }
  };

  const createNewAdmin = async (
    data: AdminCreateDto,
    shouldInvite: boolean
  ) => {
    await createAdmin
      .mutateAsync({
        data,
        shouldInvite,
      })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ['users'] });
        notification.success(t('USERS.DRAWER.MESSAGES.ADMIN_CREATED'));
        closeModal();
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  const updateAdminData = async (data: AdminUpdateDto) => {
    await updateAdmin
      .mutateAsync(data)
      .then(() => {
        notification.success(t('USERS.DRAWER.MESSAGES.ADMIN_UPDATED'));
        queryClient.invalidateQueries({ queryKey: ['admin', id] });
        queryClient.invalidateQueries({ queryKey: ['users'] });
        methods.reset();
        closeModal();
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  const onSendInvitation = async () => {
    await sendInvitation
      .mutateAsync(id!)
      .then(() => {
        notification.success(t('USERS.DRAWER.MESSAGES.INVITATION_SEND'));
        queryClient.invalidateQueries({ queryKey: ['users'] });
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  const onCloseDrawer = () => {
    if (isDirty) {
      openCancellationDialog();
    } else {
      closeModal();
    }
  };

  const isPerformingApiRequest =
    createAdmin.isPending || updateAdmin.isPending || sendInvitation.isPending;

  if (isLoading && id) {
    return <FormSkeleton />;
  }
  return (
    <FormProvider {...methods}>
      <form
        id="admin-form"
        className="drawer__users__form"
        onSubmit={methods.handleSubmit((data) => onSubmit(data, false))}
      >
        <BasicInfoSection />
        {footerElement &&
          createPortal(
            <>
              <Button
                onClick={onCloseDrawer}
                type="button"
                className="drawer__users__form__actions__cancel"
                variant="outlined"
              >
                {t('ACTIONS.CANCEL')}
              </Button>
              <Button
                form="admin-form"
                loading={isPerformingApiRequest}
                onClick={methods.handleSubmit((data) => onSubmit(data, false))}
              >
                {id ? t('ACTIONS.UPDATE') : t('ACTIONS.SAVE')}
              </Button>
              {id ? (
                isNonActiveUser(status) && (
                  <Button
                    onClick={onSendInvitation}
                    loading={isPerformingApiRequest}
                    disabled={
                      isPerformingApiRequest || methods.formState.isDirty
                    }
                  >
                    {+status! === Status.New
                      ? t('USERS.DRAWER.ACTIONS.SEND_INVITATION')
                      : t('USERS.DRAWER.ACTIONS.SEND_INVITATION_AGAIN')}
                  </Button>
                )
              ) : (
                <Button
                  loading={isPerformingApiRequest}
                  onClick={methods.handleSubmit((data) => onSubmit(data, true))}
                  disabled={isArchivedUser(status) || isPerformingApiRequest}
                >
                  {t('USERS.DRAWER.ACTIONS.SAVE_AND_INVITE')}
                </Button>
              )}
            </>,
            footerElement
          )}
      </form>
    </FormProvider>
  );
}
