import { useQuery } from '@tanstack/react-query';
import type { TermReportSectionFormDto } from '../../../models/term-reports/sections/term-report-section.dto';
import apiRequest from '../../../services/apiWrapper';
import { TERM_REPORT_SECTIONS_URL } from '../../../utils/constants/urlConstants';

async function fetchTermReportSection(id: string) {
  const response = await apiRequest.get<TermReportSectionFormDto>(
    `${TERM_REPORT_SECTIONS_URL}/${id}`
  );
  return response.data;
}

export function useGetTermReportSectionById(reportId: string | null) {
  return useQuery({
    queryKey: ['term-report-section', reportId],
    queryFn: () => fetchTermReportSection(reportId!),
    enabled: Boolean(reportId),
  });
}
