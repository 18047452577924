import { useMutation } from '@tanstack/react-query';
import type { GroupCreateEditDto } from '../../models/groups/group-create-edit.dto';
import apiRequest from '../../services/apiWrapper';
import { GROUPS_URL } from '../../utils/constants/urlConstants';

async function updateGroupById(
  id: string | null,
  updateGroupData: GroupCreateEditDto
) {
  return apiRequest.put(`${GROUPS_URL}/${id}`, updateGroupData);
}

export function useUpdateGroupById(id: string | null) {
  return useMutation({
    mutationFn: (updateGroupData: GroupCreateEditDto) =>
      updateGroupById(id, updateGroupData),
  });
}
