import { useMutation } from '@tanstack/react-query';
import type { MarkAsPaidDto } from '../../../models/taxes/mark-as-paid.dto';
import apiRequest from '../../../services/apiWrapper';
import { TRANSPORT_PAYMENTS } from '../../../utils/constants/urlConstants';

async function markAsPaid(data: MarkAsPaidDto) {
  return apiRequest.post(`${TRANSPORT_PAYMENTS}/students/bank-transfer`, data);
}

export function useMarkTransportationAsPaid() {
  return useMutation({
    mutationFn: (data: MarkAsPaidDto) => markAsPaid(data),
  });
}
