import { Search } from '@carbon/icons-react';
import { Input } from '@ph-react-ui/core';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import type { PathMatch } from 'react-router-dom';
import { useDebounce } from '../../../hooks/common/useDebounce';

interface SearchInputProps {
  searchInputName?: string;
  pathMatch: PathMatch<string> | null;
  placeholder: string;
}

export const SearchInput = ({
  pathMatch,
  placeholder = '',
  searchInputName = 'name',
}: SearchInputProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchInput, setSearchInput] = useState(
    searchParams.get(searchInputName) ?? ''
  );
  const debouncedInput = useDebounce(searchInput);

  useEffect(() => {
    if (pathMatch) {
      if (debouncedInput !== searchParams.get(searchInputName)) {
        if (debouncedInput) {
          searchParams.set(searchInputName, debouncedInput);
          searchParams.set('page', '1');
          setSearchParams(searchParams);
        } else {
          if (searchParams.get(searchInputName)) {
            searchParams.delete(searchInputName);
            setSearchParams(searchParams);
          }
        }
      }
    }
  }, [
    searchParams,
    setSearchParams,
    debouncedInput,
    pathMatch,
    searchInputName,
  ]);

  return (
    <>
      <Input
        ref={inputRef}
        value={searchInput}
        onChange={setSearchInput}
        compact
        placeholder={placeholder}
        prefix={
          <Search className="groups__actions__input__icon groups__actions__input__icon--action" />
        }
      />
    </>
  );
};
