import { useQuery } from '@tanstack/react-query';
import type { PaginatedList } from '../../../models/common/paginated-list.model';
import type { TermReportInProgressDetailsDto } from '../../../models/term-reports/term-reports-details.dto';
import apiRequest from '../../../services/apiWrapper';
import { createPaginatedQueryKeys } from '../../../services/createPaginatedQueryKeys';
import { TERM_REPORTS_URL } from '../../../utils/constants/urlConstants';

async function fetchAllTermReports(searchParams: URLSearchParams) {
  const response = await apiRequest.get<
    PaginatedList<TermReportInProgressDetailsDto>
  >(`${TERM_REPORTS_URL}?${searchParams.toString()}`);
  return response.data;
}

function createQueryKey(searchParams: URLSearchParams) {
  return {
    title: searchParams.get('title'),
    status: searchParams.get('status'),
    ...createPaginatedQueryKeys(searchParams),
  };
}

export function usePaginatedAllTermReports(searchParams: URLSearchParams) {
  return useQuery({
    queryKey: ['term-reports-in-progress', createQueryKey(searchParams)],
    queryFn: () => fetchAllTermReports(searchParams),
  });
}
