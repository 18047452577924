import { useQuery } from '@tanstack/react-query';
import type { BusRouteMap } from '../../../models/transportation/routes/bus-routes-map';
import apiRequest from '../../../services/apiWrapper';
import { BUS_ROUTES_URL } from '../../../utils/constants/urlConstants';

async function getBusRoutesMap() {
  const res = await apiRequest.get<BusRouteMap>(`${BUS_ROUTES_URL}/map-image`);

  return res.data;
}

export function useGetBusRoutesMap() {
  return useQuery({
    queryKey: ['bus-routes-map'],
    queryFn: () => getBusRoutesMap(),
    throwOnError: false,
  });
}
