import { useMutation } from '@tanstack/react-query';
import type { BusRouteDTO } from '../../../models/transportation/bus-route.dto';
import apiRequest from '../../../services/apiWrapper';
import { BUS_ROUTES_URL } from '../../../utils/constants/urlConstants';

async function editBusRoute(id: string | null, updateRouteData: BusRouteDTO) {
  return apiRequest.put(`${BUS_ROUTES_URL}/${id}`, updateRouteData);
}

export function useEditBusRoute(id: string | null) {
  return useMutation({
    mutationFn: (updateRouteData: BusRouteDTO) =>
      editBusRoute(id, updateRouteData),
  });
}
