import { useMutation } from '@tanstack/react-query';
import type { TransportationPlanPatchDto } from '../../../models/transportation/routes/transportation-plan-patch.dto';
import apiRequest from '../../../services/apiWrapper';
import { TRANSPORTATION_BUS_STOPS_URL } from '../../../utils/constants/urlConstants';

async function patchTransportationPlanForStudent(
  data: TransportationPlanPatchDto
) {
  return await apiRequest.patch(`${TRANSPORTATION_BUS_STOPS_URL}/assign`, data);
}

export function usePatchTransportationPlanForStudent() {
  return useMutation({
    mutationFn: (data: TransportationPlanPatchDto) =>
      patchTransportationPlanForStudent(data),
  });
}
