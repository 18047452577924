import { useQuery } from '@tanstack/react-query';
import type { EvaluationScoreDetails } from '../../models/evaluation-plans/evaluation-score-details.dto';
import apiRequest from '../../services/apiWrapper';
import { EVALUATIONS_URL } from '../../utils/constants/urlConstants';

async function getEvaluationScoreById({
  evaluationId,
}: {
  evaluationId: string | null;
}) {
  const response = await apiRequest.get<EvaluationScoreDetails>(
    `${EVALUATIONS_URL}/${evaluationId}/review`
  );

  return response.data;
}

export function useGetEvaluationScoreById({
  evaluationId,
}: {
  evaluationId: string | null;
}) {
  return useQuery({
    queryKey: ['student-evaluation-scores', evaluationId],
    queryFn: () => getEvaluationScoreById({ evaluationId }),
    enabled: Boolean(evaluationId),
  });
}
