import { useQuery } from '@tanstack/react-query';
import type { WeeklyReportUserDetailsDto } from '../../../models/meal-menus/admin/weekly-report-user-details.dto';
import apiRequest from '../../../services/apiWrapper';
import { MEAL_MENUS_URL } from '../../../utils/constants/urlConstants';
import { MealType } from '../../../utils/enums/meal-type.enum';
import { Role } from '../../../utils/enums/role.enum';

interface WeeklyReportDetailsDto {
  users: WeeklyReportUserDetailsDto[];
}

async function getWeeklyReportById(id: string, weekIndex: string) {
  const response = await apiRequest.get<WeeklyReportDetailsDto>(
    `${MEAL_MENUS_URL}/${id}/weekly-report?index=${weekIndex}`
  );
  return response.data;
}

export function useGetWeeklyReportById(id: string, weekIndex: string) {
  return useQuery({
    queryKey: ['weekly-report', id, weekIndex],
    queryFn: () => getWeeklyReportById(id, weekIndex),
    select: (data) => filterUsersByMeal(data),
  });
}

function filterUsersByMeal(data: WeeklyReportDetailsDto) {
  return data.users.reduce(
    (acc, user) => {
      if (user.mealType === MealType.Meat && user.role === Role.Student)
        acc.studentMeatMeal.push(user.name);
      else if (
        user.mealType === MealType.Vegeterian &&
        user.role === Role.Student
      )
        acc.studentVegMeal.push(user.name);
      return acc;
    },
    {
      studentMeatMeal: [] as string[],
      studentVegMeal: [] as string[],
    }
  );
}
