import { useQuery } from '@tanstack/react-query';
import { getMonth, getYear } from 'date-fns';
import type { FoodPricePlanResponse } from '../../models/food/payments/food-price-plan.dto';
import apiRequest from '../../services/apiWrapper';
import { FOOD_PAYMENTS } from '../../utils/constants/urlConstants';

async function getFoodPaymentPricePlan(month: string, year: string) {
  const currentDate = new Date();
  let monthToSend = (getMonth(currentDate) + 1).toString();
  let yearToSend = getYear(currentDate).toString();

  if (month && year) {
    monthToSend = month;
    yearToSend = year;
  }

  const response = await apiRequest.get<FoodPricePlanResponse>(
    `${FOOD_PAYMENTS}/students/price-plan?year=${yearToSend}&month=${monthToSend}`
  );

  return response.data;
}

export function useGetFoodPaymentPricePlan(month: string, year: string) {
  return useQuery({
    queryKey: ['food-price-plan'],
    queryFn: () => getFoodPaymentPricePlan(month, year),
    throwOnError: false,
  });
}
