import { CaretUp, CaretDown } from '@carbon/icons-react';
import { useSearchParams } from 'react-router-dom';
import { NO_ORDERING } from '../../../../utils/constants/orderingConstants';
import { OrderingDirection } from '../../../../utils/enums/ordering-direction.enum';

interface ThSortItemProps {
  title: string;
  ordering: number;
}

export function ThSortItem({ title, ordering }: ThSortItemProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const orderingSearchParam = searchParams.get('ordering');
  const orderingDirectionSearchParam = searchParams.get('orderingDirection');

  const orderingDirection = orderingDirectionSearchParam
    ? +orderingDirectionSearchParam
    : NO_ORDERING;

  const alreadySortedByKey = (sortKey: number) =>
    !orderingSearchParam || sortKey.toString() === orderingSearchParam;

  const createSearchParamsByKey = (sortKey: number) => {
    searchParams.set('ordering', sortKey.toString());
    searchParams.set('orderingDirection', '0');
    setSearchParams(searchParams);
  };

  const calculateNewOrderingDirection = () => {
    let newOrderingDirection: number;
    if (orderingDirectionSearchParam) {
      newOrderingDirection = +orderingDirectionSearchParam + 1;
    } else {
      newOrderingDirection = 0;
    }

    return newOrderingDirection;
  };

  const updateSearchParamsByKey = (sortKey: number, direction: number) => {
    searchParams.set('page', '1');
    searchParams.set('ordering', sortKey.toString());
    if (direction < NO_ORDERING) {
      searchParams.set('orderingDirection', direction.toString());
      setSearchParams(searchParams);
    } else {
      searchParams.delete('ordering');
      searchParams.delete('orderingDirection');
      setSearchParams(searchParams);
    }
  };

  const onOrderingChange = (sortKey: number) => {
    if (alreadySortedByKey(sortKey)) {
      const newOrderingDirection = calculateNewOrderingDirection();
      updateSearchParamsByKey(sortKey, newOrderingDirection);
    } else {
      createSearchParamsByKey(sortKey);
    }
  };

  return (
    <button
      type="button"
      className="users__table__headers__item__button"
      onClick={() => onOrderingChange(ordering)}
    >
      <span className="users__table__headers__item__button__text">
        {}
        {title}
      </span>
      <div className="users__table__headers__item__button__sort">
        <CaretUp
          className={`users__table__headers__item__button__sort__icon ${
            Boolean(searchParams.get('ordering')) &&
            searchParams.get('ordering') === ordering.toString() &&
            orderingDirection === OrderingDirection.Descending
              ? 'users__table__headers__item__button__sort__icon-disabled'
              : ''
          }`}
        />
        <CaretDown
          className={`users__table__headers__item__button__sort__icon ${
            Boolean(searchParams.get('ordering')) &&
            searchParams.get('ordering') === ordering.toString() &&
            orderingDirection === OrderingDirection.Ascending
              ? 'users__table__headers__item__button__sort__icon-disabled'
              : ''
          }`}
        />
      </div>
    </button>
  );
}
