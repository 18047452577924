import { useQuery } from '@tanstack/react-query';
import type { GroupDetailsDto } from '../../models/groups/group-details.dto';
import apiRequest from '../../services/apiWrapper';
import { GROUPS_URL } from '../../utils/constants/urlConstants';

async function getGroupById(id: string | null) {
  const response = await apiRequest.get<GroupDetailsDto>(`${GROUPS_URL}/${id}`);

  return response.data;
}

export function useGetGroupById(id: string | null, enabled: boolean = true) {
  return useQuery({
    queryKey: ['group', id],
    queryFn: () => getGroupById(id),
    enabled,
  });
}
