import { useMutation } from '@tanstack/react-query';
import apiRequest from '../../services/apiWrapper';
import { EVALUATION_PLANS_URL } from '../../utils/constants/urlConstants';

async function approveEvaluationPlanById(id: string) {
  return apiRequest.put(`${EVALUATION_PLANS_URL}/${id}/approve`, {});
}

export function useApproveEvaluationPlanById() {
  return useMutation({
    mutationFn: (id: string) => approveEvaluationPlanById(id),
  });
}
