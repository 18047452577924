import { useSearchParams } from 'react-router-dom';
import type { CreateUserForm } from '../../../../models/users/create-user-form';
import { CreateParentForm } from './parents/CreateParentForm';
import { UpdateParentForm } from './parents/UpdateParentForm';

export function ParentForm({ footerElement }: CreateUserForm) {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('userId');

  return id ? (
    <UpdateParentForm footerElement={footerElement} />
  ) : (
    <CreateParentForm footerElement={footerElement} />
  );
}
