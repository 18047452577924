import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import type { ParentUpdateFormDto } from '../../../models/users/parents/parent-update.dto';

export function useGetMainParentProfile() {
  const queryClient = useQueryClient();

  const getMainParent = useCallback(() => {
    const data: ParentUpdateFormDto | undefined = queryClient.getQueryData([
      'authenticated-parent',
    ]);

    return data?.parents.filter((parent) => parent.isPrimaryParent)[0];
  }, [queryClient]);

  return { getMainParent };
}
