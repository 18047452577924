import { Toggle } from '@ph-react-ui/core';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../../../utils/components/hoc-components';
import {
  getCommonValidators,
  VALIDATION as V,
} from '../../../../../utils/inputValidators';

interface ParentInvoicingSectionProps {
  shouldReceiveInvoiceWatch: boolean;
  setReceiveInvoice: () => void;
  prefix?: string;
}

export const ParentInvoicingSection = ({
  shouldReceiveInvoiceWatch,
  setReceiveInvoice,
  prefix = '',
}: ParentInvoicingSectionProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Toggle value={shouldReceiveInvoiceWatch} onChange={setReceiveInvoice}>
        {t('USERS.DRAWER.PLACEHOLDERS.RECIEVE_INVOICE')}
      </Toggle>
      <div className="invoice-wrapper">
        <div className="mb-6">
          <Input
            name={`${prefix}invoiceInformation.invoiceName`}
            rules={
              shouldReceiveInvoiceWatch
                ? getCommonValidators([V.MAX_LENGTH, V.REQUIRED])
                : getCommonValidators([V.MAX_LENGTH, V.NOT_REQUIRED])
            }
            className="drawer__users__form__input"
            placeholder={t('USERS.DRAWER.PLACEHOLDERS.INVOICE_NAME') ?? ''}
            label={t('USERS.DRAWER.LABELS.INVOICE_NAME') ?? ''}
          />
        </div>

        <div className="mb-6">
          <Input
            name={`${prefix}invoiceInformation.invoiceCity`}
            rules={getCommonValidators([V.MAX_LENGTH])}
            className="drawer__users__form__input"
            placeholder={t('USERS.DRAWER.PLACEHOLDERS.INVOICE_CITY') ?? ''}
            label={t('USERS.DRAWER.LABELS.INVOICE_CITY') ?? ''}
          />
        </div>

        <div className="mb-6">
          <Input
            name={`${prefix}invoiceInformation.invoiceStreet`}
            rules={getCommonValidators([V.MAX_LENGTH])}
            className="drawer__users__form__input"
            placeholder={t('USERS.DRAWER.PLACEHOLDERS.INVOICE_STREET') ?? ''}
            label={t('USERS.DRAWER.LABELS.INVOICE_STREET') ?? ''}
          />
        </div>

        <div className="mb-6">
          <Input
            name={`${prefix}invoiceInformation.invoiceZip`}
            rules={getCommonValidators([V.MAX_LENGTH])}
            className="drawer__users__form__input"
            placeholder={t('USERS.DRAWER.PLACEHOLDERS.INVOICE_ZIP') ?? ''}
            label={t('USERS.DRAWER.LABELS.INVOICE_ZIP') ?? ''}
          />
        </div>

        <div className="mb-6">
          <Input
            name={`${prefix}invoiceInformation.invoiceVatNo`}
            rules={getCommonValidators([V.MAX_LENGTH])}
            className="drawer__users__form__input"
            placeholder={t('USERS.DRAWER.PLACEHOLDERS.INVOICE_VAT_NO') ?? ''}
            label={t('USERS.DRAWER.LABELS.INVOICE_VAT_NO') ?? ''}
          />
        </div>

        <div className="mb-6">
          <Input
            name={`${prefix}invoiceInformation.invoiceIdNoBulstat`}
            rules={getCommonValidators([V.MAX_LENGTH])}
            className="drawer__users__form__input"
            placeholder={t('USERS.DRAWER.PLACEHOLDERS.INVOICE_ID') ?? ''}
            label={t('USERS.DRAWER.LABELS.INVOICE_ID') ?? ''}
          />
        </div>
      </div>
    </>
  );
};
