import { useMutation } from '@tanstack/react-query';
import type { TermReportDenyDto } from '../../../models/term-reports/term-report-deny.dto';
import apiRequest from '../../../services/apiWrapper';
import { TERM_REPORTS_URL } from '../../../utils/constants/urlConstants';

async function denyTermReportById(data: TermReportDenyDto) {
  return apiRequest.put(`${TERM_REPORTS_URL}/${data.id}/deny`, {
    comment: data.comment,
  });
}

export function useDenyTermReportById() {
  return useMutation({
    mutationFn: (data: TermReportDenyDto) => denyTermReportById(data),
  });
}
