import { Add, TrashCan } from '@carbon/icons-react';
import {
  Button,
  Checkbox,
  Drawer,
  Table,
  useNotifications,
} from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { usePaginatedAllEvaluationPlans } from '../../../hooks/evaluation-plans/usePaginatedAllEvaluationPlans';
import { useUnassignEvaluationPlanFromGroups } from '../../../hooks/evaluation-plans/useUnassignEvaluationPlanFromGroups';
import { useModal } from '../../../hooks/useModal';
import { useSelectAll } from '../../../hooks/useSelectAll';
import { ASSIGNED_GROUPS } from '../../../utils/constants/evaluation-plans/modals';
import { TableSkeleton } from '../../shared/components/skeletons/TableSkeleton';
import { DeleteConfirmationDialog } from '../../transportation/routes/modals/DeleteConfirmationDialog';
import { AssignEvaluationPlanToGroupsDialog } from './AssignEvaluationPlanToGroupsDialog';

export function AssignedGroupsDrawer() {
  const { t } = useTranslation();
  const notifications = useNotifications();
  const [
    selectedEvaluationPlanAssignedGroups,
    setSelectedEvaluationPlanAssignedGroups,
  ] = useState<{ id: string; name: string }[] | undefined>(undefined);
  const queryClient = useQueryClient();
  const [isAddGroupsModalOpen, setIsAddGroupsModalOpen] = useState(false);
  const [groupsToUnassign, setGroupsToUnassign] = useState<string[]>([]);
  const { close: closeModal } = useModal(ASSIGNED_GROUPS);
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  const onCloseDrawer = () => {
    closeModal();
  };
  const { data: evaluationPlans, isSuccess } =
    usePaginatedAllEvaluationPlans(searchParams);

  const unassignEvaluationPlanFromGroups =
    useUnassignEvaluationPlanFromGroups(id);

  useEffect(() => {
    if (evaluationPlans?.result) {
      const groups =
        evaluationPlans.result.find((ep) => {
          return ep.id === id;
        })?.assignedGroups ?? [];

      setSelectedEvaluationPlanAssignedGroups(groups);
    }
  }, [evaluationPlans, id]);

  const { control, handleSelectAll, selectAll, getSelectedItems } =
    useSelectAll(selectedEvaluationPlanAssignedGroups);

  const headers = [
    <div className="users__table__select-all">
      <Checkbox
        onChange={(checked) => handleSelectAll(checked)}
        value={selectAll}
      />
      {t('EVALUATION_PLANS.TABLE.HEADERS.GROUP_NAME')}
    </div>,
    t('EVALUATION_PLANS.TABLE.HEADERS.ACTIONS'),
  ];

  const tableChildren = useMemo(() => {
    return selectedEvaluationPlanAssignedGroups?.reduce(
      (acc: any, group, index) => {
        let groupArray = [
          <div className="users__table__checkbox">
            <Controller
              key={group.id}
              name={`items.${index}.value`}
              control={control}
              render={({ field }) => (
                <>
                  <Checkbox className="users__table-first" {...field} />
                </>
              )}
            />
            {group.name}
          </div>,
          <div className="transportation-management__users__table__actions">
            <button
              className="transportation-management__users__table__button transportation-management__users__table__button-delete"
              onClick={() => onOpenRemoveGroupDialog(group.id)}
            >
              <TrashCan />
            </button>
          </div>,
        ];
        return [...acc, groupArray];
      },
      []
    );
  }, [control, selectedEvaluationPlanAssignedGroups]);

  const onOpenAssignGroupsModal = () => {
    setIsAddGroupsModalOpen(true);
  };

  const onOpenRemoveGroupDialog = (id: string | string[]) => {
    if (Array.isArray(id)) {
      setGroupsToUnassign(id);
    } else {
      setGroupsToUnassign([id]);
    }
  };

  const onUnassignGroupsFromEvaluationPlan = (groupIds: string[]) => {
    unassignEvaluationPlanFromGroups
      .mutateAsync({ groupIds })
      .then(() => {
        notifications.success(t('EVALUATION_PLANS.MESSAGES.UNASSIGN_SUCCESS'));
        queryClient.invalidateQueries({ queryKey: ['evaluation-plans'] });
        queryClient.invalidateQueries({
          queryKey: ['avaliable-groups-for-evaluation-plan', id],
        });
        setGroupsToUnassign([]);
      })
      .catch((resError) => {
        notifications.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  return (
    <Drawer
      header={t('EVALUATION_PLANS.TITLES.ASSIGNED_GROUPS')}
      footer={
        <div className="drawer__transportation-management__form__actions">
          <Button
            onClick={onCloseDrawer}
            type="button"
            className="drawer__transportation-management__form__actions__cancel"
            variant="outlined"
          >
            {t('ACTIONS.CANCEL')}
          </Button>
        </div>
      }
    >
      <div className="transportation-management__users">
        <div className="transportation-management__users__actions">
          {isSuccess &&
            selectedEvaluationPlanAssignedGroups &&
            selectedEvaluationPlanAssignedGroups.length > 0 && (
              <Button
                compact
                disabled={getSelectedItems()?.length === 0}
                onClick={() => onOpenRemoveGroupDialog(getSelectedItems())}
              >
                {t('ACTIONS.UNASSIGN')}
              </Button>
            )}
        </div>
        {isSuccess ? (
          selectedEvaluationPlanAssignedGroups &&
          selectedEvaluationPlanAssignedGroups.length > 0 ? (
            <Table headers={headers} rows={tableChildren} />
          ) : (
            <p className="transportation-management__users__text">
              {t(
                'EVALUATION_PLANS.MESSAGES.NO_GROUPS_ASSIGNED_TO_EVALUATION_PLAN'
              )}
            </p>
          )
        ) : (
          <TableSkeleton headers={headers} />
        )}
        <div className="drawer__transportation-management__form__add-stop mt-4">
          <button
            type="button"
            className="drawer__transportation-management__form__add-stop__button"
            onClick={onOpenAssignGroupsModal}
          >
            <Add className="drawer__transportation-management__form__add-stop__button__icon" />
            <span className="ml-1">
              {t('EVALUATION_PLANS.ACTIONS.ASSIGN_MORE_GROUPS')}
            </span>
          </button>
        </div>
      </div>
      {groupsToUnassign.length > 0 && (
        <DeleteConfirmationDialog
          title={t('EVALUATION_PLANS.TITLES.UNASSIGN_EVALUATION_PLAN')}
          unassignAction={setGroupsToUnassign}
          handleRemove={() =>
            onUnassignGroupsFromEvaluationPlan(groupsToUnassign)
          }
          paragraphText={t(
            'EVALUATION_PLANS.MESSAGES.UNASSIGN_EVALUATION_PLAN'
          )}
          actionButtonTitle={t('ACTIONS.UNASSIGN') ?? ''}
        />
      )}
      {isAddGroupsModalOpen && (
        <AssignEvaluationPlanToGroupsDialog
          setIsAddGroupsModalOpen={setIsAddGroupsModalOpen}
        />
      )}
    </Drawer>
  );
}
