import { Toggle } from '@ph-react-ui/core';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../../../utils/components/hoc-components';
import type { ParentSectionType } from '../../../../../utils/enums/parent-type.enum';
import {
  getCommonValidators,
  VALIDATION as V,
} from '../../../../../utils/inputValidators';
import { BasicInfoSection } from './BasicInfoSection';

interface ParentSectionProps {
  requiredFields?: {
    [key: string]: boolean;
  };
  prefix?: string;
  mainParent?: boolean;
  setMainParent?: () => void;
  hideMainParentToggle?: boolean;
  type: ParentSectionType;
}

export function ParentSection({
  requiredFields,
  prefix = '',
  mainParent = false,
  setMainParent,
  hideMainParentToggle = false,
  type,
}: ParentSectionProps) {
  const { t } = useTranslation();

  return (
    <>
      {!hideMainParentToggle && (
        <div className="mb-6 mt-6">
          <Toggle value={mainParent} onChange={setMainParent}>
            {t('USERS.DRAWER.TITLES.IS_MAIN_PARENT')}
          </Toggle>
        </div>
      )}
      <BasicInfoSection prefix={prefix} type={type} />
      <div className="mb-6">
        <Input
          name={`${prefix}phoneNumber`}
          rules={{
            required: {
              value: requiredFields?.['phoneNumber'] ?? false,
              message: t('ERROR.VALIDATION.REQUIRED'),
            },
            maxLength: {
              value: 20,
              message: t('USERS.DRAWER.ERRORS.PHONE_TOO_LONG'),
            },
          }}
          className="drawer__users__form__input"
          placeholder={t(`USERS.DRAWER.PLACEHOLDERS.${type}PHONE`) ?? ''}
          label={t(`USERS.DRAWER.LABELS.${type}PHONE`)!}
        />
      </div>

      <div className="mb-6">
        <Input
          name={`${prefix}address`}
          rules={
            (getCommonValidators([V.MAX_LENGTH]),
            {
              required: {
                value: requiredFields?.['address'] ?? false,
                message: t('ERROR.VALIDATION.REQUIRED'),
              },
            })
          }
          className="drawer__users__form__input"
          placeholder={t(`USERS.DRAWER.PLACEHOLDERS.${type}ADDRESS`) ?? ''}
          label={t(`USERS.DRAWER.LABELS.${type}ADDRESS`) ?? ''}
        />
      </div>

      <div className="mb-6">
        <Input
          name={`${prefix}workAddress`}
          rules={
            (getCommonValidators([V.MAX_LENGTH]),
            {
              required: {
                value: requiredFields?.['workAddress'] ?? false,
                message: t('ERROR.VALIDATION.REQUIRED'),
              },
            })
          }
          className="drawer__users__form__input"
          placeholder={t(`USERS.DRAWER.PLACEHOLDERS.${type}WORK_ADDRESS`) ?? ''}
          label={t(`USERS.DRAWER.LABELS.${type}WORK_ADDRESS`) ?? ''}
        />
      </div>
    </>
  );
}
