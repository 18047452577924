import { useMutation } from '@tanstack/react-query';
import apiRequest from '../../services/apiWrapper';
import { LOGOUT_URL } from '../../utils/constants/urlConstants';

async function logout() {
  const refreshToken = localStorage.getItem('refreshToken');
  await apiRequest.post(LOGOUT_URL, {
    refreshToken,
  });
}

export function useLogout() {
  return useMutation({
    mutationFn: () => logout(),
  });
}
