export function formatFileName(fileName: string) {
  const [name, extension] = fileName.split('.');
  if (name.length > 10) {
    return (
      name.slice(0, 3) +
      '...' +
      name.slice(name.length - 3, name.length) +
      '.' +
      extension
    );
  }
  return fileName;
}
