import { Search } from '@carbon/icons-react';
import {
  Button,
  Checkbox,
  Dialog,
  Input,
  Table,
  useNotifications,
} from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import {
  type Dispatch,
  type SetStateAction,
  useMemo,
  useRef,
  useState,
  useEffect,
} from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebounce } from '../../../../hooks/common/useDebounce';
import { useGetListOfAvailableStudentsForBusStop } from '../../../../hooks/transportation/students/useGetListOfAvailableStudentsForBusStop';
import { usePatchTransportationPlanForStudent } from '../../../../hooks/transportation/students/usePatchTransportationPlanForStudent';
import { useCancellationDialog } from '../../../../hooks/useCancellationDialog';
import { useSelectAll } from '../../../../hooks/useSelectAll';
import { TableSkeleton } from '../../../shared/components/skeletons/TableSkeleton';

interface StudentsAvailableForBusStopModalProps {
  setIsStudentsModelOpen: Dispatch<SetStateAction<boolean>>;
  busStopId: string;
  busStopType: string;
}

export function StudentsAvailableForBusStopModal({
  setIsStudentsModelOpen,
  busStopId,
  busStopType,
}: StudentsAvailableForBusStopModalProps) {
  const notification = useNotifications();
  const { t } = useTranslation();
  const { open: openCancellationDialog } = useCancellationDialog();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [searchInput, setSearchInput] = useState('');
  const debouncedInput = useDebounce(searchInput, 500);
  const [filteredUsers, setFilteredUsers] = useState<
    { name: string; id: string }[]
  >([]);
  const queryClient = useQueryClient();

  const { data, isSuccess } = useGetListOfAvailableStudentsForBusStop({
    busStopId,
    busStopType,
  });

  useEffect(() => {
    if (isSuccess) {
      setFilteredUsers(data?.students);
    }
  }, [isSuccess, data?.students]);

  useEffect(() => {
    if (data?.students) {
      if (debouncedInput) {
        const filteredUsers = data.students.filter((student) =>
          student.name.toLowerCase().includes(debouncedInput.toLowerCase())
        );

        setFilteredUsers(filteredUsers);
      } else {
        setFilteredUsers(data.students);
      }
    }
  }, [debouncedInput, data?.students]);

  const addStudentsToBusStop = usePatchTransportationPlanForStudent();

  const {
    selectAll,
    handleSubmit,
    handleSelectAll,
    dirtyFields,
    control,
    getSelectedItems,
  } = useSelectAll(data?.students);

  const headers = [
    <div className="users__table__select-all">
      <Checkbox
        onChange={(checked) => handleSelectAll(checked)}
        value={selectAll}
      />
      {t('SIDEMENU.STUDENTS')}
    </div>,
  ];

  const onCloseModal = () => {
    if (dirtyFields) {
      openCancellationDialog();
    } else {
      setIsStudentsModelOpen(false);
    }
  };

  const tableChildren = useMemo(() => {
    return filteredUsers.reduce((acc: any, student, index) => {
      let userArray = [
        <div className="users__table__checkbox">
          <Controller
            key={student.id}
            name={`items.${index}.value`}
            control={control}
            render={({ field }) => (
              <>
                <Checkbox className="users__table-first" {...field} />
              </>
            )}
          />
          {student.name}
        </div>,
      ];
      return [...acc, userArray];
    }, []);
  }, [control, filteredUsers]);

  const onSubmit = () => {
    const selectedStudentsIds = getSelectedItems();
    addStudentsToBusStop
      .mutateAsync({
        studentIds: selectedStudentsIds,
        busStopId,
        busStopType: +busStopType,
      })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ['transportation-usage'] });
        queryClient.invalidateQueries({
          queryKey: ['students-on-bus-stop', busStopId],
        });
        queryClient.invalidateQueries({
          queryKey: ['available-students-bus-stop', busStopId, busStopType],
        });
        queryClient.invalidateQueries({ queryKey: ['bus-routes'] });
        notification.success(
          t(`TRANSPORTATION_MANAGEMENT.MESSAGES.STUDENTS_ADDED_TO_BUS_STOP`)
        );
        setIsStudentsModelOpen(false);
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  return (
    <Dialog
      header={t('SIDEMENU.STUDENTS')}
      className="dialog__confirm"
      onClose={() => setIsStudentsModelOpen(false)}
    >
      <div className="dialog__confirm__content__scroll">
        <Input
          className="mb-3"
          ref={inputRef}
          value={searchInput}
          onChange={setSearchInput}
          compact
          placeholder={t('USERS.TABLE.FILTERS.SEARCH_USER') ?? ''}
          prefix={
            <Search className="groups__actions__input__icon groups__actions__input__icon--action" />
          }
        />
        {isSuccess ? (
          data.students.length > 0 ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Table headers={headers} rows={tableChildren} />
            </form>
          ) : (
            <p className="mt-4">No available students</p>
          )
        ) : (
          <TableSkeleton headers={[t('GROUPS.TABLE.HEADERS.NAME')]} />
        )}
      </div>
      <div className="dialog__confirm__footer">
        <Button
          type="button"
          color="secondary"
          variant="outlined"
          onClick={onCloseModal}
        >
          {t('ACTIONS.CANCEL')}
        </Button>
        <div className="dialog__confirm__footer__actions">
          <Button type="submit" onClick={handleSubmit(onSubmit)}>
            {t('ACTIONS.ADD')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
