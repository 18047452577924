import { useMutation } from '@tanstack/react-query';
import type { EmployeeCompleteProfileDto } from '../../../models/admission/employee/employee-complete-profile.dto';
import publicApiRequest from '../../../services/publicApiWrapper';
import { PUBLIC_EMPLOYEE_URL } from '../../../utils/constants/urlConstants';

async function completeEmployeeRegistration(
  invitationId: string | null,
  employeeData: EmployeeCompleteProfileDto
) {
  return publicApiRequest.post(
    `${PUBLIC_EMPLOYEE_URL}/complete-registration?invitationId=${invitationId}`,
    employeeData
  );
}

export function useCompleteRegistrationForEmployee(
  invitationId: string | null
) {
  return useMutation({
    mutationFn: (employeeData: EmployeeCompleteProfileDto) =>
      completeEmployeeRegistration(invitationId, employeeData),
  });
}
