import { Information } from '@carbon/icons-react';
import {
  Button,
  Textarea as TextareaBase,
  Tooltip,
  useNotifications,
} from '@ph-react-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEvaluateStudent } from '../../../hooks/evaluation-plans/useEvaluateStudent';
import { useGetEvaluationPlanByIdForEvaluation } from '../../../hooks/evaluation-plans/useGetEvaluationPlanByIdForEvaluation';
import { useGetEvaluationPlanLegendDetails } from '../../../hooks/evaluation-plans/useGetEvaluationPlanLegendDetails';
import { useModal } from '../../../hooks/useModal';
import type { EvaluationScore } from '../../../models/evaluation-plans/evaluation-plans-details.dto';
import type { CriteriaDetailsDto } from '../../../models/evaluation-plans/evaluation-plans-form.dto';
import { Select, Textarea } from '../../../utils/components/hoc-components';
import { EVALUATION_CRITERIA_LEGEND } from '../../../utils/constants/evaluation-plans/modals';
import { getFormattedEvaluationCriteriaOptions } from '../../../utils/formatEvaluationCriteria';
import {
  getCommonValidators,
  VALIDATION as V,
} from '../../../utils/inputValidators';
import { NavigateBack } from '../../shared/components/NavigateBack';

export function EvaluationProcess() {
  const notification = useNotifications();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { open: openEvaluationCriteriaLegend } = useModal(
    EVALUATION_CRITERIA_LEGEND
  );
  const { id: evaluationPlanId } = useParams();
  const studentId = searchParams.get('studentId') ?? '';
  const name = searchParams.get('name') ?? null;

  const { data: evaluationPlan } =
    useGetEvaluationPlanByIdForEvaluation(evaluationPlanId);
  const completeEvaluation = useEvaluateStudent(studentId);
  const { setLegendDetails } = useGetEvaluationPlanLegendDetails();

  const methods = useForm<EvaluationScore>({
    defaultValues: {},
  });

  const onSave = async (data: EvaluationScore) => {
    let scoresData = {} as any;
    const criteriaKeys = Object.keys(data);
    criteriaKeys.forEach((criteria) => {
      scoresData[criteria] = Object.assign({}, data[criteria]);
    });

    const formData = {
      evaluationPlanId,
      scores: { ...scoresData },
    };

    await completeEvaluation
      .mutateAsync(formData)
      .then(() => {
        notification.success(
          t('EVALUATION_PLANS.MESSAGES.SUCCESSFULLY_EVALUATED_STUDENT')
        );
        navigate('../students');
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  const handleOpenEvaluationPlanLegend = (
    criteriaDetails: CriteriaDetailsDto
  ) => {
    setLegendDetails(criteriaDetails);
    openEvaluationCriteriaLegend();
  };

  return (
    <FormProvider {...methods}>
      <form
        className="evaluation-process"
        onSubmit={methods.handleSubmit(onSave)}
      >
        <div className="evaluation-process__actions">
          <NavigateBack
            onNavigate={() =>
              navigate(`../evaluate?studentId=${studentId}&name=${name}`)
            }
            text={t('EVALUATION_PLANS.MESSAGES.GO_BACK')}
          />
        </div>
        <div className="evaluation-process__container">
          <div className="evaluation-process__container__name">
            <h3>
              {`${t('EVALUATION_PLANS.MESSAGES.EVALUATION_OF')} `}
              <span>{name}</span>
              {` | ${evaluationPlan?.name}`}
            </h3>
          </div>
          <div className="evaluation-process__container__criterias">
            {evaluationPlan?.strands.map((strand, strandKey) => (
              <div
                className="evaluation-process__container__criterias__criteria"
                key={strandKey}
              >
                <div className="evaluation-process__container__criterias__criteria__name">
                  <h3>
                    {`${++strandKey}. `}
                    <span>{strand.name}</span>
                  </h3>
                </div>
                {strand.criterias.map((criteria, criteriaKey) => (
                  <div
                    className="evaluation-process__container__criterias__criteria__details"
                    key={criteriaKey}
                  >
                    <div className="evaluation-process__container__criterias__criteria__details__criteria">
                      <TextareaBase
                        label={t('EVALUATION_PLANS.FORM.LABELS.CRITERIA') ?? ''}
                        rows={2}
                        value={criteria.name}
                        readonly
                        suffix={
                          <Tooltip
                            position="right"
                            message={t(
                              'EVALUATION_PLANS.MESSAGES.EVALUATION_CRITERIA_DESCRIPTION'
                            )}
                            className="evaluation-process__container__criterias__criteria__details__criteria__tooltip"
                          >
                            <Information
                              onClick={() =>
                                handleOpenEvaluationPlanLegend(criteria)
                              }
                            />
                          </Tooltip>
                        }
                      />
                    </div>
                    <div className="evaluation-process__container__criterias__criteria__details__score">
                      <Select
                        placeholder={
                          t('EVALUATION_PLANS.FORM.LABELS.SELECT_SCORE') ?? ''
                        }
                        label={
                          t('EVALUATION_PLANS.FORM.LABELS.SELECT_SCORE') ?? ''
                        }
                        compact
                        options={getFormattedEvaluationCriteriaOptions()}
                        multi={false}
                        name={`${strandKey}.${++criteriaKey}.score`}
                        rules={getCommonValidators([V.REQUIRED])}
                      />
                    </div>
                    <div className="evaluation-process__container__criterias__criteria__details__note">
                      <Textarea
                        name={`${strandKey}.${criteriaKey}.note`}
                        label={t('EVALUATION_PLANS.FORM.LABELS.NOTE') ?? ''}
                        rows={1}
                        rules={getCommonValidators([V.MAX_LENGTH_600])}
                      />
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        <div className="evaluation-process__save">
          <Button variant="outlined" type="submit">
            {t('ACTIONS.SAVE')}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
