import { useMutation } from '@tanstack/react-query';
import type { AdminCompleteProfileDto } from '../../../models/admission/admin/admin-complete-profile.dto';
import publicApiRequest from '../../../services/publicApiWrapper';
import { PUBLIC_ADMIN_URL } from '../../../utils/constants/urlConstants';

async function completeAdminRegistration(
  invitationId: string | null,
  adminData: AdminCompleteProfileDto
) {
  return publicApiRequest.post(
    `${PUBLIC_ADMIN_URL}/complete-registration?invitationId=${invitationId}`,
    adminData
  );
}
export function useCompleteRegistrationForAdmin(invitationId: string | null) {
  return useMutation({
    mutationFn: (adminData: AdminCompleteProfileDto) =>
      completeAdminRegistration(invitationId, adminData),
  });
}
