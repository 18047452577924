import { useMutation } from '@tanstack/react-query';
import apiRequest from '../../services/apiWrapper';
import {
  FILES_URL,
  PUBLIC_FILES_URL,
} from '../../utils/constants/urlConstants';

interface FileDetailsDto {
  id: string;
  uri: string;
  expiredAt: number;
}

async function downloadFile(fileId: string, invitationId?: string) {
  const url = invitationId
    ? `${PUBLIC_FILES_URL}?invitationId=${invitationId}`
    : FILES_URL;
  const reponse = await apiRequest.get<FileDetailsDto>(`${url}/${fileId}`);

  return reponse.data;
}

export function useDownloadFile(invitationId?: string) {
  return useMutation({
    mutationFn: (fileId: string) => downloadFile(fileId, invitationId),
  });
}
