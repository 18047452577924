import { useQuery } from '@tanstack/react-query';
import type { PaginatedList } from '../../../models/common/paginated-list.model';
import type { StudentsAbsencesDto } from '../../../models/users/students/student-absences.dto';
import apiRequest from '../../../services/apiWrapper';
import { createPaginatedQueryKeys } from '../../../services/createPaginatedQueryKeys';
import { STUDENT_ABSENCE_URL } from '../../../utils/constants/urlConstants';

async function fetchStudentAbsences(searchParams: URLSearchParams) {
  const response = await apiRequest.get<PaginatedList<StudentsAbsencesDto>>(
    `${STUDENT_ABSENCE_URL}?${searchParams.toString()}`
  );

  return response.data;
}

function createQueryKey(searchParams: URLSearchParams) {
  return {
    name: searchParams.get('name'),
    fromDate: searchParams.get('fromDate'),
    toDate: searchParams.get('toDate'),
    ...createPaginatedQueryKeys(searchParams),
  };
}
export function usePaginatedStudentAbsences(searchParams: URLSearchParams) {
  return useQuery({
    queryKey: ['student-absences', createQueryKey(searchParams)],
    queryFn: () => fetchStudentAbsences(searchParams),
  });
}
