import { Button, Drawer, Table, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetAllTermReportsOfStudent } from '../../../hooks/term-reports/useGetAllTermReportsOfStudent';
import { useSendTermReportToParent } from '../../../hooks/term-reports/useSendTermReportToParent';
import { useModal } from '../../../hooks/useModal';
import { REVIEW_TERM_REPORTS } from '../../../utils/constants/term-reports/modals';
import {
  getTermReportStatus,
  TERM_REPORT_STATUS,
} from '../../../utils/constants/term-reports/status';
import { TermReportStatus } from '../../../utils/enums/term-report-status.enum';
import { ActionMenuHorizontal } from '../../shared/components/ActionMenuHorizontal';
import { TableSkeleton } from '../../shared/components/skeletons/TableSkeleton';

export const ReviewTermReport = () => {
  const { t } = useTranslation();
  const notification = useNotifications();
  const queryClient = useQueryClient();
  const naviate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('userId');
  const { close: closeModal } = useModal(REVIEW_TERM_REPORTS);
  const {
    data: reports,
    isSuccess,
    isPending,
  } = useGetAllTermReportsOfStudent(id!);
  const sentTermReportToParent = useSendTermReportToParent();

  const headers = [
    t('TERM_REPORTS.TABLE.HEADERS.REPORT'),
    t('TERM_REPORTS.TABLE.HEADERS.STATUS'),
    t('TERM_REPORTS.TABLE.HEADERS.ACTIONS'),
  ];

  const onEditTermReport = useCallback(
    (reportId: string) => {
      naviate(`/dashboard/term-reports/create-edit/${id}?reportId=${reportId}`);
    },
    [id, naviate]
  );

  const onSubmitToParent = useCallback(
    (reportId: string) => {
      sentTermReportToParent
        .mutateAsync(reportId)
        .then(() => {
          queryClient.invalidateQueries({
            queryKey: ['student-term-reports'],
          });
          queryClient.invalidateQueries({
            queryKey: ['term-report', reportId],
          });
          notification.success(
            t('TERM_REPORTS.MESSAGES.SUCCESSFULLY_SENT_TO_PARENT')
          );
        })
        .catch((resError) => {
          notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
        });
    },
    [notification, queryClient, sentTermReportToParent, t]
  );

  const tableChildren = useMemo(() => {
    return reports?.termReports.reduce((acc: any, report) => {
      let reportsArray = [
        report.title,
        getTermReportStatus(TERM_REPORT_STATUS[report.status]),
        <ActionMenuHorizontal
          options={[
            {
              label: t('ACTIONS.EDIT'),
              onClick: () => {
                onEditTermReport(report.id);
              },
            },
            {
              label: t('ACTIONS.SUBMIT_TO_PARENT'),
              onClick: () => {
                onSubmitToParent(report.id);
              },
              hidden:
                report.status !== TermReportStatus.Approved &&
                report.status !== TermReportStatus.SentToParent,
            },
          ]}
        />,
      ];

      return [...acc, reportsArray];
    }, []);
  }, [onEditTermReport, onSubmitToParent, reports?.termReports, t]);

  return (
    <Drawer
      className="term-reports"
      footer={
        <Button
          onClick={() => closeModal()}
          type="button"
          className="drawer__users__form__actions__cancel"
          variant="outlined"
        >
          {t('ACTIONS.CANCEL')}
        </Button>
      }
    >
      {isPending && <TableSkeleton />}
      {isSuccess &&
        (reports.termReports.length ? (
          <Table headers={headers} rows={tableChildren} />
        ) : (
          t('TERM_REPORTS.TABLE.LABELS.NO_RESULTS')
        ))}
    </Drawer>
  );
};
