import type { SelectOption, SimpleDate } from '@ph-react-ui/core';
import { Button, useNotifications } from '@ph-react-ui/core';
import { useEffect, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGetAuthenticatedParentProfile } from '../../../hooks/profile/parent/useGetAuthenticatedParentProfile';
import { useCancellationDialog } from '../../../hooks/useCancellationDialog';
import { useModal } from '../../../hooks/useModal';
import { useGetStudentsForParent } from '../../../hooks/users/parents/useGetStudentsForParent';
import { useMarkAbsence } from '../../../hooks/users/parents/useMarkAbsence';
import type {
  RegisterAbsenceDTO,
  RegisterAbsenceForm,
} from '../../../models/users/parents/parent-register-absence.dto';
import { mapDateToSimpleDate } from '../../../services/mapDateToSimpleDate';
import { mapSimpleDateToString } from '../../../services/mapSimpleDateToString';
import {
  DatePicker,
  Select,
  Textarea,
} from '../../../utils/components/hoc-components';
import { STUDENT_ABSENCE_REASON } from '../../../utils/constants/student-absence-reason';
import { REGISTER_AN_ABSENCE } from '../../../utils/constants/users/modals';
import { StudentAbsenceReason } from '../../../utils/enums/student-absence-reason.enum';
import { hideIbanNumberDetails } from '../../../utils/formatIbanNumber';
import {
  getCommonValidators,
  VALIDATION as V,
} from '../../../utils/inputValidators';
import { Dialog } from '../../shared/components/Dialog';

export function RegisterAnAbsence() {
  const { t } = useTranslation();
  const notification = useNotifications();
  const { open: openCancellationDialog } = useCancellationDialog();
  const [studentOptions, setStudentOptions] = useState<SelectOption<string>[]>(
    []
  );
  const { close: closeModal } = useModal(REGISTER_AN_ABSENCE);

  const markAbsence = useMarkAbsence();

  const absenceReasonOptions = [
    {
      label: t(
        `PARENT_PROFILE.ABSENCE_REASON.${
          STUDENT_ABSENCE_REASON[StudentAbsenceReason.Family]
        }`
      ),
      value: StudentAbsenceReason.Family,
    },
    {
      label: t(
        `PARENT_PROFILE.ABSENCE_REASON.${
          STUDENT_ABSENCE_REASON[StudentAbsenceReason.Medical]
        }`
      ),
      value: StudentAbsenceReason.Medical,
    },
  ];

  const { data: parent } = useGetAuthenticatedParentProfile();
  const { data: students } = useGetStudentsForParent({
    enabled: Boolean(parent),
  });

  useEffect(() => {
    const studentOptions = students
      ? students.map((student) => ({ label: student.name, value: student.id }))
      : [];

    setStudentOptions(studentOptions);
  }, [students]);

  const methods = useForm<RegisterAbsenceForm>({
    defaultValues: {
      studentId: '',
      absenceReason: 1,
      fromDate: {} as SimpleDate,
      toDate: {} as SimpleDate,
      note: '',
    },
  });

  const { isDirty } = methods.formState;
  const fromDateWatch = methods.watch('fromDate');

  const parentProfileMethods = useFormContext();

  const onCloseModal = () => {
    if (isDirty) {
      openCancellationDialog();
    } else {
      closeModal();
    }
  };

  const onSubmit = async (data: RegisterAbsenceForm) => {
    const formData = {
      ...data,
      fromDate: mapSimpleDateToString(data.fromDate),
      toDate: mapSimpleDateToString(data.toDate),
    } as RegisterAbsenceDTO;

    await markAbsence
      .mutateAsync(formData as RegisterAbsenceDTO)
      .then(() => {
        notification.success(t('PARENT_PROFILE.NOTIFICATIONS.ABSENCE_ADDED'));
        closeModal();
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  const openEditIban = () => {
    closeModal();
    parentProfileMethods.setFocus('iban');
  };

  return (
    <Dialog
      className="dialog__absence"
      onClose={onCloseModal}
      dialogHeader={t('PARENT_PROFILE.TITLES.REGISTER_AN_ABSENCE')}
      actionButton={
        <Button
          type="submit"
          onClick={methods.handleSubmit((data) => onSubmit(data))}
        >
          {t('ACTIONS.CONFIRM')}
        </Button>
      }
    >
      <FormProvider {...methods}>
        <Select
          name="studentId"
          options={studentOptions}
          label={t('PARENT_PROFILE.LABELS.SELECT_STUDENT') ?? ''}
          placeholder={t('PARENT_PROFILE.PLACEHOLDERS.SELECT_STUDENT') ?? ''}
          rules={getCommonValidators([V.REQUIRED])}
        />
        <div className="dialog__absence__content">
          <div className="dialog__absence__content__dates">
            <DatePicker
              name="fromDate"
              label={t('PARENT_PROFILE.LABELS.FROM_DATE') ?? ''}
              rules={getCommonValidators([V.REQUIRED])}
              minDate={mapDateToSimpleDate(new Date())}
            />
            <DatePicker
              name="toDate"
              label={t('PARENT_PROFILE.LABELS.TO_DATE') ?? ''}
              rules={getCommonValidators([V.REQUIRED])}
              minDate={fromDateWatch as {} as SimpleDate}
            />
          </div>
          <div className="dialog__absence__content__reason">
            <Select
              name="absenceReason"
              options={absenceReasonOptions}
              label={t('PARENT_PROFILE.LABELS.ABSENCE_REASON') ?? ''}
              rules={getCommonValidators([V.REQUIRED])}
            />
          </div>
          <div className="dialog__absence__content__additional">
            <Textarea
              name="note"
              rows={13}
              label={t('PARENT_PROFILE.LABELS.NOTE') ?? ''}
              className="dialog__absence__content__additional__note"
              rules={getCommonValidators([V.MAX_LENGTH])}
            />
          </div>
          <div className="dialog__absence__content__iban-info">
            <h4>
              {parent?.familyInformation.iban ? (
                <>
                  * {t('PARENT_PROFILE.CONTENT.REFUND_TEXT') ?? ''}:{' '}
                  {hideIbanNumberDetails(parent?.familyInformation.iban)}
                </>
              ) : (
                <>* {t('PARENT_PROFILE.CONTENT.ADD_IBAN_NOTIFICATION') ?? ''}</>
              )}
              <span>
                {' '}
                |
                <button
                  onClick={() => openEditIban()}
                  className="dialog__absence__content__iban-info__edit-iban"
                >
                  Edit
                </button>
              </span>
            </h4>
          </div>
        </div>
      </FormProvider>
    </Dialog>
  );
}
