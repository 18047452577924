import { useQuery } from '@tanstack/react-query';
import type { EmployeePublicDetails } from '../../../models/admission/employee/employee-public-details.dto';
import { mapStringToSimpleDate } from '../../../services/mapStringToSimpleDate';
import publicApiRequest from '../../../services/publicApiWrapper';
import { PUBLIC_EMPLOYEE_URL } from '../../../utils/constants/urlConstants';

async function getEmployeeData(invitationId: string | null) {
  const response = await publicApiRequest.get<EmployeePublicDetails>(
    `${PUBLIC_EMPLOYEE_URL}?invitationId=${invitationId}`
  );

  return response.data;
}

export function useGetPublicEmployeeById(invitationId: string | null) {
  return useQuery({
    queryKey: ['public-employee', invitationId],
    queryFn: () => getEmployeeData(invitationId),
    select: (data) => ({
      ...data,
      birthDate: mapStringToSimpleDate(data.birthDate),
      diplomaIssueDate: data.diplomaIssueDate
        ? mapStringToSimpleDate(data.diplomaIssueDate)
        : null,
    }),
  });
}
