import { useQuery } from '@tanstack/react-query';
import type { PaginatedList } from '../../../models/common/paginated-list.model';
import type { TermReportSectionsDetailsDto } from '../../../models/term-reports/sections/term-report-sections-details.dto';
import apiRequest from '../../../services/apiWrapper';
import { createPaginatedQueryKeys } from '../../../services/createPaginatedQueryKeys';
import { TERM_REPORT_SECTIONS_URL } from '../../../utils/constants/urlConstants';

async function fetchTermReportSections(searchParams: URLSearchParams) {
  const response = await apiRequest.get<
    PaginatedList<TermReportSectionsDetailsDto>
  >(`${TERM_REPORT_SECTIONS_URL}?${searchParams.toString()}`);
  return response.data;
}

function createQueryKey(searchParams: URLSearchParams) {
  return {
    name: searchParams.get('name'),
    status: searchParams.get('status'),
    ...createPaginatedQueryKeys(searchParams),
  };
}

export function usePaginatedTermReportSections(searchParams: URLSearchParams) {
  return useQuery({
    queryKey: ['term-report-sections', createQueryKey(searchParams)],
    queryFn: () => fetchTermReportSections(searchParams),
  });
}
