import { CheckmarkOutline, MisuseOutline } from '@carbon/icons-react';
import { useTranslation } from 'react-i18next';
import {
  LOWERCASE_REGEX,
  SPECIAL_CHAR_REGEX,
  UPPERCASE_REGEX,
  NUMBER_REGEX,
} from '../../../../utils/constants/authContants';

interface PasswordRequirementsProps {
  className?: string;
  password: string;
}

export function PasswordRequirements({
  className,
  password,
}: PasswordRequirementsProps) {
  const { t } = useTranslation();

  return (
    <div className={`password__requirements ${className}`}>
      <span className="password__requirements__title">
        {t('ADMISSION.COMPLETE_PROFILE.TITLES.PASSWORD_MUST')}
      </span>
      <ul className="password__requirements__list">
        <li className="password__requirements__list__item">
          {password?.length >= 12 ? (
            <CheckmarkOutline
              className={`password__requirements__list__item__icon password__requirements__list__item__icon-valid`}
            />
          ) : (
            <MisuseOutline
              className={`password__requirements__list__item__icon`}
            />
          )}
          {t('ADMISSION.COMPLETE_PROFILE.PASSWORD_REQUIREMENTS.MIN_LENGTH')}
        </li>
        <li className="password__requirements__list__item">
          {password?.length <= 64 && password?.length >= 12 ? (
            <CheckmarkOutline
              className={`password__requirements__list__item__icon password__requirements__list__item__icon-valid`}
            />
          ) : (
            <MisuseOutline
              className={`password__requirements__list__item__icon`}
            />
          )}
          {t('ADMISSION.COMPLETE_PROFILE.PASSWORD_REQUIREMENTS.MAX_LENGTH')}
        </li>
        <li className="password__requirements__list__item">
          {password?.match(LOWERCASE_REGEX) ? (
            <CheckmarkOutline
              className={`password__requirements__list__item__icon password__requirements__list__item__icon-valid`}
            />
          ) : (
            <MisuseOutline
              className={`password__requirements__list__item__icon`}
            />
          )}
          {t(
            'ADMISSION.COMPLETE_PROFILE.PASSWORD_REQUIREMENTS.LOWERCASE_LETTERS'
          )}
        </li>
        <li className="password__requirements__list__item">
          {password?.match(UPPERCASE_REGEX) ? (
            <CheckmarkOutline
              className={`password__requirements__list__item__icon password__requirements__list__item__icon-valid`}
            />
          ) : (
            <MisuseOutline
              className={`password__requirements__list__item__icon`}
            />
          )}
          {t(
            'ADMISSION.COMPLETE_PROFILE.PASSWORD_REQUIREMENTS.UPPERCASE_LETTERS'
          )}
        </li>
        <li className="password__requirements__list__item">
          {password?.match(NUMBER_REGEX) ? (
            <CheckmarkOutline
              className={`password__requirements__list__item__icon password__requirements__list__item__icon-valid`}
            />
          ) : (
            <MisuseOutline
              className={`password__requirements__list__item__icon`}
            />
          )}
          {t('ADMISSION.COMPLETE_PROFILE.PASSWORD_REQUIREMENTS.NUMBER')}
        </li>
        <li className="password__requirements__list__item">
          {password?.match(SPECIAL_CHAR_REGEX) ? (
            <CheckmarkOutline
              className={`password__requirements__list__item__icon password__requirements__list__item__icon-valid`}
            />
          ) : (
            <MisuseOutline
              className={`password__requirements__list__item__icon`}
            />
          )}
          {t('ADMISSION.COMPLETE_PROFILE.PASSWORD_REQUIREMENTS.SPECIAL_LETTER')}
        </li>
      </ul>
    </div>
  );
}
