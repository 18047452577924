import type { SimpleTime } from '@ph-react-ui/core';

export function mapSimpleTimeToString(time: SimpleTime | null): string | null {
  if (!time) {
    return null;
  }
  let hours = time.hours.toString();
  let minutes = time.minutes.toString();

  if (time.hours < 10) {
    hours = `0${hours}`;
  }

  if (time.minutes < 10) {
    minutes = `0${minutes}`;
  }
  return `${hours}:${minutes}:00`;
}

export function mapStringToSimpleTime(data: string | null): SimpleTime | null {
  if (!data) {
    return null;
  }
  const simpleTimeData = data.split(':');
  return mapTimeToSimpleTime(simpleTimeData);
}

export function mapTimeToSimpleTime(data: string[]): SimpleTime {
  return {
    hours: parseInt(data[0]),
    minutes: parseInt(data[1]),
  };
}

export function formatTimeWithoutSeconds(time: string | null): string | null {
  if (!time) {
    return null;
  }
  return time.substring(0, 5);
}
