import { ChevronLeft } from '@carbon/icons-react';

interface NavigateBackProps {
  onNavigate: () => void;
  text: string;
}

export function NavigateBack({ onNavigate, text }: NavigateBackProps) {
  return (
    <button onClick={onNavigate} type="button" className="navigate-back">
      <ChevronLeft />
      <h4>{text}</h4>
    </button>
  );
}
