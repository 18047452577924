import { useQuery } from '@tanstack/react-query';
import type { BusRouteDTO } from '../../../models/transportation/bus-route.dto';
import apiRequest from '../../../services/apiWrapper';
import { mapStringToSimpleTime } from '../../../services/mappingsSimpleTime';
import { BUS_ROUTES_URL } from '../../../utils/constants/urlConstants';

async function getBusRoute(id: string | null) {
  const res = await apiRequest.get<BusRouteDTO>(`${BUS_ROUTES_URL}/${id}`);

  return res.data;
}

export function useGetBusRoute(id: string | null, enabled: boolean = true) {
  return useQuery({
    queryKey: ['bus-route', id],
    queryFn: () => getBusRoute(id),
    select: (data) => ({
      ...data,
      busStops: data?.busStops?.map((busStop) => ({
        ...busStop,
        timeOfDropOff: mapStringToSimpleTime(busStop.timeOfDropOff),
        timeOfPickUp: mapStringToSimpleTime(busStop.timeOfPickUp),
      })),
    }),
    enabled,
  });
}
