import { Button, Table, Tag } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate, useSearchParams } from 'react-router-dom';
import {
  createQueryKey,
  usePaginatedTransportationTaxes,
} from '../../../../hooks/taxes/transportation/usePaginatedTransportationTaxes';
import {
  Scholarship,
  ScholarshipTranslations,
} from '../../../../models/users/students/scholarship-type';
import { TaxesOrdering } from '../../../../utils/enums/taxes-ordering.enum';
import { MonthPagination } from '../../../shared/components/MonthPagination';
import { Pagination } from '../../../shared/components/Pagination';
import { MonthPaginationSkeleton } from '../../../shared/components/skeletons/MonthPaginationSkeleton';
import { TableSkeleton } from '../../../shared/components/skeletons/TableSkeleton';
import { ThSortItem } from '../../../shared/components/tables/ThSortItem';
import { TaxesFilters } from '../../../shared/components/TaxesFilters';

export function TransportationHistory() {
  const match = useMatch('/dashboard/payments/transport/history');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const monthParam = searchParams.get('month');
  const yearParam = searchParams.get('year');
  const queryClient = useQueryClient();

  const {
    data: transportationTaxes,
    isSuccess,
    isPending,
    isError,
  } = usePaginatedTransportationTaxes(searchParams);

  useEffect(() => {
    if (transportationTaxes) {
      searchParams.set('month', transportationTaxes.month.toString());
      searchParams.set('year', transportationTaxes.year.toString());

      queryClient.setQueryData(
        ['transportation-taxes', createQueryKey(searchParams)],
        transportationTaxes
      );
    }
  }, [transportationTaxes, searchParams, queryClient]);

  const onNavigateToPaymentPage = useCallback(
    (studentId: string) => {
      navigate(
        `/dashboard/payments/transport?studentId=${studentId}&month=${monthParam}&year=${yearParam}`
      );
    },
    [monthParam, navigate, yearParam]
  );

  const headers = [
    t('USERS.TABLE.HEADERS.NAME'),
    t('TAXES.TABLE.HEADERS.TYPE'),
    t('TAXES.TABLE.HEADERS.PAID_BY'),
    t('TAXES.TABLE.HEADERS.AMOUNT'),
    t('TAXES.TABLE.HEADERS.STATUS'),
    t('TAXES.TABLE.HEADERS.PAID_WITH'),
    <ThSortItem
      title={t('TAXES.TABLE.HEADERS.PAID_AT')}
      ordering={TaxesOrdering.PaidAt}
    ></ThSortItem>,
    t('USERS.TABLE.HEADERS.ACTION'),
  ];

  const tableChildren = useMemo(() => {
    return transportationTaxes?.result.reduce((acc: any, user) => {
      let userArray = [
        user.name,
        t(`TAXES.LABELS.${ScholarshipTranslations[user.scholarshipType!]}`),
        user.paidByName,
        <span
          className={`${
            !user.isPaid ? 'taxes__transportation__table__row-danger' : ''
          }`}
        >
          {user.amount} &nbsp; {t('PAYMENTS.LABELS.BGN')}
        </span>,
        user.isPaid ? (
          <Tag type="success">{t('TAXES.LABELS.PAID')}</Tag>
        ) : (
          <Tag type="danger">{t('TAXES.LABELS.NOT_PAID')}</Tag>
        ),
        user.isPaid
          ? user.isPaidWithCard
            ? t('TAXES.LABELS.CARD')
            : t('TAXES.LABELS.TRANSFER')
          : '',
        user.paidAt ? format(new Date(user.paidAt), 'dd.MM.yyyy') : '',
        !user.isPaid &&
        user.scholarshipType === Scholarship.FULL_SCHOLARSHIP ? (
          <Button
            compact
            variant="outlined"
            className="table-last"
            onClick={() => onNavigateToPaymentPage(user.id)}
          >
            {t('ACTIONS.PAY_NOW')}
          </Button>
        ) : (
          ''
        ),
      ];

      return [...acc, userArray];
    }, []);
  }, [t, transportationTaxes?.result, onNavigateToPaymentPage]);

  return (
    <div className="taxes__transportation">
      <div className="taxes__transportation__month__pagination">
        {!isPending ? (
          <MonthPagination
            month={transportationTaxes?.month.toString() || monthParam}
            year={transportationTaxes?.year.toString() || yearParam}
          />
        ) : (
          <MonthPaginationSkeleton />
        )}
      </div>

      {isSuccess && transportationTaxes && (
        <>
          <div className="taxes__food__filter">
            <TaxesFilters pathMatch={match} />
          </div>
          <Table rows={tableChildren} headers={headers} />
          <div className="users__pagination">
            <Pagination data={transportationTaxes} />
          </div>
        </>
      )}
      {isError && t('TAXES.MESSAGES.NO_TRANSPORTATION_TAXES')}
      {isPending && <TableSkeleton />}
    </div>
  );
}
