import { useMutation } from '@tanstack/react-query';
import apiRequest from '../../../services/apiWrapper';
import { GROUPS_URL } from '../../../utils/constants/urlConstants';

interface DeleteMemberFromGroupDto {
  groupId: string;
  userIds: string[];
}

async function deleteGroupMembers(groupId: string, userIds: string[]) {
  const queryString = userIds.map((id) => `userProfileIds=${id}`).join('&');
  return apiRequest.delete(
    `${GROUPS_URL}/${groupId}/members?${queryString}`,
    {}
  );
}

export function useDeleteGroupMembers() {
  return useMutation({
    mutationFn: (data: DeleteMemberFromGroupDto) =>
      deleteGroupMembers(data.groupId, data.userIds),
  });
}
