import { Table, useNotifications } from '@ph-react-ui/core';
import { format } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDownloadFile } from '../../../../../hooks/files/useDownloadFile';
import type { FileDetailsDto } from '../../../../../models/users/files/file-details.dto';
import {
  DOCUMENT_KEY,
  getDocumentType,
} from '../../../../../utils/constants/users/document';
import { ActionMenuHorizontal } from '../../../../shared/components/ActionMenuHorizontal';
import { DialogDeleteFile } from './DialogDeleteFile';

interface FilesTableProps {
  data: any[];
  removeFile: (index?: number | number[]) => void;
}

export const FilesTable = ({ data, removeFile }: FilesTableProps) => {
  const { t } = useTranslation();
  const notification = useNotifications();
  const [deleteFileId, setDeleteFileIdModal] = useState('');
  const [deleteFileIndex, setDeleteFileIndex] = useState<number | null>(null);
  const downloadFile = useDownloadFile();

  const onDeleteFile = ({
    index,
    fileId,
  }: {
    index: number;
    fileId: string;
  }) => {
    setDeleteFileIdModal(fileId);
    setDeleteFileIndex(index);
  };

  const onDownloadFile = useCallback(
    (fileId: string) => {
      downloadFile
        .mutateAsync(fileId)
        .then((res) => window.open(res.uri))
        .catch((resError) => {
          notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
        });
    },
    [downloadFile, notification, t]
  );

  const filesChildren = useMemo(() => {
    return data?.reduce((acc: any, file: FileDetailsDto, index: number) => {
      let fileArray: (string | JSX.Element)[] = [
        file.fileName,
        getDocumentType(DOCUMENT_KEY[file.fileType]),
        format(new Date(file.uploadDate), 'dd.MM.yyyy'),
        <ActionMenuHorizontal
          options={[
            {
              label: t('ACTIONS.DOWNLOAD'),
              onClick: () => onDownloadFile(file.id),
            },
            {
              label: t('ACTIONS.DELETE'),
              onClick: () => onDeleteFile({ index, fileId: file.id }),
            },
          ]}
        />,
      ];

      if (file.notAttached) {
        fileArray.pop();
      }

      return [...acc, fileArray];
    }, []);
  }, [data, t, onDownloadFile]);

  const tableHeaders = [
    t('USERS.DRAWER.DOCUMENTS.TABLE.HEADERS.NAME'),
    t('USERS.DRAWER.DOCUMENTS.TABLE.HEADERS.TYPE'),
    t('USERS.DRAWER.DOCUMENTS.TABLE.HEADERS.DATE'),
    t('USERS.DRAWER.DOCUMENTS.TABLE.HEADERS.ACTIONS'),
  ];

  if (!data?.length) return null;
  return (
    <>
      <Table headers={tableHeaders} rows={filesChildren} />
      {deleteFileId && (
        <DialogDeleteFile
          fileId={deleteFileId}
          removeFile={() =>
            deleteFileIndex !== null && removeFile(deleteFileIndex)
          }
          closeModal={() => setDeleteFileIdModal('')}
        />
      )}
    </>
  );
};
