import { useQuery } from '@tanstack/react-query';
import type { PaymentDetailsDto } from '../../../../models/taxes/school/payment-details.dto';
import apiRequest from '../../../../services/apiWrapper';
import { SCHOOL_TAX_PREVIOUS_UNPAID_TAX } from '../../../../utils/constants/urlConstants';

async function fetchPreviousUnpaidTax(
  paymentIds: string[],
  checkPayments?: boolean
) {
  if (checkPayments) {
    const queryString = paymentIds.map((id) => `paymentIds=${id}`).join('&');
    const response = await apiRequest.get<PaymentDetailsDto>(
      `${SCHOOL_TAX_PREVIOUS_UNPAID_TAX}?${queryString}`
    );

    return response.data;
  }
  return null;
}

export function useGetPreviousUnpaidTax(
  paymentIds: string[],
  checkPayments?: boolean
) {
  return useQuery({
    queryKey: ['previous-unpaid', paymentIds],
    queryFn: () => fetchPreviousUnpaidTax(paymentIds, checkPayments),
    enabled: paymentIds.length !== 0,
  });
}
