import { Button } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { type MouseEvent, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetTransportPaymentPricePlan } from '../../hooks/payments/useGetTransportPaymentPricePlan';
import { useSendTransportParameters } from '../../hooks/payments/useSendTransportParameters';
import { useGetMainParentProfile } from '../../hooks/profile/parent/useGetMainParentProfile';
import type { BoricaParametersDto } from '../../models/payments/borica-parameters.dto';
import type { TransportParametersDto } from '../../models/payments/transport-parameters.dto';
import type { TransportPricePlanDto } from '../../models/transport/payments/transport-price.plan.dto';
import { MONTH_NAMES } from '../../utils/constants/dateContstants';
import { DEFAULT_PAYMENT_FORM_STATE } from '../../utils/constants/payments/default-payment-form-state';
import { BoricaResponseForm } from './BoricaResponseForm';

export function TransportPayment() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement | null>(null);
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const monthParam = searchParams.get('month') ?? '';
  const yearParam = searchParams.get('year') ?? '';
  const studentId = searchParams.get('studentId') ?? '';
  const withFallback = searchParams.get('withFallback') ?? '';

  const [formState, setFormState] = useState<BoricaParametersDto>(
    DEFAULT_PAYMENT_FORM_STATE
  );

  const { getMainParent } = useGetMainParentProfile();
  const { data: transportPaymentPricePlan, isPending } =
    useGetTransportPaymentPricePlan(monthParam, yearParam);

  const sendTransportParameters = useSendTransportParameters();

  const methods = useForm<{ studentId: string }>({
    defaultValues: {
      studentId: '',
    },
  });

  const onNavigateBack = () => {
    if (Boolean(withFallback)) {
      navigate(-1);
    } else {
      navigate('/dashboard/payments');
    }
  };

  useEffect(() => {
    if (formRef.current && formState.saleRequest.AMOUNT) {
      formRef.current?.submit();
    }
  }, [formState]);

  const onPayNow = async (data: { studentId: string }) => {
    const formData: TransportParametersDto = {
      ...data,
      month: +monthParam,
      year: +yearParam,
    };
    const res = await sendTransportParameters.mutateAsync(formData);
    setFormState(res);
  };

  const handlePayPerStudent = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const payButton = event.target as HTMLButtonElement;

    methods.handleSubmit(() => {
      onPayNow({
        studentId: payButton.id,
      });
    })();
  };

  const renderStudents = () => {
    let studentsPlans = transportPaymentPricePlan?.pricePlans;

    if (
      studentId &&
      monthParam &&
      yearParam &&
      transportPaymentPricePlan?.pricePlans
    ) {
      queryClient.invalidateQueries({ queryKey: ['transportation-taxes'] });
      studentsPlans = transportPaymentPricePlan?.pricePlans.filter(
        (studentPlan) => studentPlan.student.id === studentId
      );
    }

    if (!studentsPlans?.length && !isPending) {
      return <h3>{t('PAYMENTS.TITLES.NO_UNPAID_PLANS')}</h3>;
    }

    return (
      studentsPlans &&
      studentsPlans.map((paymentPlan: TransportPricePlanDto, index: number) => (
        <div key={index}>
          <div className="payments__wrapper__flex">
            <div className="payments__wrapper">
              <p className="payments__pre-payment__label">
                {t('USERS.TABLE.ROLE.STUDENT')}
              </p>
              <p className="payments__pre-payment__value">
                {paymentPlan.student.name}
              </p>
            </div>
            <span>{paymentPlan.amount} BGN</span>
          </div>
          <div className="payments__pre-payment__plans">
            <h4 className="payments__pre-payment__plans__title">
              {t('PAYMENTS.TITLES.TRANSPORTATION_PLANS')}{' '}
              {t(`MONTHS.${MONTH_NAMES[+monthParam]}`)}
            </h4>
            <div className="payments__pre-payment__plans__weeks"></div>
            <div className="payments__pre-payment__plans__pay-button">
              <Button
                compact
                type="submit"
                id={paymentPlan.student.id}
                onClick={handlePayPerStudent}
              >
                {t('ACTIONS.PAY_NOW')}
              </Button>
            </div>
          </div>
        </div>
      ))
    );
  };

  return (
    <div className="payments__pre-payment">
      {!isPending && (
        <FormProvider {...methods}>
          <form>
            <h3 className="payments__pre-payment__title">
              {t('PAYMENTS.TITLES.TRANSPORT_ORDER')}
            </h3>
            <div className="payments__wrapper">
              <p className="payments__pre-payment__label">
                {t('USERS.TABLE.ROLE.PARENT')}
              </p>
              <p className="payments__pre-payment__value">
                {getMainParent()?.name}
              </p>
            </div>
            {renderStudents()}
            <div className="payments__pre-payment__actions">
              <Button compact variant="outlined" onClick={onNavigateBack}>
                {t('ACTIONS.BACK')}
              </Button>
            </div>
          </form>
        </FormProvider>
      )}
      <BoricaResponseForm ref={formRef} formState={formState} />
    </div>
  );
}
