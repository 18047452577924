import { useMutation } from '@tanstack/react-query';
import apiRequest from '../../services/apiWrapper';
import { TERM_REPORTS_URL } from '../../utils/constants/urlConstants';

async function sendTermReportToParent(id: string | null) {
  return apiRequest.post(`${TERM_REPORTS_URL}/${id}/send`, {});
}

export function useSendTermReportToParent() {
  return useMutation({
    mutationFn: (id: string | null) => sendTermReportToParent(id),
  });
}
