import { Skeleton } from '@ph-react-ui/core';

export function FilterSkeleton() {
  return (
    <div className="skeletons__filter">
      <Skeleton width="5%" height="20px" borderRadius="large" />
      <Skeleton width="5%" height="20px" borderRadius="large" />
      <Skeleton width="5%" height="20px" borderRadius="large" />
      <Skeleton width="5%" height="20px" borderRadius="large" />
      <Skeleton width="5%" height="20px" borderRadius="large" />
    </div>
  );
}
