import { useQuery } from '@tanstack/react-query';
import { getMonth, getYear } from 'date-fns';
import type { TransportPricePlanResponse } from '../../models/transport/payments/transport-price.plan.dto';
import apiRequest from '../../services/apiWrapper';
import { TRANSPORT_PAYMENTS } from '../../utils/constants/urlConstants';

async function getTransportPricePlan(month: string, year: string) {
  const currentDate = new Date();

  let monthToSend = (getMonth(currentDate) + 1).toString();
  let yearToSend = getYear(currentDate).toString();

  if (month && year) {
    monthToSend = month;
    yearToSend = year;
  }

  const response = await apiRequest.get<TransportPricePlanResponse>(
    `${TRANSPORT_PAYMENTS}/students/price-plan?year=${yearToSend}&month=${monthToSend}`
  );

  return response.data;
}

export function useGetTransportPaymentPricePlan(month: string, year: string) {
  return useQuery({
    queryKey: ['transport-price-plan'],
    queryFn: () => getTransportPricePlan(month, year),
    throwOnError: false,
  });
}
