import { Button, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDenyEvaluationPlanById } from '../../../hooks/evaluation-plans/useDenyEvaluationPlanById';
import { useGetEvaluationPlanById } from '../../../hooks/evaluation-plans/useGetEvaluationPlanById';
import { useModal } from '../../../hooks/useModal';
import type { EvaluationPlansDetailsDto } from '../../../models/evaluation-plans/evaluation-plans-details.dto';
import { Textarea } from '../../../utils/components/hoc-components';
import { DENY_EVALUATION_PLAN } from '../../../utils/constants/evaluation-plans/modals';
import {
  getCommonValidators,
  VALIDATION as V,
} from '../../../utils/inputValidators';
import { Dialog } from '../../shared/components/Dialog';
import { FormSkeleton } from '../../shared/components/skeletons/FormSkeleton';

export const DenyEvaluationPlanModal = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const notification = useNotifications();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { close: closeModal } = useModal(DENY_EVALUATION_PLAN);

  const isReviewingNote = pathname.includes('evaluation-plans/my');

  const { data: evaluationPlan, isLoading } = useGetEvaluationPlanById(id!);
  const denyEvaluationPlan = useDenyEvaluationPlanById();

  const methods = useForm({
    defaultValues: {
      deniedReason: '',
    },
    values: evaluationPlan,
  });

  const onSubmit = (data: EvaluationPlansDetailsDto) => {
    denyEvaluationPlan
      .mutateAsync({
        id: data.id,
        reason: data.deniedReason,
      })
      .then(() => {
        notification.success(
          t('EVALUATION_PLANS.MESSAGES.SUCCESSFULLY_DENIED')
        );
        queryClient.invalidateQueries({
          queryKey: ['my-evaluation-plans'],
        });
        queryClient.invalidateQueries({
          queryKey: ['evaluation-plans'],
        });
        navigate(-1);
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  if (isLoading) {
    return <FormSkeleton />;
  }
  return (
    <Dialog
      dialogHeader={
        isReviewingNote
          ? t('EVALUATION_PLANS.TITLES.EVALUATION_PLAN_REVIEW')
          : t('EVALUATION_PLANS.TITLES.DENY_EVALUATION_PLAN')
      }
      onClose={() => {
        closeModal();
      }}
      actionButton={
        !isReviewingNote ? (
          <Button onClick={methods.handleSubmit((data) => onSubmit(data))}>
            {t('ACTIONS.CONTINUE')}
          </Button>
        ) : null
      }
    >
      <FormProvider {...methods}>
        <form>
          <div className="dialog__confirm__content">
            <Textarea
              disabled={isReviewingNote}
              name="deniedReason"
              className="dialog__confirm__content__textarea"
              label={t('EVALUATION_PLANS.FORM.LABELS.DENY_NOTE') ?? ''}
              placeholder={
                t('EVALUATION_PLANS.FORM.PLACEHOLDERS.DENY_NOTE') ?? ''
              }
              rules={getCommonValidators([V.MAX_LENGTH_2000, V.REQUIRED])}
            />
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};
