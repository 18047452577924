import { useQueryClient } from '@tanstack/react-query';
import jwt_decode from 'jwt-decode';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import type {
  AuthInfo,
  AuthProviderProps,
  AuthContext as AuthContextProps,
  JWTData,
} from '../../models/auth/auth.model';
import { isTokenExpirationValid } from '../../utils/auth/tokenUtils';
import { clearLocalStorage } from '../../utils/localStorage';

const AuthContext = createContext<AuthContextProps | null>(null);

export function AuthProvider({ children }: AuthProviderProps) {
  const [authState, setAuthState] = useState<AuthInfo | null>(() => {
    const accessToken = localStorage.getItem('accessToken') || '';
    const refreshToken = localStorage.getItem('refreshToken') || '';
    const expires = Number(localStorage.getItem('expires'));
    const role = Number(localStorage.getItem('role') || -1);
    const scope = localStorage.getItem('scope') || '';
    return { accessToken, refreshToken, expires, role, scope };
  });

  const queryClient = useQueryClient();

  const setAuthInfo = ({
    accessToken,
    expires,
    refreshToken,
    role,
  }: AuthInfo) => {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('expires', expires.toString());
    localStorage.setItem('role', role.toString());

    const { scope } = jwt_decode<JWTData>(accessToken);
    localStorage.setItem('scope', scope || '');
    setAuthState({ accessToken, expires, refreshToken, role, scope });
  };

  const isAuthenticated = useCallback(() => {
    if (!authState?.accessToken || !authState?.expires) {
      return false;
    }

    if (isTokenExpirationValid(authState.expires)) {
      return true;
    }

    const refreshToken = localStorage.getItem('refreshToken') || '';
    if (refreshToken) {
      return true;
    }
    return false;
  }, [authState]);

  const logout = useCallback(() => {
    clearLocalStorage();
    queryClient.clear();
    setAuthState(null);
  }, [queryClient]);

  const getAccessToken = () => {
    return localStorage.getItem('accessToken');
  };

  const contextValue = useMemo(() => {
    return {
      authState,
      getAccessToken,
      isAuthenticated,
      logout,
      setAuthInfo,
    };
  }, [authState, isAuthenticated, logout]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within AuthProvider');
  }

  return context;
}
