import { t } from 'i18next';
import { useAuth } from '../../../../../hooks/auth/useAuth';
import { mapDateToSimpleDate } from '../../../../../services/mapDateToSimpleDate';
import {
  Input,
  DatePicker,
} from '../../../../../utils/components/hoc-components';
import { Role } from '../../../../../utils/enums/role.enum';
import {
  getCommonValidators,
  VALIDATION as V,
} from '../../../../../utils/inputValidators';

interface PedagogicalSectionProps {
  canCreateStudentPlans?: boolean;
}

export const PedagogicalSection = ({
  canCreateStudentPlans = false,
}: PedagogicalSectionProps) => {
  const { authState } = useAuth();
  const isEmployee = authState?.role === Role.Employee || canCreateStudentPlans;
  const employeeRule = {
    required: {
      value: isEmployee,
      message: t('ERROR.VALIDATION.REQUIRED'),
    },
  };

  return (
    <>
      <div className="mb-6">
        <Input
          name="education"
          rules={getCommonValidators([V.MAX_LENGTH])}
          label={t('USERS.DRAWER.LABELS.EDUCATION') ?? ''}
          className="drawer__users__form__input"
          placeholder={t('USERS.DRAWER.PLACEHOLDERS.EDUCATION') ?? ''}
        />
      </div>
      <div className="mb-6">
        <Input
          name="levelOfEducation"
          rules={{
            ...getCommonValidators([V.MAX_LENGTH]),
            ...employeeRule,
          }}
          label={t('USERS.DRAWER.LABELS.LEVEL_OF_EDUCATION') ?? ''}
          className="drawer__users__form__input"
          placeholder={t('USERS.DRAWER.PLACEHOLDERS.LEVEL_OF_EDUCATION') ?? ''}
        />
      </div>
      <div className="mb-6">
        <Input
          name="diplomaSeries"
          rules={{
            ...getCommonValidators([V.MAX_LENGTH]),
            ...employeeRule,
          }}
          label={t('USERS.DRAWER.LABELS.DIPLOMA_SERIES') ?? ''}
          className="drawer__users__form__input"
          placeholder={t('USERS.DRAWER.PLACEHOLDERS.DIPLOMA_SERIES') ?? ''}
        />
      </div>

      <div className="mb-6">
        <Input
          name="diplomaNumber"
          rules={{
            ...getCommonValidators([V.MAX_LENGTH]),
            ...employeeRule,
          }}
          label={t('USERS.DRAWER.LABELS.DIPLOMA_NUMBER') ?? ''}
          className="drawer__users__form__input"
          placeholder={t('USERS.DRAWER.PLACEHOLDERS.DIPLOMA_NUMBER') ?? ''}
        />
      </div>

      <div className="mb-6">
        <DatePicker
          rules={employeeRule}
          name="diplomaIssueDate"
          maxDate={mapDateToSimpleDate(new Date())}
          className="drawer__users__form__input"
          placeholder={t('USERS.DRAWER.PLACEHOLDERS.DIPLOMA_ISSUE_DATE') ?? ''}
          label={t('USERS.DRAWER.LABELS.DIPLOMA_ISSUE_DATE') ?? ''}
        />
      </div>
    </>
  );
};
