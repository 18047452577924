import { Table, Skeleton } from '@ph-react-ui/core';
import type { ReactNode } from 'react';
interface TableSkeletonProps {
  headers?: string[] | ReactNode[];
  size?: number;
}

const baseHeader = Array.from({ length: 5 }).fill('') as string[];

export function TableSkeleton({
  size = 10,
  headers = baseHeader,
}: TableSkeletonProps) {
  const row = headers.map((item, index) => {
    if (index === 0) {
      return (
        <div className="skeletons__table__item skeletons__table__item-first">
          <Skeleton variant="circular" height="20px" width="20px" />
          <Skeleton borderRadius="large" height="20px" width="80%" />
        </div>
      );
    }
    return (
      <div
        className={`py-2 skeletons__table__item ${
          index === headers.length - 1 ? 'skeletons__table__item-last' : ''
        }`}
      >
        <Skeleton borderRadius="large" height="20px" width="40%" />
      </div>
    );
  });
  const rows = Array(size)
    .fill(null)
    .map(() => row);

  return <Table headers={headers} rows={rows} />;
}
