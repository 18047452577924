import { Button, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useArchiveEvaluationPlanById } from '../../../hooks/evaluation-plans/useArchiveEvaluationPlan';
import { useModal } from '../../../hooks/useModal';
import { ARCHIVE_PLAN } from '../../../utils/constants/evaluation-plans/modals';
import { Dialog } from '../../shared/components/Dialog';

export const ArchiveEvaluationPlan = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const active = searchParams.get('active');
  const notification = useNotifications();
  const queryClient = useQueryClient();
  const { close: closeModal } = useModal(ARCHIVE_PLAN);
  const archiveEvaluationPlan = useArchiveEvaluationPlanById();

  const onArchiveEvaluationPlan = () => {
    archiveEvaluationPlan
      .mutateAsync(id!)
      .then(() => {
        notification.success(
          t('EVALUATION_PLANS.MESSAGES.SUCCESSFULLY_ARCHIVED')
        );
        queryClient.invalidateQueries({ queryKey: ['my-evaluation-plans'] });
        queryClient.invalidateQueries({ queryKey: ['evaluation-plans'] });

        closeModal();
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  return (
    <Dialog
      onClose={() => closeModal()}
      dialogHeader={t('EVALUATION_PLANS.TITLES.ARCHIVE_PLAN')}
      actionButton={
        <Button onClick={onArchiveEvaluationPlan}>
          {t('ACTIONS.CONTINUE')}
        </Button>
      }
    >
      <h2>{t('EVALUATION_PLANS.MODALS.ARCHIVE')}</h2>
      {active === 'true' && (
        <h4>{t('EVALUATION_PLANS.MODALS.ARCHIVE_NOTE')}</h4>
      )}
    </Dialog>
  );
};
