import { useQuery } from '@tanstack/react-query';
import type { StudentStopDetailsDto } from '../../../models/transportation/students/student-stop-details.dto';
import apiRequest from '../../../services/apiWrapper';
import { TRANSPORTATION_BUS_STOPS_URL } from '../../../utils/constants/urlConstants';

export async function getStudentsBusStopDetails(id: string | null) {
  const response = await apiRequest.get<StudentStopDetailsDto>(
    `${TRANSPORTATION_BUS_STOPS_URL}/students/${id}`
  );
  return response.data;
}

export function useGetStudentsBusStopDetails(id: string | null) {
  return useQuery({
    queryKey: ['students-stop-details', id],
    queryFn: () => getStudentsBusStopDetails(id),
  });
}
