import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import type { CriteriaDetailsDto } from '../../models/evaluation-plans/evaluation-plans-form.dto';

export function useGetEvaluationPlanLegendDetails() {
  const queryClient = useQueryClient();

  const setLegendDetails = useCallback(
    (criteriaDetails: CriteriaDetailsDto) => {
      queryClient.setQueryData(['evaluation-plan-legent-details'], {
        ...criteriaDetails,
      });
    },
    [queryClient]
  );

  const removeLegendDetails = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: ['evaluation-plan-legent-details'],
    });
  }, [queryClient]);

  const { data: legendDetails } = useQuery<CriteriaDetailsDto>({
    queryKey: ['evaluation-plan-legent-details'],
    queryFn: () => new Promise(() => false),
  });

  return { legendDetails, setLegendDetails, removeLegendDetails };
}
