import { Time } from '@carbon/icons-react';
import { Button } from '@ph-react-ui/core';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useGetAllEvaluationScoresByStudentId } from '../../../hooks/evaluation-plans/useGetAllEvaluationScoresByStudentId';
import { useModal } from '../../../hooks/useModal';
import { EVALUATION_SCORES } from '../../../utils/constants/evaluation-plans/modals';
import { NavigateBack } from '../../shared/components/NavigateBack';

export function StudentEvaluations() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { open: openModal } = useModal(EVALUATION_SCORES);
  const { data: evaluationScores } = useGetAllEvaluationScoresByStudentId(id);
  const [searchParams] = useSearchParams();
  const name = searchParams.get('name');

  const onOpenEvaluationScores = ({
    evaluationId,
    templateName,
    date,
  }: {
    evaluationId: string;
    templateName: string;
    date: string;
  }) => {
    openModal({ evaluationId, templateName, date });
  };

  return (
    <div className="student-evaluations">
      <div className="student-evaluations__actions">
        <NavigateBack
          onNavigate={() => navigate('/dashboard/evaluation-plans/students')}
          text={t('EVALUATION_PLANS.MESSAGES.GO_BACK')}
        />
      </div>
      <section className="student-evaluations__menu">
        <h2 className="student-evaluations__menu__title">{name}</h2>
        {evaluationScores?.length === 0
          ? t('EVALUATION_PLANS.TITLES.NO_EVALUATIONS')
          : evaluationScores?.map((evaluationPlan, index) => (
              <div className="student-evaluations__menu__item" key={index}>
                <h3 className="student-evaluations__menu__item__title">
                  {evaluationPlan.template.name}
                </h3>
                <div className="student-evaluations__menu__item__buttons">
                  {evaluationPlan.evaluations.map((item, index) => (
                    <Button
                      compact
                      onClick={() =>
                        onOpenEvaluationScores({
                          evaluationId: item.id,
                          templateName: evaluationPlan.template.name,
                          date: item.createdAt,
                        })
                      }
                      key={index}
                    >
                      <Time />
                      {format(new Date(item.createdAt), 'dd.MM.yyyy')}
                    </Button>
                  ))}
                </div>
              </div>
            ))}
      </section>
    </div>
  );
}
