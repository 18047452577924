import { useMutation } from '@tanstack/react-query';
import type { MealMenuCreateDto } from '../../../models/meal-menus/admin/meal-menu-create.dto';
import apiRequest from '../../../services/apiWrapper';
import { MEAL_MENUS_URL } from '../../../utils/constants/urlConstants';

async function createMealMenuForMonthAndYear(data: MealMenuCreateDto) {
  return apiRequest.post(MEAL_MENUS_URL, data);
}

export function useCreateMealMenuForMonthAndYear() {
  return useMutation({
    mutationFn: (data: MealMenuCreateDto) =>
      createMealMenuForMonthAndYear(data),
  });
}
