import { useQuery } from '@tanstack/react-query';
import type { StudentRequestDetailsDto } from '../../../models/transportation/students/student-request-details.dto';
import apiRequest from '../../../services/apiWrapper';
import { TRANSPORTATION_BUS_STOPS_URL } from '../../../utils/constants/urlConstants';

export async function getStudentRequestDetails(id: string | null) {
  const response = await apiRequest.get<StudentRequestDetailsDto>(
    `${TRANSPORTATION_BUS_STOPS_URL}/requests/${id}`
  );
  return response.data;
}

export function useGetStudentRequestDetails(id: string | null) {
  return useQuery({
    queryKey: ['students-request-details', id],
    queryFn: () => getStudentRequestDetails(id),
  });
}
