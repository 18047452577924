import { useQuery } from '@tanstack/react-query';
import type { PaginatedList } from '../../../../../models/common/paginated-list.model';
import type { HistoryOfSchoolTaxesPaymentsDto } from '../../../../../models/taxes/school/history-of-school-taxes-payments.dto';
import apiRequest from '../../../../../services/apiWrapper';
import { createPaginatedQueryKeys } from '../../../../../services/createPaginatedQueryKeys';
import { SCHOOL_TAX_PAYMENT_HISTORY_URL } from '../../../../../utils/constants/urlConstants';

async function fetchHistoryOfSchoolTaxPayments(searchParams: URLSearchParams) {
  const response = await apiRequest.get<
    PaginatedList<HistoryOfSchoolTaxesPaymentsDto>
  >(`${SCHOOL_TAX_PAYMENT_HISTORY_URL}?${searchParams.toString()}`);

  return response.data;
}

export function usePaginatedHistoryOfSchoolTaxPayments(
  searchParams: URLSearchParams
) {
  return useQuery({
    queryKey: ['school-taxes-history', createPaginatedQueryKeys(searchParams)],
    queryFn: () => fetchHistoryOfSchoolTaxPayments(searchParams),
  });
}
