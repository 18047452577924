import { useMutation } from '@tanstack/react-query';
import type { ParentsUpdateDto } from '../../../models/users/parents/parent-update.dto';
import apiRequest from '../../../services/apiWrapper';
import { FAMILY_URL } from '../../../utils/constants/urlConstants';

async function updateParentById(
  id: string | null,
  updateParentData: ParentsUpdateDto
) {
  return apiRequest.put(`${FAMILY_URL}/${id}`, updateParentData);
}

// id -> family id
export function useUpdateParentById(id: string | null) {
  return useMutation({
    mutationFn: (updateParentData: ParentsUpdateDto) =>
      updateParentById(id, updateParentData),
  });
}
