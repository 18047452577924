import { useMutation } from '@tanstack/react-query';
import apiRequest from '../../services/apiWrapper';
import { EVALUATION_PLANS_URL } from '../../utils/constants/urlConstants';

async function submitEvaluationPlanForApprovalById(id: string | undefined) {
  return apiRequest.put(`${EVALUATION_PLANS_URL}/${id}/submit`, {});
}

export function useSubmitEvaluationPlanForApprovalById() {
  return useMutation({
    mutationFn: (id: string | undefined) =>
      submitEvaluationPlanForApprovalById(id),
  });
}
