import { Button } from '@ph-react-ui/core';
import type { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export function ErrorView({ error, resetErrorBoundary }: FallbackProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onGoToMain = () => {
    resetErrorBoundary();
    navigate('');
  };
  return (
    <div className="ph-light error">
      <div className="error__header">{error.status}</div>
      <div className="error__message">
        {t(`NETWORK_ERRORS.${error.errors[0]}`)}
      </div>
      <div className="error__actions">
        <Button color="primary" variant="outlined" onClick={resetErrorBoundary}>
          {t('ERROR.ACTIONS.RETRY')}
        </Button>
        <Button color="primary" variant="filled" onClick={onGoToMain}>
          {t('ERROR.ACTIONS.GO_TO_MAIN')}
        </Button>
      </div>
    </div>
  );
}
