import { Button, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDeleteGroupMembers } from '../../../hooks/groups/members/useDeleteGroupMembers';
import { useModal } from '../../../hooks/useModal';
import { REMOVE_GROUP_MEMBER } from '../../../utils/constants/users/modals';
import { Dialog } from '../../shared/components/Dialog';

export const RemoveGroupMember = () => {
  const { t } = useTranslation();
  const notification = useNotifications();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const { id: groupId } = useParams();
  const userIds = searchParams.get('userId')?.split('&') as [];
  const { close: closeModal } = useModal(REMOVE_GROUP_MEMBER);
  const removeMembers = useDeleteGroupMembers();

  const onRemoveMembers = () => {
    removeMembers
      .mutateAsync({
        groupId: groupId as string,
        userIds: userIds,
      })
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ['members', groupId as string],
        });
        closeModal();
        notification.success(t('GROUPS.MEMBERS.REMOVE_SUCCESS'));
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  return (
    <Dialog
      onClose={() => closeModal()}
      dialogHeader={t('GROUPS.MEMBERS.REMOVE_TITLE')}
      actionButton={
        <Button onClick={onRemoveMembers}>{t('ACTIONS.REMOVE')}</Button>
      }
    >
      <h2>
        {userIds?.length > 1
          ? t('GROUPS.MEMBERS.ARE_YOU_SURE_MULTI_REMOVE')
          : t('GROUPS.MEMBERS.ARE_YOU_SURE_REMOVE')}
      </h2>
    </Dialog>
  );
};
