import { useMutation } from '@tanstack/react-query';
import apiRequest from '../../services/apiWrapper';
import { EVALUATION_PLANS_URL } from '../../utils/constants/urlConstants';

async function archiveEvaluationPlan(id: string) {
  return apiRequest.put(`${EVALUATION_PLANS_URL}/${id}/archive`, {});
}

export function useArchiveEvaluationPlanById() {
  return useMutation({
    mutationFn: (id: string) => archiveEvaluationPlan(id),
  });
}
