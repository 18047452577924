import { useMutation } from '@tanstack/react-query';
import type { EvaluationPlanDenyDto } from '../../models/evaluation-plans/evaluation-plan-deny.dto';
import apiRequest from '../../services/apiWrapper';
import { EVALUATION_PLANS_URL } from '../../utils/constants/urlConstants';

async function denyEvaluationPlanById(data: EvaluationPlanDenyDto) {
  return apiRequest.put(`${EVALUATION_PLANS_URL}/${data.id}/deny`, {
    reason: data.reason,
  });
}

export function useDenyEvaluationPlanById() {
  return useMutation({
    mutationFn: (data: EvaluationPlanDenyDto) => denyEvaluationPlanById(data),
  });
}
