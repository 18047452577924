import { useTranslation } from 'react-i18next';
import { mapDateToSimpleDate } from '../../../services/mapDateToSimpleDate';
import { DatePicker, Input } from '../../../utils/components/hoc-components';
import {
  getCommonValidators,
  VALIDATION as V,
} from '../../../utils/inputValidators';

interface StudentInformationProps {
  formGroupName: string;
}

export const StudentInformation = ({
  formGroupName = '',
}: StudentInformationProps) => {
  const { t } = useTranslation();
  const numberOfQuestions = 6;
  const arrayQA = Array.from({ length: numberOfQuestions }, (_, i) => i + 1);

  return (
    <>
      <div className="mb-6">
        <Input
          name={`${formGroupName}name`}
          rules={getCommonValidators([V.REQUIRED, V.MAX_LENGTH])}
          className="drawer__users__form__input"
          placeholder={t('ADMISSION.APPLICATION.LABELS.FULLNAME_PUPIL') ?? ''}
          label={t('ADMISSION.APPLICATION.LABELS.FULLNAME_PUPIL')!}
        />
      </div>

      <div className="mb-6">
        <DatePicker
          name={`${formGroupName}birthDate`}
          rules={getCommonValidators([V.REQUIRED])}
          maxDate={mapDateToSimpleDate(new Date())}
          className="drawer__users__form__input"
          placeholder={t('ADMISSION.APPLICATION.LABELS.BIRTH_DATE') ?? ''}
          label={t('ADMISSION.APPLICATION.LABELS.BIRTH_DATE')!}
        />
      </div>

      <div className="mb-6">
        <Input
          name={`${formGroupName}address`}
          rules={getCommonValidators([V.REQUIRED, V.MAX_LENGTH])}
          className="drawer__users__form__input"
          placeholder={t('ADMISSION.APPLICATION.LABELS.CURRENT_ADDRESS') ?? ''}
          label={t('ADMISSION.APPLICATION.LABELS.CURRENT_ADDRESS')!}
        />
      </div>

      <div className="mb-6">
        <Input
          name={`${formGroupName}schoolYear`}
          rules={getCommonValidators([V.MAX_LENGTH])}
          className="drawer__users__form__input"
          placeholder={t('ADMISSION.APPLICATION.LABELS.SCHOOL_YEAR') ?? ''}
          label={t('ADMISSION.APPLICATION.LABELS.SCHOOL_YEAR')!}
        />
      </div>

      <div className="mb-6 full-width">
        <Input
          name={`${formGroupName}siblings`}
          rules={getCommonValidators([V.MAX_LENGTH])}
          className="drawer__users__form__input"
          placeholder={t('ADMISSION.APPLICATION.LABELS.SIBLINGS') ?? ''}
          label={t('ADMISSION.APPLICATION.LABELS.SIBLINGS')!}
        />
      </div>

      {arrayQA.map((num) => (
        <div className="mb-6 full-width" key={`question-${num}`}>
          <Input
            name={`${formGroupName}additionalInformation[${num - 1}].answer`}
            label={t(`ADMISSION.APPLICATION.QUESTIONS.Q${num}`) ?? ''}
            placeholder={t(`ADMISSION.APPLICATION.QUESTIONS.Q${num}`) ?? ''}
            rules={getCommonValidators([V.MAX_LENGTH_2000])}
          />
        </div>
      ))}
    </>
  );
};
