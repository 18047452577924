import { useMutation } from '@tanstack/react-query';
import type { TransportationPlanUpdateDto } from '../../../models/transportation/students/transportation-plan-update.dto';
import apiRequest from '../../../services/apiWrapper';
import { TRANSPORTATION_BUS_STOPS_URL } from '../../../utils/constants/urlConstants';

async function updateTransportationPlanForStudent(
  id: string | null,
  data: TransportationPlanUpdateDto
) {
  const response = await apiRequest.put(
    `${TRANSPORTATION_BUS_STOPS_URL}/assign`,
    data
  );

  return response.data;
}

export function useUpdateTransportationPlanForStudent(id: string | null) {
  return useMutation({
    mutationFn: (data: TransportationPlanUpdateDto) =>
      updateTransportationPlanForStudent(id, data),
  });
}
