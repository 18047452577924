import { Table, Tag, Tooltip } from '@ph-react-ui/core';
import { format } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { usePaginatedHistoryOfSchoolTaxPayments } from '../../../../../hooks/taxes/school-taxes/parent/history/usePaginatedHistoryOfSchoolTaxPayments';
import { ActionMenuHorizontal } from '../../../../shared/components/ActionMenuHorizontal';
import { Pagination } from '../../../../shared/components/Pagination';
import { TableSkeleton } from '../../../../shared/components/skeletons/TableSkeleton';

export function SchoolTaxesHistory() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const { data, isSuccess, isPending } =
    usePaginatedHistoryOfSchoolTaxPayments(searchParams);

  const headers = [
    t('TAXES.TABLE.HEADERS.STUDENT'),
    t('TAXES.TABLE.HEADERS.AMOUNT'),
    t('TAXES.TABLE.HEADERS.STATUS'),
    t('TAXES.TABLE.HEADERS.PAYMENT_PERIOD'),
    t('TAXES.TABLE.HEADERS.PAYMENT_DATE'),
    t('TAXES.TABLE.HEADERS.PAID_WITH'),
    t('TAXES.TABLE.HEADERS.NOTES'),
    t('TAXES.TABLE.HEADERS.ACTIONS'),
  ];

  const onPayNow = useCallback(
    (id: string, name: string) => {
      navigate(
        `/dashboard/payments/school-taxes/pre-payment/${id}/${name}?withFallback=true`
      );
    },
    [navigate]
  );

  const tableChildren = useMemo(() => {
    return data?.result.reduce((acc: any, user) => {
      let userArray = [
        user.name,
        <span>
          {user.amount.toFixed(2)}&nbsp;{t('TAXES.LABELS.BGN')}
        </span>,
        user.isPaid ? (
          <Tag type="success">{t('TAXES.STATUS.PAID')}</Tag>
        ) : (
          <Tag type="accent">{t('TAXES.STATUS.PENDING')}</Tag>
        ),
        user.fromDate
          ? `${t(
              `MONTHS.${format(new Date(user.fromDate), 'MMMM').toUpperCase()}`
            )} ${new Date(user.fromDate).getFullYear()}`
          : '-',
        user.isPaid && user.paidAt
          ? format(new Date(user.paidAt), 'dd.MM.yyyy')
          : '',
        user.isPaid
          ? user.isPaidWithCard
            ? t('TAXES.LABELS.CARD')
            : t('TAXES.LABELS.TRANSFER')
          : '',

        user.note ? (
          <span className="taxes__school__table__row__info">
            <Tooltip
              className="taxes__school__table__row__tooltip"
              message={user.note}
            >
              {user.note.slice(0, 25) + '...'}
            </Tooltip>
          </span>
        ) : (
          ''
        ),
        !user.isPaid ? (
          <ActionMenuHorizontal
            options={[
              {
                label: t('ACTIONS.PAY_NOW'),
                onClick: () => onPayNow(user.id, user.name),
              },
            ]}
          />
        ) : (
          ''
        ),
      ];

      return [...acc, userArray];
    }, []);
  }, [data, t, onPayNow]);
  return (
    <div className="taxes__school">
      <h2 className="taxes__school__title">
        {t('TAXES.TITLES.SCHOOL_TAXES_HISTORY')}
      </h2>
      {isPending && <TableSkeleton />}
      {isSuccess && data ? (
        <>
          <Table headers={headers} rows={tableChildren} />
          <div className="users__pagination">
            <Pagination data={data} />
          </div>
        </>
      ) : (
        <TableSkeleton />
      )}
    </div>
  );
}
