import { useQuery } from '@tanstack/react-query';
import type { PaginatedList } from '../../../models/common/paginated-list.model';
import type { BusRouteDTO } from '../../../models/transportation/bus-route.dto';
import apiRequest from '../../../services/apiWrapper';
import { BUS_ROUTES_URL } from '../../../utils/constants/urlConstants';

async function getBusRoutes() {
  const res = await apiRequest.get<PaginatedList<BusRouteDTO>>(
    `${BUS_ROUTES_URL}?size=30`
  );

  return res.data;
}

export function useGetBusRoutes() {
  return useQuery({
    queryKey: ['bus-routes'],
    queryFn: () => getBusRoutes(),
    throwOnError: false,
  });
}
