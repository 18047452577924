import { useQuery } from '@tanstack/react-query';
import type { BusStopsPerStudent } from '../../../models/transportation/parent/bus-stops-per-student';
import apiRequest from '../../../services/apiWrapper';
import { TRANSPORTATION_BUS_STOPS_URL_PARENT } from '../../../utils/constants/urlConstants';

async function getBusStopsPerStudent(sudentId: string | null) {
  const response = await apiRequest.get<BusStopsPerStudent>(
    `${TRANSPORTATION_BUS_STOPS_URL_PARENT}/students/${sudentId}`
  );

  return response.data;
}

export function useGetBusStopsPerStudent(
  sudentId: string | null,
  enabled: boolean = true
) {
  return useQuery({
    queryKey: ['bus-stop-per-student', sudentId],
    queryFn: () => getBusStopsPerStudent(sudentId),
    enabled,
  });
}
