import { Table, useNotifications, Select } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate, useSearchParams } from 'react-router-dom';
import { useApproveTermReportById } from '../../../hooks/term-reports/admin/useApproveTermReportById';
import { usePaginatedAllTermReports } from '../../../hooks/term-reports/admin/usePaginatedAllTermReports';
import { useModal } from '../../../hooks/useModal';
import type { TermReportInProgressDetailsDto } from '../../../models/term-reports/term-reports-details.dto';
import { DENY_TERM_REPORT } from '../../../utils/constants/term-reports/modals';
import {
  getTermReportStatus,
  TERM_REPORT_STATUS,
  TERM_REPORT_STATUS_OPTIONS,
} from '../../../utils/constants/term-reports/status';
import { TermReportStatus } from '../../../utils/enums/term-report-status.enum';
import { UserOrdering } from '../../../utils/enums/user-ordering.enum';
import { ActionMenuHorizontal } from '../../shared/components/ActionMenuHorizontal';
import { Pagination } from '../../shared/components/Pagination';
import { SearchInput } from '../../shared/components/SearchByNameInput';
import { FilterSkeleton } from '../../shared/components/skeletons/FilterSkeleton';
import { TableSkeleton } from '../../shared/components/skeletons/TableSkeleton';
import { ThSortItem } from '../../shared/components/tables/ThSortItem';

export const AllReports = () => {
  const match = useMatch('/dashboard/term-reports/my-reports');
  const notification = useNotifications();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { open: onOpenDenyTermReport } = useModal(DENY_TERM_REPORT);

  const {
    data: reports,
    isPending,
    isSuccess,
  } = usePaginatedAllTermReports(searchParams);

  const approveTermReport = useApproveTermReportById();

  const onFilterByStatusChanged = (
    value: string | number | (string | number)[] | null
  ) => {
    if (value) {
      searchParams.set(
        'status',
        typeof value === 'string' ? value : value?.toString()
      );
      searchParams.set('page', '1');
      setSearchParams(searchParams);
    } else {
      searchParams.delete('status');
      setSearchParams(searchParams);
    }
  };

  const openDenyTermReportModal = useCallback(
    (id: string) => {
      onOpenDenyTermReport({
        id,
      });
    },
    [onOpenDenyTermReport]
  );

  const onApproveTermReport = useCallback(
    (id: string) => {
      approveTermReport
        .mutateAsync(id)
        .then(() => {
          notification.success(
            t('TERM_REPORTS.MESSAGES.SUCCESSFULLY_APPROVED')
          );
          queryClient.invalidateQueries({
            queryKey: ['term-reports-in-progress'],
          });
          queryClient.invalidateQueries({
            queryKey: ['term-reports'],
          });
        })
        .catch((resError) => {
          notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
        });
    },
    [approveTermReport, notification, queryClient, t]
  );

  const onEditTermReport = useCallback(
    (id: string) => {
      navigate(`approve-edit/${id}`);
    },
    [navigate]
  );

  const headers = [
    t('TERM_REPORTS.TABLE.HEADERS.NAME'),
    t('TERM_REPORTS.TABLE.HEADERS.STATUS'),
    <div className="users__table">
      <ThSortItem
        title={t('TERM_REPORTS.TABLE.HEADERS.TITLE')}
        ordering={UserOrdering.Title}
      />
    </div>,
    t('TERM_REPORTS.TABLE.HEADERS.ACTIONS'),
  ];

  const getReportActions = useCallback(
    (report: TermReportInProgressDetailsDto) => {
      if (report.status === TermReportStatus.Submitted) {
        return (
          <ActionMenuHorizontal
            options={[
              {
                label: t('TERM_REPORTS.TABLE.ACTIONS.EDIT'),
                onClick: () => {
                  onEditTermReport(report.id);
                },
              },
              {
                label: t('TERM_REPORTS.TABLE.ACTIONS.APPROVE'),
                onClick: () => {
                  onApproveTermReport(report.id);
                },
              },
              {
                label: t('TERM_REPORTS.TABLE.ACTIONS.DENY'),
                onClick: () => {
                  openDenyTermReportModal(report.id);
                },
              },
            ]}
          />
        );
      }
      return <div className="action-menu-placeholder"></div>;
    },
    [onApproveTermReport, onEditTermReport, openDenyTermReportModal, t]
  );

  const tableChildren = useMemo(() => {
    return reports?.result.reduce((acc: any, report) => {
      let reportsArray = [
        report.studentName,
        getTermReportStatus(TERM_REPORT_STATUS[report.status]),
        report.title,
        getReportActions(report),
      ];

      return [...acc, reportsArray];
    }, []);
  }, [reports?.result, getReportActions]);

  return (
    <div>
      <div className="term-reports__filters">
        {isPending ? (
          <FilterSkeleton />
        ) : (
          <>
            <SearchInput
              pathMatch={match}
              placeholder={t('TERM_REPORTS.TABLE.FILTERS.SEARCH_TITLE')}
              searchInputName="title"
            />
            <Select
              compact
              options={TERM_REPORT_STATUS_OPTIONS}
              clearable
              placeholder={
                t('TERM_REPORTS.TABLE.FILTERS.FILTER_BY_STATUS') ?? ''
              }
              onChange={onFilterByStatusChanged}
              value={searchParams.get('status') ?? ''}
            />
          </>
        )}
      </div>
      {isSuccess && reports ? (
        <>
          <Table headers={headers} rows={tableChildren} />
          <div className="term-reports__pagination">
            <Pagination data={reports} />
          </div>
        </>
      ) : (
        <TableSkeleton headers={headers} />
      )}
    </div>
  );
};
