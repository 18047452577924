import { Close, Image } from '@carbon/icons-react';
import type { FileDetailsDto } from '../../../models/users/files/file-details.dto';
import { formatFileName } from '../../../services/formatFileName';

interface FileListItemProps {
  file: FileDetailsDto;
  index: number;
  removeFile: (index: number) => void;
}
export function FileListItem({ file, index, removeFile }: FileListItemProps) {
  return (
    <div className="upload__file__other__item">
      <button
        className="upload__file__other__item__remove"
        onClick={() => removeFile(index)}
      >
        <Close className="upload__file__other__item__icon" />
      </button>
      <div className="upload__file__other__item__img">
        <Image className="upload__file__other__item__img__icon" />
      </div>
      <p className="upload__file__other__item__text">
        {formatFileName(file.fileName)}
      </p>
    </div>
  );
}
