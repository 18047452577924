import { useMutation } from '@tanstack/react-query';
import apiRequest from '../../../services/apiWrapper';
import { TRANSPORTATION_BUS_STOPS_URL } from '../../../utils/constants/urlConstants';

async function approveStudentTransportationRequestById(id: string) {
  return apiRequest.put(
    `${TRANSPORTATION_BUS_STOPS_URL}/requests/${id}/approve`,
    {}
  );
}

export function useApproveStudentTransportationRequestById() {
  return useMutation({
    mutationFn: (id: string) => approveStudentTransportationRequestById(id),
  });
}
