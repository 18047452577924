import { Button } from '@ph-react-ui/core';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';
import { SearchInput } from '../../shared/components/SearchByNameInput';

interface GroupFilterProps {
  handleSubmit: () => void;
  isDisabled: boolean;
}

export const GroupsFilter = ({
  handleSubmit,
  isDisabled,
}: GroupFilterProps) => {
  const { t } = useTranslation();
  const match = useMatch('/dashboard/groups');

  return (
    <div className="pt-2 pb-1">
      <div className="groups__filters">
        <div className="groups__filters__item-header">
          <div>
            <span className="groups__filters__item__title">
              {t('GROUPS.TABLE.FILTERS.TITLE')}
            </span>
          </div>
        </div>
        <div className="groups__filters__actions">
          <SearchInput pathMatch={match} placeholder={t('GROUPS.FILTER')} />
          <Button type="button" onClick={handleSubmit} disabled={isDisabled}>
            {t('GROUPS.ACTIONS.DELETE_GROUPS')}
          </Button>
        </div>
      </div>
    </div>
  );
};
