interface UploadProgressProps {
  uploadPercentage: number;
}

export function UploadProgressBar({ uploadPercentage }: UploadProgressProps) {
  return (
    <div className="upload__file__progress">
      <div
        className="upload__file__progress-bar"
        style={{ width: `${uploadPercentage ?? 0}%` }}
      >
        <div className="upload__file__progress__value">{uploadPercentage}%</div>
      </div>
    </div>
  );
}
