import { useTranslation } from 'react-i18next';
import { useParams, Navigate } from 'react-router-dom';
import { useAuth } from '../../../hooks/auth/useAuth';
import { Role } from '../../../utils/enums/role.enum';
import { CompleteProfileAdmin } from './admin/CompleteProfileAdmin';
import { CompleteProfileEmployee } from './employee/CompleteProfileEmployee';
import { CompleteProfileParentCandidate } from './parent-candidate/CompleteProfileParentCandidate';

export function CompleteProfileLayout() {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();
  const { role } = useParams();

  const pages = [
    {
      index: Role.Admin,
      element: <CompleteProfileAdmin />,
    },
    {
      index: Role.Employee,
      element: <CompleteProfileEmployee />,
    },
    {
      index: Role.Parent,
      element: <CompleteProfileParentCandidate />,
    },
    {
      index: Role.Candidate,
      element: <CompleteProfileParentCandidate />,
    },
  ];

  const renderPage = () => {
    const page = pages.find((page) => page.index.toString() === role);

    return page?.element;
  };

  return isAuthenticated() ? (
    <Navigate to="/dashboard" />
  ) : (
    <div className="admission">
      <h2 className="mb-8">
        {t('ADMISSION.COMPLETE_PROFILE.LABELS.COMPLETE_PROFILE')}
      </h2>
      {renderPage()}
    </div>
  );
}
