import { useMutation } from '@tanstack/react-query';
import type { UseMutationOptions } from '@tanstack/react-query';
import axios from 'axios';
import type { AxiosError } from 'axios';
import { PASSWORD_URL } from '../../utils/constants/urlConstants';

interface SetPasswordCredentials {
  requestId: string;
  newPassword: string;
}

async function setPassword(requestId: string, newPassword: string) {
  const response = await axios.put(PASSWORD_URL, {
    requestId,
    newPassword,
  });

  return response.data;
}

export function useSetPassword(
  options?: UseMutationOptions<undefined, AxiosError, any>
) {
  return useMutation({
    mutationFn: ({ newPassword, requestId }: SetPasswordCredentials) =>
      setPassword(requestId, newPassword),
    ...options,
  });
}
