import { CircleSolid } from '@carbon/icons-react';
import { Button, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useApproveTermReportById } from '../../../hooks/term-reports/admin/useApproveTermReportById';
import { useGetTermReportById } from '../../../hooks/term-reports/useGetTermReportById';
import { useModal } from '../../../hooks/useModal';
import { Input, TextEditor } from '../../../utils/components/hoc-components';
import { DENY_TERM_REPORT } from '../../../utils/constants/term-reports/modals';
import { ApproveEditTermReportSection } from './ApproveEditReportSection';

export const ApproveEditReport = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const notification = useNotifications();
  const queryClient = useQueryClient();
  const { open: openDenyTermReport } = useModal(DENY_TERM_REPORT);

  const { data: report } = useGetTermReportById(id!);
  const approveTermReport = useApproveTermReportById();

  const methods = useForm({
    defaultValues: {
      title: '',
      introContent: '',
      sections: [],
      outroContent: '',
    },
    values: report,
  });

  const onDenyTermReport = () => {
    openDenyTermReport({
      id: id!,
    });
  };

  const onApproveTermReport = () => {
    approveTermReport
      .mutateAsync(id!)
      .then(() => {
        notification.success(t('TERM_REPORTS.MESSAGES.SUCCESSFULLY_APPROVED'));
        queryClient.invalidateQueries({
          queryKey: ['term-report', id],
        });
        queryClient.invalidateQueries({
          queryKey: ['student-term-reports'],
        });
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  return (
    <FormProvider {...methods}>
      <div className="term-reports">
        <Input
          disabled
          name="title"
          label={t('TERM_REPORTS.FORM.TITLE') ?? ''}
          placeholder={t('TERM_REPORTS.FORM.TITLE') ?? ''}
          className="term-reports__form__input-title disabled-to-auto"
        />
        <TextEditor
          disabled
          name="introContent"
          label={t('TERM_REPORTS.FORM.INTRO') ?? ''}
          placeholder={t('TERM_REPORTS.FORM.INTRO') ?? ''}
          className="disabled-to-auto"
        />
        <div className="term-reports__section-legenda">
          <div className="to-be-reviewed">
            <CircleSolid size={24} /> {t('TERM_REPORTS.LEGEND.TO_BE_REVIEWED')}
          </div>
          <div className="approved">
            <CircleSolid size={24} /> {t('TERM_REPORTS.LEGEND.APPROVED')}
          </div>
          <div className="rejected">
            <CircleSolid size={24} /> {t('TERM_REPORTS.LEGEND.REJECTED')}
          </div>
          <div className="new">
            <CircleSolid size={24} /> {t('TERM_REPORTS.LEGEND.NEW')}
          </div>
        </div>
        {report?.sections?.map((section, index) => (
          <ApproveEditTermReportSection key={index} section={section} />
        ))}
        <TextEditor
          disabled
          name="outroContent"
          label={t('TERM_REPORTS.FORM.OUTRO') ?? ''}
          placeholder={t('TERM_REPORTS.FORM.OUTRO') ?? ''}
          className="disabled-to-auto"
        />
        <div className="term-reports__form__actions">
          <Button
            variant="outlined"
            className="term-reports__form__actions__cancel"
            onClick={() => navigate(-1)}
          >
            {t('ACTIONS.CANCEL')}
          </Button>
          <Button onClick={onDenyTermReport}>{t('ACTIONS.DENY')}</Button>
          <Button onClick={onApproveTermReport}>{t('ACTIONS.APPROVE')}</Button>
        </div>
      </div>
    </FormProvider>
  );
};
