import { useQuery } from '@tanstack/react-query';
import type { AvailableStudentsForBusStopDto } from '../../../models/transportation/students/available-students-for-bus-stop.dto';
import apiRequest from '../../../services/apiWrapper';
import { TRANSPORTATION_BUS_STOPS_URL } from '../../../utils/constants/urlConstants';

async function getListOfAvailableStudentsForBusStop(
  busStopId: string,
  busStopType: string
) {
  const response = await apiRequest.get<AvailableStudentsForBusStopDto>(
    `${TRANSPORTATION_BUS_STOPS_URL}/${busStopId}/available-students?busStopType=${busStopType}`
  );

  return response.data;
}

export function useGetListOfAvailableStudentsForBusStop({
  busStopId,
  busStopType,
}: {
  busStopId: string;
  busStopType: string;
}) {
  return useQuery({
    queryKey: ['available-students-bus-stop', busStopId, busStopType],
    queryFn: () => getListOfAvailableStudentsForBusStop(busStopId, busStopType),
  });
}
