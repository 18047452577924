import { useQuery } from '@tanstack/react-query';
import type { PaginatedList } from '../../models/common/paginated-list.model';
import type { StudentTermReportsListDto } from '../../models/term-reports/student-term-reports-list.dto';
import apiRequest from '../../services/apiWrapper';
import { createPaginatedQueryKeys } from '../../services/createPaginatedQueryKeys';

async function getPaginatedStudentTermReportsById(
  studentId: string,
  searchParams: URLSearchParams
) {
  const response = await apiRequest.get<
    PaginatedList<StudentTermReportsListDto>
  >(`/api/students/${studentId}/term-reports/sent?${searchParams.toString()}`);

  return response.data;
}

function createQueryKey(searchParams: URLSearchParams) {
  return {
    studentId: searchParams.get('studentId'),
    ...createPaginatedQueryKeys(searchParams),
  };
}

export function usePaginatedStudentTermReportsById(
  studentId: string,
  searchParams: URLSearchParams
) {
  return useQuery({
    queryKey: ['studentReports', createQueryKey(searchParams)],
    queryFn: () => getPaginatedStudentTermReportsById(studentId, searchParams),
    throwOnError: false,
    enabled: Boolean(studentId),
  });
}
