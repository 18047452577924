import { useQuery } from '@tanstack/react-query';
import type { FoodTaxesDetailsDto } from '../../../../models/taxes/food/food-taxes-details.dto';
import type { FoodTaxesPaginatedList } from '../../../../models/taxes/food/food-taxes-paginated-list.model';
import apiRequest from '../../../../services/apiWrapper';
import { createPaginatedQueryKeys } from '../../../../services/createPaginatedQueryKeys';
import { FOOD_PAYMENTS } from '../../../../utils/constants/urlConstants';

async function fetchFoodTaxes(searchParams: URLSearchParams) {
  const response = await apiRequest.get<
    FoodTaxesPaginatedList<FoodTaxesDetailsDto>
  >(`${FOOD_PAYMENTS}/students?${searchParams.toString()}`);

  return response.data;
}

export function createQueryKey(searchParams: URLSearchParams) {
  return {
    fromDate: searchParams.get('fromDate'),
    toDate: searchParams.get('toDate'),
    month: searchParams.get('month'),
    year: searchParams.get('year'),
    name: searchParams.get('name'),
    isPaid: searchParams.get('isPaid'),
    ...createPaginatedQueryKeys(searchParams),
  };
}

export function usePaginatedFoodTaxes(searchParams: URLSearchParams) {
  return useQuery({
    queryKey: ['food-taxes', createQueryKey(searchParams)],
    queryFn: () => fetchFoodTaxes(searchParams),
    throwOnError: false,
  });
}
