import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import type { ApiRequest } from '../models/auth/api-wrapper';
import { createAPIError } from './createApiError';
import { mapEmptyStringToNull } from './mapEmptyStringToNull';

export const publicApiInstance = axios.create({
  baseURL: import.meta.env.VITE_REACT_APP_API,
});

const publicApiRequest: ApiRequest = {
  get: async (url: string, options?: AxiosRequestConfig) => {
    try {
      return await publicApiInstance.get(url, options);
    } catch (error) {
      throw createAPIError(error);
    }
  },
  post: async <TRequest, TResponse>(url: string, body: TRequest) => {
    body = body instanceof FormData ? body : mapEmptyStringToNull(body);

    try {
      return await publicApiInstance.post<TRequest, AxiosResponse<TResponse>>(
        url,
        body
      );
    } catch (error: any) {
      throw createAPIError(error);
    }
  },
  put: async (url: string, body: any, options?: any) => {
    body = body instanceof FormData ? body : mapEmptyStringToNull(body);

    try {
      return await publicApiInstance.put(url, body, options);
    } catch (error) {
      throw createAPIError(error);
    }
  },
  patch: async (url: string, body?: any) => {
    body = body instanceof FormData ? body : mapEmptyStringToNull(body);

    try {
      return await publicApiInstance.patch(url, body);
    } catch (error) {
      throw createAPIError(error);
    }
  },
  delete: async (url: string) => {
    try {
      return await publicApiInstance.delete(url);
    } catch (error) {
      throw createAPIError(error);
    }
  },
};

export default publicApiRequest;
