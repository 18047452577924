export function createPaginatedQueryKeys(searchParams: URLSearchParams): {
  page: string | null;
  size: string | null;
  ordering: string | null;
  orderingDirection: string | null;
} {
  return {
    page: searchParams.get('page'),
    size: searchParams.get('size'),
    ordering: searchParams.get('ordering'),
    orderingDirection: searchParams.get('orderingDirection'),
  };
}
