import { ArchiveEvaluationPlan } from '../../evaluation-plans/modals/ArchiveEvaluationPlan';
import { AssignedGroupsDrawer } from '../../evaluation-plans/modals/AssignedGroupsDrawer';
import { DenyEvaluationPlanModal } from '../../evaluation-plans/modals/DenyEvaluationPlanModal';
import { DuplicateEvaluationPlan } from '../../evaluation-plans/modals/DuplicateEvaluationPlan';
import { EvaluationCriteriaLegend } from '../../evaluation-plans/modals/EvaluationCriteriaLegend';
import { EvaluationScoresDialog } from '../../evaluation-plans/modals/EvaluationScoresDialog';
import { RegisterEmployeeAbsence } from '../../food-management/admin/modals/RegisterEmployeeAbsence';
import { AddToGroup } from '../../groups/modals/AddToGroup';
import { AddUser } from '../../groups/modals/AddUser';
import { CreateEditGroup } from '../../groups/modals/CreateEditGroup';
import { DeleteGroup } from '../../groups/modals/DeleteGroup';
import { DeleteMultipleGroups } from '../../groups/modals/DeleteMultipleGroups';
import { RemoveGroupMember } from '../../groups/modals/RemoveGroupMember';
import { RegisterAnAbsence } from '../../profile/parent/RegisterAnAbsence';
import { MarkAsPaidDialog } from '../../taxes/MarkAsPaidDialog';
import { BankTransferDetailsModal } from '../../taxes/school/parent/BankTransferDetailsModal';
import { ConfirmPaymentCancellationModal } from '../../taxes/school/parent/history/ConfirmPaymentCancellationModal';
import { PartialPaymentModal } from '../../taxes/school/parent/PartialPaymentModal';
import { SelectPaymentMethodDialog } from '../../taxes/school/parent/SelectPaymentMethodDialog';
import { CommentTermReportSectionModal } from '../../term-reports/admin/CommentTermReportSectionModal';
import { DenyTermReportModal } from '../../term-reports/admin/DenyTermReportModal';
import { ReviewTermReport } from '../../term-reports/modals/ReviewTermReport';
import { TermReportModal } from '../../term-reports/parent/modals/TermReportModal';
import { TransportationRequest } from '../../transportation/parent/modals/TransportationRequest';
import { CreateEditRoute } from '../../transportation/routes/create-edit/CreateEditRoute';
import { BusStopDrawer } from '../../transportation/routes/modals/BusStopDrawer';
import { DeleteRoute } from '../../transportation/routes/modals/DeleteRoute';
import { EditTransportationRequestDrawer } from '../../transportation/users/drawers/EditTransportationRequestDrawer';
import { StudentsRoutesDrawer } from '../../transportation/users/drawers/StudentsRoutesDrawer';
import { DenyStudentTransportationRequestModal } from '../../transportation/users/modals/DenyStudentTransportationRequestModal';
import { ArchiveMultiple } from '../../users/archive/ArchiveMultiple';
import { ToggleArchiveUser } from '../../users/archive/ToggleArchiveUser';
import { CreateEditUser } from '../../users/create-edit/CreateEditUser';

interface ModalFactoryProps {
  modal: string | null;
}

const modals = {
  CREATE_EDIT_USER: <CreateEditUser />,
  ARCHIVE_USER: <ToggleArchiveUser />,
  UNARCHIVE_USER: <ToggleArchiveUser />,
  ARCHIVE_USERS: <ArchiveMultiple />,
  ADD_TO_GROUP: <AddToGroup />,
  REGISTER_AN_ABSENCE: <RegisterAnAbsence />,
  CONFIRM_BANK_PAYMENT: <MarkAsPaidDialog />,
  CREATE_EDIT_GROUP: <CreateEditGroup />,
  DELETE_GROUP: <DeleteGroup />,
  DELETE_MULTIPLE_GROUPS: <DeleteMultipleGroups />,
  MOVE_GROUP_MEMBER: <AddToGroup />,
  REMOVE_GROUP_MEMBER: <RemoveGroupMember />,
  ADD_GROUP_USER: <AddUser />,
  CREATE_EDIT_ROUTE: <CreateEditRoute />,
  DELETE_ROUTE: <DeleteRoute />,
  BUS_STOP_DRAWER: <BusStopDrawer />,
  MANAGE_USER_ROUTES: <StudentsRoutesDrawer />,
  PARTIAL_PAYMENT: <PartialPaymentModal />,
  SELECT_PAYMENT_PERIOD: <SelectPaymentMethodDialog />,
  TRANSFER_DETAILS: <BankTransferDetailsModal />,
  CONFIRM_PAYMENT_CANCELLATION: <ConfirmPaymentCancellationModal />,
  DENY_EVALUATION_PLAN: <DenyEvaluationPlanModal />,
  DUPLICATE_PLAN: <DuplicateEvaluationPlan />,
  ARCHIVE_PLAN: <ArchiveEvaluationPlan />,
  ASSIGNED_GROUPS: <AssignedGroupsDrawer />,
  EVALUATION_SCORES: <EvaluationScoresDialog />,
  EVALUATION_CRITERIA_LEGEND: <EvaluationCriteriaLegend />,
  REVIEW_TERM_REPORTS: <ReviewTermReport />,
  VIEW_TERM_REPORT: <TermReportModal />,
  REGISTER_EMPLOYEE_ABSENCE: <RegisterEmployeeAbsence />,
  TRANSPORTATION_REQUEST: <TransportationRequest />,
  DENY_TERM_REPORT: <DenyTermReportModal />,
  COMMENT_TERM_REPORT_SECTION: <CommentTermReportSectionModal />,
  TRANSPORTATION_REQUEST_DENY: <DenyStudentTransportationRequestModal />,
  TRANSPORTATION_EDIT_REQUEST: <EditTransportationRequestDrawer />,
} as const;

export function ModalFactory({ modal }: ModalFactoryProps) {
  if (!modal) return null;
  const key = convertToUnderscoreSeparated(modal) as keyof typeof modals;

  return modals[key];
}

function convertToUnderscoreSeparated(inputText: string) {
  return inputText.replaceAll('-', '_').toUpperCase();
}
