import { useMutation } from '@tanstack/react-query';
import type { TermReportCreateDto } from '../../models/term-reports/term-report-create.dto';
import apiRequest from '../../services/apiWrapper';
import { TERM_REPORTS_URL } from '../../utils/constants/urlConstants';

interface TermReportBody {
  report: TermReportCreateDto;
  shouldSubmit: boolean;
}

async function createTermReport(data: TermReportBody) {
  return apiRequest.post<TermReportCreateDto, {}>(
    `${TERM_REPORTS_URL}?shouldSubmit=${data.shouldSubmit}`,
    data.report
  );
}

export function useCreateTermReport() {
  return useMutation({
    mutationFn: (data: TermReportBody) => createTermReport(data),
  });
}
