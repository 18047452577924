import { Table, Tag, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../../hooks/auth/useAuth';
import { useApproveEvaluationPlanById } from '../../../hooks/evaluation-plans/useApproveEvaluationPlanById';
import { usePaginatedAllEvaluationPlans } from '../../../hooks/evaluation-plans/usePaginatedAllEvaluationPlans';
import { useModal } from '../../../hooks/useModal';
import { EVALUATION_PLANS_STATUS } from '../../../utils/constants/evaluation-plans/evaluation-plans-status';
import {
  ARCHIVE_PLAN,
  ASSIGNED_GROUPS,
  DENY_EVALUATION_PLAN,
  DUPLICATE_PLAN,
} from '../../../utils/constants/evaluation-plans/modals';
import { EvaluationPlanStatus } from '../../../utils/enums/evaluation-plan-status.enum';
import { Role } from '../../../utils/enums/role.enum';
import { getTagType } from '../../../utils/getTagType';
import { ActionMenuHorizontal } from '../../shared/components/ActionMenuHorizontal';
import { Pagination } from '../../shared/components/Pagination';
import { TableSkeleton } from '../../shared/components/skeletons/TableSkeleton';

export function AllEvaluationPlans() {
  const { t } = useTranslation();
  const { authState } = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const notification = useNotifications();
  const queryClient = useQueryClient();
  const isAdmin = authState?.role === Role.Admin;

  const { open: openDenyEvaluationPlanModal } = useModal(DENY_EVALUATION_PLAN);
  const { open: openArchiveEvaluationPlanModal } = useModal(ARCHIVE_PLAN);
  const { open: openDuplicateEvaluationPlanModal } = useModal(DUPLICATE_PLAN);

  const { data: evaluationPlans, isSuccess } =
    usePaginatedAllEvaluationPlans(searchParams);
  const approveEvaluationPlan = useApproveEvaluationPlanById();

  const { open: openAssignedGroupsDrawer } = useModal(ASSIGNED_GROUPS);

  const headers = [
    t('EVALUATION_PLANS.TABLE.HEADERS.NAME'),
    t('EVALUATION_PLANS.TABLE.HEADERS.CREATOR'),
    t('EVALUATION_PLANS.TABLE.HEADERS.CREATED_ON'),
    t('EVALUATION_PLANS.TABLE.HEADERS.UPDATED_ON'),
    t('EVALUATION_PLANS.TABLE.HEADERS.STATUS'),
    t('EVALUATION_PLANS.TABLE.HEADERS.ASSIGNED_GROUPS'),
    t('EVALUATION_PLANS.TABLE.HEADERS.ACTIONS'),
  ];

  const onReviewPlan = useCallback(
    (id: string) => {
      navigate(id);
    },
    [navigate]
  );

  const onOpenAssignedGroupsDrawer = useCallback(
    (id: string) => {
      openAssignedGroupsDrawer({ id });
    },
    [openAssignedGroupsDrawer]
  );

  const onApproveEvaluationPlan = useCallback(
    (id: string) => {
      approveEvaluationPlan
        .mutateAsync(id)
        .then(() => {
          notification.success(
            t('EVALUATION_PLANS.MESSAGES.SUCCESSFULLY_APPROVED')
          );
          queryClient.invalidateQueries({
            queryKey: ['my-evaluation-plans'],
          });
          queryClient.invalidateQueries({
            queryKey: ['evaluation-plans'],
          });
        })
        .catch((resError) => {
          notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
        });
    },
    [approveEvaluationPlan, notification, queryClient, t]
  );

  const onDenyEvaluationPlan = useCallback(
    (id: string) => {
      openDenyEvaluationPlanModal({
        id,
      });
    },
    [openDenyEvaluationPlanModal]
  );

  const onOpenArchiveEvaluationPlan = useCallback(
    (id: string, assignedGroups: number) => {
      openArchiveEvaluationPlanModal({
        id,
        active: assignedGroups > 0 ? 'true' : 'false',
      });
    },
    [openArchiveEvaluationPlanModal]
  );

  const onOpenDuplicateEvaluationPlan = useCallback(
    (id: string) => {
      openDuplicateEvaluationPlanModal({ id });
    },
    [openDuplicateEvaluationPlanModal]
  );

  const tableChildren = useMemo(() => {
    return evaluationPlans?.result.reduce((acc: any, evaluationPlan) => {
      let userArray = [
        evaluationPlan.name,
        evaluationPlan.creatorName,
        format(new Date(evaluationPlan.createdAt), 'dd.MM.yyyy'),
        format(new Date(evaluationPlan.lastUpdatedAt), 'dd.MM.yyyy'),
        <Tag type={getTagType(evaluationPlan.status)}>
          {t(
            `EVALUATION_PLANS.STATUS.${
              EVALUATION_PLANS_STATUS[evaluationPlan.status]
            }`
          )}
        </Tag>,
        evaluationPlan.assignedGroups.map((group) => group.name).join(', '),
        <ActionMenuHorizontal
          options={[
            {
              label: t('EVALUATION_PLANS.ACTIONS.EDIT_ASSIGNED_GROUPS'),
              hidden: evaluationPlan.status !== EvaluationPlanStatus.Approved,
              onClick: () => onOpenAssignedGroupsDrawer(evaluationPlan.id),
            },
            {
              label: t('EVALUATION_PLANS.ACTIONS.REVIEW'),
              onClick: () => {
                onReviewPlan(`../${evaluationPlan.id}`);
              },
            },
            {
              label: t('EVALUATION_PLANS.ACTIONS.APPROVE'),
              onClick: () => {
                onApproveEvaluationPlan(evaluationPlan.id);
              },
              hidden:
                !isAdmin ||
                evaluationPlan.status !== EvaluationPlanStatus.PendingApproval,
            },
            {
              label: t('EVALUATION_PLANS.ACTIONS.DENY'),
              onClick: () => {
                onDenyEvaluationPlan(evaluationPlan.id);
              },
              hidden:
                !isAdmin ||
                evaluationPlan.status !== EvaluationPlanStatus.PendingApproval,
            },
            {
              label: t('EVALUATION_PLANS.ACTIONS.ARCHIVE'),
              onClick: () => {
                onOpenArchiveEvaluationPlan(
                  evaluationPlan.id,
                  evaluationPlan.assignedGroups.length
                );
              },
              hidden: !isAdmin,
            },
            {
              label: t('EVALUATION_PLANS.ACTIONS.DUPLICATE'),
              onClick: () => {
                onOpenDuplicateEvaluationPlan(evaluationPlan.id);
              },
              hidden: !isAdmin,
            },
          ]}
        ></ActionMenuHorizontal>,
      ];

      return [...acc, userArray];
    }, []);
  }, [
    evaluationPlans?.result,
    isAdmin,
    onApproveEvaluationPlan,
    onDenyEvaluationPlan,
    onOpenArchiveEvaluationPlan,
    onOpenDuplicateEvaluationPlan,
    onReviewPlan,
    t,
    onOpenAssignedGroupsDrawer,
  ]);

  return (
    <div className="evaluation-plans">
      <h2 className="evaluation-plans__title">
        {t('EVALUATION_PLANS.TITLES.ALL_EVALUATION_PLANS')}
      </h2>
      {isSuccess && evaluationPlans ? (
        <>
          <Table headers={headers} rows={tableChildren} />
          <div className="users__pagination">
            <Pagination data={evaluationPlans} />
          </div>
        </>
      ) : (
        <TableSkeleton headers={headers} />
      )}
    </div>
  );
}
