import { Button, Select } from '@ph-react-ui/core';
import { useTranslation } from 'react-i18next';
import { DocumentType } from '../../../../../utils/enums/document-type.enum';
import { Dialog } from '../../../../shared/components/Dialog';

interface DialogFileTypeProps {
  title: string;
  fileType: DocumentType | null;
  closeModal: () => void;
  fileUpload: () => void;
  setFileType: (val: DocumentType | null) => void;
}

export const DialogFileType = ({
  title,
  fileType,
  closeModal,
  fileUpload,
  setFileType,
}: DialogFileTypeProps) => {
  const { t } = useTranslation();

  const options: {
    label: string;
    value: DocumentType;
  }[] = [
    {
      label: t('USERS.DRAWER.DOCUMENTS.TYPE.DIPLOMA'),
      value: DocumentType.Diploma,
    },
    {
      label: t('USERS.DRAWER.DOCUMENTS.TYPE.MEDICAL_CONDITION'),
      value: DocumentType.MedicalCondition,
    },
    {
      label: t('USERS.DRAWER.DOCUMENTS.TYPE.BIRTH_CERTIFICATE'),
      value: DocumentType.BirthCertificate,
    },
    {
      label: t('USERS.DRAWER.DOCUMENTS.TYPE.RELOCATION'),
      value: DocumentType.Relocation,
    },
    {
      label: t('USERS.DRAWER.DOCUMENTS.TYPE.OTHER'),
      value: DocumentType.Any,
    },
  ];

  return (
    <Dialog
      onClose={closeModal}
      dialogHeader={t('UPLOAD_FILES.TITLES.UPLOAD_DOCUMENT')}
      actionButton={
        <Button onClick={fileUpload} disabled={fileType === null}>
          {t('UPLOAD_FILES.ACTIONS.UPLOAD')}
        </Button>
      }
    >
      <h2>{title}</h2>
      <Select
        placeholder={t('USERS.DRAWER.PLACEHOLDERS.SELECT_DOCUMENT_TYPE') ?? ''}
        multi={false}
        options={options}
        onChange={(val) => setFileType(val)}
      />
    </Dialog>
  );
};
