import { useMutation } from '@tanstack/react-query';
import apiRequest from '../../../services/apiWrapper';
import { STUDENT_ABSENCE_URL } from '../../../utils/constants/urlConstants';

async function fetchExportStudentAbsencesReport(searchParams: URLSearchParams) {
  return await apiRequest.get(
    `${STUDENT_ABSENCE_URL}/export?${searchParams.toString()}`,
    { responseType: 'blob' }
  );
}

export function useExportStudentAbsencesReport(searchParams: URLSearchParams) {
  return useMutation({
    mutationFn: () => fetchExportStudentAbsencesReport(searchParams),
  });
}
