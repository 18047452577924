import { useMutation } from '@tanstack/react-query';
import type { StudentRequestUpdateDto } from '../../../models/transportation/students/student-request-update.dto';
import apiRequest from '../../../services/apiWrapper';
import { TRANSPORTATION_BUS_STOPS_URL } from '../../../utils/constants/urlConstants';

async function updateTransportationRequestForStudent(
  id: string | null,
  data: StudentRequestUpdateDto
) {
  const response = await apiRequest.put(
    `${TRANSPORTATION_BUS_STOPS_URL}/requests/${id}`,
    data
  );

  return response.data;
}

export function useUpdateTransportationRequestForStudent(id: string | null) {
  return useMutation({
    mutationFn: (data: StudentRequestUpdateDto) =>
      updateTransportationRequestForStudent(id, data),
  });
}
