import { useQuery } from '@tanstack/react-query';
import type { StudentDetailsWithIdDto } from '../../../models/users/students/student-details.dto';
import type { StudentUpdateFormWithIdDto } from '../../../models/users/students/student-update-form.dto';
import apiRequest from '../../../services/apiWrapper';
import { mapStringToSimpleDate } from '../../../services/mapStringToSimpleDate';

interface GetStudentsForParentProps {
  parentId: string | null;
  enabled?: boolean;
}

async function getStudentsForParent(familyId: string | null) {
  const response = await apiRequest.get<{
    students: StudentDetailsWithIdDto[];
  }>(`/api/users/families/${familyId}/students`);

  return response.data;
}

function mapStudentsToStudentUpdateFormDto(data: {
  students: StudentDetailsWithIdDto[];
}): StudentUpdateFormWithIdDto[] {
  return data.students.map((student) => ({
    ...student,
    birthDate: mapStringToSimpleDate(student.birthDate),
  }));
}

export function useGetStudentsForFamily({
  parentId,
  enabled = true,
}: GetStudentsForParentProps) {
  return useQuery({
    queryKey: ['parent-students'],
    queryFn: () => getStudentsForParent(parentId),
    select: (data) => mapStudentsToStudentUpdateFormDto(data),
    enabled,
  });
}
