import { useMutation } from '@tanstack/react-query';
import type { ParentsUpdateDto } from '../../../models/users/parents/parent-update.dto';
import apiRequest from '../../../services/apiWrapper';
import { PARENTS_URL } from '../../../utils/constants/urlConstants';

async function updateAuthenticatedParentProfile(parent: ParentsUpdateDto) {
  return apiRequest.put(`${PARENTS_URL}/profile`, parent);
}

export function useUpdateAuthenticatedParentProfile() {
  return useMutation({
    mutationFn: (parent: ParentsUpdateDto) =>
      updateAuthenticatedParentProfile(parent),
  });
}
