import { useQuery } from '@tanstack/react-query';
import type { BankTransferDetailsDto } from '../../../models/taxes/school/bank-transfer-details.dto';
import apiRequest from '../../../services/apiWrapper';
import { SCHOOL_TAX_PAYMENTS } from '../../../utils/constants/urlConstants';

async function fetchBankTransferDetails(id: string) {
  const response = await apiRequest.get<BankTransferDetailsDto>(
    `${SCHOOL_TAX_PAYMENTS}/${id}/bank-details`
  );

  return response.data;
}

export function useGetBankTransferDetails(id: string) {
  return useQuery({
    queryKey: ['bank-transfer-details'],
    queryFn: () => fetchBankTransferDetails(id),
    throwOnError: false,
  });
}
