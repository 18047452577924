import { useMutation } from '@tanstack/react-query';
import type { UseMutationOptions } from '@tanstack/react-query';
import type { ResponseError } from '../../models/auth/api-wrapper';
import type { LoginDto, UserCredentialsDto } from '../../models/auth/login.dto';
import publicApiRequest from '../../services/publicApiWrapper';

async function signIn(email: string, password: string): Promise<LoginDto> {
  const response = await publicApiRequest.post<UserCredentialsDto, LoginDto>(
    '/api/auth/sign-in',
    {
      email,
      password,
    }
  );

  return response.data;
}

export function useSignIn(
  options?: UseMutationOptions<LoginDto, ResponseError, any>
) {
  return useMutation({
    mutationFn: ({ email, password }: UserCredentialsDto) =>
      signIn(email as string, password as string),
    ...options,
  });
}
