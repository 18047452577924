import { Add, TrashCan } from '@carbon/icons-react';
import { Button, useNotifications, type SimpleDate } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { useRef } from 'react';
import { createPortal } from 'react-dom';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCancellationDialog } from '../../../../../hooks/useCancellationDialog';
import { useModal } from '../../../../../hooks/useModal';
import { useCreateParent } from '../../../../../hooks/users/parents/useCreateParent';
import type { CreateUserForm } from '../../../../../models/users/create-user-form';
import type { ParentCreateFormDto } from '../../../../../models/users/parents/parent-create-form.dto';
import type { ParentCreateDto } from '../../../../../models/users/parents/parent-create.dto';
import { Scholarship } from '../../../../../models/users/students/scholarship-type';
import { mapSimpleDateToString } from '../../../../../services/mapSimpleDateToString';
import {
  Textarea,
  Checkbox,
  Input,
  Select,
} from '../../../../../utils/components/hoc-components';
import { CREATE_EDIT_USER } from '../../../../../utils/constants/users/modals';
import { ParentSectionType } from '../../../../../utils/enums/parent-type.enum';
import {
  getCommonValidators,
  VALIDATION as V,
} from '../../../../../utils/inputValidators';
import { QuestionsAnswers } from '../../../../admission/application/Q&A';
import { FileUpload } from '../../../../shared/components/FileUpload';
import { FilesTable } from '../common/FilesTable';
import { ParentInvoicingSection } from '../common/ParentInvoicingSection';
import { ParentSection } from '../common/ParentSection';
import { StudentSection } from '../common/StudentSection';

export function CreateParentForm({ footerElement }: CreateUserForm) {
  const studentsRef = useRef<HTMLDivElement>(null);
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { open: openCancellationDialog } = useCancellationDialog();
  const notification = useNotifications();

  const scholarshipOptions = Object.entries(Scholarship).map(
    ([key, value]) => ({
      label: t(`USERS.DRAWER.LABELS.${key}`),
      value,
    })
  );

  const methods = useForm<ParentCreateFormDto>({
    mode: 'onTouched',
    defaultValues: {
      parents: [
        {
          name: '',
          phoneNumber: '',
          workAddress: '',
          email: '',
          address: '',
          isPrimaryParent: false,
          parentType: 0,
        },
        {
          name: '',
          phoneNumber: '',
          workAddress: '',
          email: '',
          address: '',
          isPrimaryParent: false,
          parentType: 1,
        },
      ],
      students: [
        {
          additionalInformation: Array.from({ length: 6 }, (_, i) => i + 1).map(
            (num) => ({
              question: t(`ADMISSION.APPLICATION.QUESTIONS.Q${num}`) as string,
              answer: '',
            })
          ),
        },
      ],
      familyInformation: {
        invoiceInformation: {
          shouldReceiveInvoice: false,
          invoiceName: '',
          invoiceCity: '',
          invoiceStreet: '',
          invoiceZip: '',
          invoiceVatNo: '',
          invoiceIdNoBulstat: '',
        },
        files: [],
        notes: '',
      },
    },
  });

  const { isDirty, isSubmitted } = methods.formState;

  const createParent = useCreateParent();
  const { close: closeModal } = useModal(CREATE_EDIT_USER);

  const { append, remove } = useFieldArray({
    control: methods?.control,
    name: 'students',
  });

  const { append: appendFileToTable, remove: removeFileFromTable } =
    useFieldArray({
      control: methods.control,
      name: 'familyInformation.files',
      keyName: 'familyInformation.files',
    });

  const files = methods.watch('familyInformation.files');
  const studentsWatch = methods.watch('students');
  const receiveInvoice = methods.watch(
    'familyInformation.invoiceInformation.shouldReceiveInvoice'
  );

  const parent1AsMainWatch = methods.watch('parents.0.isPrimaryParent');
  const parent2AsMainWatch = methods.watch('parents.1.isPrimaryParent');

  const createNewParent = async (
    data: ParentCreateFormDto,
    shouldInvite: boolean
  ) => {
    if (studentsWatch.length === 0) {
      studentsRef.current?.focus();
      return;
    }
    const { familyInformation, ...rest } = data;
    const formData: ParentCreateDto = {
      ...rest,
      students: [
        ...data.students.map((student) => ({
          ...student,
          birthDate: mapSimpleDateToString(student.birthDate),
          fileIds: [],
          notes: '',
        })),
      ],
      familyInformation: {
        notes: familyInformation.notes,
        iban: familyInformation.iban,
        invoiceInformation: familyInformation.invoiceInformation,
        fileIds: familyInformation.files.map((file) => file.id),
      },
    };
    await createParent
      .mutateAsync({
        data: formData,
        shouldInvite,
      })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ['users'] });
        notification.success(t('USERS.DRAWER.MESSAGES.PARENT_CREATED'));
        closeModal();
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  const onSubmit = async (
    data: ParentCreateFormDto,
    shouldInvite?: boolean
  ) => {
    createNewParent(data, shouldInvite as boolean);
  };

  const onCloseDrawer = () => {
    if (isDirty) {
      openCancellationDialog();
    } else {
      closeModal();
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        id="parent-form"
        className="drawer__users__form"
        onSubmit={methods.handleSubmit((data) => onSubmit(data, false))}
      >
        <div className="drawer__users__form__students" ref={studentsRef}>
          <div className="">
            <h3 className="my-8 drawer__users__form__students__title">
              {methods.watch('students').length
                ? t('USERS.DRAWER.TITLES.NEW_STUDENT')
                : t('USERS.DRAWER.TITLES.OR_ADD_NEW_STUDENT')}
            </h3>
            {studentsWatch.map((item, index) => {
              return (
                <div className="drawer__users__form__students__new" key={index}>
                  <div className=" my-4 drawer__users__form__students__subtitle">
                    {index + 1}. student
                  </div>
                  <Button
                    className="drawer__users__form__students__new__trash"
                    variant="link"
                    color="danger"
                    onClick={() => remove(index)}
                  >
                    <TrashCan />
                  </Button>
                  <div
                    key={item.id}
                    className="drawer__users__form__students__item"
                  >
                    <StudentSection formGroupName={`students.${index}.`} />
                    <QuestionsAnswers prefix={`students.${index}.`} />
                    <Select
                      placeholder={
                        t('USERS.DRAWER.PLACEHOLDERS.SCHOLARSHIP_TYPE') ?? ''
                      }
                      label={t('USERS.DRAWER.LABELS.SCHOLARSHIP_TYPE') ?? ''}
                      name={`students.${index}.scholarshipType`}
                      options={scholarshipOptions}
                    />
                    <div className="my-6 drawer__users__form__employee__permissions">
                      <Checkbox
                        name={`students.${index}.isSecondChild`}
                        className="drawer__users__form__employee__permissions__item"
                      >
                        {t('USERS.DRAWER.LABELS.SECOND_CHILD')}
                      </Checkbox>
                      <Checkbox
                        name={`students.${index}.isStudyingInCurrentYear`}
                        className="drawer__users__form__employee__permissions__item"
                      >
                        {t('USERS.DRAWER.LABELS.IS_CURRENTLY_STUDYING')}
                      </Checkbox>
                      <Checkbox
                        name={`students.${index}.willStudyNextYear`}
                        className="drawer__users__form__employee__permissions__item"
                      >
                        {t('USERS.DRAWER.LABELS.WILL_STUDY_NEXT_YEAR')}
                      </Checkbox>
                    </div>
                  </div>
                </div>
              );
            })}

            <Button
              typeof="button"
              className="mb-4"
              variant="outlined"
              compact
              onClick={() =>
                append({
                  id: '',
                  name: '',
                  address: '',
                  birthDate: '' as unknown as SimpleDate,
                  medicalCondition: '',
                  allergies: '',
                  siblings: '',
                  egn: '',
                  gpName: '',
                  gpPhoneNumber: '',
                  citizenship: '',
                  schoolYear: '',
                  birthplace: '',
                  scholarshipType: Scholarship.NO_SCHOLARSHIP,
                  isSecondChild: false,
                  isStudyingInCurrentYear: false,
                  willStudyNextYear: false,
                  files: [],
                  notes: '',
                  additionalInformation: Array.from(
                    { length: 5 },
                    (_, i) => i + 1
                  ).map((num) => ({
                    question: t(
                      `ADMISSION.APPLICATION.QUESTIONS.Q${num}`
                    ) as string,
                    answer: '',
                  })),
                })
              }
            >
              <Add /> {t('USERS.DRAWER.ACTIONS.ADD_A_STUDENT')}
            </Button>
          </div>
          <span
            className={`drawer__users__form__students__error ${
              studentsWatch.length === 0 && isSubmitted
                ? 'drawer__users__form__students__error-visible'
                : ''
            }`}
          >
            {t('ERROR.VALIDATION.INVALID_EXISTING_OR_NEW_STUDENTS')}
          </span>
        </div>
        <h3 className="my-3 drawer__users__form__students__title">
          {t('USERS.DRAWER.TITLES.PARENTS_INFORMATIONS')}
        </h3>
        <h4 className="mt-3 drawer__users__form__students__subtitle">
          {t('USERS.DRAWER.TITLES.MOTHER_INFO')}
        </h4>
        <ParentSection
          prefix={'parents[0].'}
          mainParent={parent1AsMainWatch}
          setMainParent={() => {
            methods.setValue('parents.0.isPrimaryParent', !parent1AsMainWatch);
            if (parent2AsMainWatch && !parent1AsMainWatch) {
              methods.setValue('parents.1.isPrimaryParent', false);
            }
          }}
          type={ParentSectionType.MOTHER}
        />
        <hr />
        <h4 className="drawer__users__form__students__subtitle">
          {t('USERS.DRAWER.TITLES.FATHER_INFO')}
        </h4>
        <ParentSection
          prefix={'parents[1].'}
          mainParent={parent2AsMainWatch}
          setMainParent={() => {
            methods.setValue('parents.1.isPrimaryParent', !parent2AsMainWatch);
            if (parent1AsMainWatch && !parent2AsMainWatch) {
              methods.setValue('parents.0.isPrimaryParent', false);
            }
          }}
          type={ParentSectionType.FATHER}
        />
        <div className="my-3">
          <ParentInvoicingSection
            shouldReceiveInvoiceWatch={receiveInvoice}
            setReceiveInvoice={() =>
              methods.setValue(
                'familyInformation.invoiceInformation.shouldReceiveInvoice',
                !receiveInvoice
              )
            }
            prefix="familyInformation."
          />
        </div>
        <div className="my-3">
          <Input
            name="familyInformation.iban"
            rules={getCommonValidators([V.IBAN_PATTERN])}
            className="drawer__users__form__input"
            placeholder={t('USERS.DRAWER.PLACEHOLDERS.IBAN') ?? ''}
            label={t('USERS.DRAWER.LABELS.IBAN') ?? ''}
          />
        </div>
        <div>
          <h3 className="my-8 drawer__users__form__students__title">
            {t('USERS.DRAWER.TITLES.DOCUMENTS')}
          </h3>
          <FilesTable data={files} removeFile={removeFileFromTable} />
          <FileUpload appendFile={appendFileToTable} />
          <Textarea
            rules={getCommonValidators([V.MAX_LENGTH])}
            name="familyInformation.notes"
            rows={8}
            label={t('USERS.DRAWER.LABELS.DOCUMENT_NOTES') ?? ''}
          />
        </div>
      </form>
      {footerElement &&
        createPortal(
          <>
            <Button
              onClick={onCloseDrawer}
              className="drawer__users__form__actions__cancel"
              variant="outlined"
            >
              {t('ACTIONS.CANCEL')}
            </Button>
            <Button
              form="parent-form"
              onClick={methods.handleSubmit((data) => onSubmit(data, false))}
            >
              {t('ACTIONS.SAVE')}
            </Button>
            <Button
              onClick={methods.handleSubmit((data) => onSubmit(data, true))}
              disabled={!(parent1AsMainWatch || parent2AsMainWatch)}
            >
              {t('USERS.DRAWER.ACTIONS.SAVE_AND_INVITE')}
            </Button>
          </>,
          footerElement
        )}
    </FormProvider>
  );
}
