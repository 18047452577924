import { useTranslation } from 'react-i18next';
import { Input } from '../../../utils/components/hoc-components';

interface QuestionsAnswersProps {
  readOnly?: boolean;
  prefix?: string;
}

export const QuestionsAnswers = ({
  readOnly = false,
  prefix = '',
}: QuestionsAnswersProps) => {
  const { t } = useTranslation();

  const numberOfQuestions = 6;
  const arrayQA = Array.from({ length: numberOfQuestions }, (_, i) => i + 1);

  return (
    <>
      {arrayQA.map((num) => (
        <div className="mb-6" key={`question-${num}`}>
          <Input
            disabled={readOnly}
            name={`${prefix}additionalInformation[${num - 1}].answer`}
            label={t(`ADMISSION.APPLICATION.QUESTIONS.Q${num}`) ?? ''}
            placeholder={t(`ADMISSION.APPLICATION.QUESTIONS.Q${num}`) ?? ''}
            rules={{
              maxLength: {
                value: 2000,
                message: t('ERROR.VALIDATION.MAX_LENGTH_ANSWERS'),
              },
            }}
          />
        </div>
      ))}
    </>
  );
};
