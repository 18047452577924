import { useMutation } from '@tanstack/react-query';
import type { TransportationRequestDto } from '../../../models/transportation/parent/transportation-request.dto';
import apiRequest from '../../../services/apiWrapper';
import { TRANSPORTATION_BUS_STOPS_URL } from '../../../utils/constants/urlConstants';

async function createParentRequest(routeData: TransportationRequestDto) {
  return apiRequest.post<TransportationRequestDto, void>(
    `${TRANSPORTATION_BUS_STOPS_URL}/requests`,
    routeData
  );
}

export function useCreateParentRequest() {
  return useMutation({
    mutationFn: (routeData: TransportationRequestDto) =>
      createParentRequest(routeData),
  });
}
