import { useMutation } from '@tanstack/react-query';
import type { AttachStudentsToParentDto } from '../../../models/users/students/attach-students-to-parent.dto';
import apiRequest from '../../../services/apiWrapper';
import { FAMILY_URL } from '../../../utils/constants/urlConstants';

export function attachStudentsToParent(
  id: string | null,
  data: AttachStudentsToParentDto
) {
  return apiRequest.post(`${FAMILY_URL}/${id}/students`, data);
}

// family id
export function useAttachStudentsToFamily(id: string | null) {
  return useMutation({
    mutationFn: (data: AttachStudentsToParentDto) =>
      attachStudentsToParent(id, data),
  });
}
