import { useQuery } from '@tanstack/react-query';
import type { PaginatedList } from '../../../models/common/paginated-list.model';
import type { StudentTransportationUsageDto } from '../../../models/transportation/students/student-transportation-usage.dto';
import apiRequest from '../../../services/apiWrapper';
import { createPaginatedQueryKeys } from '../../../services/createPaginatedQueryKeys';
import { TRANSPORTATION_URL } from '../../../utils/constants/urlConstants';

export async function fetchTransportationUsage(searchParams: URLSearchParams) {
  const response = await apiRequest.get<
    PaginatedList<StudentTransportationUsageDto>
  >(`${TRANSPORTATION_URL}/usage-breakdown?${searchParams.toString()}`);

  return response.data;
}

export function createQueryKey(searchParams: URLSearchParams) {
  return {
    name: searchParams.get('name'),
    ...createPaginatedQueryKeys(searchParams),
  };
}

export function usePaginatedStudentsTransportationUsage(
  searchParams: URLSearchParams
) {
  return useQuery({
    queryKey: ['transportation-usage', createQueryKey(searchParams)],
    queryFn: () => fetchTransportationUsage(searchParams),
    throwOnError: false,
  });
}
