import { Button, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useDenyTransportationRequestById } from '../../../../hooks/transportation/students/useDenyStudentTransportationRequest';
import { useModal } from '../../../../hooks/useModal';
import type { TransportationRequestDenyFormDto } from '../../../../models/transportation/students/student-transportation-request-deny.dto';
import { Textarea } from '../../../../utils/components/hoc-components';
import { TRANSPORTATION_REQUEST_DENY } from '../../../../utils/constants/transportation/modals';
import {
  getCommonValidators,
  VALIDATION as V,
} from '../../../../utils/inputValidators';
import { Dialog } from '../../../shared/components/Dialog';

export const DenyStudentTransportationRequestModal = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const { t } = useTranslation();
  const notification = useNotifications();
  const queryClient = useQueryClient();
  const { close: closeModal } = useModal(TRANSPORTATION_REQUEST_DENY);

  const denyTransportationRequest = useDenyTransportationRequestById();

  const methods = useForm({
    defaultValues: {
      reason: '',
    },
  });

  const onSubmit = (data: TransportationRequestDenyFormDto) => {
    denyTransportationRequest
      .mutateAsync({
        id: id!,
        reason: data.reason,
      })
      .then(() => {
        notification.success(
          t('TRANSPORTATION_MANAGEMENT.MESSAGES.SUCCESSFULLY_DENIED')
        );
        queryClient.invalidateQueries({
          queryKey: ['transportation-requests'],
        });
        closeModal();
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  return (
    <Dialog
      dialogHeader={t('TRANSPORTATION_MANAGEMENT.LABELS.DENY_REQUEST')}
      onClose={() => {
        closeModal();
      }}
      actionButton={
        <Button onClick={methods.handleSubmit((data) => onSubmit(data))}>
          {t('ACTIONS.CONTINUE')}
        </Button>
      }
    >
      <FormProvider {...methods}>
        <form>
          <div className="dialog__confirm__content">
            <Textarea
              name="reason"
              className="dialog__confirm__content__textarea"
              label={t('TRANSPORTATION_MANAGEMENT.LABELS.DENY_NOTE') ?? ''}
              placeholder={
                t('TRANSPORTATION_MANAGEMENT.LABELS.DENY_NOTE') ?? ''
              }
              rules={getCommonValidators([V.REQUIRED, V.MAX_LENGTH_2000])}
            />
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};
