import { Folder } from '@carbon/icons-react';
import { Button, Checkbox, Table, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { type Dispatch, type SetStateAction, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useAssignEvaluationPlanToGroups } from '../../../hooks/evaluation-plans/useAssignEvaluationPlanToGroups';
import { useGetAvailableGroupsForEvaluatinoPlanById } from '../../../hooks/evaluation-plans/useGetAvailableGroupsForEvaluationPlanById';
import { useSelectAll } from '../../../hooks/useSelectAll';
import { Dialog } from '../../shared/components/Dialog';
import { TableSkeleton } from '../../shared/components/skeletons/TableSkeleton';

interface AssignEvaluationPlanToGroupsDialogProps {
  setIsAddGroupsModalOpen: Dispatch<SetStateAction<boolean>>;
}

export function AssignEvaluationPlanToGroupsDialog({
  setIsAddGroupsModalOpen,
}: AssignEvaluationPlanToGroupsDialogProps) {
  const { t } = useTranslation();
  const notification = useNotifications();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  const assignEvaluationPlanToGroups = useAssignEvaluationPlanToGroups(id);

  const { data, isSuccess } = useGetAvailableGroupsForEvaluatinoPlanById(id);

  const { handleSubmit, getSelectedItems, control } = useSelectAll(data);

  const tableChildren = useMemo(() => {
    return data?.reduce((acc: any, group, index) => {
      let groupArray = [
        <div className="groups__table__checkbox">
          <Controller
            name={`items.${index}.value`}
            control={control}
            render={({ field }) => (
              <Checkbox className="groups__table-first" {...field} />
            )}
          />
          <Folder size={20} />
          {group.name}
        </div>,
      ];
      return [...acc, groupArray];
    }, []);
  }, [control, data]);

  const onSubmit = () => {
    const selectedItemsIds = getSelectedItems();
    assignEvaluationPlanToGroups
      .mutateAsync({ groupIds: selectedItemsIds })
      .then(() => {
        notification.success(
          t('EVALUATION_PLANS.MESSAGES.ASSIGN_TO_GROUP_SUCCESS')
        );
        queryClient.invalidateQueries({ queryKey: ['my-evaluation-plans'] });
        queryClient.invalidateQueries({ queryKey: ['evaluation-plans'] });
        queryClient.invalidateQueries({
          queryKey: ['avaliable-groups-for-evaluation-plan', id],
        });
        setIsAddGroupsModalOpen(false);
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  return (
    <Dialog
      dialogHeader={t('EVALUATION_PLANS.TITLES.ASSIGN_TO_GROUP')}
      onClose={() => setIsAddGroupsModalOpen(false)}
      actionButton={
        <Button
          type="submit"
          onClick={handleSubmit(onSubmit)}
          disabled={getSelectedItems()?.length === 0}
        >
          {t('ACTIONS.ASSIGN')}
        </Button>
      }
    >
      <div className="dialog__confirm__content__scroll">
        {isSuccess && data ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Table
              headers={[t('GROUPS.TABLE.HEADERS.NAME'), '']}
              rows={tableChildren}
            />
          </form>
        ) : (
          <TableSkeleton headers={[t('GROUPS.TABLE.HEADERS.NAME')]} />
        )}
      </div>
    </Dialog>
  );
}
