import { useQuery } from '@tanstack/react-query';
import type { EmployeeProfileDetailsDto } from '../../../models/users/employees/employee-profile-details.dto';
import apiRequest from '../../../services/apiWrapper';
import { mapStringToSimpleDate } from '../../../services/mapStringToSimpleDate';
import { EMPLOYEES_URL } from '../../../utils/constants/urlConstants';

async function getAuthenticatedEmployeeProfile() {
  const response = await apiRequest.get<EmployeeProfileDetailsDto>(
    `${EMPLOYEES_URL}/profile`
  );

  return response.data;
}

export function useGetAuthenticatedEmployeeProfile() {
  return useQuery({
    queryKey: ['authenticated-employee'],
    queryFn: () => getAuthenticatedEmployeeProfile(),
    select: (data) => ({
      ...data,
      birthDate: mapStringToSimpleDate(data.birthDate),
      diplomaIssueDate: mapStringToSimpleDate(data.diplomaIssueDate),
    }),
  });
}
