import { useMutation } from '@tanstack/react-query';
import apiRequest from '../../services/apiWrapper';
import { EVALUATION_PLANS_URL } from '../../utils/constants/urlConstants';

async function duplicateEvaluationPlan(id: string) {
  return apiRequest.post(`${EVALUATION_PLANS_URL}/${id}/duplicate`, {});
}

export function useDuplicateEvaluationPlanById() {
  return useMutation({
    mutationFn: (id: string) => duplicateEvaluationPlan(id),
  });
}
