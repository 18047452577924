import { useMutation } from '@tanstack/react-query';
import type { StudentMealMenuUpdateResponseDto } from '../../../models/meal-menus/student/student-meal-menu-update-response.dto';
import apiRequest from '../../../services/apiWrapper';
import { MEAL_MENUS_URL } from '../../../utils/constants/urlConstants';
import type { MealType } from '../../../utils/enums/meal-type.enum';

interface MealItem {
  mealMenuId: string;
  type: MealType;
  index: number;
}

interface UpdateStudentMealMenuArgs {
  studentId: string;
  mealItem: MealItem;
}

async function setStudentWeeklyMealMenu({
  studentId,
  mealItem,
}: UpdateStudentMealMenuArgs) {
  const response = await apiRequest.put<
    MealItem,
    StudentMealMenuUpdateResponseDto
  >(`${MEAL_MENUS_URL}/students/${studentId}`, mealItem);

  return response.data;
}

export function useSetStudentWeeklyMealMenu() {
  return useMutation({
    mutationFn: (data: UpdateStudentMealMenuArgs) =>
      setStudentWeeklyMealMenu(data),
  });
}
