import { useQuery } from '@tanstack/react-query';
import type { ParentsUpdateFormDto } from '../../../models/users/parents/parent-update.dto';
import publicApiRequest from '../../../services/publicApiWrapper';
import { PUBLIC_CANDIDATE_URL } from '../../../utils/constants/urlConstants';

async function getCandidateData(invitationId: string | null) {
  const response = await publicApiRequest.get<ParentsUpdateFormDto>(
    `${PUBLIC_CANDIDATE_URL}?invitationId=${invitationId}`
  );

  return response.data;
}

export function useGetPublicCandidateById(
  invitationId: string | null,
  enabled: boolean = true
) {
  return useQuery({
    queryKey: ['public-candidate', invitationId],
    queryFn: () => getCandidateData(invitationId),
    select: (data) => ({
      ...data,
      parents: data.parents.map((parent) => ({
        ...parent,
        userProfileId: parent.id,
      })),
    }),
    enabled,
  });
}
