import { Button, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGetAuthenticatedEmployeeProfile } from '../../../hooks/profile/employee/useGetAuthenticatedEmployeeProfile';
import { useUpdateAuthenticatedEmployeeProfile } from '../../../hooks/profile/employee/useUpdateAuthenticatedEmlpyeeProfile';
import type { EmployeeProfileFormDetailsDto } from '../../../models/users/employees/employee-profile-details.dto';
import type { EmployeeUpdateProfileDto } from '../../../models/users/employees/employee-update-profile.dto';
import { mapSimpleDateToString } from '../../../services/mapSimpleDateToString';
import { Input } from '../../../utils/components/hoc-components';
import {
  getCommonValidators,
  VALIDATION as V,
} from '../../../utils/inputValidators';
import { FileUpload } from '../../shared/components/FileUpload';
import { FormSkeleton } from '../../shared/components/skeletons/FormSkeleton';
import { BasicInfoSection } from '../../users/create-edit/forms/common/BasicInfoSection';
import { FilesTable } from '../../users/create-edit/forms/common/FilesTable';
import { ActiveEmployeeSection } from '../../users/create-edit/forms/employee/ActiveEmployeeSection';
import { PedagogicalSection } from '../../users/create-edit/forms/employee/PedagogicalSection';

export function EmployeeProfile() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const notification = useNotifications();
  const { data: employee, isLoading } = useGetAuthenticatedEmployeeProfile();
  const updateAuthenticatedEmployee = useUpdateAuthenticatedEmployeeProfile();
  const methods = useForm({ values: employee });
  const isPedagogicalEmployee = employee?.canCreateStudentPlans;

  const { append: appendFileToTable, remove: removeFileFromTable } =
    useFieldArray({
      control: methods.control,
      name: 'files',
      keyName: 'files',
    });

  const files = methods.watch('files');

  const onUpdateEmployee = async (data: EmployeeProfileFormDetailsDto) => {
    const { files, birthDate, diplomaIssueDate, ...rest } = data;
    const formData: EmployeeUpdateProfileDto = {
      ...rest,
      birthDate: mapSimpleDateToString(birthDate),
      diplomaIssueDate: mapSimpleDateToString(diplomaIssueDate),
      fileIds: (files ?? []).map((file) => file.id),
    };

    await updateAuthenticatedEmployee
      .mutateAsync(formData)
      .then(() =>
        notification.success(t('USERS.DRAWER.MESSAGES.EMPLOYEE_UPDATED'))
      )
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
    queryClient.invalidateQueries({ queryKey: ['authenticated-employee'] });
  };

  if (isLoading) {
    return (
      <div className="mx-8 my-10">
        <FormSkeleton />
      </div>
    );
  }

  return (
    <div className="profile">
      <div className="profile__employee">
        <FormProvider {...methods}>
          <BasicInfoSection />
          <div className="mb-6">
            <Input
              name="position"
              rules={getCommonValidators([V.MAX_LENGTH])}
              label={t('USERS.DRAWER.LABELS.POSITION') ?? ''}
              className="drawer__users__form__input"
              placeholder={t('USERS.DRAWER.PLACEHOLDERS.POSITION') ?? ''}
            />
          </div>
          <ActiveEmployeeSection
            isPedagogicalEmployee={isPedagogicalEmployee}
          />
          {isPedagogicalEmployee && <PedagogicalSection />}
          <h3 className="my-8 drawer__users__form__students__title">
            {t('USERS.DRAWER.TITLES.DOCUMENTS')}
          </h3>
          <FilesTable data={files} removeFile={removeFileFromTable} />
          <FileUpload appendFile={appendFileToTable} />
          <div className="profile__employee__actions">
            <Button
              className="profile__employee__actions__update"
              form="employee-form"
              onClick={methods.handleSubmit((data) => onUpdateEmployee(data))}
            >
              {t('ACTIONS.UPDATE')}
            </Button>
          </div>
        </FormProvider>
      </div>
    </div>
  );
}
