import { Checkmark } from '@carbon/icons-react';
import { Table, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { fetchInstallmentsByStudent } from '../../../../hooks/taxes/school-taxes/parent/history/useGetInstallmentsByStudent';
import { useGetSchoolTaxes } from '../../../../hooks/taxes/school-taxes/useGetSchoolTaxes';
import { useModal } from '../../../../hooks/useModal';
import type { StudentSchoolTaxesDto } from '../../../../models/taxes/school/school-taxes-details.dto';
import type { ScholarshipType } from '../../../../models/users/students/scholarship-type';
import { Scholarship } from '../../../../models/users/students/scholarship-type';
import { PARTIAL_PAYMENT } from '../../../../utils/constants/taxes/modals';
import { ActionMenuHorizontal } from '../../../shared/components/ActionMenuHorizontal';
import { TableSkeleton } from '../../../shared/components/skeletons/TableSkeleton';

export const ParentSchoolTaxes = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const notification = useNotifications();
  const queryClient = useQueryClient();
  const { open: openPartialPaymentModal } = useModal(PARTIAL_PAYMENT);

  const { data, isLoading, isSuccess } = useGetSchoolTaxes();

  const headers = [
    t('PAYMENTS.TABLE.HEADERS.STUDENT_NAME'),
    t('PAYMENTS.TABLE.HEADERS.TOTAL_TAX'),
    // t('PAYMENTS.TABLE.HEADERS.MONTHLY_TAX'),
    t('PAYMENTS.TABLE.HEADERS.PAID_AMOUNT'),
    t('PAYMENTS.TABLE.HEADERS.PENDING_AMOUNT'),
    t('PAYMENTS.TABLE.HEADERS.NOTES'),
    t('PAYMENTS.TABLE.HEADERS.ACTIONS'),
  ];

  const onNavigateToFullPayment = useCallback(
    (studentName: string) => {
      fetchInstallmentsByStudent(studentName).then((res) => {
        const remainingInstallmentsIds = res.result
          .filter((item) => item.isPaid === false)
          .map((installment) => installment.id);
        queryClient.invalidateQueries({ queryKey: ['school-taxes-history'] });
        remainingInstallmentsIds.length
          ? navigate(
              `pre-payment/${remainingInstallmentsIds.join('&')}/${studentName}`
            )
          : notification.default(
              t('TAXES.MESSAGES.NO_SCHOOL_TAXES', { name: studentName })
            );
      });
    },
    [navigate, notification, queryClient, t]
  );

  const onOpenPartialPaymentModal = useCallback(
    (student: StudentSchoolTaxesDto) => {
      openPartialPaymentModal({
        id: student.id,
        name: student.name,
      });
    },
    [openPartialPaymentModal]
  );

  const isOnSchoolTaxesScholarship = (scholarshipType: ScholarshipType) =>
    scholarshipType !== Scholarship.NO_SCHOLARSHIP;

  const tableChildren = useMemo(() => {
    return data?.reduce((acc: any, student) => {
      let studentArray = [
        <span>{student.name}</span>,
        <span>{student.totalAmount.toFixed(2)}</span>,
        // <span>{student.monthlyAmount.toFixed(2)}</span>,
        <span>{student.paidAmount.toFixed(2)}</span>,
        <span>{student.remainingAmount.toFixed(2)}</span>,
        isOnSchoolTaxesScholarship(student.scholarshipType) ? (
          t('PAYMENTS.TABLE.LABELS.ON_SCHOLARSHIP')
        ) : student.totalAmount === student.paidAmount ? (
          <div className="paid-tax">
            <Checkmark size={'24'} /> {t('PAYMENTS.TABLE.LABELS.TAX_PAID')}
          </div>
        ) : student.remainingInstallments === 0 ? (
          t('PAYMENTS.TABLE.LABELS.REVIEW_PAYMENTS')
        ) : null,
        !isOnSchoolTaxesScholarship(student.scholarshipType) &&
        student.totalAmount !== student.paidAmount ? (
          <ActionMenuHorizontal
            options={[
              {
                label: t('PAYMENTS.TABLE.ACTIONS.PAY_PARTIALLY'),
                hidden: student.remainingInstallments === 0,
                onClick: () => {
                  onOpenPartialPaymentModal(student);
                },
              },
              {
                label: t('PAYMENTS.TABLE.ACTIONS.PAY_FULL'),
                hidden: student.remainingInstallments === 0,
                onClick: () => {
                  onNavigateToFullPayment(student.name);
                },
              },
              {
                label: t('PAYMENTS.TABLE.ACTIONS.VIEW_HISTORY'),
                hidden: student.remainingInstallments !== 0,
                onClick: () => {
                  navigate('/dashboard/payments/school-taxes/history');
                },
              },
            ]}
          />
        ) : (
          ''
        ),
      ];

      return [...acc, studentArray];
    }, []);
  }, [data, onNavigateToFullPayment, onOpenPartialPaymentModal, navigate, t]);

  return (
    <div className="payments">
      <h2 className="payments__item__titles__h2">
        {t('PAYMENTS.TITLES.SCHOOL_TAXES')}
      </h2>
      <p className="payments__item__titles__p">
        {t('PAYMENTS.LABELS.AMOUNTS_NOTE')}
      </p>
      {isLoading && <TableSkeleton />}
      {isSuccess && data && <Table headers={headers} rows={tableChildren} />}
    </div>
  );
};
