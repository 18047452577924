import { Select, type SelectOption, Table } from '@ph-react-ui/core';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { usePaginatedStudentsToEvaluate } from '../../../hooks/evaluation-plans/usePaginatedStudentsToEvaluate';
import { usePaginatedGroups } from '../../../hooks/groups/usePaginatedGroups';
import { UserOrdering } from '../../../utils/enums/user-ordering.enum';
import { ActionMenuHorizontal } from '../../shared/components/ActionMenuHorizontal';
import { Pagination } from '../../shared/components/Pagination';
import { SearchInput } from '../../shared/components/SearchByNameInput';
import { TableSkeleton } from '../../shared/components/skeletons/TableSkeleton';
import { ThSortItem } from '../../shared/components/tables/ThSortItem';

export function StudentsToEvaluate() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const match = useMatch('/dashboard/evaluation-plans/students');
  const [searchParams, setSearchParams] = useSearchParams();

  const [groupsSearchOptions, setGroupsSearchOptions] = useState<
    SelectOption<string>[]
  >([]);
  const params = new URLSearchParams({
    page: '1',
    size: '999',
  });

  const { data: groups } = usePaginatedGroups(params);

  const { data: students } = usePaginatedStudentsToEvaluate(searchParams);

  useEffect(() => {
    if (groups) {
      const groupsOptions = groups.result?.map((group) => ({
        label: group.name,
        value: group.name,
      }));

      setGroupsSearchOptions(groupsOptions);
    }
  }, [groups]);

  const isSuccess = true;

  const headers = [
    <ThSortItem
      title={t('EVALUATION_PLANS.TABLE.HEADERS.NAME')}
      ordering={UserOrdering.Name}
    ></ThSortItem>,
    t('EVALUATION_PLANS.TABLE.HEADERS.ASSIGNED_GROUPS'),
    t('EVALUATION_PLANS.TABLE.HEADERS.ACTIONS'),
  ];

  const onFilterByGroupChanged = (
    value: string | number | (string | number)[] | null
  ) => {
    if (value) {
      searchParams.set(
        'groupName',
        typeof value === 'string' ? value : value?.toString()
      );
      searchParams.set('page', '1');
      setSearchParams(searchParams);
    } else {
      searchParams.delete('groupName');
      setSearchParams(searchParams);
    }
  };

  const tableChildren = useMemo(() => {
    return students?.result?.reduce((acc: any, student) => {
      let userArray = [
        student.student.name,
        student.groups?.map((group) => group.name).join(', '),
        <ActionMenuHorizontal
          options={[
            {
              label: t('EVALUATION_PLANS.ACTIONS.EVALUATE'),
              onClick: () =>
                navigate(
                  `../evaluate?studentId=${student.student.id}&name=${student.student.name}`
                ),
            },
            {
              label: t('EVALUATION_PLANS.ACTIONS.VIEW_PREVIOUS_EVALUATIONS'),
              onClick: () =>
                navigate(
                  `${student.student.id}/evaluations?name=${student.student.name}`
                ),
            },
          ]}
        />,
      ];

      return [...acc, userArray];
    }, []);
  }, [students, t, navigate]);

  return (
    <div className="evaluation-plans">
      <div className="evaluation-plans__actions">
        <h2>{t('EVALUATION_PLANS.TITLES.STUDENTS_TO_EVALUATE')}</h2>
      </div>
      <div className="evaluation-plans__filter">
        <SearchInput
          searchInputName="studentName"
          pathMatch={match}
          placeholder={t('USERS.TABLE.FILTERS.SEARCH_USER')}
        />
        {!!groupsSearchOptions.length && (
          <Select
            compact
            options={groupsSearchOptions}
            clearable
            placeholder={
              t('EVALUATION_PLANS.PLACEHOLDERS.FILTER_BY_GROUP') ?? ''
            }
            onChange={onFilterByGroupChanged}
            value={searchParams.get('groupName') ?? ''}
          />
        )}
      </div>
      {isSuccess && students ? (
        <>
          <Table headers={headers} rows={tableChildren} />
          <div className="users__pagination">
            <Pagination data={students} />
          </div>
        </>
      ) : (
        <TableSkeleton headers={headers} />
      )}
    </div>
  );
}
