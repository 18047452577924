import { useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface TextEditorProps {
  label?: string;
  placeholder?: string;
  disabled?: boolean;
}

export function TextEditor({
  label,
  placeholder,
  disabled,
  ...rest
}: TextEditorProps) {
  const [value, setValue] = useState('');
  const ref = useRef<ReactQuill>(null);

  return (
    <div className="text-editor">
      {label && (
        <label htmlFor="text-editor" className="text-editor__label">
          {label}
        </label>
      )}
      <ReactQuill
        ref={ref}
        readOnly={disabled}
        id="text-editor"
        theme="snow"
        value={value}
        onChange={setValue}
        className={`text-editor__editor ${disabled ? 'disabled' : ''}`}
        placeholder={placeholder}
        {...rest}
      ></ReactQuill>
    </div>
  );
}
