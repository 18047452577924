import { useQuery } from '@tanstack/react-query';
import type { BusRoutesWithBusStopsDetailsDto } from '../../../models/transportation/routes/bus-routes-with-bus-stops-details.dto';
import apiRequest from '../../../services/apiWrapper';
import { BUS_ROUTES_URL } from '../../../utils/constants/urlConstants';

async function getBusRoutesWithBusStops(routeId?: string, studentId?: string) {
  const response = await apiRequest.get<BusRoutesWithBusStopsDetailsDto>(
    `${BUS_ROUTES_URL}/route-stops?routeId=${routeId}&studentId=${studentId}`
  );

  return response.data;
}

export function useGetBusRoutesWithBusStops({
  routeId,
  studentId,
}: {
  routeId?: string;
  studentId?: string;
}) {
  return useQuery({
    queryKey: ['bus-routes-with-bus-stops', routeId, studentId],
    queryFn: () => getBusRoutesWithBusStops(routeId, studentId),
    select: (data) =>
      data.routeStops.reduce((selectOptions: any, routeStop) => {
        const busStops = routeStop.stops.map((busStop) => ({
          label: `${routeStop.route.name} / ${busStop.address}`,
          routeId: routeStop.route.id,
          value: busStop.id,
        }));
        return [...selectOptions, ...busStops];
      }, []),
    enabled: studentId !== undefined,
  });
}
