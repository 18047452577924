import { useMutation } from '@tanstack/react-query';
import type { AdminSettingsDto } from '../../../models/settings/admin/admin-settings.dto';
import apiRequest from '../../../services/apiWrapper';
import { SETTINGS_URL } from '../../../utils/constants/urlConstants';

async function updateAdminSettings(data: AdminSettingsDto) {
  return apiRequest.put(SETTINGS_URL, data);
}

export function useUpdateAdminSettings() {
  return useMutation({
    mutationFn: (data: AdminSettingsDto) => updateAdminSettings(data),
  });
}
