import type { ComponentType } from 'react';
import type { UseControllerProps } from 'react-hook-form';
import { useController } from 'react-hook-form';

export function withController<TProps>(Component: ComponentType<TProps>) {
  const WrapperComponent = (
    props: UseControllerProps<any> & TProps & { className?: string }
  ) => {
    const {
      field,
      fieldState: { error },
    } = useController({
      name: props.name,
      rules: props.rules,
      control: props.control,
    });
    const isRequiredField = (props.rules?.required as any)?.value;
    return (
      <Component
        {...field}
        {...props}
        error={!!error}
        errorMsg={error?.message}
        className={`${props.className ? props.className : ''} ${
          isRequiredField ? 'required' : ''
        }`}
      />
    );
  };

  return WrapperComponent;
}
