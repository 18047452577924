import { useMutation } from '@tanstack/react-query';
import apiRequest from '../../../services/apiWrapper';
import { GROUPS_URL } from '../../../utils/constants/urlConstants';

interface MoveMemberDataDto {
  destinationGroupIds: string[];
  userProfileIds: string[];
}

async function moveGroupMembers(
  id: string | null,
  moveMemberData: MoveMemberDataDto
) {
  return apiRequest.put(`${GROUPS_URL}/${id}/members/move`, moveMemberData);
}

export function useMoveGroupMembers(id: string | null) {
  return useMutation({
    mutationFn: (moveMemberData: MoveMemberDataDto) =>
      moveGroupMembers(id, moveMemberData),
  });
}
