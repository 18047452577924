import { Document, Page } from 'react-pdf';
import { useGetFile } from '../../../hooks/files/useGetFile';

interface FilePreviewProps {
  fileId: string;
}
export function FilePreview({ fileId }: FilePreviewProps) {
  const { data: fileResponse } = useGetFile({
    fileId: fileId!,
    enabled: Boolean(fileId),
  });
  return (
    <Document file={fileResponse?.uri}>
      <Page
        pageNumber={1}
        scale={1 / Math.sqrt(2)}
        renderTextLayer={false}
        renderAnnotationLayer={false}
        className="file__preview__page"
      />
    </Document>
  );
}
