import { useQuery } from '@tanstack/react-query';
import type { ParentUpdateFormDto } from '../../../models/users/parents/parent-update.dto';
import apiRequest from '../../../services/apiWrapper';
import { PARENTS_URL } from '../../../utils/constants/urlConstants';

async function getAuthenticatedParentProfile() {
  const response = await apiRequest.get<ParentUpdateFormDto>(
    `${PARENTS_URL}/profile`
  );
  return response.data;
}

export function useGetAuthenticatedParentProfile() {
  return useQuery({
    queryKey: ['authenticated-parent'],
    queryFn: () => getAuthenticatedParentProfile(),
    select: (data) => ({
      ...data,
      parents: data.parents.map((parent) => ({
        ...parent,
        userProfileId: parent.id,
      })),
    }),
  });
}
