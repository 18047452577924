import { Add, Checkmark, Close, Folder } from '@carbon/icons-react';
import { Button, Checkbox, Table, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { useCreateGroupMembers } from '../../../hooks/groups/members/useCreateGroupMembers';
import { useMoveGroupMembers } from '../../../hooks/groups/members/useMoveGroupMembers';
import { useCreateGroup } from '../../../hooks/groups/useCreateGroup';
import { usePaginatedGroups } from '../../../hooks/groups/usePaginatedGroups';
import { useCancellationDialog } from '../../../hooks/useCancellationDialog';
import { useModal } from '../../../hooks/useModal';
import type { GroupCreateEditDto } from '../../../models/groups/group-create-edit.dto';
import type { GroupsDto } from '../../../models/groups/groups.dto';
import { Input } from '../../../utils/components/hoc-components';
import { ADD_TO_GROUP } from '../../../utils/constants/users/modals';
import {
  getCommonValidators,
  VALIDATION as V,
} from '../../../utils/inputValidators';
import { Dialog } from '../../shared/components/Dialog';
import { TableSkeleton } from '../../shared/components/skeletons/TableSkeleton';

interface CreateNewGroupProps {
  appendNewGroup: (id: string, name: string) => void;
}
interface GroupValues {
  groups: {
    id: string;
    name: string;
    value: boolean;
  }[];
}

export const AddToGroup = () => {
  const { t } = useTranslation();
  const notification = useNotifications();
  const [searchParams] = useSearchParams();
  const { id: groupId } = useParams();
  const queryClient = useQueryClient();
  const modalType = searchParams.get('modal');
  const userIds = searchParams.get('userId');
  const [filteredGroups, setFilteredGroups] = useState<
    GroupsDto[] | undefined
  >();

  const { open: openCancellationDialog } = useCancellationDialog();

  const { close: closeModal } = useModal(ADD_TO_GROUP);

  const params = new URLSearchParams({
    page: '1',
    size: '99',
  });

  const { data, isSuccess } = usePaginatedGroups(params);
  const addGroupMembers = useCreateGroupMembers();
  const moveGroupMembers = useMoveGroupMembers(groupId as string);

  useEffect(() => {
    const filteredGroups = data?.result.filter((group) => group.id !== groupId);
    setFilteredGroups(filteredGroups);
  }, [data, groupId]);

  const {
    control,
    handleSubmit,
    watch,
    formState: { isDirty },
  } = useForm({
    values: {
      groups: filteredGroups?.map((group) => ({
        id: group.id,
        name: group.name,
        value: false,
      })),
    } as GroupValues,
  });

  const { fields: groups, append } = useFieldArray({
    control,
    name: 'groups',
  });

  const tableChildren = useMemo(() => {
    return groups.reduce((acc: any, group, index) => {
      let groupArray = [
        <div className="groups__table__checkbox">
          <Controller
            name={`groups.${index}.value`}
            control={control}
            render={({ field }) => (
              <Checkbox className="groups__table-first" {...field} />
            )}
          />
          <Folder size={20} />
          {group.name}
        </div>,
      ];
      return [...acc, groupArray];
    }, []);
  }, [control, groups]);

  const appendNewGroup = (id: string, name: string) => {
    append({
      id,
      name,
      value: true,
    });
  };

  const onSubmit = (data: GroupValues) => {
    const selectedGroupIds = data.groups
      .filter((group) => group.value)
      .map((group) => group.id);
    const mutation =
      modalType === ADD_TO_GROUP ? addGroupMembers : moveGroupMembers;
    mutation
      .mutateAsync({
        destinationGroupIds: selectedGroupIds,
        userProfileIds: userIds?.split('&') as [],
      })
      .then(() => {
        notification.success(
          t(
            `GROUPS.DIALOG.MESSAGES.MEMBERS_${
              modalType === ADD_TO_GROUP ? 'ADDED' : 'MOVED'
            }`
          )
        );
        queryClient.invalidateQueries({ queryKey: ['members', groupId] });
        selectedGroupIds.forEach((id) =>
          queryClient.invalidateQueries({ queryKey: ['members', id] })
        );
        closeModal();
      })
      .catch((resError) => {
        notification.danger(resError.errors[0]);
      });
  };

  const onCloseModal = () => {
    if (isDirty) {
      openCancellationDialog();
    } else {
      closeModal();
    }
  };

  return (
    <Dialog
      dialogHeader={t('SIDEMENU.GROUPS')}
      onClose={onCloseModal}
      actionButton={
        <Button
          type="submit"
          onClick={handleSubmit(onSubmit)}
          disabled={!watch('groups')?.some((group) => group.value)}
        >
          {t('ACTIONS.ADD')}
        </Button>
      }
    >
      <div className="dialog__confirm__content__scroll">
        {isSuccess && groups ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Table
              headers={[t('GROUPS.TABLE.HEADERS.NAME'), '']}
              rows={tableChildren}
            />
          </form>
        ) : (
          <TableSkeleton headers={[t('GROUPS.TABLE.HEADERS.NAME')]} />
        )}
        <CreateNewGroup appendNewGroup={appendNewGroup} />
      </div>
    </Dialog>
  );
};

const CreateNewGroup = ({ appendNewGroup }: CreateNewGroupProps) => {
  const { t } = useTranslation();
  const notification = useNotifications();
  const [inputVisible, setInputVisible] = useState(false);

  const methods = useForm({
    defaultValues: {
      name: '',
    },
  });

  const createGroup = useCreateGroup();

  const onSubmit = (data: GroupCreateEditDto) => {
    createGroup
      .mutateAsync(data)
      .then((res) => {
        notification.success(t('GROUPS.DIALOG.MESSAGES.CREATE_SUCCESS'));
        appendNewGroup(res.data.id, methods.getValues('name'));
        methods.reset();
        setInputVisible(false);
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  if (!inputVisible)
    return (
      <p className="dialog__confirm__content__sticky">
        <Add size={20} />
        <span
          onClick={() => {
            setInputVisible(true);
          }}
          aria-hidden
        >
          {t('GROUPS.DIALOG.HEADERS.CREATE_GROUP')}
        </span>
      </p>
    );
  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit((data) => onSubmit(data))}
        className="dialog__confirm__content__sticky"
      >
        <Input
          className="drawer__users__form__input"
          placeholder={t('USERS.DRAWER.PLACEHOLDERS.NAME') ?? ''}
          name="name"
          rules={getCommonValidators([V.REQUIRED, V.MAX_LENGTH])}
        />
        <div className="icons">
          {!!methods.watch('name').length && (
            <Checkmark
              size={20}
              className="checkmark"
              onClick={methods.handleSubmit((data) => onSubmit(data))}
            />
          )}
          <Close
            size={20}
            className="close"
            onClick={() => setInputVisible(false)}
          />
        </div>
      </form>
    </FormProvider>
  );
};
