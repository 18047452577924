import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { useGetBankTransferDetails } from '../../../../hooks/taxes/school-taxes/useGetBankTransferDetails';
import { useModal } from '../../../../hooks/useModal';
import { TRANSFER_DETAILS } from '../../../../utils/constants/taxes/modals';
import { Dialog } from '../../../shared/components/Dialog';
import { SimpleSkeleton } from '../../../shared/components/skeletons/SimpleSkeleton';

export const BankTransferDetailsModal = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const studentName = searchParams.get('name');
  const { close: closeModal } = useModal(TRANSFER_DETAILS);

  const { data, isLoading, isSuccess } = useGetBankTransferDetails(id!);

  const onCloseModal = () => {
    closeModal();
  };

  return (
    <Dialog
      onClose={onCloseModal}
      dialogHeader={t('PAYMENTS.DIALOGS.LABELS.INFORMATION_BANK_TRANSFER')}
    >
      <div className="dialog__confirm__content">
        <h3>{t('PAYMENTS.DIALOGS.LABELS.BANK_TRANSFER_WARN')}</h3>
        {isLoading && <SimpleSkeleton rows={5} />}
        {isSuccess && data && (
          <div
            className="dialog__confirm__content__payment-wrapper
        "
          >
            <p>{t('PAYMENTS.DIALOGS.LABELS.RECIEVER')}</p>
            <p className="bold-text">{data.recipient}</p>
            <p>{t('SETTINGS.LABELS.IBAN')}</p>
            <p className="bold-text">{data.iban}</p>
            <p>{t('SETTINGS.LABELS.BIC')}</p>
            <p className="bold-text">{data.bic}</p>
            <p>{t('SETTINGS.LABELS.BANK_NAME')}</p>
            <p className="bold-text">{data.bankName}</p>
            <p>{t('PAYMENTS.DIALOGS.LABELS.PAYMENT_DETAILS')}</p>
            <p className="bold-text">
              {data.code} - {studentName}
            </p>
          </div>
        )}
      </div>
    </Dialog>
  );
};
