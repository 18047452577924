import { useMutation } from '@tanstack/react-query';
import apiRequest from '../../services/apiWrapper';
import { GROUPS_URL } from '../../utils/constants/urlConstants';

async function deleteGroupsById(groupIds: string[]) {
  const queryString = groupIds.map((id) => `groupIds=${id}`).join('&');
  return apiRequest.delete(`${GROUPS_URL}?${queryString}`, {});
}

export function useDeleteGroupsById() {
  return useMutation({
    mutationFn: (groupIds: string[]) => deleteGroupsById(groupIds),
  });
}
