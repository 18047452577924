import { Button, Tooltip, useNotifications } from '@ph-react-ui/core';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetTermReportSectionById } from '../../../hooks/term-reports/employee/useGetTermReportSectionById';
import { useSubmitTermReportSectionById } from '../../../hooks/term-reports/employee/useSubmitTermReportSection';
import { useUpdateTermReportSectionById } from '../../../hooks/term-reports/employee/useUpdateTermReportSection';
import type { TermReportSectionFormDto } from '../../../models/term-reports/sections/term-report-section.dto';
import queryClient from '../../../services/queryClient';
import { Input, TextEditor } from '../../../utils/components/hoc-components';
import { TermReportStatus } from '../../../utils/enums/term-report-status.enum';
import { CancellationDialog } from '../../shared/components/CancellationDialog';

export const CreateEditTermReportSection = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const notification = useNotifications();
  const navigate = useNavigate();
  const [isCancellationDialogOpen, setIsCancellationDialogOpen] =
    useState(false);

  const { data: report } = useGetTermReportSectionById(id!);

  const saveTermReportSection = useUpdateTermReportSectionById(id!);
  const submitTermReportSection = useSubmitTermReportSectionById();

  const methods = useForm<TermReportSectionFormDto>({
    defaultValues: {
      content: '',
    },
    values: report,
  });

  const onNavigateBack = () => {
    navigate('/dashboard/term-reports/student-reports');
  };

  const onDiscardChanges = () => {
    if (methods.formState.isDirty) {
      setIsCancellationDialogOpen(true);
    } else {
      onNavigateBack();
    }
  };

  const onSaveSection = (data: TermReportSectionFormDto) => {
    saveTermReportSection
      .mutateAsync({
        content: data.content,
      })
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ['term-reports'],
        });
        queryClient.invalidateQueries({
          queryKey: ['term-report-sections'],
        });
        queryClient.invalidateQueries({
          queryKey: ['term-report-section', id],
        });
        notification.success(t('TERM_REPORTS.MESSAGES.SUCCESSFULLY_SAVED'));
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  const onSubmitSection = () => {
    submitTermReportSection
      .mutateAsync(id!)
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ['term-reports'],
        });
        queryClient.invalidateQueries({
          queryKey: ['term-report-sections'],
        });
        onNavigateBack();
        notification.success(t('TERM_REPORTS.MESSAGES.SUCCESSFULLY_SUBMITTED'));
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  return (
    <FormProvider {...methods}>
      <form className="term-reports">
        <div className="term-reports__form">
          <div className="term-reports__form__wrapper">
            <Input
              name="termReport.title"
              disabled
              label={t('TERM_REPORTS.FORM.TITLE') ?? ''}
              placeholder={t('TERM_REPORTS.FORM.TITLE') ?? ''}
            />
            <Input
              name="name"
              disabled
              label={t('TERM_REPORTS.FORM.SECTION_NAME') ?? ''}
              placeholder={t('TERM_REPORTS.FORM.SECTION_NAME') ?? ''}
            />
          </div>
          <TextEditor
            name="content"
            label={t('TERM_REPORTS.FORM.COMMENT') ?? ''}
            placeholder={t('TERM_REPORTS.FORM.COMMENT') ?? ''}
          />
        </div>
        <div className="term-reports__form__actions">
          <Button
            variant="outlined"
            className="term-reports__form__actions__cancel"
            onClick={onDiscardChanges}
          >
            {t('ACTIONS.CANCEL')}
          </Button>
          <Tooltip
            message={t('TERM_REPORTS.MESSAGES.TOOLTIP_NOTE')}
            position="left"
          >
            <Button
              onClick={methods.handleSubmit((data) => onSaveSection(data))}
              disabled={
                report?.termReport.status === TermReportStatus.SentToParent ||
                report?.termReport.status === TermReportStatus.Approved
              }
            >
              {t('ACTIONS.SAVE')}
            </Button>
          </Tooltip>
          <Tooltip
            message={t('TERM_REPORTS.MESSAGES.TOOLTIP_NOTE')}
            position="bottom"
          >
            <Button
              disabled={
                methods.formState.isDirty ||
                report?.termReport.status === TermReportStatus.SentToParent ||
                report?.termReport.status === TermReportStatus.Approved
              }
              onClick={methods.handleSubmit(() => onSubmitSection())}
            >
              {t('ACTIONS.SUBMIT_FOR_REVIEW')}
            </Button>
          </Tooltip>
        </div>
      </form>
      {isCancellationDialogOpen && (
        <CancellationDialog onDiscardChanges={onNavigateBack} />
      )}
    </FormProvider>
  );
};
