import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

export function useCancellationDialog() {
  const queryClient = useQueryClient();

  const open = useCallback(() => {
    queryClient.setQueryData(['cancellation-dialog'], true);
  }, [queryClient]);

  const close = useCallback(() => {
    queryClient.setQueryData(['cancellation-dialog'], false);
  }, [queryClient]);

  const { data: isOpen } = useQuery<boolean>({
    queryKey: ['cancellation-dialog'],
    queryFn: () => new Promise(() => false),
  });

  return { isOpen, open, close };
}
