import { Button, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useCreateGroup } from '../../../hooks/groups/useCreateGroup';
import { useGetGroupById } from '../../../hooks/groups/useGetGroupById';
import { useUpdateGroupById } from '../../../hooks/groups/useUpdateGroup';
import { useCancellationDialog } from '../../../hooks/useCancellationDialog';
import { useModal } from '../../../hooks/useModal';
import type { GroupCreateEditDto } from '../../../models/groups/group-create-edit.dto';
import { Input } from '../../../utils/components/hoc-components';
import { CREATE_EDIT_GROUP } from '../../../utils/constants/groups/modals';
import {
  getCommonValidators,
  VALIDATION as V,
} from '../../../utils/inputValidators';
import { Dialog } from '../../shared/components/Dialog';
import { FormSkeleton } from '../../shared/components/skeletons/FormSkeleton';

export const CreateEditGroup = () => {
  const { t } = useTranslation();
  const notification = useNotifications();
  const [searchParams] = useSearchParams();
  const { open: openCancellationDialog } = useCancellationDialog();
  const queryClient = useQueryClient();
  const id = searchParams.get('groupId');
  const { close: closeModal } = useModal(CREATE_EDIT_GROUP);
  const { data: group, isLoading } = useGetGroupById(id, Boolean(id));

  const createGroup = useCreateGroup();
  const updateGroup = useUpdateGroupById(id);

  const methods = useForm({
    defaultValues: {
      name: '',
    },
    values: group,
  });

  const { isDirty } = methods.formState;

  const onSubmit = (data: GroupCreateEditDto) => {
    if (id) {
      updateGroupData(data);
    } else {
      createNewGroup(data);
    }
  };

  const createNewGroup = (data: GroupCreateEditDto) => {
    createGroup
      .mutateAsync(data)
      .then(() => {
        notification.success(t('GROUPS.DIALOG.MESSAGES.CREATE_SUCCESS'));
        queryClient.invalidateQueries({ queryKey: ['groups'] });
        closeModal();
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  const updateGroupData = (data: GroupCreateEditDto) => {
    updateGroup
      .mutateAsync(data)
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ['group', id] });
        queryClient.invalidateQueries({ queryKey: ['groups'] });
        methods.reset();
        closeModal();
        notification.success(t('GROUPS.DIALOG.MESSAGES.RENAME_SUCCESS'));
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  const onCloseModal = () => {
    if (isDirty) {
      openCancellationDialog();
    } else {
      closeModal();
    }
  };

  if (isLoading && id) {
    return <FormSkeleton />;
  }
  return (
    <Dialog
      onClose={onCloseModal}
      dialogHeader={
        id
          ? t('GROUPS.DIALOG.HEADERS.RENAME_GROUP')
          : t('GROUPS.DIALOG.HEADERS.CREATE_GROUP')
      }
      actionButton={
        <Button
          type="submit"
          onClick={methods.handleSubmit((data) => onSubmit(data))}
        >
          {id ? t('ACTIONS.UPDATE') : t('ACTIONS.ADD')}
        </Button>
      }
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit((data) => onSubmit(data))}>
          <div className="dialog__confirm__content">
            <Input
              className="drawer__users__form__input"
              label={t('USERS.DRAWER.LABELS.NAME')!}
              placeholder={t('USERS.DRAWER.PLACEHOLDERS.NAME') ?? ''}
              name="name"
              rules={getCommonValidators([V.REQUIRED, V.MAX_LENGTH])}
            />
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};
