import { useQuery } from '@tanstack/react-query';
import type { StudentDetailsWithIdDto } from '../../../models/users/students/student-details.dto';
import apiRequest from '../../../services/apiWrapper';
import { mapStringToSimpleDate } from '../../../services/mapStringToSimpleDate';

async function getStudentById(id: string | null) {
  const response = await apiRequest.get<StudentDetailsWithIdDto>(
    `/api/users/students/${id}`
  );

  return response.data;
}

export function useGetStudentById(id: string | null, enabled: boolean = true) {
  return useQuery({
    queryKey: ['student', id],
    queryFn: () => getStudentById(id),
    select: (data) => ({
      ...data,
      birthDate: mapStringToSimpleDate(data.birthDate),
    }),
    enabled,
  });
}
