import { Checkmark, WarningAltFilled } from '@carbon/icons-react';
import { Button } from '@ph-react-ui/core';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetPaymentResponse } from '../../hooks/payments/useGetPaymentResponse';
import { PAYMENT_TYPE } from '../../utils/constants/payments/payment-type';
import { PaymentTransactionStatus } from '../../utils/enums/payment-transaction-status';

export function PaymentResponse() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const { data: paymentResponse } = useGetPaymentResponse(id);

  const onNavigateBack = () => {
    navigate('/dashboard/payments');
  };

  return (
    <div className="payments">
      <div className="payments__response">
        {paymentResponse?.status === PaymentTransactionStatus.Successful ? (
          <>
            <Checkmark className="payments__response__icon payments__response__icon-success" />
            <h3 className="payments__response__title">
              {t('PAYMENTS.TITLES.SUCCESSFUL_PAYMENT')}
            </h3>
          </>
        ) : (
          <>
            <WarningAltFilled className="payments__response__icon payments__response__icon-error" />
            <h3 className="payments__response__title">
              {t('PAYMENTS.TITLES.UNSUCCESSFUL_PAYMENT')}
            </h3>
          </>
        )}

        <div className="payments__response__list">
          <span className="payments__response__list__item">
            {t('PAYMENTS.LABELS.PAYMENT_TYPE')}
          </span>
          {paymentResponse && (
            <span className="payments__response__list__item">
              {t(PAYMENT_TYPE[paymentResponse.paymentType])}
            </span>
          )}
          {/* Am I going to visible at some point? */}
          {/* <span className="payments__response__list__item">Email</span>
          <span className="payments__response__list__item">
            parent@parent.com
          </span> */}
          <span className="payments__response__list__item">
            {t('PAYMENTS.LABELS.AMOUNT_PAID')}
          </span>
          <span className="payments__response__list__item">
            {paymentResponse?.amount} {t('PAYMENTS.LABELS.BGN')}
          </span>
          <span className="payments__response__list__item">
            {t('PAYMENTS.LABELS.TRANSACTION_ID')}
          </span>
          <span className="payments__response__list__item">{id}</span>
        </div>
        <div className="payments__response__actions">
          <Button compact onClick={onNavigateBack}>
            {t('ACTIONS.CLOSE')}
          </Button>
        </div>
      </div>
    </div>
  );
}
