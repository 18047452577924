import { useQuery } from '@tanstack/react-query';
import type { StudentTermReportsDetailsDto } from '../../models/term-reports/term-report.dto';
import apiRequest from '../../services/apiWrapper';

async function fetchStudentTermReport(id: string) {
  const response = await apiRequest.get<StudentTermReportsDetailsDto>(
    `api/students/${id}/term-reports`
  );
  return response.data;
}

export function useGetAllTermReportsOfStudent(id: string) {
  return useQuery({
    queryKey: ['student-term-reports', id],
    queryFn: () => fetchStudentTermReport(id),
  });
}
