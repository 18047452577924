import { Button } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useSearchParams } from 'react-router-dom';
import {
  createQueryKey,
  usePaginatedTransportationTaxes,
} from '../../../hooks/taxes/transportation/usePaginatedTransportationTaxes';
import { useModal } from '../../../hooks/useModal';
import type { TaxUser } from '../../../models/taxes/tax-user';
import { CONFIRM_BANK_PAYMENT } from '../../../utils/constants/users/modals';
import { MonthPagination } from '../../shared/components/MonthPagination';
import { MonthPaginationSkeleton } from '../../shared/components/skeletons/MonthPaginationSkeleton';
import { TableSkeleton } from '../../shared/components/skeletons/TableSkeleton';
import { TaxesBaseTable } from '../../shared/components/TaxesBaseTable';

export function TransportationTaxes() {
  const match = useMatch('/dashboard/taxes/transportation');
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const monthParam = searchParams.get('month');
  const yearParam = searchParams.get('year');
  const queryClient = useQueryClient();
  const { open: openConfirmBankPaymentModal } = useModal(CONFIRM_BANK_PAYMENT);

  const {
    data: transportationTaxes,
    isSuccess,
    isPending,
    isError,
  } = usePaginatedTransportationTaxes(searchParams);

  useEffect(() => {
    if (transportationTaxes) {
      searchParams.set('month', transportationTaxes.month.toString());
      searchParams.set('year', transportationTaxes.year.toString());

      queryClient.setQueryData(
        ['transportation-taxes', createQueryKey(searchParams)],
        transportationTaxes
      );
    }
  }, [transportationTaxes, searchParams, queryClient]);

  const renderHeader = (users: TaxUser[], dirtyFields: any) => {
    const onOpenManyMarkAsPaid = () => {
      const ids = users
        .filter((user) => user.value)
        .map((user) => user.id)
        .join('&');
      openConfirmBankPaymentModal({
        userIds: ids,
      });
    };
    return (
      <Button
        disabled={!dirtyFields.items?.some((user: TaxUser) => user.value)}
        className="taxes__transportation__filter__bulk"
        onClick={onOpenManyMarkAsPaid}
      >
        {t('ACTIONS.MARK_AS_PAID')}
      </Button>
    );
  };

  return (
    <div className="taxes__transportation">
      <div className="taxes__transportation__month__pagination">
        {!isPending ? (
          <MonthPagination
            month={transportationTaxes?.month.toString() || monthParam}
            year={transportationTaxes?.year.toString() || yearParam}
          />
        ) : (
          <MonthPaginationSkeleton />
        )}
      </div>
      <div className="taxes__transportation__content">
        {isSuccess && transportationTaxes && (
          <>
            <TaxesBaseTable
              data={transportationTaxes}
              renderBulkActions={renderHeader}
              pathMatch={match}
            />
          </>
        )}
        {isError && t('TAXES.MESSAGES.NO_TRANSPORTATION_TAXES')}
        {isPending && <TableSkeleton />}
      </div>
    </div>
  );
}
