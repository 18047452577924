import { useQuery } from '@tanstack/react-query';
import type { TermReportFormDto } from '../../models/term-reports/term-report.dto';
import apiRequest from '../../services/apiWrapper';
import { TERM_REPORTS_URL } from '../../utils/constants/urlConstants';

async function fetchTermReport(id: string) {
  const response = await apiRequest.get<TermReportFormDto>(
    `${TERM_REPORTS_URL}/${id}`
  );
  return response.data;
}

export function useGetTermReportById(reportId: string | null) {
  return useQuery({
    queryKey: ['term-report', reportId],
    queryFn: () => fetchTermReport(reportId!),
    enabled: Boolean(reportId),
  });
}
