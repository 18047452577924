import { useMutation } from '@tanstack/react-query';
import type { RegisterAbsenceDTO } from '../../../models/users/parents/parent-register-absence.dto';
import apiRequest from '../../../services/apiWrapper';
import { STUDENT_ABSENCE_URL } from '../../../utils/constants/urlConstants';

async function markAbsence(studentData: RegisterAbsenceDTO) {
  return apiRequest.post<RegisterAbsenceDTO, void>(
    STUDENT_ABSENCE_URL,
    studentData
  );
}

export function useMarkAbsence() {
  return useMutation({
    mutationFn: (studentData: RegisterAbsenceDTO) => markAbsence(studentData),
  });
}
