import { Button } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useSearchParams } from 'react-router-dom';
import { usePaginatedFoodTaxes } from '../../../../hooks/taxes/food/admin/usePaginatedFoodTaxes';
import { useModal } from '../../../../hooks/useModal';
import type { TaxUser } from '../../../../models/taxes/tax-user';
import { CONFIRM_BANK_PAYMENT } from '../../../../utils/constants/users/modals';
import { MonthPagination } from '../../../shared/components/MonthPagination';
import { MonthPaginationSkeleton } from '../../../shared/components/skeletons/MonthPaginationSkeleton';
import { TableSkeleton } from '../../../shared/components/skeletons/TableSkeleton';
import { TaxesBaseTable } from '../../../shared/components/TaxesBaseTable';

export function AdminFoodTaxes() {
  const match = useMatch('/dashboard/taxes/food');
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const monthParam = searchParams.get('month');
  const yearParam = searchParams.get('year');
  const queryClient = useQueryClient();
  const { open: openConfirmBankPaymentModal } = useModal(CONFIRM_BANK_PAYMENT);

  const {
    data: foodTaxes,
    isSuccess,
    isPending,
    isError,
  } = usePaginatedFoodTaxes(searchParams);

  useEffect(() => {
    if (foodTaxes) {
      searchParams.set('month', foodTaxes.month.toString());
      searchParams.set('year', foodTaxes.year.toString());
    }
  }, [foodTaxes, searchParams, queryClient]);

  const renderHeader = (users: TaxUser[], dirtyFields: any) => {
    const onOpenManyMarkAsPaid = () => {
      const ids = users
        .filter((user) => user.value)
        .map((user) => user.id)
        .join('&');
      openConfirmBankPaymentModal({
        userIds: ids,
        mealMenuId: foodTaxes?.mealMenuId!,
      });
    };

    return (
      <Button
        disabled={!dirtyFields.items?.some((user: TaxUser) => user.value)}
        className="taxes__food__filter__bulk"
        onClick={onOpenManyMarkAsPaid}
      >
        {t('ACTIONS.MARK_AS_PAID')}
      </Button>
    );
  };

  return (
    <div className="taxes__food">
      <div className="taxes__food__month__pagination">
        {!isPending ? (
          <MonthPagination
            month={foodTaxes?.month.toString() || monthParam}
            year={foodTaxes?.year.toString() || yearParam}
          />
        ) : (
          <MonthPaginationSkeleton />
        )}
      </div>
      <div className="taxes__food__content">
        {isSuccess && foodTaxes && (
          <TaxesBaseTable
            renderBulkActions={renderHeader}
            data={foodTaxes}
            pathMatch={match}
          />
        )}
        {isError && t('TAXES.MESSAGES.NO_FOOD_TAXES')}
        {isPending && <TableSkeleton />}
      </div>
    </div>
  );
}
