import { useMutation } from '@tanstack/react-query';
import type { UpdateTermReportSectionDto } from '../../../models/term-reports/sections/term-report-section.dto';

import apiRequest from '../../../services/apiWrapper';
import { TERM_REPORT_SECTIONS_URL } from '../../../utils/constants/urlConstants';

async function updateTermReportSectionById(
  id: string | null,
  updateSection: UpdateTermReportSectionDto
) {
  return apiRequest.put(`${TERM_REPORT_SECTIONS_URL}/${id}`, updateSection);
}

export function useUpdateTermReportSectionById(id: string | null) {
  return useMutation({
    mutationFn: (updateSection: UpdateTermReportSectionDto) =>
      updateTermReportSectionById(id, updateSection),
  });
}
