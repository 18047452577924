import { Add, TrashCan } from '@carbon/icons-react';
import { type Control, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGetAssignableEmployeesForTermReports } from '../../../hooks/term-reports/useGetAssignableEmployees';
import type { TermReportFormDto } from '../../../models/term-reports/term-report.dto';
import { Input, Select } from '../../../utils/components/hoc-components';
import {
  getCommonValidators,
  VALIDATION as V,
} from '../../../utils/inputValidators';

interface AssignedSectionFieldArrayProps {
  control: Control<TermReportFormDto>;
}

export const AssignedSectionFieldArray = ({
  control,
}: AssignedSectionFieldArrayProps) => {
  const { t } = useTranslation();
  const { data } = useGetAssignableEmployeesForTermReports();

  const getFormatedEmployeeOptions = data?.employees.map((employee) => ({
    label: employee.name,
    value: employee.id,
  }));

  const {
    append,
    remove,
    fields: sectionFields,
  } = useFieldArray({
    control,
    name: 'sections',
  });

  const onAddSection = () => {
    append({
      name: '',
      id: '',
      assignedToId: '',
    });
  };

  const onRemoveSection = (index: number) => {
    remove(index);
  };

  return (
    <>
      {sectionFields.map((_, index) => (
        <section className="term-reports__form__section" key={index}>
          <Input
            name={`sections[${index}].name`}
            label={t('TERM_REPORTS.FORM.NAME') ?? ''}
            placeholder={t('TERM_REPORTS.FORM.NAME') ?? ''}
            rules={getCommonValidators([V.MAX_LENGTH])}
          />
          <div className="ph-input">
            <p className="ph-label">{t('TERM_REPORTS.FORM.ASSIGN')}</p>
            <Select
              name={`sections[${index}].assignedToId`}
              placeholder={t('TERM_REPORTS.FORM.ASSIGN') ?? ''}
              options={getFormatedEmployeeOptions}
              className="select-to-input"
              compact
              clearable
            />
          </div>
          <button
            onClick={() => onRemoveSection(index)}
            type="button"
            className="term-reports__form__section__remove rounded-btn"
          >
            <TrashCan />
          </button>
        </section>
      ))}
      <div className="term-reports__form__add-more">
        <button type="button" className="rounded-btn" onClick={onAddSection}>
          <Add />
        </button>
      </div>
    </>
  );
};
