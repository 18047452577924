import React from 'react';
import type { ReactNode } from 'react';
import './App.scss';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { ApplicationForm } from './features/admission/application';
import { CompleteProfileLayout } from './features/admission/complete-profile/CompleteProfileLayout';
import { Login, ForgotPassword, SetPassword } from './features/auth';
import { NotFound } from './features/error/NotFound';
import { CreateEditEvaluationPlans } from './features/evaluation-plans/create-edit/CreateEditEvaluationPlans';
import { EvaluationProcess } from './features/evaluation-plans/evaluation-process/EvaluationProcess';
import { SelectEvaluationPlan } from './features/evaluation-plans/evaluation-process/SelectEvaluationPlan';
import { StudentEvaluations } from './features/evaluation-plans/evaluations/StudentEvaluations';
import { ReviewEvaluationPlan } from './features/evaluation-plans/ReviewEvaluationPlan';
import { AllEvaluationPlans } from './features/evaluation-plans/tables/AllEvaluationPlans';
import { MyEvaluationPlans } from './features/evaluation-plans/tables/MyEvaluationPlans';
import { StudentsToEvaluate } from './features/evaluation-plans/tables/StudentsToEvaluate';
import { AdminFoodManagement } from './features/food-management/admin/AdminFoodManagement';
import { WeeklyMenuReports } from './features/food-management/admin/WeeklyMenuReports';
import { ParentFoodManagement } from './features/food-management/parent/ParentFoodManagement';
import { PreviewSaveMealChoice } from './features/food-management/parent/PreviewSaveMealChoice';
import { GroupMembers } from './features/groups/GroupMembers';
import { Groups } from './features/groups/Groups';
import { FoodPayment } from './features/payments/FoodPayment';
import { PaymentResponse } from './features/payments/PaymentResponse';
import { Payments } from './features/payments/Payments';
import { SchoolTaxPayment } from './features/payments/SchoolTaxPayment';
import { TransportPayment } from './features/payments/TransportPayment';
import { EmployeeProfile } from './features/profile/employee/EmployeeProfile';
import { ParentProfile } from './features/profile/parent/ParentProfile';
import { AdminSettings } from './features/settings/admin/AdminSettings';
import { EmployeeSettings } from './features/settings/employee/EmployeeSettings';
import { AuthLayout } from './features/shared/layouts/auth/AuthLayout';
import { DashboardLayout } from './features/shared/layouts/dashboard/DashboardLayout';
import { AdminFoodTaxes } from './features/taxes/food/admin/AdminFoodTaxes';
import { AdminSchoolTaxesReview } from './features/taxes/school/admin/AdminSchoolTaxesReview';
import { SchoolTaxesHistory } from './features/taxes/school/parent/history/SchoolTaxesHistory';
import { ParentSchoolTaxes } from './features/taxes/school/parent/ParentSchoolTaxes';
import { TransportationHistory } from './features/taxes/transportation/parent/TransportationHistory';
import { TransportationTaxes } from './features/taxes/transportation/TransportationTaxes';
import { ApproveEditReport } from './features/term-reports/admin/ApproveEditReport';
import { CreateEditTermReport } from './features/term-reports/create-edit/CreateEditTermReport';
import { CreateEditTermReportSection } from './features/term-reports/create-edit/CreateTermReportSection';
import { ParentTermReports } from './features/term-reports/parent/ParentTermReports';
import { StudentReports } from './features/term-reports/StudentReports';
import { TermReports } from './features/term-reports/TermReports';
import { TransportationPlan } from './features/transportation/parent/TransportationPlan';
import { TransportationRoutesManagement } from './features/transportation/routes/TransportationRoutesManagement';
import { StudentsTransportationManagement } from './features/transportation/users/StudentsTransportationManagement';
import { StudentAbsences } from './features/users/student-absences/StudentAbsences';
import { Users } from './features/users/Users';
import { useAuth } from './hooks/auth/useAuth';
import { Role } from './utils/enums/role.enum';
import { ScopeAccess } from './utils/enums/scope-access.enum';
interface AuthenticatedRouteProps {
  children: ReactNode;
}
const AuthenticatedRoute = ({ children }: AuthenticatedRouteProps) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated() ? (
    <>{children}</>
  ) : (
    <Navigate
      to={`/auth?continueTo=[${location.pathname}${location.search}]`}
      replace
    />
  );
};

const App: React.FC = () => {
  const { authState } = useAuth();
  const location = useLocation();
  return (
    <main>
      <Routes>
        <Route path="/" element={<Navigate to="/auth" />} />
        <Route path="/auth" element={<AuthLayout />}>
          <Route index element={<Login />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="set-password" element={<SetPassword />} />
        </Route>
        <Route
          path=":role/complete-profile"
          element={<CompleteProfileLayout />}
        />
        <Route path="/admission">
          <Route path="application" element={<ApplicationForm />} />
        </Route>
        <Route
          path="/dashboard"
          element={
            <AuthenticatedRoute>
              <DashboardLayout />
            </AuthenticatedRoute>
          }
        >
          <Route
            index
            element={
              <Navigate
                to={authState?.role === Role.Admin ? 'users' : 'profile'}
              />
            }
          />
          {authState?.role === Role.Admin && (
            <>
              <Route path="users" element={<Users />}></Route>
              <Route path="groups">
                <Route index element={<Groups />} />
                <Route path=":id/members" element={<GroupMembers />}></Route>
              </Route>
              <Route path="settings" element={<AdminSettings />}></Route>
              <Route path="transportation">
                <Route index element={<TransportationRoutesManagement />} />
                <Route
                  path="routes"
                  element={<TransportationRoutesManagement />}
                />
                <Route
                  path="students"
                  element={<StudentsTransportationManagement />}
                />
              </Route>
              <Route path="food-management">
                <Route index element={<AdminFoodManagement />} />
                <Route
                  path=":id/weekly-report"
                  element={<WeeklyMenuReports />}
                />
              </Route>
              <Route path="taxes">
                <Route path="food" element={<AdminFoodTaxes />}></Route>
                <Route
                  path="transportation"
                  element={<TransportationTaxes />}
                ></Route>
                <Route
                  path="school-taxes"
                  element={<AdminSchoolTaxesReview />}
                ></Route>
              </Route>
              <Route path="evaluation-plans">
                <Route path="all" element={<AllEvaluationPlans />} />
                <Route path="my" element={<MyEvaluationPlans />} />
                <Route path="students">
                  <Route index element={<StudentsToEvaluate />} />
                  <Route
                    path=":id/evaluations"
                    element={<StudentEvaluations />}
                  />
                </Route>
                <Route
                  path="create-edit"
                  element={<CreateEditEvaluationPlans />}
                />
                <Route
                  path="create-edit/:id"
                  element={<CreateEditEvaluationPlans />}
                />
                <Route path="evaluate" element={<SelectEvaluationPlan />} />
                <Route path="evaluate/:id" element={<EvaluationProcess />} />
                <Route path=":id" element={<ReviewEvaluationPlan />} />
              </Route>
              <Route path="term-reports">
                <Route path="my-reports">
                  <Route index element={<TermReports />} />
                  <Route
                    path="approve-edit/:id"
                    element={<ApproveEditReport />}
                  />
                </Route>
                <Route
                  path="create-edit/:id"
                  element={<CreateEditTermReport />}
                />
                <Route path="student-reports" element={<StudentReports />} />
                <Route
                  path="student-reports/:id"
                  element={<CreateEditTermReportSection />}
                />
              </Route>
              <Route path="student-absences" element={<StudentAbsences />} />
            </>
          )}
          {authState?.role === Role.Parent && (
            <>
              <Route path="profile" element={<ParentProfile />}></Route>
              <Route path="food-management">
                <Route index element={<ParentFoodManagement />}></Route>
                <Route
                  path="meal-choice"
                  element={<PreviewSaveMealChoice />}
                ></Route>
              </Route>
              <Route path="payments">
                <Route index element={<Payments />}></Route>
                <Route path="response">
                  <Route index element={<PaymentResponse />}></Route>
                  <Route path=":id" element={<PaymentResponse />}></Route>
                </Route>

                <Route path="food" element={<FoodPayment />}></Route>
                <Route path="transport">
                  <Route index element={<TransportPayment />} />
                  <Route path="history" element={<TransportationHistory />} />
                </Route>
                <Route path="school-taxes">
                  <Route index element={<ParentSchoolTaxes />} />
                  <Route
                    path="pre-payment/:id/:name"
                    element={<SchoolTaxPayment />}
                  />
                  <Route
                    path="history"
                    element={<SchoolTaxesHistory />}
                  ></Route>
                </Route>
              </Route>
              <Route
                path="transportation-plan"
                element={<TransportationPlan />}
              />
              <Route path="taxes">
                <Route path="school-taxes"></Route>
              </Route>
              <Route
                path="transportation-plan"
                element={<TransportationPlan />}
              />
              <Route path="term-reports" element={<ParentTermReports />} />
            </>
          )}
          {authState?.role === Role.Employee && (
            <>
              <Route path="profile" element={<EmployeeProfile />}></Route>
              {authState?.scope?.includes(ScopeAccess.Transport) && (
                <>
                  <Route path="settings" element={<EmployeeSettings />}></Route>
                  <Route path="taxes">
                    <Route
                      path="transportation"
                      element={<TransportationTaxes />}
                    ></Route>
                  </Route>

                  <Route path="transportation">
                    <Route index element={<TransportationRoutesManagement />} />
                    <Route
                      path="routes"
                      element={<TransportationRoutesManagement />}
                    />
                    <Route
                      path="students"
                      element={<StudentsTransportationManagement />}
                    />
                  </Route>
                </>
              )}
              {authState?.scope?.includes(ScopeAccess.StudentPlans) && (
                <>
                  <Route path="groups">
                    <Route index element={<Groups />} />
                    <Route
                      path=":id/members"
                      element={<GroupMembers />}
                    ></Route>
                  </Route>
                  <Route path="evaluation-plans">
                    <Route path="all" element={<AllEvaluationPlans />} />
                    <Route path="my" element={<MyEvaluationPlans />} />
                    <Route path="students">
                      <Route index element={<StudentsToEvaluate />} />
                      <Route
                        path=":id/evaluations"
                        element={<StudentEvaluations />}
                      />
                    </Route>
                    <Route
                      path="create-edit"
                      element={<CreateEditEvaluationPlans />}
                    />
                    <Route
                      path="create-edit/:id"
                      element={<CreateEditEvaluationPlans />}
                    />
                    <Route path="evaluate" element={<SelectEvaluationPlan />} />
                    <Route
                      path="evaluate/:id"
                      element={<EvaluationProcess />}
                    />
                    <Route path=":id" element={<ReviewEvaluationPlan />} />
                  </Route>
                  <Route path="term-reports">
                    <Route path="my-reports" element={<TermReports />} />
                    <Route
                      path="create-edit/:id"
                      element={<CreateEditTermReport />}
                    />
                    <Route
                      path="student-reports"
                      element={<StudentReports />}
                    />
                    <Route
                      path="student-reports/:id"
                      element={<CreateEditTermReportSection />}
                    />
                  </Route>
                  <Route
                    path="student-absences"
                    element={<StudentAbsences />}
                  />
                </>
              )}
            </>
          )}
        </Route>
        <Route
          path="*"
          element={
            authState?.accessToken ? (
              <NotFound />
            ) : (
              <Navigate
                to={`/auth?continueTo=[${location.pathname}${location.search}]`}
              />
            )
          }
        ></Route>
      </Routes>
    </main>
  );
};

export default App;
