import { useQuery } from '@tanstack/react-query';
import type { EmployeeDetailsDto } from '../../../models/users/employees/employee-details.dto';
import apiRequest from '../../../services/apiWrapper';
import { mapStringToSimpleDate } from '../../../services/mapStringToSimpleDate';

async function getEmployeeById(id: string | null) {
  const response = await apiRequest.get<EmployeeDetailsDto>(
    `/api/users/employees/${id}`
  );

  return response.data;
}

export function useGetEmployeeById(id: string | null, enabled: boolean = true) {
  return useQuery({
    queryKey: ['employee', id],
    queryFn: () => getEmployeeById(id),
    select: (data) => ({
      ...data,
      birthDate: mapStringToSimpleDate(data.birthDate),
      diplomaIssueDate: mapStringToSimpleDate(data.diplomaIssueDate),
    }),
    enabled,
  });
}
