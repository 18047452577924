import { useQuery } from '@tanstack/react-query';
import type { EvaluationPlansDetailsDto } from '../../models/evaluation-plans/evaluation-plans-details.dto';
import apiRequest from '../../services/apiWrapper';
import { EVALUATION_PLANS_URL } from '../../utils/constants/urlConstants';

async function getEvaluationPlan(id: string | undefined) {
  const response = await apiRequest.get<EvaluationPlansDetailsDto>(
    `${EVALUATION_PLANS_URL}/${id}`
  );

  return response.data;
}

export function useGetEvaluationPlanById(id: string | undefined) {
  return useQuery({
    queryKey: ['evaluation-plans', id],
    queryFn: () => getEvaluationPlan(id),
    enabled: Boolean(id),
  });
}
