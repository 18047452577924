import { CloseOutline } from '@carbon/icons-react';
import { Button, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useCancelSchoolTaxPayment } from '../../../../../hooks/taxes/school-taxes/parent/history/useCancelSchoolTaxPayment';
import { useModal } from '../../../../../hooks/useModal';
import { createPaginatedQueryKeys } from '../../../../../services/createPaginatedQueryKeys';
import { CONFIRM_PAYMENT_CANCELLATION } from '../../../../../utils/constants/users/modals';
import { Dialog } from '../../../../shared/components/Dialog';

export function ConfirmPaymentCancellationModal() {
  const { t } = useTranslation();
  const notification = useNotifications();
  const { close: onCloseModal } = useModal(CONFIRM_PAYMENT_CANCELLATION);
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const amount = searchParams.get('amount');
  const name = searchParams.get('name');

  const cancelPayment = useCancelSchoolTaxPayment();
  const onCancelPayment = () => {
    cancelPayment
      .mutateAsync(id!)
      .then(() => {
        notification.success(t('TAXES.DIALOGS.MESSAGES.PAYMENT_CANCELLED'));
        queryClient.invalidateQueries({
          queryKey: [
            'school-taxes-history',
            createPaginatedQueryKeys(searchParams),
          ],
        });
        onCloseModal();
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };
  return (
    <Dialog
      dialogHeader={t('TAXES.DIALOGS.TITLES.CONFIRM_PAYMENT_CANCELLATION')}
      onClose={() => onCloseModal()}
      actionButton={
        <Button onClick={onCancelPayment}>{t('ACTIONS.CONFIRM')}</Button>
      }
    >
      <div>
        <CloseOutline className="dialog__cancellation__icon" />
      </div>
      {t('TAXES.DIALOGS.LABELS.CONFIRM_PAYMENT_CANCELLATION', {
        name,
        amount,
      })}
    </Dialog>
  );
}
