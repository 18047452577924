import { useQuery } from '@tanstack/react-query';
import type { PaginatedList } from '../../../models/common/paginated-list.model';
import type { StudentTransportationRequestsDto } from '../../../models/transportation/students/student-transportation-requests.dto';
import apiRequest from '../../../services/apiWrapper';
import { createPaginatedQueryKeys } from '../../../services/createPaginatedQueryKeys';
import { TRANSPORTATION_BUS_STOPS_URL } from '../../../utils/constants/urlConstants';

export async function fetchTransportationRequests(
  searchParams: URLSearchParams
) {
  const response = await apiRequest.get<
    PaginatedList<StudentTransportationRequestsDto>
  >(`${TRANSPORTATION_BUS_STOPS_URL}/requests?${searchParams.toString()}`);

  return response.data;
}

export function createQueryKey(searchParams: URLSearchParams) {
  return {
    name: searchParams.get('name'),
    status: searchParams.get('status'),
    ...createPaginatedQueryKeys(searchParams),
  };
}

export function usePaginatedStudentsTransportationRequests(
  searchParams: URLSearchParams
) {
  return useQuery({
    queryKey: ['transportation-requests', createQueryKey(searchParams)],
    queryFn: () => fetchTransportationRequests(searchParams),
    throwOnError: false,
  });
}
