import { useQuery } from '@tanstack/react-query';
import type { ParentsUpdateFormDto } from '../../../models/users/parents/parent-update.dto';
import publicApiRequest from '../../../services/publicApiWrapper';
import { PUBLIC_PARENT_URL } from '../../../utils/constants/urlConstants';

async function getParentData(invitationId: string | null) {
  const response = await publicApiRequest.get<ParentsUpdateFormDto>(
    `${PUBLIC_PARENT_URL}?invitationId=${invitationId}`
  );

  return response.data;
}

export function useGetPublicParentById(
  invitationId: string | null,
  enabled: boolean = true
) {
  return useQuery({
    queryKey: ['public-parent', invitationId],
    queryFn: () => getParentData(invitationId),
    enabled,
    select: (data) => ({
      ...data,
      parents: data.parents.map((parent) => ({
        ...parent,
        userProfileId: parent.id,
      })),
    }),
  });
}
