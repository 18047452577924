import { Add, TrashCan } from '@carbon/icons-react';
import { Button, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGetAdminSettings } from '../../../hooks/settings/admin/useGetAdminSettings';
import { useUpdateAdminSettings } from '../../../hooks/settings/admin/useUpdateAdminSettings';
import type { AdminSettingsFormDto } from '../../../models/settings/admin/admin-settings-form.dto';
import type { AdminSettingsDto } from '../../../models/settings/admin/admin-settings.dto';
import { Input } from '../../../utils/components/hoc-components';
import {
  getCommonValidators,
  VALIDATION as V,
} from '../../../utils/inputValidators';

export function EmployeeSettings() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data: settings } = useGetAdminSettings();
  const notification = useNotifications();
  const updateSettings = useUpdateAdminSettings();

  const methods = useForm<AdminSettingsFormDto>({
    values: settings,
  });

  const { append, remove } = useFieldArray({
    control: methods.control,
    name: 'transportSettings.additionalReceivers',
  });

  const additionalReceiversWatch = methods.watch(
    'transportSettings.additionalReceivers'
  );

  const onAddNewReceiver = (index: number) => {
    append({ name: '', id: index });
  };

  const onDeleteReceiver = (index: number) => {
    remove(index);
  };

  const onSubmit = async (data: AdminSettingsFormDto) => {
    const filteredReceivers =
      data.transportSettings?.additionalReceivers?.filter(
        (receiver) => receiver.name
      );
    const formData: AdminSettingsDto = {
      ...data,
      transportSettings: {
        ...data.transportSettings,
        additionalReceivers: filteredReceivers.length
          ? filteredReceivers.map((receiver) => receiver.name)
          : null,
      },
    } as {} as AdminSettingsDto;
    updateSettings
      .mutateAsync(formData)
      .then(() => {
        notification.success(t('SETTINGS.MESSAGES.SETTINGS_UPDATED'));
        queryClient.invalidateQueries({ queryKey: ['admin-settings'] });
      })
      .catch((error) =>
        notification.danger(t(`NETWORK_ERRORS.${error.errors[0]}`))
      );
  };

  const settingsBaseRules = getCommonValidators([
    V.REQUIRED,
    V.NUMBER_PATTERN,
    V.MAX_LENGTH,
  ]);

  return (
    <FormProvider {...methods}>
      <form
        className="employee-settings"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <div className="employee-settings__section pb-4">
          <h2 className="employee-settings__section__title">
            {t('SETTINGS.TITLES.TRANSPORT')}
          </h2>
          <div className="employee-settings__section__item settings__section__item-1">
            <span className="employee-settings__section__item__label">
              {t('SETTINGS.LABELS.TRANSPORT_FEE_ONE_WAY')}
            </span>
            <Input
              name="transportSettings.oneWayMonthlyTax"
              className="employee-settings__section__item__input"
              rules={{
                ...settingsBaseRules,
                validate: {
                  value: (value) => {
                    return (
                      (+value > 0 ||
                        t('SETTINGS.ERRORS.POSITIVE_NUMBER_ONLY')) ??
                      ''
                    );
                  },
                },
              }}
            />
          </div>
          <div className="employee-settings__section__item settings__section__item-2">
            <span className="employee-settings__section__item__label settings__section__item__label-extend">
              {t('SETTINGS.LABELS.TRANSPORTATION_COMPANY')}
            </span>
            <Input
              name="transportSettings.transportationCompanyEmail"
              className="employee-settings__section__item__input settings__section__item__input-expand"
              rules={getCommonValidators([
                V.REQUIRED,
                V.MAX_LENGTH,
                V.EMAIL_PATTERN,
              ])}
            />
          </div>
          <div className="employee-settings__section__item settings__section__item-3">
            <span className="employee-settings__section__item__label">
              {t('SETTINGS.LABELS.TRANSPORT_FEE_TWO_WAY')}
            </span>
            <Input
              name="transportSettings.twoWayMonthlyTax"
              className="employee-settings__section__item__input"
              rules={{
                ...settingsBaseRules,
                validate: {
                  value: (value) => {
                    return (
                      (+value > 0 ||
                        t('SETTINGS.ERRORS.POSITIVE_NUMBER_ONLY')) ??
                      ''
                    );
                  },
                },
              }}
            />
          </div>
          {additionalReceiversWatch?.length > 0 &&
            additionalReceiversWatch.map((field, index) => (
              <div
                className="employee-settings__section__item settings__section__item-5 settings__section__item settings__section__item-right"
                key={index}
              >
                {index === 0 && (
                  <span className="employee-settings__section__item__label settings__section__item__label-extend settings__section__item__label-not-required">
                    {t('SETTINGS.LABELS.ADDITIONAL_RECEIVERS')}
                  </span>
                )}

                <>
                  <Input
                    key={field.id}
                    name={`transportSettings.additionalReceivers.${index}.name`}
                    className="employee-settings__section__item__input settings__section__item__input-expand"
                    rules={getCommonValidators([V.EMAIL_PATTERN, V.MAX_LENGTH])}
                  />
                  {index !== 0 && (
                    <button
                      type="button"
                      className="employee-settings__section__item__trash"
                      onClick={() => onDeleteReceiver(index)}
                    >
                      <TrashCan className="employee-settings__section__item__trash__icon" />
                    </button>
                  )}
                  <button
                    type="button"
                    className="employee-settings__section__item__add"
                    onClick={() => onAddNewReceiver(index)}
                  >
                    <Add className="employee-settings__section__item__add__icon" />
                    <span>{t('ACTIONS.ADD_MORE_RECEIVERS')}</span>
                  </button>
                </>
              </div>
            ))}
        </div>
        <div className="employee-settings__actions">
          <Button
            className="employee-settings__actions__button"
            type="submit"
            disabled={updateSettings.isPending}
          >
            {t('ACTIONS.SAVE')}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
