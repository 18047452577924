export const Scholarship = {
  NO_SCHOLARSHIP: 0,
  FULL_SCHOLARSHIP: 1,
  PARTIAL_SCHOLARSHIP: 2,
} as const;

export type ScholarshipType = (typeof Scholarship)[keyof typeof Scholarship];

export const ScholarshipTranslations = {
  [Scholarship.NO_SCHOLARSHIP]: 'NO_SCHOLARSHIP',
  [Scholarship.FULL_SCHOLARSHIP]: 'FULL_SCHOLARSHIP',
  [Scholarship.PARTIAL_SCHOLARSHIP]: 'PARTIAL_SCHOLARSHIP',
} as const;
