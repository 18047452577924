import { Button, Input, Textarea, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../hooks/auth/useAuth';
import { useApproveEvaluationPlanById } from '../../hooks/evaluation-plans/useApproveEvaluationPlanById';
import { useGetEvaluationPlanById } from '../../hooks/evaluation-plans/useGetEvaluationPlanById';
import { useModal } from '../../hooks/useModal';
import { DENY_EVALUATION_PLAN } from '../../utils/constants/evaluation-plans/modals';
import { EvaluationPlanStatus } from '../../utils/enums/evaluation-plan-status.enum';
import { Role } from '../../utils/enums/role.enum';

export const ReviewEvaluationPlan = () => {
  const { id } = useParams();
  const { authState } = useAuth();
  const navigate = useNavigate();
  const notification = useNotifications();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const isAdmin = authState?.role === Role.Admin;

  const { open: onOpenDenyPlanModal } = useModal(DENY_EVALUATION_PLAN);
  const { data: evaluationPlan } = useGetEvaluationPlanById(id);
  const approveEvaluationPlan = useApproveEvaluationPlanById();

  const onApproveEvaluationPlan = () => {
    approveEvaluationPlan
      .mutateAsync(id!)
      .then(() => {
        notification.success(
          t('EVALUATION_PLANS.MESSAGES.SUCCESSFULLY_APPROVED')
        );
        queryClient.invalidateQueries({
          queryKey: ['my-evaluation-plans'],
        });
        queryClient.invalidateQueries({
          queryKey: ['evaluation-plans'],
        });
        navigate(-1);
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  const onDenyEvaluationPlan = () => {
    onOpenDenyPlanModal({ id: id! });
  };

  return (
    <div className="evaluation-plans evaluation-plans-review">
      <h2>{evaluationPlan?.name}</h2>
      <div className="evaluation-plans__form">
        <section className="evaluation-plans__form__basic-info">
          <Textarea
            disabled
            value={evaluationPlan?.description}
            label={t('EVALUATION_PLANS.FORM.LABELS.DESCRIPTION') ?? ''}
            rows={5}
          />
        </section>
        {evaluationPlan?.strands.map((strand, index) => (
          <section className="evaluation-plans__form__strand" key={index}>
            <div className="evaluation-plans__form__strand__name">
              <Input
                disabled
                value={strand.name}
                label={t('EVALUATION_PLANS.FORM.LABELS.STRAND') ?? ''}
                className="evaluation-plans__form__strand__name__input"
              />
            </div>
            <div className="evaluation-plans__form__strand__criteria">
              {strand.criterias.map((criteria, i) => (
                <div
                  className="evaluation-plans__form__strand__criteria__item"
                  key={i}
                >
                  <div className="evaluation-plans__form__strand__criteria__item__name">
                    <Input
                      disabled
                      value={criteria.name}
                      label={t('EVALUATION_PLANS.FORM.LABELS.CRITERIA') ?? ''}
                      className="evaluation-plans__form__strand__criteria__item__name__input"
                    />
                  </div>
                  <hr className="evaluation-plans__form__strand__criteria__hr" />
                  <Input
                    disabled
                    value={criteria.exceedsExpectations}
                    label={
                      t('EVALUATION_PLANS.FORM.LABELS.EXCEEDS_EXPECTATIONS') ??
                      ''
                    }
                  />
                  <Input
                    disabled
                    value={criteria.meetsExpectations}
                    label={
                      t('EVALUATION_PLANS.FORM.LABELS.MEETS_EXPECTATIONS') ?? ''
                    }
                  />
                  <Input
                    disabled
                    value={criteria.approachingExpectations}
                    label={
                      t(
                        'EVALUATION_PLANS.FORM.LABELS.APPROACHING_EXPECTATIONS'
                      ) ?? ''
                    }
                  />
                  <Input
                    disabled
                    value={criteria.belowExpectations}
                    label={
                      t('EVALUATION_PLANS.FORM.LABELS.BELOW_EXPECTATIONS') ?? ''
                    }
                  />
                </div>
              ))}
            </div>
          </section>
        ))}
      </div>
      <div className="evaluation-plans__form__actions">
        <Button
          variant="outlined"
          className="evaluation-plans__form__actions__cancel"
          onClick={() => navigate(-1)}
        >
          {t('ACTIONS.BACK')}
        </Button>
        {isAdmin &&
          evaluationPlan?.status === EvaluationPlanStatus.PendingApproval && (
            <>
              <Button color="danger" onClick={onDenyEvaluationPlan}>
                {t('ACTIONS.DENY')}
              </Button>
              <Button onClick={onApproveEvaluationPlan}>
                {t('ACTIONS.APPROVE')}
              </Button>
            </>
          )}
      </div>
    </div>
  );
};
