import { Button, useNotifications, type SelectOption } from '@ph-react-ui/core';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useCreateParentRequest } from '../../../../hooks/transportation/routes/useCreateParentRequest';
import { useGetBusRoutesByStudentId } from '../../../../hooks/transportation/routes/useGetBusRoutesByStudentId';
import { useModal } from '../../../../hooks/useModal';
import type { TransportationRequestDto } from '../../../../models/transportation/parent/transportation-request.dto';
import type { StudentTransportationUpdateFormDto } from '../../../../models/transportation/students/student-transportation-update-form.dto';
import { Select } from '../../../../utils/components/hoc-components';
import { TRANSPORTATION_REQUEST } from '../../../../utils/constants/transportation/modals';
import { TransportationFormDirection } from '../../../../utils/enums/transportation-form-direction.enum';
import { TransportationUsage } from '../../../../utils/enums/transportation-usage.enum';
import { Dialog } from '../../../shared/components/Dialog';
import { TravelDirectionRadioGroup } from '../../common/TravelDirectionRadioGroup';

export function TransportationRequest() {
  const notification = useNotifications();
  const [busStopsOptions, setBusStopOptions] = useState<SelectOption<string>[]>(
    []
  );
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const studentId = searchParams.get('studentId');
  const { close } = useModal(TRANSPORTATION_REQUEST);

  const { data } = useGetBusRoutesByStudentId({
    routeId: '',
    studentId: studentId!,
  });
  const sendRequest = useCreateParentRequest();

  const methods = useForm({
    defaultValues: {
      travelDirection: TransportationUsage.TwoWay,
      route: '',
      pickUpStop: {
        id: '',
        address: '',
      },
      dropOffStop: {
        id: '',
        address: '',
      },
    },
  });
  const travelDirection = methods.watch('travelDirection');
  const route = methods.watch('route');

  const routeSelectOptions = data?.routeStops.map((route) => ({
    label: route.route.name,
    value: route.route.id,
  }));

  const showPickUpSelect = (+travelDirection === TransportationUsage.TwoWay ||
    +travelDirection === TransportationUsage.OneWay) as boolean;

  const showDropOffSelect = (+travelDirection === TransportationUsage.TwoWay ||
    +travelDirection === TransportationUsage.OneWay) as boolean;

  useEffect(() => {
    if (route) {
      const selectedRoute = data?.routeStops.find(
        (routeOption) => routeOption.route.id === route
      );
      if (selectedRoute && selectedRoute.stops) {
        const busStopOptions = selectedRoute.stops?.map((stop) => ({
          label: stop.address,
          value: stop.id,
        }));
        setBusStopOptions(busStopOptions);
      } else {
        setBusStopOptions([]);
      }
    } else {
      const allRoutes = data?.routeStops.map((routeOption) => ({
        label: routeOption.route.name,
        value: routeOption.route.id,
      }));
      setBusStopOptions(allRoutes!);
    }
  }, [data?.routeStops, route]);

  const createFormData = (
    data: StudentTransportationUpdateFormDto
  ): TransportationRequestDto => {
    const formData = {
      studentId: studentId!,
    };
    switch (+data.travelDirection) {
      case TransportationUsage.OneWay:
        return {
          ...formData,
          pickUpStopId:
            data.direction === TransportationFormDirection.Pick &&
            data.pickUpStop?.id
              ? data.pickUpStop.id
              : null,
          dropOffStopId:
            data.direction === TransportationFormDirection.Drop &&
            data.dropOffStop?.id
              ? data.dropOffStop?.id
              : null,
        };
      case TransportationUsage.TwoWay:
        return {
          ...formData,
          pickUpStopId: data.pickUpStop?.id ? data.pickUpStop.id : null,
          dropOffStopId: data.dropOffStop?.id ? data.dropOffStop?.id : null,
        };
      default: {
        return {
          ...formData,
          pickUpStopId: null,
          dropOffStopId: null,
        };
      }
    }
  };

  const onSubmit = (data: StudentTransportationUpdateFormDto) => {
    const formData = createFormData(data);
    sendRequest
      .mutateAsync(formData)
      .then(() => {
        close();
        notification.success(
          t('TRANSPORTATION_MANAGEMENT.MESSAGES.REQUEST_SENT')
        );
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  return (
    <Dialog
      onClose={() => close()}
      dialogHeader={t(
        'TRANSPORTATION_MANAGEMENT.DRAWER.TITLES.TRANSPORTATION_USAGE_REQUEST'
      )}
      actionButton={
        <Button type="submit" onClick={methods.handleSubmit(onSubmit)}>
          {t('ACTIONS.SUBMIT_FOR_APPROVAL')}
        </Button>
      }
      className="dialog__transportation-request"
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="drawer__transportation-management__form__radio-group">
            <TravelDirectionRadioGroup travelDirection={travelDirection} />
          </div>
          <div className="drawer__transportation-management__form__selects">
            {Boolean(+travelDirection) && (
              <Select
                clearable
                options={routeSelectOptions}
                name="route"
                label={t('TRANSPORTATION_MANAGEMENT.DRAWER.LABELS.ROUTE') ?? ''}
                placeholder={
                  t('TRANSPORTATION_MANAGEMENT.DRAWER.PLACEHOLDERS.ROUTE') ?? ''
                }
              />
            )}
            {+travelDirection === TransportationUsage.OneWay && (
              <>
                <Select
                  name="direction"
                  label={
                    t('TRANSPORTATION_MANAGEMENT.DRAWER.LABELS.DIRECTION') ?? ''
                  }
                  placeholder={
                    t(
                      'TRANSPORTATION_MANAGEMENT.DRAWER.PLACEHOLDERS.SELECT_DIRECTION'
                    ) ?? ''
                  }
                  options={[
                    {
                      value: TransportationFormDirection.Pick,
                      label: t(
                        'TRANSPORTATION_MANAGEMENT.DRAWER.LABELS.PICK_UP'
                      ),
                    },
                    {
                      value: TransportationFormDirection.Drop,
                      label: t(
                        'TRANSPORTATION_MANAGEMENT.DRAWER.LABELS.DROP_OFF'
                      ),
                    },
                  ]}
                />
                <Select
                  clearable
                  name="pickUpStop.id"
                  disabled={!route}
                  rules={{
                    required: {
                      value: showPickUpSelect,
                      message: t('ERROR.VALIDATION.REQUIRED'),
                    },
                  }}
                  label={
                    t('TRANSPORTATION_MANAGEMENT.DRAWER.LABELS.PICKUP_TIME') ??
                    ''
                  }
                  placeholder={
                    t(
                      'TRANSPORTATION_MANAGEMENT.DRAWER.PLACEHOLDERS.PICK_UP'
                    ) ?? ''
                  }
                  options={busStopsOptions}
                />
              </>
            )}
            {+travelDirection === TransportationUsage.TwoWay && (
              <>
                <Select
                  clearable
                  name="pickUpStop.id"
                  disabled={!route}
                  rules={{
                    required: {
                      value: showPickUpSelect,
                      message: t('ERROR.VALIDATION.REQUIRED'),
                    },
                  }}
                  label={
                    t('TRANSPORTATION_MANAGEMENT.DRAWER.LABELS.PICKUP_TIME') ??
                    ''
                  }
                  placeholder={
                    t(
                      'TRANSPORTATION_MANAGEMENT.DRAWER.PLACEHOLDERS.PICK_UP'
                    ) ?? ''
                  }
                  options={busStopsOptions}
                />
                <Select
                  clearable
                  disabled={!route}
                  name="dropOffStop.id"
                  rules={{
                    required: {
                      value: showDropOffSelect,
                      message: t('ERROR.VALIDATION.REQUIRED'),
                    },
                  }}
                  label={
                    t('TRANSPORTATION_MANAGEMENT.DRAWER.LABELS.DROPOFF_TIME') ??
                    ''
                  }
                  placeholder={
                    t(
                      'TRANSPORTATION_MANAGEMENT.DRAWER.PLACEHOLDERS.DROP_OFF'
                    ) ?? ''
                  }
                  options={busStopsOptions}
                />
              </>
            )}
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
}
