import { useQuery } from '@tanstack/react-query';
import type { ParentUpdateFormDto } from '../../../models/users/parents/parent-update.dto';
import apiRequest from '../../../services/apiWrapper';

async function getParentById(id: string | null) {
  const response = await apiRequest.get<ParentUpdateFormDto>(
    `/api/users/parents/${id}/family`
  );

  return response.data;
}

export function useGetParentById(id: string | null, enabled: boolean = true) {
  return useQuery({
    queryKey: ['parent', id],
    queryFn: () => getParentById(id),
    select: (data) => ({
      ...data,
      parents: data.parents.map((parent) => ({
        ...parent,
        userProfileId: parent.id,
      })),
    }),
    enabled,
  });
}
