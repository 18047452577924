import { useQuery } from '@tanstack/react-query';
import type { EvaluationPlansList } from '../../models/evaluation-plans/evaluation-plans-details.dto';
import apiRequest from '../../services/apiWrapper';
import { EVALUATIONS_URL } from '../../utils/constants/urlConstants';

async function getEvaluationPlansPerStudent(id: string | undefined) {
  const response = await apiRequest.get<EvaluationPlansList>(
    `${EVALUATIONS_URL}/students/${id}/plans`
  );

  return response.data;
}

export function useGetEvaluationPlansPerStudent(id: string | undefined) {
  return useQuery({
    queryKey: ['evaluation-plans-per-student', id],
    queryFn: () => getEvaluationPlansPerStudent(id),
    enabled: Boolean(id),
  });
}
