import { useMutation } from '@tanstack/react-query';
import apiRequest from '../../../services/apiWrapper';
import { TERM_REPORT_SECTIONS_URL } from '../../../utils/constants/urlConstants';

async function submitTermReportSectionById(id: string | null) {
  return apiRequest.put(`${TERM_REPORT_SECTIONS_URL}/${id}/submit`, {});
}

export function useSubmitTermReportSectionById() {
  return useMutation({
    mutationFn: (id: string | null) => submitTermReportSectionById(id),
  });
}
