import { QueryClient } from '@tanstack/react-query';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retryDelay: 500,
      staleTime: 1000 * 60 * 30,
      throwOnError: true,
    },
  },
});

export default queryClient;
