import { Button } from '@ph-react-ui/core';
import type { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from '../../../shared/components/Dialog';

interface DeleteConfirmationDialogProps {
  title: string;
  actionButtonTitle?: string;
  paragraphText: string;
  unassignAction: Dispatch<SetStateAction<string[]>>;
  handleRemove: () => void;
}

export function DeleteConfirmationDialog({
  title,
  actionButtonTitle,
  paragraphText,
  unassignAction,
  handleRemove,
}: DeleteConfirmationDialogProps) {
  const { t } = useTranslation();
  return (
    <Dialog
      dialogHeader={title}
      onClose={() => unassignAction([])}
      actionButton={
        <Button onClick={handleRemove}>
          {actionButtonTitle || t('ACTIONS.DELETE')}
        </Button>
      }
    >
      <h2>{paragraphText}</h2>
    </Dialog>
  );
}
