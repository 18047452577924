import { Button, useNotifications } from '@ph-react-ui/core';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useCreateTermReport } from '../../../hooks/term-reports/useCreateTermReport';
import { useGetTermReportById } from '../../../hooks/term-reports/useGetTermReportById';
import { useSubmitTermReportForApprovalById } from '../../../hooks/term-reports/useSubmitTermReportForApprovalById';
import { useUpdateTermReportById } from '../../../hooks/term-reports/useUpdateTermReport';
import type { TermReportFormDto } from '../../../models/term-reports/term-report.dto';
import queryClient from '../../../services/queryClient';
import { Input, TextEditor } from '../../../utils/components/hoc-components';
import { TermReportStatus } from '../../../utils/enums/term-report-status.enum';
import {
  getCommonValidators,
  VALIDATION as V,
} from '../../../utils/inputValidators';
import { CancellationDialog } from '../../shared/components/CancellationDialog';
import { AssignedSectionFieldArray } from './AssignedSectionFieldArray';

export const CreateEditTermReport = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const notification = useNotifications();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const reportId = searchParams.get('reportId');
  const [isCancellationDialogOpen, setIsCancellationDialogOpen] =
    useState(false);

  const { data: report } = useGetTermReportById(reportId);

  const createTermReport = useCreateTermReport();
  const updateTermReport = useUpdateTermReportById(reportId);
  const submitReportForApproval = useSubmitTermReportForApprovalById();

  const methods = useForm<TermReportFormDto>({
    defaultValues: {
      title: '',
      introContent: '',
      sections: [],
      outroContent: '',
    },
    values: report,
  });

  const onNavigateBack = () => {
    navigate(-1);
  };

  const onDiscardChanges = () => {
    if (methods.formState.isDirty) {
      setIsCancellationDialogOpen(true);
    } else {
      onNavigateBack();
    }
  };

  const onSaveTermReport = (data: TermReportFormDto, shouldSubmit: boolean) => {
    if (reportId) {
      updateTermReport
        .mutateAsync(data)
        .then(() => {
          queryClient.invalidateQueries({
            queryKey: ['student-term-reports'],
          });
          queryClient.invalidateQueries({
            queryKey: ['term-report', reportId],
          });
          notification.success(t('TERM_REPORTS.MESSAGES.SUCCESSFULLY_EDIT'));
        })
        .catch((resError) => {
          notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
        });
    } else {
      const hasEmptyFields = Object.values(methods.watch()).some(
        (section) => section === null
      );
      const hasEmptySectionsFields = !methods
        .watch('sections')
        .every((section) => section.assignedToId && section.name);
      if (shouldSubmit && methods.watch('sections').length === 0) {
        notification.danger(t('TERM_REPORTS.MESSAGES.ADD_SECTION'));
        return null;
      } else if (shouldSubmit && (hasEmptySectionsFields || hasEmptyFields)) {
        notification.danger(t('TERM_REPORTS.MESSAGES.FILL_IN_SECTIONS'));
        return null;
      }
      createTermReport
        .mutateAsync({
          report: {
            ...data,
            studentId: id!,
            sections: data.sections.map((section) => ({
              assignedToId: section.assignedToId,
              name: section.name,
            })),
          },
          shouldSubmit,
        })
        .then(() => {
          queryClient.invalidateQueries({
            queryKey: ['term-reports'],
          });
          queryClient.invalidateQueries({
            queryKey: ['student-term-reports', id],
          });
          onNavigateBack();
          notification.success(
            shouldSubmit
              ? t('TERM_REPORTS.MESSAGES.SUCCESSFULLY_CREATED_AND_SUBMITTED')
              : t('TERM_REPORTS.MESSAGES.SUCCESSFULLY_CREATED')
          );
        })
        .catch((resError) => {
          notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
        });
    }
  };

  const onSubmitForApproval = () => {
    const hasEmptyFields = Object.values(methods.watch()).some(
      (section) => section === null
    );
    const hasEmptySectionsFields = !methods
      .watch('sections')
      .every((section) => section.assignedToId && section.name);
    if (hasEmptyFields || hasEmptySectionsFields) {
      notification.danger(t('TERM_REPORTS.MESSAGES.FILL_IN_SECTIONS'));
      return null;
    }
    submitReportForApproval
      .mutateAsync(reportId)
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ['student-term-reports'],
        });
        queryClient.invalidateQueries({
          queryKey: ['term-report', reportId],
        });
        onNavigateBack();
        notification.success(t('TERM_REPORTS.MESSAGES.SUCCESSFULLY_SUBMITTED'));
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  return (
    <FormProvider {...methods}>
      <form className="term-reports">
        <div className="term-reports__form">
          <Input
            name="title"
            label={t('TERM_REPORTS.FORM.TITLE') ?? ''}
            placeholder={t('TERM_REPORTS.FORM.TITLE') ?? ''}
            className="term-reports__form__input-title"
            rules={getCommonValidators([V.MAX_LENGTH, V.REQUIRED])}
          />
          <TextEditor
            name="introContent"
            label={t('TERM_REPORTS.FORM.INTRO') ?? ''}
            placeholder={t('TERM_REPORTS.FORM.INTRO') ?? ''}
          />
          <AssignedSectionFieldArray control={methods.control} />
          <TextEditor
            name="outroContent"
            label={t('TERM_REPORTS.FORM.OUTRO') ?? ''}
            placeholder={t('TERM_REPORTS.FORM.OUTRO') ?? ''}
          />
        </div>
        <div className="term-reports__form__actions">
          <Button
            variant="outlined"
            className="term-reports__form__actions__cancel"
            onClick={onDiscardChanges}
          >
            {t('ACTIONS.CANCEL')}
          </Button>
          <Button
            onClick={methods.handleSubmit((data) =>
              onSaveTermReport(data, false)
            )}
          >
            {t('ACTIONS.SAVE')}
          </Button>
          {reportId ? (
            <Button
              disabled={
                report?.status === TermReportStatus.Submitted ||
                methods.formState.isDirty
              }
              onClick={methods.handleSubmit(onSubmitForApproval)}
            >
              {t('ACTIONS.SUBMIT_FOR_APPROVAL')}
            </Button>
          ) : (
            <Button
              onClick={methods.handleSubmit((data) =>
                onSaveTermReport(data, true)
              )}
            >
              {t('ACTIONS.SAVE_AND_SUBMIT_FOR_APPROVAL')}
            </Button>
          )}
        </div>
      </form>
      {isCancellationDialogOpen && (
        <CancellationDialog onDiscardChanges={onNavigateBack} />
      )}
    </FormProvider>
  );
};
