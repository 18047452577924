import { useQuery } from '@tanstack/react-query';
import type { PaymentResponseDto } from '../../models/payments/payment-response.dto';
import apiRequest from '../../services/apiWrapper';
import { PAYMENTS_URL } from '../../utils/constants/urlConstants';

async function getPaymentResponse(id: string | undefined) {
  const response = await apiRequest.get<PaymentResponseDto>(
    `${PAYMENTS_URL}/transactions/${id}`
  );

  return response.data;
}

export function useGetPaymentResponse(id: string | undefined) {
  return useQuery({
    queryKey: ['payment-response'],
    queryFn: () => getPaymentResponse(id),
    throwOnError: false,
  });
}
