import { useMutation } from '@tanstack/react-query';
import type { AdminUpdateDto } from '../../../models/users/admins/admin-update.dto';
import apiRequest from '../../../services/apiWrapper';
import { ADMINS_URL } from '../../../utils/constants/urlConstants';

async function updateAdminById(
  id: string | null,
  updateEmployeeData: AdminUpdateDto
) {
  return apiRequest.put(`${ADMINS_URL}/${id}`, updateEmployeeData);
}

export function useUpdateAdminById(id: string | null) {
  return useMutation({
    mutationFn: (updateEmployeeData: AdminUpdateDto) =>
      updateAdminById(id, updateEmployeeData),
  });
}
