import { useTranslation } from 'react-i18next';
import { Checkbox } from '../../../../../utils/components/hoc-components';

export const EmployeePermissionsSection = () => {
  const { t } = useTranslation();

  return (
    <div className="mb-12 drawer__users__form__employee__permissions">
      <Checkbox
        name="canCreateStudentPlans"
        className=" drawer__users__form__employee__permissions__item"
      >
        {t('USERS.DRAWER.LABELS.CAN_CREATE_STUDENTS_PLANS')}
      </Checkbox>
      <Checkbox
        name="canOrganizeTransport"
        className=" drawer__users__form__employee__permissions__item"
      >
        {t('USERS.DRAWER.LABELS.CAN_ORGANISE_TRANSPORT')}
      </Checkbox>
      <Checkbox
        name="canAccessStudentAbsences"
        className=" drawer__users__form__employee__permissions__item"
      >
        {t('USERS.DRAWER.LABELS.RECEIVE_ABSENCE_DOCUMENTS')}
      </Checkbox>
    </div>
  );
};
