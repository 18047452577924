import { Checkmark, TrashCan, Upload, Warning } from '@carbon/icons-react';
import { Button } from '@ph-react-ui/core';
import type { FieldArrayMethodProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { RenderArgs } from '../../../models/files/file-upload';
import type { FileDetailsDto } from '../../../models/users/files/file-details.dto';
import {
  DOCUMENTS_ALLOWED_EXTENSIONS,
  MAX_DOCUMENTS_FILE_SIZE,
} from '../../../utils/constants/files/filesConstants';
import type { DocumentType } from '../../../utils/enums/document-type.enum';
import { isValidFileExtension } from '../../../utils/files/isValidFileExtension';
import { isValidFileSize } from '../../../utils/files/isValidFileSize';
import { BaseFileUpload } from './BaseFileUpload';

interface MandatoryFileUploadProps {
  title: string;
  fileType: DocumentType;
  invitationId: string;
  appendFile: (
    value: FileDetailsDto | FileDetailsDto[],
    options?: FieldArrayMethodProps | undefined
  ) => void;
  isAlreadyAvailable?: boolean;
}

export function MandatoryFileUpload({
  title,
  fileType,
  invitationId,
  appendFile,
  isAlreadyAvailable = false,
}: MandatoryFileUploadProps) {
  const { t } = useTranslation();

  const renderHeader = (args: RenderArgs, isAlreadyAvailable: boolean) => {
    return (
      <>
        <h4 className="upload__file__required__item__title">{title}</h4>
        {(args.filled && !args.error) || isAlreadyAvailable ? (
          <Checkmark className="upload__file__required__item__icon upload__file__required__item__icon__absolute upload__file__required__item__icon-success" />
        ) : (
          <Warning className="upload__file__required__item__icon upload__file__required__item__icon__absolute upload__file__required__item__icon-warning" />
        )}

        <Upload className="upload__file__required__item__icon" />

        <p className="upload__file__required__item__text">
          {args.file ? args.file.name : t('UPLOAD_FILES.MESSAGES.CHOOSE_FILE')}
        </p>
      </>
    );
  };

  const renderFooter = ({
    onFileSelected,
    file,
    filled,
    error,
    onRemoveFile,
    emitFileUpload,
    isUploading,
    setFilled,
  }: RenderArgs) => {
    const onFileUpload = async () => {
      if (file && fileType !== null) {
        const data = await emitFileUpload(file, fileType);
        if (data) {
          const { id } = data;
          onSuccessfulFileUpload(id, file, fileType);
        }
      }
    };

    const onSuccessfulFileUpload = (
      id: string,
      file: File,
      fileType: DocumentType
    ) => {
      setFilled(true);
      appendFile({
        id: id,
        fileName: file.name,
        fileType: fileType,
        uploadDate: new Date().toString(),
        shouldDisplay: false,
      });
    };

    return (
      <>
        {file ? (
          filled ? (
            <Button
              compact
              type="button"
              onClick={onFileSelected}
              disabled={isUploading}
            >
              {t('UPLOAD_FILES.ACTIONS.REATTACH')}
            </Button>
          ) : (
            <div className="upload__file__required__item__actions">
              <Button
                compact
                type="button"
                onClick={onFileUpload}
                disabled={isUploading || Boolean(error)}
              >
                {t('UPLOAD_FILES.ACTIONS.UPLOAD')}
              </Button>
              <button
                className="upload__file__text__button 
              upload__file__required__item__icon 
              upload__file__required__item__icon-trash"
                onClick={() => {
                  onRemoveFile();
                }}
              >
                <TrashCan />
              </button>
            </div>
          )
        ) : (
          <Button
            compact
            type="button"
            onClick={onFileSelected}
            disabled={isUploading}
          >
            {t('UPLOAD_FILES.ACTIONS.ATTACH')}
          </Button>
        )}
      </>
    );
  };

  return (
    <BaseFileUpload
      invitationId={invitationId}
      allowedTypes=".doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .pdf, .jpg, .jpeg, .png"
      renderFooter={renderFooter}
      renderHeader={(args) => renderHeader(args, isAlreadyAvailable)}
      validationFns={[
        (file: File) => isValidFileSize(file, MAX_DOCUMENTS_FILE_SIZE),
        (file: File) =>
          isValidFileExtension(file, DOCUMENTS_ALLOWED_EXTENSIONS),
      ]}
      validationErrors={['DOCUMENT_FILE_TOO_LARGE', 'INVALID_EXTENSION']}
    />
  );
}
