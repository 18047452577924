import { ChevronLeft, ChevronRight } from '@carbon/icons-react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useGetSchoolYearBaseSettings } from '../../../hooks/settings/useGetSchoolYearBaseSettings';
import { MONTH_NAMES } from '../../../utils/constants/dateContstants';

interface MonthPaginationProps {
  month: string | null;
  year: string | null;
  action?: () => void;
}

export function MonthPagination({ month, year, action }: MonthPaginationProps) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: schoolYearData, isSuccess } = useGetSchoolYearBaseSettings();

  const onPrevMonth = () => {
    if (month && year) {
      if (+month === 1) {
        const prevYear = (+year - 1).toString();
        searchParams.set('month', '12');
        searchParams.set('year', prevYear);
      } else {
        let prevMonth = (+month - 1).toString();
        // skip the months outside school year
        if (
          schoolYearData?.startOfSchoolYear &&
          schoolYearData.endOfSchoolYear &&
          prevMonth === schoolYearData.startOfSchoolYear?.month.toString()
        ) {
          prevMonth = (schoolYearData.endOfSchoolYear?.month + 1).toString();
        }
        searchParams.set('year', year);
        searchParams.set('month', prevMonth);
      }
      action?.();
      setSearchParams(searchParams);
    }
  };

  const onNextMonth = () => {
    if (month && year) {
      if (+month === 12) {
        const nextYear = (+year + 1).toString();
        searchParams.set('month', '1');
        searchParams.set('year', nextYear);
      } else {
        let nextMonth = (+month + 1).toString();
        // skip the months outside school year
        if (
          schoolYearData?.startOfSchoolYear &&
          schoolYearData.endOfSchoolYear &&
          month === (schoolYearData.endOfSchoolYear?.month + 1).toString()
        ) {
          nextMonth = (schoolYearData.startOfSchoolYear?.month + 1).toString();
        }
        searchParams.set('year', year);
        searchParams.set('month', nextMonth);
      }
      action?.();
      setSearchParams(searchParams);
    }
  };
  if (!month && !year) {
    const currentDate = new Date();
    month = (currentDate.getMonth() + 1).toString();
    year = currentDate.getFullYear().toString();
  }
  return month && year ? (
    <div className="month-pagination">
      <button
        type="button"
        className="month-pagination__button"
        onClick={() => onPrevMonth()}
        disabled={!isSuccess}
      >
        <ChevronLeft />
      </button>
      <button
        type="button"
        className="month-pagination__button"
        onClick={() => onNextMonth()}
        disabled={!isSuccess}
      >
        <ChevronRight />
      </button>

      <span>
        {t(`MONTHS.${MONTH_NAMES[+month]}`)} {year}
      </span>
    </div>
  ) : null;
}
