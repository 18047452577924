import { NotificationProvider } from '@ph-react-ui/core';
import {
  QueryClientProvider,
  QueryErrorResetBoundary,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import axios from 'axios';
import type { ReactNode } from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from 'react-router-dom';
import App from './app';
import './i18n';
import { ErrorView } from './features/error/ErrorView';
import { AuthProvider } from './hooks/auth/useAuth';
import queryClient from './services/queryClient';

axios.defaults.baseURL = import.meta.env.VITE_REACT_APP_API;

const root = ReactDOM.createRoot(document.getElementById('root')!);

function QueryBoundaries({ children }: { children: ReactNode }) {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary onReset={reset} FallbackComponent={ErrorView}>
          {children}
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}

root.render(
  <BrowserRouter basename="">
    <QueryClientProvider client={queryClient}>
      <NotificationProvider>
        <AuthProvider>
          <QueryBoundaries>
            <App />
          </QueryBoundaries>
        </AuthProvider>
      </NotificationProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  </BrowserRouter>
);
