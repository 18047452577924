import { Button, Checkbox, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useCancellationDialog } from '../../../hooks/useCancellationDialog';
import { useModal } from '../../../hooks/useModal';
import { useArchiveUsers } from '../../../hooks/users/useArchiveUser';
import { useGetRelatives } from '../../../hooks/users/useGetRelatives';
import { useUnarchiveUsers } from '../../../hooks/users/useUnarchiveUser';
import { ARCHIVE_USER } from '../../../utils/constants/users/modals';
import { Dialog } from '../../shared/components/Dialog';

export const ToggleArchiveUser = () => {
  const notification = useNotifications();
  const { close: closeModal } = useModal(ARCHIVE_USER);
  const queryClient = useQueryClient();
  const { open: openCancellationDialog } = useCancellationDialog();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('userId');
  const role = searchParams.get('userRole');
  const modal = searchParams.get('modal');

  const { t } = useTranslation();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const isUnarchiving = modal === 'unarchive-user';

  const archiveUsers = useArchiveUsers();
  const unarchiveUsers = useUnarchiveUsers();
  const { data } = useGetRelatives(id!, +role!, isUnarchiving);

  const hasRelatives = data?.parents.length || data?.students.length;

  const handleArchive = () => {
    const mutation = isUnarchiving ? unarchiveUsers : archiveUsers;
    mutation
      .mutateAsync({
        userIds: [id!, ...selectedIds],
        includeRelatives: false,
      })
      .then(() => {
        closeModal();
        notification.success(t('USERS.ARCHIVE.ALERT.USER_UPDATED'));
        queryClient.invalidateQueries({ queryKey: ['users'] });
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  const handleCheckboxChange = (id: string) => {
    setSelectedIds((prevState) => {
      if (prevState.includes(id)) {
        return prevState.filter((relativeId) => relativeId !== id);
      } else {
        return [...prevState, id];
      }
    });
  };

  const onCloseModal = () => {
    if (selectedIds.length > 0) {
      openCancellationDialog();
    } else {
      closeModal();
    }
  };

  const translationAction = isUnarchiving ? 'UNARCHIVE' : 'ARCHIVE';

  return (
    <Dialog
      onClose={() => onCloseModal()}
      dialogHeader={t(`USERS.ACTIONS.${translationAction}`)}
      actionButton={
        <Button onClick={handleArchive}>{t('ACTIONS.CONFIRM')}</Button>
      }
    >
      {!hasRelatives ? (
        <h2>{t(`USERS.ARCHIVE.HEADING.ARE_YOU_SURE_${translationAction}`)}</h2>
      ) : (
        <>
          <h2>{t(`USERS.ARCHIVE.HEADING.WHAT_DO_YOU_${translationAction}`)}</h2>
          <div className="dialog__confirm__content__related">
            <>
              {data?.parents.map((parent) => (
                <Checkbox
                  key={parent.id}
                  checked={selectedIds.includes(parent.id)}
                  onChange={() => handleCheckboxChange(parent.id)}
                >
                  {t('USERS.TABLE.ROLE.PARENT')}: {parent.name}
                </Checkbox>
              ))}
              {data?.students.map((student) => (
                <Checkbox
                  key={student.id}
                  checked={selectedIds.includes(student.id)}
                  onChange={() => handleCheckboxChange(student.id)}
                >
                  {t('USERS.TABLE.ROLE.STUDENT')}: {student.name}
                </Checkbox>
              ))}
            </>
          </div>
        </>
      )}
    </Dialog>
  );
};
