import { useMutation } from '@tanstack/react-query';
import type { BusRouteMapDTO } from '../../../models/transportation/routes/bus-routes-map';
import apiRequest from '../../../services/apiWrapper';
import { BUS_ROUTES_URL } from '../../../utils/constants/urlConstants';

async function editBusRouteMap(file: BusRouteMapDTO) {
  return apiRequest.put(`${BUS_ROUTES_URL}/map-image`, file);
}

export function useEditBusRouteMap() {
  return useMutation({
    mutationFn: (file: BusRouteMapDTO) => editBusRouteMap(file),
  });
}
