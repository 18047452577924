import { useQuery } from '@tanstack/react-query';
import type { StudentEvaluationScoresListDto } from '../../models/evaluation-plans/student-evaluation-scores-list.dto';
import apiRequest from '../../services/apiWrapper';
import { STUDENT_EVALUATIONS_URL } from '../../utils/constants/urlConstants';

async function getAllEvaluationScoresByStudentId(id: string | undefined) {
  const response = await apiRequest.get<StudentEvaluationScoresListDto[]>(
    `${STUDENT_EVALUATIONS_URL}/${id}/evaluations`
  );

  return response.data;
}

export function useGetAllEvaluationScoresByStudentId(id: string | undefined) {
  return useQuery({
    queryKey: ['student-evaluation-scores', id],
    queryFn: () => getAllEvaluationScoresByStudentId(id),
    enabled: Boolean(id),
  });
}
