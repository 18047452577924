import { Outlet } from 'react-router-dom';
import Logo from '../../../../assets/icons/logo-denis-diderot.svg';

export function AuthLayout() {
  return (
    <div className="auth">
      <img src={Logo} alt="logo" className="auth__logo" />
      <div className="auth__window">
        <Outlet />
      </div>
    </div>
  );
}
