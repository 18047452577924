import { Button, Table } from '@ph-react-ui/core';
import { format } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useSearchParams } from 'react-router-dom';
import { usePaginatedStudentsTransportationUsage } from '../../../hooks/transportation/students/usePaginatedStudentsTransportationUsage';
import { useModal } from '../../../hooks/useModal';
import type { StudentTransportationUsageDto } from '../../../models/transportation/students/student-transportation-usage.dto';
import { MANAGE_USER_ROUTES_MODAL } from '../../../utils/constants/transportation/modals';
import { TRAVEL_DIRECTION } from '../../../utils/constants/transportation/travel-direction';
import { TransportationUserManagementOrdering } from '../../../utils/enums/transportation-user-management-ordering.enum';
import { Pagination } from '../../shared/components/Pagination';
import { SearchInput } from '../../shared/components/SearchByNameInput';
import { TableSkeleton } from '../../shared/components/skeletons/TableSkeleton';
import { ThSortItem } from '../../shared/components/tables/ThSortItem';

export function StudentsTransportationInUsage() {
  const pathMatch = useMatch('/dashboard/transportation/students');
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const { open: openManageUserRoutesModal } = useModal(
    MANAGE_USER_ROUTES_MODAL
  );

  const { data, isSuccess, isPending, isError } =
    usePaginatedStudentsTransportationUsage(searchParams);

  const headers = [
    <div className="users__table__select-all">
      <ThSortItem
        title={t('USERS.TABLE.HEADERS.NAME')}
        ordering={TransportationUserManagementOrdering.Name}
      />
    </div>,
    t('TRANSPORTATION_MANAGEMENT.TABLE.HEADERS.TYPE'),
    <ThSortItem
      title={t('TRANSPORTATION_MANAGEMENT.TABLE.HEADERS.ROUTE')}
      ordering={TransportationUserManagementOrdering.Route}
    />,
    t('TRANSPORTATION_MANAGEMENT.TABLE.HEADERS.PICK_UP'),
    t('TRANSPORTATION_MANAGEMENT.TABLE.HEADERS.DROP_OFF'),
    <ThSortItem
      title={t('TRANSPORTATION_MANAGEMENT.TABLE.HEADERS.APPROVED_ON')}
      ordering={TransportationUserManagementOrdering.ApprovedAt}
    />,
    t('TRANSPORTATION_MANAGEMENT.TABLE.HEADERS.ACTION'),
  ];

  const onManageStudentRoutes = useCallback(
    (studentId: string, name: string) => {
      openManageUserRoutesModal({ studentId, studentName: name });
    },
    [openManageUserRoutesModal]
  );

  const getFullBusStopName = (
    transportation: StudentTransportationUsageDto
  ) => {
    let fullBusName = '';
    if (transportation.routeName) {
      fullBusName += transportation.routeName;
    }
    if (transportation.busNumber) {
      fullBusName += ', ' + transportation.busNumber;
    }
    return fullBusName;
  };

  const tableChildren = useMemo(() => {
    return data?.result.reduce((acc: any, transportationUsage) => {
      let userArray = [
        transportationUsage.student.name,
        transportationUsage.travelDirection
          ? t(
              `TRANSPORTATION_MANAGEMENT.TABLE.LABELS.${
                TRAVEL_DIRECTION[transportationUsage.travelDirection]
              }`
            )
          : '',
        getFullBusStopName(transportationUsage),
        transportationUsage.pickUpAddress,
        transportationUsage.dropOffAddress,
        transportationUsage.approvedAt
          ? format(new Date(transportationUsage.approvedAt), 'dd.MM.yyyy')
          : '',
        <div className="taxes__food__table__actions">
          <Button
            type="button"
            compact
            onClick={() =>
              onManageStudentRoutes(
                transportationUsage.student.id,
                transportationUsage.student.name
              )
            }
          >
            {t('ACTIONS.EDIT')}
          </Button>
        </div>,
      ];

      return [...acc, userArray];
    }, []);
  }, [t, onManageStudentRoutes, data?.result]);

  return (
    <div className="transportation-management__users">
      <div className="taxes__food__filter">
        <SearchInput
          pathMatch={pathMatch}
          placeholder={t('TAXES.FILTERS.SEARCH_BY_NAME')}
        />
      </div>

      {isSuccess && data.result && (
        <>
          <Table headers={headers} rows={tableChildren} />
          <div className="users__pagination">
            <Pagination data={data} />
          </div>
        </>
      )}
      {isError && t('TAXES.MESSAGES.NO_FOOD_TAXES')}
      {isPending && <TableSkeleton headers={headers} />}
    </div>
  );
}
