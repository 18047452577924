import { useQuery } from '@tanstack/react-query';
import type { PaginatedList } from '../../models/common/paginated-list.model';
import type { UsersDto } from '../../models/users/users.dto';
import apiRequest from '../../services/apiWrapper';
import { createPaginatedQueryKeys } from '../../services/createPaginatedQueryKeys';
import { USERS_PROFILES_URL } from '../../utils/constants/urlConstants';

async function fetchUsers(searchParams: URLSearchParams) {
  const response = await apiRequest.get<PaginatedList<UsersDto>>(
    `${USERS_PROFILES_URL}?${searchParams.toString()}`
  );
  return response.data;
}

function createQueryKey(searchParams: URLSearchParams) {
  return {
    role: searchParams.get('role'),
    name: searchParams.get('name'),
    ...createPaginatedQueryKeys(searchParams),
  };
}

export function usePaginatedUsers(searchParams: URLSearchParams) {
  return useQuery({
    queryKey: ['users', createQueryKey(searchParams)],
    queryFn: () => fetchUsers(searchParams),
  });
}
