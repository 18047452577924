import { useMutation } from '@tanstack/react-query';
import apiRequest from '../../services/apiWrapper';
import { USERS_PROFILES_URL } from '../../utils/constants/urlConstants';

interface UnarchiveUsersDto {
  userIds: string[];
  includeRelatives: boolean;
}

async function unarchiveUsers(userIds: string[], includeRelatives: boolean) {
  return apiRequest.put<UnarchiveUsersDto, void>(
    `${USERS_PROFILES_URL}/unarchive`,
    {
      userIds,
      includeRelatives,
    }
  );
}

export function useUnarchiveUsers() {
  return useMutation({
    mutationFn: ({ userIds, includeRelatives }: UnarchiveUsersDto) =>
      unarchiveUsers(userIds, includeRelatives),
  });
}
