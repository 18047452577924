export enum ParentType {
  Mother,
  Father,
  Other,
}

export enum ParentSectionType {
  MOTHER = 'MOTHER_',
  FATHER = 'FATHER_',
}
