export enum TermReportStatus {
  SentToParent,
  Submitted,
  Approved,
  Rejected,
  New,
}

export enum TermReportSectionStatus {
  New,
  Submitted,
  Approved,
  Rejected,
}
