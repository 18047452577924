import { DropPhoto, TrashCan } from '@carbon/icons-react';
import { Button, Dialog } from '@ph-react-ui/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { RenderArgs } from '../../../models/files/file-upload';
import { MAX_DOCUMENTS_FILE_SIZE } from '../../../utils/constants/files/filesConstants';
import { DocumentType } from '../../../utils/enums/document-type.enum';
import { isValidFileExtension } from '../../../utils/files/isValidFileExtension';
import { isValidFileSize } from '../../../utils/files/isValidFileSize';
import { BaseFileUpload } from './BaseFileUpload';

interface SimpleFileUploadModalPropTypes {
  allowedTypes: string;
  header: string;
  onCloseModal: () => void;
  onUpload: (fileId: string) => void;
  withPreview?: boolean;
  allowedTypesMessage: string;
  fileMaxSize: string;
  validFileExtensions: string[];
}

export function SimpleFileUploadModal({
  allowedTypes,
  header,
  onCloseModal,
  onUpload,
  withPreview = false,
  allowedTypesMessage,
  fileMaxSize,
  validFileExtensions,
}: SimpleFileUploadModalPropTypes) {
  const { t } = useTranslation();
  const [image, setImage] = useState('');

  const renderHeader = ({ file, onRemoveFile, error }: RenderArgs) => {
    const handlePreview = () => {
      if (file && !error) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          setImage(reader.result as string);
        };
      }
      return image ? (
        <div className="dialog__simple-file-upload__img-preview">
          <img src={image} alt={image} />
        </div>
      ) : null;
    };

    return (
      <>
        <h3 className="upload__file__title">
          {t('UPLOAD_FILES.TITLES.ATTACH_DOCUMENTS')}
        </h3>
        {(!file || !withPreview) && (
          <DropPhoto className="upload__file__icon upload__file__icon-small" />
        )}
        {withPreview && handlePreview()}
        <div className="upload__file__text">
          {file ? (
            <div className="upload__file__text-selected">
              {file.name}
              <button
                className="upload__file__text__button"
                onClick={() => {
                  onRemoveFile();
                  setImage('');
                }}
              >
                <TrashCan />
              </button>
            </div>
          ) : (
            <div>{t('UPLOAD_FILES.MESSAGES.CHOOSE_FILE')}</div>
          )}
        </div>
      </>
    );
  };

  const renderFooter = ({
    onFileSelected,
    file,
    error,
    isUploading,
    emitFileUpload,
  }: RenderArgs) => {
    const onFileUpload = async () => {
      if (file) {
        const data = await emitFileUpload(file, DocumentType.Any);
        if (data) {
          const { id } = data;
          onUpload(id);
          onCloseModal();
        }
      }
    };

    return (
      <>
        <div className="upload__file__actions">
          {file ? (
            <Button
              compact
              type="button"
              onClick={onFileUpload}
              disabled={isUploading || Boolean(error)}
            >
              {t('UPLOAD_FILES.ACTIONS.UPLOAD')}
            </Button>
          ) : (
            <Button
              compact
              type="button"
              onClick={onFileSelected}
              disabled={isUploading}
            >
              {t('UPLOAD_FILES.ACTIONS.ATTACH')}
            </Button>
          )}
        </div>
        <div className="upload__file__text mt-5">
          <p className="mb-2">{allowedTypesMessage}</p>
          <p>{fileMaxSize}</p>
        </div>
      </>
    );
  };
  return (
    <Dialog
      className="dialog__simple-file-upload"
      onClose={onCloseModal}
      header={header}
    >
      <BaseFileUpload
        allowedTypes={allowedTypes}
        renderHeader={renderHeader}
        renderFooter={renderFooter}
        validationFns={[
          (file: File) => isValidFileSize(file, MAX_DOCUMENTS_FILE_SIZE),
          (file: File) => isValidFileExtension(file, validFileExtensions),
        ]}
        validationErrors={['DOCUMENT_FILE_TOO_LARGE', 'INVALID_EXTENSION']}
        withSuccessNotification={false}
      />
    </Dialog>
  );
}
