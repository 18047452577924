import { useMutation } from '@tanstack/react-query';
import type { StudentUpdateProfileDto } from '../../../models/users/students/student-update-profile.dto';
import apiRequest from '../../../services/apiWrapper';
import { STUDENTS_URL } from '../../../utils/constants/urlConstants';

async function updateStudentProfile(student: StudentUpdateProfileDto) {
  return apiRequest.put(`${STUDENTS_URL}/${student.id}/profile`, student);
}
export function useUpdateStudentProfile() {
  return useMutation({
    mutationFn: (student: StudentUpdateProfileDto) =>
      updateStudentProfile(student),
  });
}
