import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

export interface SelectAll {
  items: {
    id: string;
    value: boolean;
    name: string;
    disabled: boolean;
  }[];
}

export function useSelectAll<T extends { id: string; name: string }>(
  data: T[] | undefined,
  filterField?: string
) {
  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
    getValues,
    setValue,
    reset,
    watch,
  } = useForm({
    values: {
      items: data?.map((item) => ({
        id: item.id,
        name: item.name,
        value: false,
        disabled: filterField ? item[filterField as keyof {}] : false,
      })),
    } as SelectAll,
  });

  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = useCallback(
    (checked: boolean) => {
      getValues('items').forEach((group, index) => {
        setValue(`items.${index}.value`, group.disabled ? false : checked, {
          shouldDirty: true,
        });
      });
      setSelectAll(checked);
    },
    [getValues, setValue]
  );

  useEffect(() => {
    const subscription = watch((value) => {
      if (value.items?.every((item) => item?.value)) {
        setSelectAll(true);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const getSelectedItems = useCallback(() => {
    return getValues('items')
      ?.filter((item) => item.value)
      .map((item) => item.id);
  }, [getValues]);

  useEffect(() => {
    setSelectAll(false);
    reset();
  }, [data, reset]);

  const values = useMemo(() => {
    return {
      handleSelectAll,
      handleSubmit,
      dirtyFields,
      control,
      selectAll,
      getSelectedItems,
      watch,
    };
  }, [
    handleSelectAll,
    handleSubmit,
    dirtyFields,
    control,
    selectAll,
    getSelectedItems,
    watch,
  ]);

  return values;
}
