import { useMutation } from '@tanstack/react-query';
import type { MarkAsPaidDto } from '../../../../models/taxes/mark-as-paid.dto';
import apiRequest from '../../../../services/apiWrapper';
import { SCHOOL_TAX_PAYMENTS } from '../../../../utils/constants/urlConstants';

async function markAsPaid(data: MarkAsPaidDto) {
  return apiRequest.post(`${SCHOOL_TAX_PAYMENTS}/students/bank-transfer`, data);
}

export function useMarkSchoolTaxesAsPaid() {
  return useMutation({
    mutationFn: (data: MarkAsPaidDto) => markAsPaid(data),
  });
}
