import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface Params {
  [key: string]: string;
}

export function useModal(name?: string) {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const open = useCallback(
    (params?: Params) => {
      if (params) {
        Object.entries(params).forEach(([param, value]) => {
          searchParams.set(param, value);
        });
      }
      searchParams.set('modal', name ?? '');
      queryClient.setQueryData(['modal'], params);
      setSearchParams(searchParams);
    },
    [searchParams, name, queryClient, setSearchParams]
  );

  const close = useCallback(
    (navigateBack = false) => {
      const params: Params | undefined = queryClient.getQueryData(['modal']);

      if (params) {
        Object.keys(params).forEach((param) => {
          searchParams.delete(param);
        });
      }
      queryClient.invalidateQueries({ queryKey: ['modal'] });
      if (navigateBack) {
        navigate(-1);
      } else {
        searchParams.delete('modal');
        setSearchParams(searchParams, { replace: true });
      }
    },
    [queryClient, searchParams, navigate, setSearchParams]
  );

  const isOpen = useMemo(() => {
    return name === searchParams.get('modal');
  }, [name, searchParams]);

  return { open, close, isOpen };
}
