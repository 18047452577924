import { ChevronLeft } from '@carbon/icons-react';
import { Button, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGetAuthenticatedParentProfile } from '../../../hooks/profile/parent/useGetAuthenticatedParentProfile';
import { useUpdateAuthenticatedParentProfile } from '../../../hooks/profile/parent/useUpdateAuthenticatedParentProfile';
import { useUpdateStudentProfile } from '../../../hooks/profile/student/useUpdateStudentProfile';
import { useModal } from '../../../hooks/useModal';
import { useGetStudentsForParent } from '../../../hooks/users/parents/useGetStudentsForParent';
import type {
  ParentsUpdateDto,
  ParentUpdateFormUserProfileIdDto,
} from '../../../models/users/parents/parent-update.dto';
import type { StudentUpdateProfileDto } from '../../../models/users/students/student-update-profile.dto';
import { mapSimpleDateToString } from '../../../services/mapSimpleDateToString';
import { Input } from '../../../utils/components/hoc-components';
import { REGISTER_AN_ABSENCE } from '../../../utils/constants/users/modals';
import { ParentSectionType } from '../../../utils/enums/parent-type.enum';
import { formatIbanNumber } from '../../../utils/formatIbanNumber';
import {
  getCommonValidators,
  VALIDATION as V,
} from '../../../utils/inputValidators';
import { QuestionsAnswers } from '../../admission/application/Q&A';
import { FileUpload } from '../../shared/components/FileUpload';
import { FormSkeleton } from '../../shared/components/skeletons/FormSkeleton';
import { FilesTable } from '../../users/create-edit/forms/common/FilesTable';
import { ParentInvoicingSection } from '../../users/create-edit/forms/common/ParentInvoicingSection';
import { ParentSection } from '../../users/create-edit/forms/common/ParentSection';
import { StudentSection } from '../../users/create-edit/forms/common/StudentSection';

export function ParentProfile() {
  const { t } = useTranslation();
  const [dropdownArray, setDropdownArray] = useState<boolean[]>([]);
  const notification = useNotifications();
  const queryClient = useQueryClient();
  const { open: openAbsenceModal } = useModal(REGISTER_AN_ABSENCE);
  const { data: parent, isLoading: isLoadingParent } =
    useGetAuthenticatedParentProfile();

  const { data: students, isLoading: isLoadingStudents } =
    useGetStudentsForParent({
      enabled: Boolean(parent),
      excludeArchived: false,
    });

  const createDropdownArray = useCallback(() => {
    const falsyArray = Array(students?.length).fill(false);
    setDropdownArray(falsyArray);
  }, [students]);

  useEffect(() => {
    if (students && students?.length > 0) {
      createDropdownArray();
    }
  }, [students, createDropdownArray]);

  const onShowMore = (index: number) => {
    const newArray = [...dropdownArray];
    newArray[index] = !dropdownArray[index];
    setDropdownArray(newArray);
  };

  const updateAuthenticatedParent = useUpdateAuthenticatedParentProfile();

  const updateStudent = useUpdateStudentProfile();

  const parentMethods = useForm({ values: parent });

  const studentsMethods = useForm({
    values: { students },
  });

  const { append: appendFileToTable, remove: removeFileFromTable } =
    useFieldArray({
      control: parentMethods.control,
      name: 'familyInformation.files',
      keyName: 'familyInformation.files',
    });

  const files = parentMethods.watch('familyInformation.files');
  const receiveInvoice = parentMethods.watch(
    'familyInformation.invoiceInformation.shouldReceiveInvoice'
  );

  useFieldArray({
    control: studentsMethods?.control,
    name: 'students',
    keyName: 'studentId',
  });

  const onUpdateParent = (data: ParentUpdateFormUserProfileIdDto) => {
    const { familyInformation, ...rest } = data;
    const formData: ParentsUpdateDto = {
      ...rest,
      familyInformation: {
        id: familyInformation.id,
        notes: familyInformation.notes,
        iban: formatIbanNumber(familyInformation.iban),
        invoiceInformation: familyInformation.invoiceInformation,
        fileIds: familyInformation.files.map((file) => file.id),
      },
    };

    updateAuthenticatedParent
      .mutateAsync(formData)
      .then(() => {
        notification.success(t('USERS.DRAWER.MESSAGES.PARENT_UPDATED'));
        queryClient.invalidateQueries({ queryKey: ['authenticated-parent'] });
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  const onUpdateStudent = async (index: number) => {
    await studentsMethods.trigger(`students.${index}`);

    if (studentsMethods.getFieldState(`students.${index}`).invalid) {
      return;
    }

    const student = studentsMethods.getValues(`students.${index}`);

    const formData: StudentUpdateProfileDto = {
      ...student,
      birthDate: mapSimpleDateToString(student.birthDate),
      additionalInformation: Array.from({ length: 6 }, (_, i) => i + 1).map(
        (num) => ({
          question: t(`ADMISSION.APPLICATION.QUESTIONS.Q${num}`) as string,
          answer: student.additionalInformation![num - 1].answer,
        })
      ),
    };
    updateStudent
      .mutateAsync(formData)
      .then(() => {
        notification.success(t('USERS.DRAWER.MESSAGES.STUDENT_UPDATED'));
        queryClient.invalidateQueries({
          queryKey: ['parent-students'],
        });
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  const onOpenAbsenceModal = useCallback(() => {
    openAbsenceModal({
      // TODO: revisit
      // parentId: parent ? parent.id : '',
      parentId: parent ? parent.familyInformation.id : '',
    });
  }, [openAbsenceModal, parent]);

  if (isLoadingParent || isLoadingStudents) {
    return (
      <div className="mx-8 my-10">
        <FormSkeleton />
      </div>
    );
  }

  return (
    <div className="profile">
      <div className="profile__parent">
        <Button compact onClick={onOpenAbsenceModal}>
          {t('ACTIONS.REGISTER_AN_ABSENCE')}
        </Button>
        <FormProvider {...parentMethods}>
          <h3 className="my-8 drawer__users__form__students__title">
            {t('USERS.DRAWER.TITLES.PARENTS_INFORMATIONS')}
          </h3>
          <h4 className="my-3 drawer__users__form__students__subtitle">
            {t('USERS.DRAWER.TITLES.MOTHER_INFO')}
          </h4>
          <ParentSection
            prefix={'parents[0].'}
            requiredFields={{
              phoneNumber: true,
              address: true,
              workAddress: true,
            }}
            hideMainParentToggle={true}
            type={ParentSectionType.MOTHER}
          />
          <h4 className="my-5 drawer__users__form__students__subtitle">
            {t('USERS.DRAWER.TITLES.FATHER_INFO')}
          </h4>
          <ParentSection
            prefix={'parents[1].'}
            requiredFields={{
              phoneNumber: true,
              address: true,
              workAddress: true,
            }}
            hideMainParentToggle={true}
            type={ParentSectionType.FATHER}
          />
          <ParentInvoicingSection
            shouldReceiveInvoiceWatch={receiveInvoice}
            setReceiveInvoice={() =>
              parentMethods.setValue(
                'familyInformation.invoiceInformation.shouldReceiveInvoice',
                !receiveInvoice
              )
            }
            prefix="familyInformation."
          />
          <div className="my-3">
            <Input
              name="familyInformation.iban"
              rules={getCommonValidators([V.IBAN_PATTERN])}
              className="drawer__users__form__input"
              placeholder={t('USERS.DRAWER.PLACEHOLDERS.IBAN') ?? ''}
              label={t('USERS.DRAWER.LABELS.IBAN') ?? ''}
            />
          </div>
          <div>
            <h3 className="my-8 drawer__users__form__students__title">
              {t('USERS.DRAWER.TITLES.DOCUMENTS')}
            </h3>
            <FilesTable data={files} removeFile={removeFileFromTable} />
            <FileUpload appendFile={appendFileToTable} />
          </div>
          <div className="profile__parent__actions">
            <Button
              className="profile__parent__actions__update"
              form="parent-form"
              onClick={parentMethods.handleSubmit((data) =>
                onUpdateParent(data)
              )}
            >
              {t('ACTIONS.UPDATE')}
            </Button>
          </div>
        </FormProvider>
      </div>
      <div className="profile__students">
        <h3 className="profile__students__title">
          {t('USERS.DRAWER.TITLES.STUDENTS')}
        </h3>
        <FormProvider {...studentsMethods}>
          {students?.map((_, index) => {
            return (
              <form
                id={`students-form-${index}`}
                key={`students-form-${index}`}
                className="profile__students__form"
              >
                {dropdownArray[index] ? (
                  <>
                    <StudentSection
                      formGroupName={`students.${index}.`}
                      requiredFields={{
                        birthDate: true,
                        egn: true,
                        birthplace: true,
                        schoolYear: false,
                        siblings: false,
                        citizenship: true,
                        address: true,
                        gpName: true,
                      }}
                    />
                    <QuestionsAnswers prefix={`students.${index}.`} />
                    <div className="profile__parent__actions">
                      <Button
                        className="profile__parent__actions__update"
                        form="parent-form"
                        onClick={() => onUpdateStudent(index)}
                      >
                        {t('ACTIONS.UPDATE')}
                      </Button>
                    </div>
                  </>
                ) : (
                  <Input
                    label={`${index + 1}. ${t('USERS.DRAWER.TITLES.STUDENT')}`}
                    placeholder="Student"
                    name={`students.${index}.name`}
                  />
                )}
                <Button
                  className={`profile__students__form__arrow ${
                    dropdownArray[index]
                      ? 'profile__students__form__arrow-active'
                      : ''
                  }`}
                  variant="link"
                  color="danger"
                  onClick={() => onShowMore(index)}
                >
                  <ChevronLeft />
                </Button>
              </form>
            );
          })}
        </FormProvider>
      </div>
    </div>
  );
}
