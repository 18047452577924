import type { SelectOption } from '@ph-react-ui/core';
import { Button, Drawer, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useGetBusRoutesWithBusStops } from '../../../../hooks/transportation/routes/useGetBusRoutesWithStops';
import { useGetStudentsBusStopDetails } from '../../../../hooks/transportation/students/useGetStudentsBusStopDetails';
import { useUpdateTransportationPlanForStudent } from '../../../../hooks/transportation/students/useUpdateTransportationPlanForStudent';
import { useCancellationDialog } from '../../../../hooks/useCancellationDialog';
import { useModal } from '../../../../hooks/useModal';
import type { StudentTransportationUpdateFormDto } from '../../../../models/transportation/students/student-transportation-update-form.dto';
import type { TransportationPlanUpdateDto } from '../../../../models/transportation/students/transportation-plan-update.dto';
import { Select } from '../../../../utils/components/hoc-components';
import { MANAGE_USER_ROUTES_MODAL } from '../../../../utils/constants/transportation/modals';
import { TransportationFormDirection } from '../../../../utils/enums/transportation-form-direction.enum';
import { TransportationUsage } from '../../../../utils/enums/transportation-usage.enum';
import { TravelDirection } from '../../../../utils/enums/travel-direction.enum';
import { TravelDirectionRadioGroup } from '../../common/TravelDirectionRadioGroup';

interface ExtenedSelectOption<Type extends string | number>
  extends SelectOption<Type> {
  routeId: string;
}

export function StudentsRoutesDrawer() {
  const { t } = useTranslation();
  const notification = useNotifications();
  const { close: closeModal } = useModal(MANAGE_USER_ROUTES_MODAL);
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const studentId = searchParams.get('studentId');
  const studentName = searchParams.get('studentName');
  const { open: openCancellationDialog } = useCancellationDialog();

  const [pickUpSelectOptions, setPickUpSelectOptions] = useState<
    SelectOption<string>[]
  >([]);
  const [dropOffSelectOptions, setDropOffSelectOptions] = useState<
    SelectOption<string>[]
  >([]);

  const { data: studentBusStops } = useGetStudentsBusStopDetails(studentId);
  const { data: busStopOptions } = useGetBusRoutesWithBusStops({
    routeId: '',
    studentId: '',
  });

  const updateTransportationUsage =
    useUpdateTransportationPlanForStudent(studentId);

  useEffect(() => {
    if (busStopOptions) {
      if (studentBusStops?.pickUpStop) {
        const currentRoute = busStopOptions.find(
          (route: ExtenedSelectOption<string>) =>
            route.value === studentBusStops?.pickUpStop?.id
        )?.routeId;
        setDropOffSelectOptions(
          busStopOptions.filter(
            (option: ExtenedSelectOption<string>) =>
              option.routeId === currentRoute
          )
        );
      } else {
        setDropOffSelectOptions(busStopOptions);
      }
      if (studentBusStops?.dropOffStop) {
        const currentRoute = busStopOptions.find(
          (route: ExtenedSelectOption<string>) =>
            route.value === studentBusStops?.dropOffStop?.id
        )?.routeId;
        setPickUpSelectOptions(
          busStopOptions.filter(
            (option: ExtenedSelectOption<string>) =>
              option.routeId === currentRoute
          )
        );
      } else {
        setPickUpSelectOptions(busStopOptions);
      }
    }
  }, [
    busStopOptions,
    studentBusStops?.dropOffStop,
    studentBusStops?.pickUpStop,
  ]);

  const methods = useForm<StudentTransportationUpdateFormDto>({
    defaultValues: {
      id: studentId ? studentId : '',
      travelDirection: TravelDirection.TwoWay.toString(),
      pickUpStop: {
        id: '',
        address: '',
      },
      dropOffStop: {
        id: '',
        address: '',
      },
    },
    values: {
      ...studentBusStops,
      travelDirection: studentBusStops
        ? studentBusStops.travelDirection?.toString()
        : TravelDirection.TwoWay.toString(),
      direction: studentBusStops?.pickUpStop
        ? TransportationFormDirection.Pick
        : TransportationFormDirection.Drop,
    },
  });

  const travelDirection = methods.watch('travelDirection');
  const direction = methods.watch('direction');
  const pickUpStop = methods.watch('pickUpStop');
  const dropOffStop = methods.watch('dropOffStop');

  useEffect(() => {
    const currentRoute = busStopOptions?.find(
      (route: ExtenedSelectOption<string>) => route.value === pickUpStop?.id
    )?.routeId;

    if (currentRoute) {
      const dropOffOptions = busStopOptions.filter(
        (option: ExtenedSelectOption<string>) => option.routeId === currentRoute
      );
      setDropOffSelectOptions(dropOffOptions);
    } else {
      setDropOffSelectOptions(busStopOptions);
    }
  }, [pickUpStop?.id, busStopOptions]);

  useEffect(() => {
    const currentRoute = busStopOptions?.find(
      (route: ExtenedSelectOption<string>) => route.value === dropOffStop?.id
    )?.routeId;

    if (currentRoute) {
      const pickUpOptions = busStopOptions.filter(
        (option: ExtenedSelectOption<string>) => option.routeId === currentRoute
      );
      setPickUpSelectOptions(pickUpOptions);
    } else {
      setPickUpSelectOptions(busStopOptions);
    }
  }, [dropOffStop?.id, busStopOptions]);

  const { isDirty } = methods.formState;

  const onCloseDrawer = () => {
    if (isDirty) {
      openCancellationDialog();
    } else {
      onCloseModal();
    }
  };

  const showPickUpSelect = (+travelDirection === TransportationUsage.TwoWay ||
    (+travelDirection === TransportationUsage.OneWay &&
      direction === TransportationFormDirection.Pick)) as boolean;

  const showDropOffSelect = (+travelDirection === TransportationUsage.TwoWay ||
    (+travelDirection === TransportationUsage.OneWay &&
      direction === TransportationFormDirection.Drop)) as boolean;

  const onCloseModal = () => {
    closeModal();
  };

  const createFormData = (
    data: StudentTransportationUpdateFormDto
  ): TransportationPlanUpdateDto => {
    const formData = {
      studentIds: [studentId as string],
    };
    switch (+data.travelDirection) {
      case TransportationUsage.OneWay:
        return {
          ...formData,
          pickUpStopId:
            data.direction === TransportationFormDirection.Pick &&
            data.pickUpStop?.id
              ? data.pickUpStop.id
              : null,
          dropOffStopId:
            data.direction === TransportationFormDirection.Drop &&
            data.dropOffStop?.id
              ? data.dropOffStop?.id
              : null,
        };
      case TransportationUsage.TwoWay:
        return {
          ...formData,
          pickUpStopId: data.pickUpStop?.id ? data.pickUpStop.id : null,
          dropOffStopId: data.dropOffStop?.id ? data.dropOffStop?.id : null,
        };
      default: {
        return {
          ...formData,
          pickUpStopId: null,
          dropOffStopId: null,
        };
      }
    }
  };

  const onSubmit = (data: StudentTransportationUpdateFormDto) => {
    const formData = createFormData(data);

    updateTransportationUsage
      .mutateAsync(formData)
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ['bus-routes'] });
        queryClient.invalidateQueries({
          queryKey: ['available-students-bus-stop'],
        });
        queryClient.invalidateQueries({ queryKey: ['students-on-bus-stop'] });
        queryClient.invalidateQueries({ queryKey: ['transportation-usage'] });
        queryClient.invalidateQueries({
          queryKey: ['students-stop-details', studentId],
        });

        onCloseModal();
      })
      .catch((resError) => {
        notification.danger(resError.errors[0]);
      });
  };

  return (
    <Drawer
      header={studentName ?? ''}
      footer={
        <div className="drawer__transportation-management__form__actions">
          <Button
            onClick={onCloseDrawer}
            type="button"
            className="drawer__transportation-management__form__actions__cancel"
            variant="outlined"
          >
            {t('ACTIONS.CANCEL')}
          </Button>
          <Button form="transportation-form" type="submit">
            {t('ACTIONS.SAVE')}
          </Button>
        </div>
      }
    >
      <FormProvider {...methods}>
        <form
          id="transportation-form"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <div className="drawer__transportation-management__form__radio-group">
            <TravelDirectionRadioGroup travelDirection={travelDirection} />
          </div>
          <div className="drawer__transportation-management__form__selects">
            {travelDirection &&
              +travelDirection === TransportationUsage.OneWay && (
                <Select
                  name="direction"
                  label={
                    t('TRANSPORTATION_MANAGEMENT.DRAWER.LABELS.DIRECTION') ?? ''
                  }
                  placeholder={
                    t(
                      'TRANSPORTATION_MANAGEMENT.DRAWER.PLACEHOLDERS.SELECT_DIRECTION'
                    ) ?? ''
                  }
                  options={[
                    {
                      value: TransportationFormDirection.Pick,
                      label: t(
                        'TRANSPORTATION_MANAGEMENT.DRAWER.LABELS.PICK_UP'
                      ),
                    },
                    {
                      value: TransportationFormDirection.Drop,
                      label: t(
                        'TRANSPORTATION_MANAGEMENT.DRAWER.LABELS.DROP_OFF'
                      ),
                    },
                  ]}
                />
              )}
            {travelDirection && showPickUpSelect && (
              <Select
                clearable
                name="pickUpStop.id"
                rules={{
                  required: {
                    value: showPickUpSelect,
                    message: t('ERROR.VALIDATION.REQUIRED'),
                  },
                }}
                label={
                  t('TRANSPORTATION_MANAGEMENT.DRAWER.LABELS.PICKUP_TIME') ?? ''
                }
                placeholder={
                  t('TRANSPORTATION_MANAGEMENT.DRAWER.PLACEHOLDERS.PICK_UP') ??
                  ''
                }
                options={pickUpSelectOptions}
              />
            )}

            {travelDirection && showDropOffSelect && (
              <Select
                clearable
                name="dropOffStop.id"
                rules={{
                  required: {
                    value: showDropOffSelect,
                    message: t('ERROR.VALIDATION.REQUIRED'),
                  },
                }}
                label={
                  t('TRANSPORTATION_MANAGEMENT.DRAWER.LABELS.DROPOFF_TIME') ??
                  ''
                }
                placeholder={
                  t('TRANSPORTATION_MANAGEMENT.DRAWER.PLACEHOLDERS.DROP_OFF') ??
                  ''
                }
                options={dropOffSelectOptions}
              />
            )}
          </div>
        </form>
      </FormProvider>
    </Drawer>
  );
}
