import { Button, Dialog } from '@ph-react-ui/core';
import { useTranslation } from 'react-i18next';
import { useCancellationDialog } from '../../../hooks/useCancellationDialog';
import { useModal } from '../../../hooks/useModal';

interface CancellationDialogProps {
  title?: string;
  message?: string;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onDiscardChanges?: () => void;
}

export function CancellationDialog({
  title,
  message,
  primaryButtonText,
  secondaryButtonText,
  onDiscardChanges: controlledDiscardChanges,
}: CancellationDialogProps) {
  const { t } = useTranslation();
  const { close } = useCancellationDialog();
  const { close: closeModal } = useModal();

  const onDiscardChanges = () => {
    controlledDiscardChanges ? controlledDiscardChanges() : closeModal();
    close();
  };
  return (
    <Dialog
      header={title || t('CANCELLATION_DIALOG.TITLE')}
      className="dialog__cancellation"
      onClose={close}
    >
      <div className="dialog__cancellation__content">
        <h3>{message || t('CANCELLATION_DIALOG.HEADER')}</h3>
      </div>
      <div className="dialog__confirm__footer">
        <Button variant="outlined" onClick={close}>
          {secondaryButtonText || t('ACTIONS.KEEP_EDITING')}
        </Button>
        <div className="dialog__confirm__footer__actions">
          <Button color="secondary" onClick={onDiscardChanges}>
            {primaryButtonText || t('ACTIONS.DISCARD_CHANGES')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
