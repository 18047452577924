import { useMutation } from '@tanstack/react-query';
import type { EvaluateStudentDto } from '../../models/evaluation-plans/evaluation-plans-details.dto';
import apiRequest from '../../services/apiWrapper';
import { EVALUATIONS_URL } from '../../utils/constants/urlConstants';

async function evaluateStudent(
  data: EvaluateStudentDto,
  studentId: string | null
) {
  return apiRequest.post(
    `${EVALUATIONS_URL}/students/${studentId}/evaluate`,
    data
  );
}

export function useEvaluateStudent(studentId: string | null) {
  return useMutation({
    mutationFn: (data: EvaluateStudentDto) => evaluateStudent(data, studentId),
  });
}
