import { useMutation } from '@tanstack/react-query';
import type { GroupMembersCreateDto } from '../../../models/groups/group-member-create.dto';
import apiRequest from '../../../services/apiWrapper';
import { GROUPS_URL } from '../../../utils/constants/urlConstants';

async function createGroupMembers(members: GroupMembersCreateDto) {
  return apiRequest.post<GroupMembersCreateDto, void>(
    `${GROUPS_URL}/members`,
    members
  );
}

export function useCreateGroupMembers() {
  return useMutation({
    mutationFn: (members: GroupMembersCreateDto) => createGroupMembers(members),
  });
}
