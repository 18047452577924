import { Button, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useModal } from '../../../hooks/useModal';
import { useArchiveUsers } from '../../../hooks/users/useArchiveUser';
import { ARCHIVE_USERS } from '../../../utils/constants/users/modals';
import { Dialog } from '../../shared/components/Dialog';

export const ArchiveMultiple = () => {
  const notification = useNotifications();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const { close: closeModal } = useModal(ARCHIVE_USERS);
  const ids = searchParams.get('userId');
  const { t } = useTranslation();

  const archiveUsers = useArchiveUsers();

  const handleArchive = () => {
    archiveUsers
      .mutateAsync({
        userIds: ids?.split('&') as string[],
        includeRelatives: true,
      })
      .then(() => {
        closeModal();
        notification.success(t('USERS.ARCHIVE.ALERT.USERS_UPDATED'));
        queryClient.invalidateQueries({ queryKey: ['users'] });
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  return (
    <Dialog
      dialogHeader={t('USERS.ACTIONS.ARCHIVE_MULTIPLE_USERS')}
      actionButton={
        <Button onClick={handleArchive}>{t('ACTIONS.CONTINUE')}</Button>
      }
      onClose={() => closeModal()}
    >
      <h2>{t('USERS.ARCHIVE.HEADING.ARE_YOU_SURE_ARCHIVE_RELATIVES')}</h2>
    </Dialog>
  );
};
