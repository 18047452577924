import { useMutation } from '@tanstack/react-query';
import apiRequest from '../../services/apiWrapper';
import {
  FILES_URL,
  PUBLIC_FILES_URL,
} from '../../utils/constants/urlConstants';

async function deleteFile(fileId: string, invitationId?: string) {
  const url = invitationId
    ? `${PUBLIC_FILES_URL}/${fileId}?invitationId=${invitationId}`
    : `${FILES_URL}/${fileId}`;
  return apiRequest.delete(url, {});
}

export function useDeleteFile(invitationId?: string) {
  return useMutation({
    mutationFn: (fileId: string) => deleteFile(fileId, invitationId),
  });
}
