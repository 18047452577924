export function formatIbanNumber(iban: string | undefined): string {
  if (!iban) return '';
  return iban.replaceAll(' ', '').toUpperCase();
}

export function hideIbanNumberDetails(iban: string | undefined): string {
  if (!iban) return '';
  const firstLettersToReplaceRegex = /.{1,18}/;

  return iban.replace(firstLettersToReplaceRegex, (m) => 'x'.repeat(m.length));
}
