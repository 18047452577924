import { useQuery } from '@tanstack/react-query';
import type { MealMenuCreateFormDto } from '../../../models/meal-menus/admin/meal-menu-create-form.dto';
import type { MealMenuDetailsDto } from '../../../models/meal-menus/admin/meal-menu-details.dto';
import apiRequest from '../../../services/apiWrapper';
import { MEAL_MENUS_URL } from '../../../utils/constants/urlConstants';
import { MealType } from '../../../utils/enums/meal-type.enum';

interface MealMenuParams {
  month: number;
  year: number;
}

async function getMealMenuForMonthAndYear({ month, year }: MealMenuParams) {
  const response = await apiRequest.get<MealMenuDetailsDto>(
    `${MEAL_MENUS_URL}?year=${year}&month=${month}`
  );

  return response.data;
}

export function useGetMealMenuForMonthAndYear({ month, year }: MealMenuParams) {
  return useQuery({
    queryKey: ['meal-menu', month, year],
    queryFn: () => getMealMenuForMonthAndYear({ month, year }),
    throwOnError: false,
    retry: false,
    select: (data) => ({ ...unwrapWeeks(data), id: data.id }),
  });
}

function unwrapWeeks(data: MealMenuDetailsDto): MealMenuCreateFormDto {
  const meatWeeks = data.weeks.filter((week) => week.type === MealType.Meat);
  const vegWeeks = data.weeks.filter(
    (week) => week.type === MealType.Vegeterian
  );
  return {
    numberOfDays: data.numberOfDays,
    numberOfWeeks: data.numberOfWeeks,
    meatWeeks,
    vegWeeks,
  };
}
