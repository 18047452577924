import { Add, TrashCan } from '@carbon/icons-react';
import { Tooltip } from '@ph-react-ui/core';
import { type Control, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { EvaluationPlansFormDto } from '../../../models/evaluation-plans/evaluation-plans-form.dto';
import { Input } from '../../../utils/components/hoc-components';
import {
  getCommonValidators,
  VALIDATION as V,
} from '../../../utils/inputValidators';

interface CriteriaFieldArrayProps {
  index: number;
  control: Control<EvaluationPlansFormDto>;
}

export const defaultCriteria = {
  name: '',
  index: 1,
  exceedsExpectations: '',
  meetsExpectations: '',
  approachingExpectations: '',
  belowExpectations: '',
};

export function CriteriaFieldArray({
  control,
  index,
}: CriteriaFieldArrayProps) {
  const { t } = useTranslation();

  const {
    append: appendCriteria,
    remove: removeCriteria,
    fields: criteriaFields,
  } = useFieldArray({
    control: control,
    name: `strands.${index}.criterias`,
  });

  const onAddCriteria = () => {
    appendCriteria({ ...defaultCriteria, index: criteriaFields.length + 1 });
  };

  const renderRemoveCriteriaButton = (index: number) => {
    const buttonNode = (
      <button
        onClick={() => removeCriteria(index)}
        type="button"
        disabled={index === 0}
        className="evaluation-plans__form__strand__criteria__item__name__button"
      >
        <TrashCan />
      </button>
    );

    return index === 0 ? (
      <Tooltip
        className="evaluation-plans__form__actions__tooltip"
        message={t('EVALUATION_PLANS.MESSAGES.CRITERIA_DELETE_BUTTON_DISABLED')}
      >
        {buttonNode}
      </Tooltip>
    ) : (
      buttonNode
    );
  };

  return (
    <section className="evaluation-plans__form__strand__criteria">
      {criteriaFields.map((field, i) => (
        <div className="evaluation-plans__form__strand__criteria__item" key={i}>
          <div className="evaluation-plans__form__strand__criteria__item__name">
            <Input
              name={`strands[${index}].criterias[${i}].name`}
              value={i + 1}
              className="hidden"
            />
            <Input
              name={`strands[${index}].criterias[${i}].name`}
              label={t('EVALUATION_PLANS.FORM.LABELS.CRITERIA') ?? ''}
              placeholder={
                t('EVALUATION_PLANS.FORM.PLACEHOLDERS.CRITERIA') ?? ''
              }
              className="evaluation-plans__form__strand__criteria__item__name__input"
              rules={getCommonValidators([V.REQUIRED, V.MAX_LENGTH])}
            />
            {renderRemoveCriteriaButton(i)}
          </div>
          <hr className="evaluation-plans__form__strand__criteria__hr" />
          <Input
            name={`strands[${index}].criterias[${i}].exceedsExpectations`}
            label={t('EVALUATION_PLANS.FORM.LABELS.EXCEEDS_EXPECTATIONS') ?? ''}
            placeholder={
              t('EVALUATION_PLANS.FORM.PLACEHOLDERS.CRITERIA_DESCRIPTION') ?? ''
            }
            rules={getCommonValidators([V.REQUIRED, V.MAX_LENGTH_3000])}
          />
          <Input
            name={`strands[${index}].criterias[${i}].meetsExpectations`}
            label={t('EVALUATION_PLANS.FORM.LABELS.MEETS_EXPECTATIONS') ?? ''}
            placeholder={
              t('EVALUATION_PLANS.FORM.PLACEHOLDERS.CRITERIA_DESCRIPTION') ?? ''
            }
            rules={getCommonValidators([V.REQUIRED, V.MAX_LENGTH_3000])}
          />
          <Input
            name={`strands[${index}].criterias[${i}].approachingExpectations`}
            label={
              t('EVALUATION_PLANS.FORM.LABELS.APPROACHING_EXPECTATIONS') ?? ''
            }
            placeholder={
              t('EVALUATION_PLANS.FORM.PLACEHOLDERS.CRITERIA_DESCRIPTION') ?? ''
            }
            rules={getCommonValidators([V.REQUIRED, V.MAX_LENGTH_3000])}
          />
          <Input
            name={`strands[${index}].criterias[${i}].belowExpectations`}
            label={t('EVALUATION_PLANS.FORM.LABELS.BELOW_EXPECTATIONS') ?? ''}
            placeholder={
              t('EVALUATION_PLANS.FORM.PLACEHOLDERS.CRITERIA_DESCRIPTION') ?? ''
            }
            rules={getCommonValidators([V.REQUIRED, V.MAX_LENGTH_3000])}
          />
        </div>
      ))}
      <div className="evaluation-plans__form__add-more">
        <button
          type="button"
          className="evaluation-plans__form__add-more__button"
          onClick={onAddCriteria}
        >
          <Add className="evaluation-plans__form__add-more__button__icon" />
          <span className="evaluation-plans__form__add-more__button__text">
            {t('EVALUATION_PLANS.ACTIONS.ADD_A_CRITERIA')}
          </span>
        </button>
      </div>
    </section>
  );
}
