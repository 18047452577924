import { Button, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { format, startOfTomorrow } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCreateEmployeeAbsence } from '../../../../hooks/meal-menus/admin/useCreateEmployeeAbsence';
import { useModal } from '../../../../hooks/useModal';
import type { EmployeeAbsenceFormDto } from '../../../../models/meal-menus/admin/employee-absence-create.dto';
import { Input } from '../../../../utils/components/hoc-components';
import { REGISTER_AN_ABSENCE } from '../../../../utils/constants/users/modals';
import {
  getCommonValidators,
  VALIDATION as V,
} from '../../../../utils/inputValidators';
import { Dialog } from '../../../shared/components/Dialog';

export function RegisterEmployeeAbsence() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { close: closeModal } = useModal(REGISTER_AN_ABSENCE);
  const notification = useNotifications();

  const createEmployeeAbsence = useCreateEmployeeAbsence();

  const methods = useForm({
    defaultValues: {
      absentVegeterianEmployees: '',
      absentMeatEmployees: '',
    },
  });

  const onRegisterAbsence = (data: EmployeeAbsenceFormDto) => {
    createEmployeeAbsence
      .mutateAsync({
        absentVegeterianEmployees: +data.absentVegeterianEmployees,
        absentMeatEmployees: +data.absentMeatEmployees,
        targetedAt: format(startOfTomorrow(), 'yyyy-MM-dd'),
      })
      .then(() => {
        notification.success(
          t('FOOD_MANAGEMENT.MESSAGES.ABSENCE_REGISTER_SUCCESS')
        );
        queryClient.invalidateQueries({
          queryKey: ['employee-absence-availability'],
        });
        closeModal();
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  return (
    <Dialog
      onClose={() => closeModal()}
      dialogHeader={t('FOOD_MANAGEMENT.TITLES.REGISTER_EMPLOYEE_ABSENCE')}
      actionButton={
        <Button onClick={methods.handleSubmit(onRegisterAbsence)}>
          {t('ACTIONS.CONFIRM')}
        </Button>
      }
    >
      <FormProvider {...methods}>
        <form
          className="dialog__absence__employee"
          onSubmit={methods.handleSubmit(onRegisterAbsence)}
        >
          <h3 className="dialog__absence__employee__title">
            {t('FOOD_MANAGEMENT.TITLES.REGISTER_EMPLOYEE_ABSENCE_FOR_DATE', {
              date: format(startOfTomorrow(), 'dd.MM.yyyy'),
            })}
          </h3>
          <Input
            name="absentVegeterianEmployees"
            label={
              t('FOOD_MANAGEMENT.LABELS.NUMBER_OF_EMPLOYEES_VEGETARIAN_MENU') ??
              ''
            }
            placeholder={
              t('FOOD_MANAGEMENT.PLACEHOLDERS.NUMBER_OF_MEALS') ?? ''
            }
            rules={getCommonValidators([V.REQUIRED, V.NUMBER_PATTERN])}
          />
          <Input
            name="absentMeatEmployees"
            label={
              t('FOOD_MANAGEMENT.LABELS.NUMBER_OF_EMPLOYEES_MEAT_MENU') ?? ''
            }
            placeholder={
              t('FOOD_MANAGEMENT.PLACEHOLDERS.NUMBER_OF_MEALS') ?? ''
            }
            rules={getCommonValidators([V.REQUIRED, V.NUMBER_PATTERN])}
          />
        </form>
      </FormProvider>
    </Dialog>
  );
}
