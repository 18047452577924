import { useQuery } from '@tanstack/react-query';
import type { PaginatedList } from '../../models/common/paginated-list.model';
import type { AllEvaluationPlansDto } from '../../models/evaluation-plans/evaluation-plans.dto';
import apiRequest from '../../services/apiWrapper';
import { createPaginatedQueryKeys } from '../../services/createPaginatedQueryKeys';
import { EVALUATION_PLANS_URL } from '../../utils/constants/urlConstants';

async function fetchEvaluationPlans(searchParams: URLSearchParams) {
  const response = await apiRequest.get<PaginatedList<AllEvaluationPlansDto>>(
    `${EVALUATION_PLANS_URL}/available-plans?${searchParams.toString()}`
  );

  return response.data;
}

export function usePaginatedAllEvaluationPlans(searchParams: URLSearchParams) {
  return useQuery({
    queryKey: ['evaluation-plans', createPaginatedQueryKeys(searchParams)],
    queryFn: () => fetchEvaluationPlans(searchParams),
  });
}
