import { useMutation } from '@tanstack/react-query';
import type { UnassignEvaluationPlanFromGroupsDto } from '../../models/evaluation-plans/unassign-evaluation-plan-from-groups';
import apiRequest from '../../services/apiWrapper';
import { EVALUATION_PLANS_URL } from '../../utils/constants/urlConstants';

async function unassignEvaluationPlanFromGroups(
  id: string | null,
  data: UnassignEvaluationPlanFromGroupsDto
) {
  return apiRequest.put(`${EVALUATION_PLANS_URL}/${id}/groups/unassign`, data);
}

export function useUnassignEvaluationPlanFromGroups(id: string | null) {
  return useMutation({
    mutationFn: (data: UnassignEvaluationPlanFromGroupsDto) =>
      unassignEvaluationPlanFromGroups(id, data),
  });
}
