import type { SelectOption } from '@ph-react-ui/core';
import i18n from '../../../i18n';
import { StudentBusStopsRequestStatus as Status } from '../../enums/student-bus-stop-request-status.enum';

export function getBusStopStatus(key: string) {
  return i18n.t(key);
}

export const BUS_STOP_STATUS = {
  [Status.NoTransportation]:
    'TRANSPORTATION_MANAGEMENT.STATUS.NO_TRANSPORTATION',
  [Status.NewRequest]: 'TRANSPORTATION_MANAGEMENT.STATUS.NEW_REQUEST',
  [Status.ReSubmitted]: 'TRANSPORTATION_MANAGEMENT.STATUS.RESUBMITTED',
};

export const BUS_STOP_STATUS_OPTIONS = Object.entries(BUS_STOP_STATUS).map(
  (status) => {
    return {
      label: getBusStopStatus(status[1]),
      value: status[0],
    };
  }
) as SelectOption<string>[];
