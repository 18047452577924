import { useMutation } from '@tanstack/react-query';
import type { BoricaParametersDto } from '../../models/payments/borica-parameters.dto';
import type { SchoolTaxParameters } from '../../models/payments/school-tax-parameters.dto';
import apiRequest from '../../services/apiWrapper';
import { SCHOOL_TAX_PAYMENTS } from '../../utils/constants/urlConstants';

async function sendSchoolTaxParameters(data: SchoolTaxParameters) {
  const res = await apiRequest.post<SchoolTaxParameters, BoricaParametersDto>(
    `${SCHOOL_TAX_PAYMENTS}/students`,
    data
  );

  return res.data;
}

export function useSendSchoolParameters() {
  return useMutation({
    mutationFn: (data: SchoolTaxParameters) => sendSchoolTaxParameters(data),
  });
}
