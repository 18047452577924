import {
  Input as InputBase,
  DatePicker as DatePickerBase,
  Select as SelectBase,
  Checkbox as CheckboxBase,
  Textarea as TextareaBase,
  TimePicker as TimePickerBase,
  Radio as RadioBase,
  RadioGroup as RadioGroupBase,
} from '@ph-react-ui/core';
import { TextEditor as TextEditorBase } from '../../features/shared/components/TextEditor';
import { withController } from '../../services/withController';

export const Input = withController(InputBase);

export const DatePicker = withController(DatePickerBase);

export const TimePicker = withController(TimePickerBase);

export const Select = withController(SelectBase);

export const Checkbox = withController(CheckboxBase);

export const Textarea = withController(TextareaBase);

export const Radio = withController(RadioBase);

export const RadioGroup = withController(RadioGroupBase);

export const TextEditor = withController(TextEditorBase);
