import { useNotifications } from '@ph-react-ui/core';
import { type DragEvent, useState, type ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useUploadFile } from '../../../hooks/files/useUploadFile';
import type { ResponseError } from '../../../models/auth/api-wrapper';
import type { RenderArgs } from '../../../models/files/file-upload';
import type { DocumentType } from '../../../utils/enums/document-type.enum';
import { UploadProgressBar } from './UploadProgressBar';

interface BaseFileUploadProps {
  renderFooter?: (args: RenderArgs) => any;
  renderHeader?: (args: RenderArgs) => any;
  validationFns: any[];
  validationErrors: string[];
  allowedTypes: string;
  invitationId?: string;
  showUploadProgressBar?: boolean;
  className?: string;
  withSuccessNotification?: boolean;
}

export function BaseFileUpload({
  renderFooter,
  renderHeader,
  validationFns,
  validationErrors,
  allowedTypes,
  invitationId,
  showUploadProgressBar = true,
  className,
  withSuccessNotification = true,
}: BaseFileUploadProps) {
  const { t } = useTranslation();
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [filled, setFilled] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState('');
  const fileRef = useRef<HTMLInputElement | null>(null);
  const [uploadPercentage, setUploadPercentage] = useState<number | null>(null);
  const uploadFile = useUploadFile(invitationId);
  const notification = useNotifications();

  const onDragEnter = () => {
    setIsDraggingOver(true);
  };

  const onDragLeave = () => {
    setIsDraggingOver(false);
  };

  const onDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const onDropFile = (event: DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    const dataTransferFile = event.dataTransfer.files[0];
    updateFileState(dataTransferFile);
    event.dataTransfer.clearData();
  };

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const targetFile = event.target.files?.[0];
    updateFileState(targetFile);
    event.target.value = '';
  };

  const onFileSelected = () => {
    setFilled(false);
    setError('');
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  const onRemoveFile = () => {
    setFile(null);
    setFilled(false);
    setError('');
  };

  const updateFileState = (file: File | undefined) => {
    const isUploading = false;
    if (!isUploading && file) {
      setFile(file);
      validationFns.forEach((fn, index) => {
        if (!fn(file)) {
          setError(validationErrors[index]);
          return;
        }
      });
    }
  };

  const isUploading = uploadPercentage
    ? uploadPercentage >= 0 && uploadPercentage < 100
    : false;

  const emitFileUpload = async (file: File, fileType: DocumentType) => {
    try {
      const { data } = await uploadFile.mutateAsync({
        file,
        fileType,
        uploadProgress: (progressEvent) => {
          if (progressEvent.total) {
            setUploadPercentage(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }
        },
      });
      withSuccessNotification &&
        notification.success(t('UPLOAD_FILES.MESSAGES.FILE_UPLOADED'));
      return data;
    } catch (error) {
      notification.danger(
        t(`NETWORK_ERRORS.${(error as ResponseError).errors[0]}`)
      );
      return null;
    }
  };

  return (
    <div
      className={`upload__file ${isDraggingOver ? 'upload__file-active' : ''} ${
        error ? 'upload__file-error' : ''
      } ${filled ? 'upload__file__required__item-filled' : ''} ${
        isDraggingOver ? 'upload__file__required__item-drag' : ''
      } ${className}`}
      onDragOver={(event) => onDragOver(event)}
      onDragEnter={onDragEnter}
      onDragLeave={() => onDragLeave()}
      onDrop={(event) => onDropFile(event)}
    >
      {renderHeader?.({
        error,
        file,
        filled,
        isUploading,
        onFileSelected,
        onRemoveFile,
        emitFileUpload,
        setFilled,
      })}
      <input
        ref={fileRef}
        type="file"
        className="upload__file__input"
        onChange={(event) => onFileChange(event)}
        accept={allowedTypes}
      />
      {renderFooter?.({
        error,
        file,
        filled,
        isUploading,
        onFileSelected,
        onRemoveFile,
        emitFileUpload,
        setFilled,
      })}
      {showUploadProgressBar &&
        uploadPercentage !== null &&
        uploadPercentage !== 100 && (
          <UploadProgressBar uploadPercentage={uploadPercentage} />
        )}
      <div className="upload__file__errors">
        {Boolean(error) && t(`UPLOAD_FILES.ERRORS.${error}`)}
      </div>
    </div>
  );
}
