import { Drawer, Select } from '@ph-react-ui/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Role } from '../../../utils/enums/role.enum';
import { AdminForm } from './forms/AdminForm';
import { EmployeeForm } from './forms/EmployeeForm';
import { ParentForm } from './forms/ParentForm';
import { StudentForm } from './forms/StudentForm';

export function CreateEditUser() {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('userId');
  const parentId = searchParams.get('parentId');
  const roleParam = searchParams.get('userRole') ?? Role.Admin.toString();
  const { t } = useTranslation();
  const [footerElement, setFooterElement] = useState<HTMLDivElement | null>(
    null
  );

  const options: {
    label: string;
    value: Role;
  }[] = [
    { label: t('USERS.DRAWER.LABELS.ADMIN'), value: Role.Admin },
    { label: t('USERS.DRAWER.LABELS.PARENT'), value: Role.Parent },
    { label: t('USERS.DRAWER.LABELS.EMPLOYEE'), value: Role.Employee },
  ];

  if (id || parentId) {
    options.push({
      label: t('USERS.DRAWER.LABELS.STUDENT'),
      value: Role.Student,
    });
    options.push({
      label: t('USERS.DRAWER.LABELS.CANDIDATE'),
      value: Role.Candidate,
    });
  }

  const handleChangeRole = (role: number | null) => {
    if (role !== null) {
      searchParams.set('userRole', role.toString());
      setSearchParams(searchParams);
    }
  };

  const forms = [
    {
      index: Role.Admin,
      element: <AdminForm footerElement={footerElement} />,
    },
    {
      index: Role.Parent,
      element: <ParentForm footerElement={footerElement} />,
    },
    {
      index: Role.Employee,
      element: <EmployeeForm footerElement={footerElement} />,
    },
    {
      index: Role.Candidate,
      element: <ParentForm footerElement={footerElement} />,
    },
    {
      index: Role.Student,
      element: <StudentForm footerElement={footerElement} />,
    },
  ];

  const renderForm = () => {
    const form = forms.find((form) => form.index.toString() === roleParam);
    if (form === undefined) return;
    return form.element;
  };

  return (
    <Drawer
      header={
        id
          ? t('USERS.DRAWER.TITLES.EDIT_THE_USER')
          : t('USERS.DRAWER.TITLES.ADD_A_USER')
      }
      footer={
        <div
          className="drawer__users__form__actions"
          ref={(elem) => setFooterElement(elem)}
        ></div>
      }
    >
      <div className="drawer__users__header">
        <Select
          disabled={Boolean(id || parentId)}
          multi={false}
          options={options}
          value={parseInt(roleParam)}
          onChange={handleChangeRole}
        />
      </div>
      {renderForm()}
    </Drawer>
  );
}
