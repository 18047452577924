import { Search } from '@carbon/icons-react';
import {
  Button,
  Input,
  Table,
  Checkbox,
  useNotifications,
} from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDebounce } from '../../../hooks/common/useDebounce';
import { useCreateGroupMembers } from '../../../hooks/groups/members/useCreateGroupMembers';
import { usePaginatedAvailableGroupMembers } from '../../../hooks/groups/members/usePaginatedAvailableGroupMembers';
import { useModal } from '../../../hooks/useModal';
import { ADD_GROUP_USER } from '../../../utils/constants/users/modals';
import { Role } from '../../../utils/enums/role.enum';
import { Dialog } from '../../shared/components/Dialog';
import { TableSkeleton } from '../../shared/components/skeletons/TableSkeleton';

interface UserValues {
  users: {
    id: string;
    value: boolean;
  }[];
}

export const AddUser = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const notification = useNotifications();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const queryClient = useQueryClient();
  const [searchInput, setSearchInput] = useState('');
  const [role, setRole] = useState<string | Role>('');
  const { close: closeModal } = useModal(ADD_GROUP_USER);
  const debouncedInput = useDebounce(searchInput);

  const { data: users, isSuccess } = usePaginatedAvailableGroupMembers(
    id ?? '',
    debouncedInput,
    role
  );

  const addGroupMembers = useCreateGroupMembers();

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
  } = useForm({
    values: {
      users: users?.result.map((user) => ({
        id: user.id,
        value: false,
      })),
    } as UserValues,
  });

  const headers = [t('USERS.TABLE.HEADERS.NAME'), ''];
  const userFilter = [
    {
      role: '',
      label: t('USERS.TABLE.FILTERS.ALL'),
    },
    {
      role: Role.Student,
      label: t('USERS.TABLE.FILTERS.STUDENTS'),
    },
    {
      role: Role.Employee,
      label: t('USERS.TABLE.FILTERS.EMPLOYEES'),
    },
    {
      role: Role.Parent,
      label: t('USERS.TABLE.FILTERS.PARENTS'),
    },
    {
      role: Role.Candidate,
      label: t('USERS.TABLE.FILTERS.CANDIDATES'),
    },
  ];

  const tableChildren = useMemo(() => {
    return users?.result.reduce((acc: any, user, index) => {
      let userArray = [
        <div className="users__table__checkbox">
          <Controller
            name={`users.${index}.value`}
            control={control}
            render={({ field }) => (
              <Checkbox className="users__table-first" {...field} />
            )}
          />
          {user.name}
        </div>,
      ];

      return [...acc, userArray];
    }, []);
  }, [users, control]);

  const onSubmit = (data: UserValues) => {
    const userIds = data.users
      .filter((user) => user.value)
      .map((user) => user.id);
    addGroupMembers
      .mutateAsync({
        destinationGroupIds: [id as string],
        userProfileIds: userIds,
      })
      .then(() => {
        notification.success(t(`GROUPS.DIALOG.MESSAGES.MEMBERS_ADDED`));
        queryClient.invalidateQueries({ queryKey: ['members', id] });
        closeModal();
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  return (
    <Dialog
      className="dialog__confirm dialog__width-auto"
      onClose={() => closeModal()}
      actionButton={
        <Button
          disabled={!dirtyFields.users?.some((user) => user.value)}
          onClick={handleSubmit(onSubmit)}
        >
          {t('ACTIONS.ADD')}
        </Button>
      }
    >
      <div className="dialog__confirm__content__scroll">
        <div className="users__filters__item-header">
          <div>
            <span className="users__filters__item__title">
              {t('USERS.TABLE.FILTERS.TITLE')}
            </span>
          </div>
          {userFilter.map((user, index) => (
            <button
              key={`filter-${index}`}
              type="button"
              className={`users__filters__item ${
                role === user.role && ' users__filters__item-active'
              }`}
              onClick={() => setRole(user.role)}
            >
              {user.label}
            </button>
          ))}
        </div>
        <Input
          className="mb-3"
          ref={inputRef}
          value={searchInput}
          onChange={setSearchInput}
          compact
          placeholder={t('USERS.TABLE.FILTERS.SEARCH_USER') ?? ''}
          prefix={
            <Search className="groups__actions__input__icon groups__actions__input__icon--action" />
          }
        />
        {isSuccess && users ? (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Table headers={headers} rows={tableChildren} />
            </form>
          </>
        ) : (
          <TableSkeleton headers={headers} />
        )}
      </div>
    </Dialog>
  );
};
