import { useMutation } from '@tanstack/react-query';
import type { StudentUpdateDto } from '../../../models/users/students/student-update.dto';
import apiRequest from '../../../services/apiWrapper';

async function updateStudentById(updateStudentData: StudentUpdateDto) {
  return apiRequest.put(
    `/api/users/students/${updateStudentData.id}`,
    updateStudentData
  );
}
export function useUpdateStudentById() {
  return useMutation({
    mutationFn: (updateStudentData: StudentUpdateDto) =>
      updateStudentById(updateStudentData),
  });
}
