import { useQuery } from '@tanstack/react-query';
import type { BusStopDTO } from '../../../models/transportation/bus-stop.dto';
import apiRequest from '../../../services/apiWrapper';
import { TRANSPORTATION_BUS_STOPS_URL } from '../../../utils/constants/urlConstants';

async function getBusStopById(busStopId: string) {
  const response = await apiRequest.get<BusStopDTO>(
    `${TRANSPORTATION_BUS_STOPS_URL}/${busStopId}`
  );

  return response.data;
}

export function useGetBusStopById(busStopId: string) {
  return useQuery({
    queryKey: ['bus-stop', busStopId],
    queryFn: () => getBusStopById(busStopId),
  });
}
