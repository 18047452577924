import { Table } from '@ph-react-ui/core';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate, useSearchParams } from 'react-router-dom';
import { usePaginatedTermReports } from '../../hooks/term-reports/usePaginatedTermReports';
import { useModal } from '../../hooks/useModal';
import { REVIEW_TERM_REPORTS } from '../../utils/constants/term-reports/modals';
import { UserOrdering } from '../../utils/enums/user-ordering.enum';
import { ActionMenuHorizontal } from '../shared/components/ActionMenuHorizontal';
import { Pagination } from '../shared/components/Pagination';
import { SearchInput } from '../shared/components/SearchByNameInput';
import { FilterSkeleton } from '../shared/components/skeletons/FilterSkeleton';
import { TableSkeleton } from '../shared/components/skeletons/TableSkeleton';
import { ThSortItem } from '../shared/components/tables/ThSortItem';

export const MyTermReports = () => {
  const match = useMatch('/dashboard/term-reports/my-reports');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { open: openReviewTermReportsModal } = useModal(REVIEW_TERM_REPORTS);
  const [searchParams] = useSearchParams();

  const {
    data: reports,
    isPending,
    isSuccess,
  } = usePaginatedTermReports(searchParams);

  const onOpenReviewReports = useCallback(
    (id: string) => {
      openReviewTermReportsModal({
        userId: id,
      });
    },
    [openReviewTermReportsModal]
  );

  const onOpenCreateReport = useCallback(
    (id: string) => {
      navigate(`/dashboard/term-reports/create-edit/${id}`);
    },
    [navigate]
  );

  const headers = [
    <div className="users__table">
      <ThSortItem
        title={t('TERM_REPORTS.TABLE.HEADERS.NAME')}
        ordering={UserOrdering.Name}
      />
    </div>,
    t('TERM_REPORTS.TABLE.HEADERS.REPORTS'),
    t('TERM_REPORTS.TABLE.HEADERS.ACTIONS'),
  ];

  const tableChildren = useMemo(() => {
    return reports?.result.reduce((acc: any, report) => {
      let reportsArray = [
        report.name,
        `${report.reportsDone}/${report.totalReports}`,
        <ActionMenuHorizontal
          options={[
            {
              label: t('TERM_REPORTS.TABLE.ACTIONS.CREATE'),
              onClick: () => {
                onOpenCreateReport(report.id);
              },
            },
            {
              label: t('TERM_REPORTS.TABLE.ACTIONS.VIEW'),
              onClick: () => {
                onOpenReviewReports(report.id);
              },
            },
          ]}
        />,
      ];

      return [...acc, reportsArray];
    }, []);
  }, [t, onOpenCreateReport, onOpenReviewReports, reports?.result]);

  return (
    <div>
      <div className="term-reports__filters">
        {isPending ? (
          <FilterSkeleton />
        ) : (
          <SearchInput
            pathMatch={match}
            placeholder={t('TERM_REPORTS.TABLE.FILTERS.SEARCH')}
          />
        )}
      </div>
      {isSuccess && reports ? (
        <>
          <Table headers={headers} rows={tableChildren} />
          <div className="term-reports__pagination">
            <Pagination data={reports} />
          </div>
        </>
      ) : (
        <TableSkeleton headers={headers} />
      )}
    </div>
  );
};
