import { Add, TrashCan } from '@carbon/icons-react';
import { Tooltip } from '@ph-react-ui/core';
import { type Control, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { EvaluationPlansFormDto } from '../../../models/evaluation-plans/evaluation-plans-form.dto';
import { Input } from '../../../utils/components/hoc-components';
import {
  getCommonValidators,
  VALIDATION as V,
} from '../../../utils/inputValidators';
import { CriteriaFieldArray, defaultCriteria } from './CriteriaFieldArray';

interface StrandFieldArrayProps {
  control: Control<EvaluationPlansFormDto>;
}

export function StrandFieldArray({ control }: StrandFieldArrayProps) {
  const {
    append: appendStrand,
    remove: removeStrand,
    fields: strandFields,
  } = useFieldArray({
    control,
    name: 'strands',
  });
  const { t } = useTranslation();

  const onAddStrand = () => {
    appendStrand({
      name: '',
      index: strandFields.length + 1,
      criterias: [defaultCriteria],
    });
  };

  const onRemoveStrand = (index: number) => {
    removeStrand(index);
  };

  const renderRemoveStrandButton = (index: number) => {
    const buttonNode = (
      <button
        onClick={() => onRemoveStrand(index)}
        type="button"
        disabled={index === 0}
        className="evaluation-plans__form__strand__name__button"
      >
        <TrashCan />
      </button>
    );
    return index === 0 ? (
      <Tooltip
        className="evaluation-plans__form__actions__tooltip"
        message={t('EVALUATION_PLANS.MESSAGES.STRAND_DELETE_BUTTON_DISABLED')}
      >
        {buttonNode}
      </Tooltip>
    ) : (
      buttonNode
    );
  };

  return (
    <>
      {strandFields.map((field, index) => (
        <section className="evaluation-plans__form__strand" key={index}>
          <div className="evaluation-plans__form__strand__name">
            <input
              name={`strands[${index}].index`}
              value={index + 1}
              className="hidden"
            />
            <Input
              name={`strands[${index}].name`}
              label={t('EVALUATION_PLANS.FORM.LABELS.STRAND') ?? ''}
              placeholder={t('EVALUATION_PLANS.FORM.PLACEHOLDERS.STRAND') ?? ''}
              className="evaluation-plans__form__strand__name__input"
              rules={getCommonValidators([V.REQUIRED, V.MAX_LENGTH])}
            />
            {renderRemoveStrandButton(index)}
          </div>

          <CriteriaFieldArray control={control} index={index} />
        </section>
      ))}
      <div className="evaluation-plans__form__add-more">
        <button
          type="button"
          className="evaluation-plans__form__add-more__button"
          onClick={onAddStrand}
        >
          <Add className="evaluation-plans__form__add-more__button__icon" />
          <span className="evaluation-plans__form__add-more__button__text">
            {t('EVALUATION_PLANS.ACTIONS.ADD_A_STRAND')}
          </span>
        </button>
      </div>
    </>
  );
}
