import { useQuery } from '@tanstack/react-query';
import type { AdminSettingsDto } from '../../../models/settings/admin/admin-settings.dto';
import apiRequest from '../../../services/apiWrapper';
import { SETTINGS_URL } from '../../../utils/constants/urlConstants';

async function getAdminSettings() {
  const res = await apiRequest.get<AdminSettingsDto>(SETTINGS_URL);

  return res.data;
}

export function useGetAdminSettings() {
  return useQuery({
    queryKey: ['admin-settings'],
    queryFn: () => getAdminSettings(),
    select: (data) => ({
      ...data,
      transportSettings: {
        ...data.transportSettings,
        additionalReceivers: data.transportSettings?.additionalReceivers.length
          ? data.transportSettings?.additionalReceivers.map((item, index) => ({
              name: item,
              id: index,
            }))
          : [{ name: '', id: null }],
      },
    }),
  });
}
