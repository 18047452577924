export const DEFAULT_PAYMENT_FORM_STATE = {
  saleRequest: {
    TERMINAL: '',
    TRTYPE: '',
    AMOUNT: '',
    CURRENCY: '',
    ORDER: '',
    DESC: '',
    MERCHANT: '',
    MERCH_NAME: '',
    MERCH_URL: '',
    EMAIL: '',
    COUNTRY: '',
    MERCH_GMT: '',
    LANG: '',
    ADDENDUM: '',
    'AD.CUST_BOR_ORDER_ID': '',
    TIMESTAMP: '',
    M_INFO: '',
    NONCE: '',
    P_SIGN: '',
    RFU: '',
    BACKREF: '',
  },
  paymentProviderUrl: '',
};
