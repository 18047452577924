import { useMutation } from '@tanstack/react-query';
import type { ParentCreateWithInviteDto } from '../../../models/users/parents/parent-create.dto';
import apiRequest from '../../../services/apiWrapper';
import { FAMILY_URL } from '../../../utils/constants/urlConstants';

async function createParent(family: ParentCreateWithInviteDto) {
  return apiRequest.post<ParentCreateWithInviteDto, void>(FAMILY_URL, family);
}

export function useCreateParent() {
  return useMutation({
    mutationFn: (parent: ParentCreateWithInviteDto) => createParent(parent),
  });
}
