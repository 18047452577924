import { Select, Table } from '@ph-react-ui/core';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate, useSearchParams } from 'react-router-dom';
import { usePaginatedTermReportSections } from '../../hooks/term-reports/employee/usePaginatedTermReportSections';
import {
  getTermReportStatus,
  TERM_REPORT_SECTION_STATUS,
  TERM_REPORT_SECTIONS_STATUS_OPTIONS,
} from '../../utils/constants/term-reports/status';
import { UserOrdering } from '../../utils/enums/user-ordering.enum';
import { ActionMenuHorizontal } from '../shared/components/ActionMenuHorizontal';
import { Pagination } from '../shared/components/Pagination';
import { SearchInput } from '../shared/components/SearchByNameInput';
import { FilterSkeleton } from '../shared/components/skeletons/FilterSkeleton';
import { TableSkeleton } from '../shared/components/skeletons/TableSkeleton';
import { ThSortItem } from '../shared/components/tables/ThSortItem';

export const StudentReports = () => {
  const match = useMatch('/dashboard/term-reports/student-reports');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    data: reports,
    isPending,
    isSuccess,
  } = usePaginatedTermReportSections(searchParams);

  const headers = [
    <div className="users__table">
      <ThSortItem
        title={t('TERM_REPORTS.TABLE.HEADERS.NAME')}
        ordering={UserOrdering.Name}
      />
    </div>,
    t('TERM_REPORTS.TABLE.HEADERS.STATUS'),
    t('TERM_REPORTS.TABLE.HEADERS.REPORT_NAME'),
    t('TERM_REPORTS.TABLE.HEADERS.SECTION_NAME'),
    t('TERM_REPORTS.TABLE.HEADERS.ACTIONS'),
  ];

  const onReportSection = useCallback(
    (id: string) => {
      navigate(`/dashboard/term-reports/student-reports/${id}`);
    },
    [navigate]
  );

  const onFilterByStatusChanged = (
    value: string | number | (string | number)[] | null
  ) => {
    if (value) {
      searchParams.set(
        'status',
        typeof value === 'string' ? value : value?.toString()
      );
      setSearchParams(searchParams);
    } else {
      searchParams.delete('status');
      setSearchParams(searchParams);
    }
  };

  const tableChildren = useMemo(() => {
    return reports?.result.reduce((acc: any, report) => {
      let reportsArray = [
        report.student.name,
        getTermReportStatus(TERM_REPORT_SECTION_STATUS[report.status]),
        report.termReport.name,
        report.name,
        <ActionMenuHorizontal
          options={[
            {
              label: t('ACTIONS.REPORT'),
              onClick: () => {
                onReportSection(report.id);
              },
            },
          ]}
        />,
      ];

      return [...acc, reportsArray];
    }, []);
  }, [t, onReportSection, reports?.result]);

  return (
    <div className="term-reports">
      <div className="term-reports__filters">
        {isPending ? (
          <FilterSkeleton />
        ) : (
          <>
            <SearchInput
              pathMatch={match}
              placeholder={t('TERM_REPORTS.TABLE.FILTERS.SEARCH')}
            />
            <Select
              compact
              options={TERM_REPORT_SECTIONS_STATUS_OPTIONS}
              clearable
              placeholder={
                t('TERM_REPORTS.TABLE.FILTERS.FILTER_BY_STATUS') ?? ''
              }
              onChange={onFilterByStatusChanged}
              value={searchParams.get('status') ?? ''}
            />
          </>
        )}
      </div>
      {isSuccess && reports ? (
        <>
          <Table headers={headers} rows={tableChildren} />
          <div className="term-reports__pagination">
            <Pagination data={reports} />
          </div>
        </>
      ) : (
        <TableSkeleton headers={headers} />
      )}
    </div>
  );
};
