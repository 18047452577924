import { useMutation } from '@tanstack/react-query';
import type { AxiosProgressEvent } from 'axios';
import apiRequest from '../../services/apiWrapper';
import {
  FILES_URL,
  PUBLIC_FILES_URL,
} from '../../utils/constants/urlConstants';
import type { DocumentType } from '../../utils/enums/document-type.enum';

export interface FileUploadResponse {
  id: string;
}

function uploadFile({
  file,
  fileType,
  uploadProgress,
  invitationId,
}: {
  file: File;
  fileType: DocumentType;
  uploadProgress?: (progressEvent: AxiosProgressEvent) => void;
  invitationId?: string;
}) {
  const url = invitationId
    ? `${PUBLIC_FILES_URL}?invitationId=${invitationId}`
    : FILES_URL;
  const formData = new FormData();
  formData.append('file', file);
  formData.append('fileType', fileType as unknown as string);

  return apiRequest.post<any, FileUploadResponse>(url, formData, {
    onUploadProgress: uploadProgress,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}
export function useUploadFile(invitationId?: string) {
  return useMutation({
    mutationFn: ({
      file,
      fileType,
      uploadProgress,
    }: {
      file: File;
      fileType: DocumentType;
      uploadProgress?: (progressEvent: AxiosProgressEvent) => void;
    }) => uploadFile({ file, fileType, uploadProgress, invitationId }),
  });
}
