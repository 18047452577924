import type { SelectOption } from '@ph-react-ui/core';
import { Button, Drawer, useNotifications } from '@ph-react-ui/core';
import { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useGetBusRoutesWithBusStops } from '../../../../hooks/transportation/routes/useGetBusRoutesWithStops';
import { useGetStudentRequestDetails } from '../../../../hooks/transportation/students/useGetStudentRequestDetails';
import { useUpdateTransportationRequestForStudent } from '../../../../hooks/transportation/students/useUpdateTransportationRequestForStudent';
import { useModal } from '../../../../hooks/useModal';
import type { StudentRequestUpdateDto } from '../../../../models/transportation/students/student-request-update.dto';
import type { StudentTransportationUpdateFormDto } from '../../../../models/transportation/students/student-transportation-update-form.dto';
import { Select } from '../../../../utils/components/hoc-components';
import { TRANSPORTATION_EDIT_REQUEST } from '../../../../utils/constants/transportation/modals';
import { TransportationFormDirection } from '../../../../utils/enums/transportation-form-direction.enum';
import { TransportationUsage } from '../../../../utils/enums/transportation-usage.enum';
import { TravelDirection } from '../../../../utils/enums/travel-direction.enum';
import { TravelDirectionRadioGroup } from '../../common/TravelDirectionRadioGroup';

interface ExtenedSelectOption<Type extends string | number>
  extends SelectOption<Type> {
  routeId: string;
}

export function EditTransportationRequestDrawer() {
  const { t } = useTranslation();
  const notification = useNotifications();
  const { close: closeDrawer } = useModal(TRANSPORTATION_EDIT_REQUEST);
  const [searchParams] = useSearchParams();
  const requestId = searchParams.get('requestId');
  const { close: closeModal } = useModal(TRANSPORTATION_EDIT_REQUEST);

  const [pickUpSelectOptions, setPickUpSelectOptions] = useState<
    SelectOption<string>[]
  >([]);
  const [dropOffSelectOptions, setDropOffSelectOptions] = useState<
    SelectOption<string>[]
  >([]);

  const { data: requestDetails } = useGetStudentRequestDetails(requestId);
  const { data: busStopOptions } = useGetBusRoutesWithBusStops({
    routeId: '',
    studentId: requestDetails?.studentId,
  });
  const updateRequest = useUpdateTransportationRequestForStudent(requestId);

  useEffect(() => {
    if (busStopOptions) {
      if (requestDetails?.pickUpStopId) {
        const currentRoute = busStopOptions.find(
          (route: ExtenedSelectOption<string>) =>
            route.value === requestDetails?.pickUpStopId
        )?.routeId;
        setDropOffSelectOptions(
          busStopOptions.filter(
            (option: ExtenedSelectOption<string>) =>
              option.routeId === currentRoute
          )
        );
      } else {
        setDropOffSelectOptions(busStopOptions);
      }
      if (requestDetails?.dropOffStopId) {
        const currentRoute = busStopOptions.find(
          (route: ExtenedSelectOption<string>) =>
            route.value === requestDetails?.dropOffStopId
        )?.routeId;
        setPickUpSelectOptions(
          busStopOptions.filter(
            (option: ExtenedSelectOption<string>) =>
              option.routeId === currentRoute
          )
        );
      } else {
        setPickUpSelectOptions(busStopOptions);
      }
    }
  }, [
    busStopOptions,
    requestDetails?.dropOffStopId,
    requestDetails?.pickUpStopId,
  ]);

  const getTravelDirection = () => {
    if (requestDetails?.dropOffStopId && requestDetails?.pickUpStopId) {
      return TravelDirection.TwoWay.toString();
    } else if (requestDetails?.dropOffStopId || requestDetails?.pickUpStopId) {
      return TravelDirection.OneWay.toString();
    }
    return TravelDirection.NoTransportation.toString();
  };

  const methods = useForm<StudentTransportationUpdateFormDto>({
    defaultValues: {
      travelDirection: TravelDirection.TwoWay.toString(),
      pickUpStopId: '',
      dropOffStopId: '',
    },
    values: {
      ...requestDetails,
      travelDirection: getTravelDirection(),
      direction: requestDetails?.pickUpStopId
        ? TransportationFormDirection.Pick
        : TransportationFormDirection.Drop,
    },
  });

  const travelDirection = methods.watch('travelDirection');
  const direction = methods.watch('direction');
  const pickUpStop = methods.watch('pickUpStopId');
  const dropOffStop = methods.watch('dropOffStopId');

  useEffect(() => {
    const currentRoute = busStopOptions?.find(
      (route: ExtenedSelectOption<string>) => route.value === pickUpStop
    )?.routeId;

    if (currentRoute) {
      const dropOffOptions = busStopOptions.filter(
        (option: ExtenedSelectOption<string>) => option.routeId === currentRoute
      );
      setDropOffSelectOptions(dropOffOptions);
    } else {
      setDropOffSelectOptions(busStopOptions);
    }
  }, [pickUpStop, busStopOptions]);

  useEffect(() => {
    const currentRoute = busStopOptions?.find(
      (route: ExtenedSelectOption<string>) => route.value === dropOffStop
    )?.routeId;

    if (currentRoute) {
      const pickUpOptions = busStopOptions.filter(
        (option: ExtenedSelectOption<string>) => option.routeId === currentRoute
      );
      setPickUpSelectOptions(pickUpOptions);
    } else {
      setPickUpSelectOptions(busStopOptions);
    }
  }, [dropOffStop, busStopOptions]);

  const showPickUpSelect = (+travelDirection === TransportationUsage.TwoWay ||
    (+travelDirection === TransportationUsage.OneWay &&
      direction === TransportationFormDirection.Pick)) as boolean;

  const showDropOffSelect = (+travelDirection === TransportationUsage.TwoWay ||
    (+travelDirection === TransportationUsage.OneWay &&
      direction === TransportationFormDirection.Drop)) as boolean;

  const createFormData = (
    data: StudentTransportationUpdateFormDto
  ): StudentRequestUpdateDto => {
    switch (+data.travelDirection) {
      case TransportationUsage.OneWay:
        return {
          pickUpStopId:
            data.direction === TransportationFormDirection.Pick
              ? data?.pickUpStopId!
              : null,
          dropOffStopId:
            data.direction === TransportationFormDirection.Drop
              ? data.dropOffStopId!
              : null,
        };
      case TransportationUsage.TwoWay:
        return {
          pickUpStopId: data.pickUpStopId!,
          dropOffStopId: data.dropOffStopId!,
        };
      default: {
        return {
          pickUpStopId: null,
          dropOffStopId: null,
        };
      }
    }
  };

  const onSubmit = (data: StudentTransportationUpdateFormDto) => {
    const formData = createFormData(data);
    updateRequest
      .mutateAsync(formData)
      .then(() => {
        notification.success(
          t('TRANSPORTATION_MANAGEMENT.MESSAGES.SUCCESS_EDIT_REQUEST')
        );
        closeModal();
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  return (
    <Drawer
      header={t('TRANSPORTATION_MANAGEMENT.LABELS.EDIT_REQUEST')}
      footer={
        <div className="drawer__transportation-management__form__actions">
          <Button
            onClick={() => closeDrawer()}
            type="button"
            className="drawer__transportation-management__form__actions__cancel"
            variant="outlined"
          >
            {t('ACTIONS.CANCEL')}
          </Button>
          <Button
            form="transportation-form"
            type="submit"
            onClick={methods.handleSubmit(onSubmit)}
          >
            {t('ACTIONS.SAVE')}
          </Button>
        </div>
      }
    >
      <FormProvider {...methods}>
        <form
          id="transportation-plan"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <div className="drawer__transportation-management__form__radio-group">
            <TravelDirectionRadioGroup travelDirection={travelDirection} />
          </div>
          <div className="drawer__transportation-management__form__selects">
            {travelDirection &&
              +travelDirection === TransportationUsage.OneWay && (
                <Select
                  name="direction"
                  label={
                    t('TRANSPORTATION_MANAGEMENT.DRAWER.LABELS.DIRECTION') ?? ''
                  }
                  placeholder={
                    t(
                      'TRANSPORTATION_MANAGEMENT.DRAWER.PLACEHOLDERS.SELECT_DIRECTION'
                    ) ?? ''
                  }
                  options={[
                    {
                      value: TransportationFormDirection.Pick,
                      label: t(
                        'TRANSPORTATION_MANAGEMENT.DRAWER.LABELS.PICK_UP'
                      ),
                    },
                    {
                      value: TransportationFormDirection.Drop,
                      label: t(
                        'TRANSPORTATION_MANAGEMENT.DRAWER.LABELS.DROP_OFF'
                      ),
                    },
                  ]}
                />
              )}
            {travelDirection && showPickUpSelect && (
              <Select
                clearable
                name="pickUpStopId"
                rules={{
                  required: {
                    value: showPickUpSelect,
                    message: t('ERROR.VALIDATION.REQUIRED'),
                  },
                }}
                label={
                  t('TRANSPORTATION_MANAGEMENT.DRAWER.LABELS.PICKUP_TIME') ?? ''
                }
                placeholder={
                  t('TRANSPORTATION_MANAGEMENT.DRAWER.PLACEHOLDERS.PICK_UP') ??
                  ''
                }
                options={pickUpSelectOptions}
              />
            )}
            {travelDirection && showDropOffSelect && (
              <Select
                clearable
                name="dropOffStopId"
                rules={{
                  required: {
                    value: showDropOffSelect,
                    message: t('ERROR.VALIDATION.REQUIRED'),
                  },
                }}
                label={
                  t('TRANSPORTATION_MANAGEMENT.DRAWER.LABELS.DROPOFF_TIME') ??
                  ''
                }
                placeholder={
                  t('TRANSPORTATION_MANAGEMENT.DRAWER.PLACEHOLDERS.DROP_OFF') ??
                  ''
                }
                options={dropOffSelectOptions}
              />
            )}
          </div>
        </form>
      </FormProvider>
    </Drawer>
  );
}
