import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { mapDateToSimpleDate } from '../../../../../services/mapDateToSimpleDate';
import {
  DatePicker,
  Input,
} from '../../../../../utils/components/hoc-components';
import {
  getCommonValidators,
  VALIDATION as V,
} from '../../../../../utils/inputValidators';

interface ActiveEmployeeSectionProps {
  isPedagogicalEmployee?: boolean;
}

export const ActiveEmployeeSection = ({
  isPedagogicalEmployee = false,
}: ActiveEmployeeSectionProps) => {
  const location = useLocation();
  const { t } = useTranslation();
  const isCompletingProfile = location.pathname.includes('complete-profile');
  const isEmployee = isCompletingProfile || isPedagogicalEmployee;

  return (
    <>
      <div className="mb-6">
        <Input
          name="phoneNumber"
          rules={{
            required: {
              value: isEmployee,
              message: t('ERROR.VALIDATION.REQUIRED'),
            },
            maxLength: {
              value: 20,
              message: t('USERS.DRAWER.ERRORS.PHONE_TOO_LONG'),
            },
          }}
          label={t('USERS.DRAWER.LABELS.PHONE') ?? ''}
          className="drawer__users__form__input"
          placeholder={t('USERS.DRAWER.PLACEHOLDERS.PHONE') ?? ''}
        />
      </div>

      <div className="mb-6">
        <DatePicker
          name="birthDate"
          maxDate={mapDateToSimpleDate(new Date())}
          className="drawer__users__form__input"
          placeholder={t('USERS.DRAWER.PLACEHOLDERS.BIRTH_DATE') ?? ''}
          label={t('USERS.DRAWER.LABELS.BIRTH_DATE') ?? ''}
        />
      </div>

      <div className="mb-6">
        <Input
          name="birthplace"
          rules={getCommonValidators([V.MAX_LENGTH])}
          label={t('USERS.DRAWER.LABELS.BIRTHPLACE') ?? ''}
          className="drawer__users__form__input"
          placeholder={t('USERS.DRAWER.PLACEHOLDERS.BIRTHPLACE') ?? ''}
        />
      </div>

      <div className="mb-6">
        <Input
          name="address"
          rules={getCommonValidators([V.MAX_LENGTH])}
          label={t('USERS.DRAWER.LABELS.ADDRESS') ?? ''}
          className="drawer__users__form__input"
          placeholder={t('USERS.DRAWER.PLACEHOLDERS.ADDRESS') ?? ''}
        />
      </div>

      <div className="mb-6">
        <Input
          name="yearsOfExperience"
          inputType="number"
          rules={{
            ...getCommonValidators([V.MAX_LENGTH]),
            required: {
              value: isPedagogicalEmployee,
              message: t('ERROR.VALIDATION.REQUIRED'),
            },
            min: {
              value: 0,
              message: t(
                'USERS.DRAWER.ERRORS.YEARS_OF_EXPERIENCE_LENGTH_ERROR'
              ),
            },
          }}
          label={t('USERS.DRAWER.LABELS.YEARS_OF_EXPERIENCE') ?? ''}
          className="drawer__users__form__input"
          placeholder={t('USERS.DRAWER.PLACEHOLDERS.YEARS_OF_EXPERIENCE') ?? ''}
        />
      </div>

      <div className="mb-6">
        <Input
          name="pks"
          rules={getCommonValidators([V.MAX_LENGTH])}
          label={t('USERS.DRAWER.LABELS.PKS') ?? ''}
          className="drawer__users__form__input"
          placeholder={t('USERS.DRAWER.PLACEHOLDERS.PKS') ?? ''}
        />
      </div>

      <div className="mb-6">
        <Input
          name="foreignLanguages"
          rules={getCommonValidators([V.MAX_LENGTH])}
          label={t('USERS.DRAWER.LABELS.FOREIGN_LANGUAGES') ?? ''}
          className="drawer__users__form__input"
          placeholder={t('USERS.DRAWER.PLACEHOLDERS.FOREIGN_LANGUAGES') ?? ''}
        />
      </div>

      <div className="mb-6">
        <Input
          name="computerSkills"
          rules={getCommonValidators([V.MAX_LENGTH])}
          label={t('USERS.DRAWER.LABELS.COMPUTER_SKILLS') ?? ''}
          className="drawer__users__form__input"
          placeholder={t('USERS.DRAWER.PLACEHOLDERS.COMPUTER_SKILLS') ?? ''}
        />
      </div>
      <div className="mb-6">
        <Input
          name="egn"
          rules={getCommonValidators([V.NUMBER_PATTERN, V.EGN_MAX, V.EGN_MIN])}
          label={t('USERS.DRAWER.LABELS.EGN') ?? ''}
          className="drawer__users__form__input"
          placeholder={t('USERS.DRAWER.PLACEHOLDERS.EGN') ?? ''}
        />
      </div>
    </>
  );
};
