import { Printer } from '@carbon/icons-react';
import { Button, Tooltip, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCreateMealMenuForMonthAndYear } from '../../../hooks/meal-menus/admin/useCreateMealMenuForMonthAndYear';
import { useGetEmployeeAbsenceAvailabilityByDate } from '../../../hooks/meal-menus/admin/useGetEmployeeAbsenceAvailability';
import { useGetMealMenuForMonthAndYear } from '../../../hooks/meal-menus/admin/useGetMealMenuForMonthAndYear';
import { useGetAdminSettings } from '../../../hooks/settings/admin/useGetAdminSettings';
import { useModal } from '../../../hooks/useModal';
import type { MealMenuCreateFormDto } from '../../../models/meal-menus/admin/meal-menu-create-form.dto';
import type { MealMenuCreateDto } from '../../../models/meal-menus/admin/meal-menu-create.dto';
import { Input, Select } from '../../../utils/components/hoc-components';
import { REGISTER_EMPLOYEE_ABSENCE } from '../../../utils/constants/food-management/modals';
import { MealType } from '../../../utils/enums/meal-type.enum';
import {
  getCommonValidators,
  VALIDATION as V,
} from '../../../utils/inputValidators';
import { MonthPagination } from '../../shared/components/MonthPagination';
import { PreviewFileUpload } from '../../shared/components/PreviewFileUpload';

export function AdminFoodManagement() {
  const queryClient = useQueryClient();
  const numberOfWeeksOptions = Array.from({ length: 6 }, (_, i) => i + 1).map(
    (option) => ({ label: option.toString(), value: option })
  );
  const { open: openRegisterAbsenceModal } = useModal(
    REGISTER_EMPLOYEE_ABSENCE
  );
  const [searchParams] = useSearchParams();
  const month = searchParams.get('month');
  const year = searchParams.get('year');
  const currentDate = new Date();
  const navigate = useNavigate();
  const notification = useNotifications();
  const createMealMenu = useCreateMealMenuForMonthAndYear();

  const { data: absenceAvailability } =
    useGetEmployeeAbsenceAvailabilityByDate();

  const { data: mealMenu } = useGetMealMenuForMonthAndYear({
    month: +month!,
    year: +year!,
  });

  const { data: settings } = useGetAdminSettings();

  const { t } = useTranslation();
  const defaultValues: MealMenuCreateFormDto = useMemo(
    () => ({
      numberOfWeeks: 6,
      numberOfDays: '',
      vegWeeks: [],
      meatWeeks: [],
    }),
    []
  );

  const methods = useForm<MealMenuCreateFormDto>({
    defaultValues,
    values: mealMenu ?? defaultValues,
  });

  const { reset, setValue } = methods;

  const {
    insert: insertVegFiles,
    remove: removeVegFiles,
    update: updateVegFile,
  } = useFieldArray({
    control: methods.control,
    name: 'vegWeeks',
    keyName: 'vegWeeks',
  });

  const {
    insert: insertMeatFiles,
    remove: removeMeatFiles,
    update: updateMeatFile,
  } = useFieldArray({
    control: methods.control,
    name: 'meatWeeks',
    keyName: 'meatWeeks',
  });

  const vegWeeks = methods.watch('vegWeeks');
  const meatWeeks = methods.watch('meatWeeks');
  const numberOfWeeks = methods.watch('numberOfWeeks');
  const numberOfDays = methods.watch('numberOfDays');

  const onSubmit = (data: MealMenuCreateFormDto) => {
    const formData: MealMenuCreateDto = {
      numberOfWeeks,
      numberOfDays: +data.numberOfDays!,
      month: +month!,
      year: +year!,
      weeks: [...data.meatWeeks, ...data.vegWeeks],
    };

    createMealMenu
      .mutateAsync(formData)
      .then(() => {
        notification.success(t('FOOD_MANAGEMENT.MESSAGES.MEAL_MENU_PUBLISHED'));
        queryClient.invalidateQueries({
          queryKey: ['meal-menu', +month!, +year!],
        });
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  const addFileToVegFieldArray = ({
    id,
    index,
    type,
  }: {
    id: string;
    index: number;
    type: MealType;
  }) => {
    updateVegFile(index, { fileId: id, index: index + 1, type });
  };

  const addFileToMeatFieldArray = ({
    id,
    index,
    type,
  }: {
    id: string;
    index: number;
    type: MealType;
  }) => {
    updateMeatFile(index, { fileId: id, index: index + 1, type });
  };

  const navigateToPrintMeals = (weekIndex: number) => {
    navigate(
      `/dashboard/food-management/${mealMenu?.id}/weekly-report?week=${weekIndex}&month=${month}&year=${year}`
    );
  };

  const fileIsMissing = useMemo(() => {
    return (
      meatWeeks.some((file) => file.fileId === '') ||
      vegWeeks.some((file) => file.fileId === '')
    );
  }, [meatWeeks, vegWeeks]);

  useEffect(() => {
    if (!mealMenu) {
      if (vegWeeks.length > numberOfWeeks) {
        const index = vegWeeks.length;
        const indexesToRemove = Array(vegWeeks.length - numberOfWeeks)
          .fill(1)
          .map((_, i) => index - 1 - i);
        removeVegFiles(indexesToRemove);
      } else if (vegWeeks.length < numberOfWeeks) {
        let index = vegWeeks.length;
        while (index < numberOfWeeks) {
          insertVegFiles(index, {
            index: index + 1,
            fileId: '',
            type: MealType.Vegeterian,
          });
          index++;
        }
      }
    }
  }, [
    setValue,
    mealMenu,
    numberOfWeeks,
    vegWeeks.length,
    insertVegFiles,
    removeVegFiles,
  ]);

  useEffect(() => {
    if (!mealMenu) {
      if (meatWeeks.length > numberOfWeeks) {
        const index = meatWeeks.length;
        const indexesToRemove = Array(meatWeeks.length - numberOfWeeks)
          .fill(1)
          .map((_, i) => index - 1 - i);
        removeMeatFiles(indexesToRemove);
      } else if (meatWeeks.length < numberOfWeeks) {
        let index = meatWeeks.length;
        while (index < numberOfWeeks) {
          insertMeatFiles(index, {
            index: index + 1,
            fileId: '',
            type: MealType.Meat,
          });
          index++;
        }
      }
    }
  }, [
    mealMenu,
    numberOfWeeks,
    meatWeeks.length,
    insertMeatFiles,
    removeMeatFiles,
  ]);

  const onOpenRegisterAbsenceModal = () => {
    openRegisterAbsenceModal();
  };

  if (!year || !month) {
    navigate(
      `/dashboard/food-management?month=${currentDate.getMonth()}&year=${currentDate.getFullYear()}`
    );
  }

  return (
    <FormProvider {...methods}>
      <form
        className="food-management"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <div className="food-management__actions">
          <div className="food-management__actions__buttons">
            <Button
              compact
              disabled={fileIsMissing || Boolean(mealMenu)}
              type="submit"
            >
              {t('ACTIONS.PUBLISH')}
            </Button>
            {absenceAvailability?.isAvailable ? (
              <Button compact onClick={onOpenRegisterAbsenceModal}>
                {t('ACTIONS.REGISTER_EMPLOYEE_ABSENCE')}
              </Button>
            ) : (
              <Tooltip
                position="right"
                message={t('FOOD_MANAGEMENT.LABELS.EMPLOYEE_ABSENCE_TOOLTIP')}
              >
                <Button compact disabled>
                  {t('ACTIONS.REGISTER_EMPLOYEE_ABSENCE')}
                </Button>
              </Tooltip>
            )}
          </div>
          {month && year && (
            <MonthPagination
              month={month}
              year={year}
              action={() => {
                reset(defaultValues);
              }}
            />
          )}
        </div>
        <div className="food-management__filter">
          <div className="food-management__filter__inputs">
            <Input
              name="numberOfDays"
              rules={{
                ...getCommonValidators([V.REQUIRED, V.NUMBER_PATTERN]),
                max: {
                  value: 31,
                  message: t('FOOD_MANAGEMENT.VALIDATION.MAX_WORKING_DAYS'),
                },
                min: {
                  value: 1,
                  message: t('FOOD_MANAGEMENT.VALIDATION.MIN_WORKING_DAYS'),
                },
              }}
              compact
              disabled={Boolean(mealMenu)}
              label={t('FOOD_MANAGEMENT.LABELS.NUMBER_OF_SCHOOL_DAYS') ?? ''}
              placeholder={
                t('FOOD_MANAGEMENT.LABELS.NUMBER_OF_SCHOOL_DAYS') ?? ''
              }
            />
            <Select
              name="numberOfWeeks"
              rules={getCommonValidators([V.REQUIRED])}
              placeholder={t('FOOD_MANAGEMENT.LABELS.NUMBER_OF_WEEKS') ?? ''}
              label={t('FOOD_MANAGEMENT.LABELS.NUMBER_OF_WEEKS') ?? ''}
              compact
              disabled={Boolean(mealMenu)}
              options={numberOfWeeksOptions}
            ></Select>
          </div>
          <div className="food-management__filter__calculations">
            {numberOfDays && settings ? (
              <div className="food-management__filter__calculations__item">
                <span className="food-management__filter__calculations__item__label">
                  {t('FOOD_MANAGEMENT.LABELS.MONTHLY_PRICE_STUDENTS')}
                </span>
                <span className="food-management__filter__calculations__item__value">
                  {+settings?.foodSettings.studentDailyPrice * +numberOfDays}
                  &nbsp;
                  {t('FOOD_MANAGEMENT.LABELS.BGN')}
                </span>
              </div>
            ) : null}
            {numberOfDays && settings ? (
              <div className="food-management__filter__calculations__item">
                <span className="food-management__filter__calculations__item__label">
                  {t('FOOD_MANAGEMENT.LABELS.MONTHLY_PRICE_EMPLOYEES')}
                </span>
                <span className="food-management__filter__calculations__item__value">
                  {+settings?.foodSettings.employeeDailyPrice * +numberOfDays}
                  &nbsp;
                  {t('FOOD_MANAGEMENT.LABELS.BGN')}
                </span>
              </div>
            ) : null}
          </div>
        </div>
        <div className="food-management__calendar">
          {vegWeeks.map((file, index) => {
            return (
              <div
                className="food-management__calendar__item"
                style={{ gridRow: 1 }}
                key={index}
              >
                {
                  <h4 className="food-management__calendar__item__title">
                    {t('FOOD_MANAGEMENT.LABELS.WEEK')} {index + 1}
                  </h4>
                }
                <PreviewFileUpload
                  id={mealMenu ? file.fileId : undefined}
                  appendFile={(id: string) =>
                    addFileToVegFieldArray({
                      id,
                      index,
                      type: file.type,
                    })
                  }
                />
              </div>
            );
          })}
          {meatWeeks.map((file, index) => {
            return (
              <div
                className="food-management__calendar__item"
                style={{ gridRow: 2 }}
                key={index}
              >
                <PreviewFileUpload
                  id={mealMenu ? file.fileId : undefined}
                  appendFile={(id: string) =>
                    addFileToMeatFieldArray({
                      id,
                      index,
                      type: file.type,
                    })
                  }
                />
              </div>
            );
          })}
          {Array.from({ length: numberOfWeeks }).map((_, index) => {
            return (
              <Button
                variant="outlined"
                className="m-3 mt-5"
                disabled={!mealMenu?.id}
                style={{ gridRow: 3 }}
                key={index}
                onClick={() => navigateToPrintMeals(index + 1)}
              >
                <Printer />
                {t('FOOD_MANAGEMENT.LABELS.PRINT_DAILY_REPORT_WEEK')}
                {index + 1}
              </Button>
            );
          })}
        </div>
      </form>
    </FormProvider>
  );
}
