import { useMutation } from '@tanstack/react-query';
import apiRequest from '../../../../../services/apiWrapper';
import { SCHOOL_TAX_PAYMENTS } from '../../../../../utils/constants/urlConstants';

async function cancelSchoolTaxPayment(transactionId: string) {
  return apiRequest.delete(`${SCHOOL_TAX_PAYMENTS}/${transactionId}`, {});
}

export function useCancelSchoolTaxPayment() {
  return useMutation({
    mutationFn: (transactionId: string) =>
      cancelSchoolTaxPayment(transactionId),
  });
}
