import { Button, useNotifications } from '@ph-react-ui/core';
import type { SimpleDate } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { FormProvider, useForm, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useCancellationDialog } from '../../../../hooks/useCancellationDialog';
import { useModal } from '../../../../hooks/useModal';
import { useCreateEmployee } from '../../../../hooks/users/employees/useCreateEmployee';
import { useGetEmployeeById } from '../../../../hooks/users/employees/useGetEmployeeById';
import { useUpdateEmployeeById } from '../../../../hooks/users/employees/useUpdateEmployeeById';
import { useUpdateNonActiveEmployeeById } from '../../../../hooks/users/employees/useUpdateNonActiveEmployeeById';
import { useInvitationById } from '../../../../hooks/users/useInvitationById';
import type { CreateUserForm } from '../../../../models/users/create-user-form';
import type { EmployeeCreateDto } from '../../../../models/users/employees/employee-create.dto';
import type { EmployeeUpdateFormDto } from '../../../../models/users/employees/employee-update-form.dto';
import type { EmployeeUpdateDto } from '../../../../models/users/employees/employee-update.dto';
import { mapSimpleDateToString } from '../../../../services/mapSimpleDateToString';
import { Input } from '../../../../utils/components/hoc-components';
import { CREATE_EDIT_USER } from '../../../../utils/constants/users/modals';
import {
  getCommonValidators,
  VALIDATION as V,
} from '../../../../utils/inputValidators';
import { isActiveUser } from '../../../../utils/isActiveUser';
import { isArchivedUser } from '../../../../utils/isArchivedUser';
import { isNewUser } from '../../../../utils/isNewUser';
import { isNonActiveUser } from '../../../../utils/isNonActiveUser';
import { FileUpload } from '../../../shared/components/FileUpload';
import { FormSkeleton } from '../../../shared/components/skeletons/FormSkeleton';
import { BasicInfoSection } from './common/BasicInfoSection';
import { FilesTable } from './common/FilesTable';
import { ActiveEmployeeSection } from './employee/ActiveEmployeeSection';
import { EmployeePermissionsSection } from './employee/EmployeePermissions';
import { PedagogicalSection } from './employee/PedagogicalSection';

export function EmployeeForm({ footerElement }: CreateUserForm) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const notification = useNotifications();
  const { open: openCancellationDialog } = useCancellationDialog();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('userId');
  const status = searchParams.get('userStatus');
  const [isInviting, setIsInviting] = useState<boolean | null>(null);
  const { data: user, isLoading } = useGetEmployeeById(id, Boolean(id));
  const { close: closeModal } = useModal(CREATE_EDIT_USER);

  const createNewEmployee = useCreateEmployee();
  const updateNonActiveEmployee = useUpdateNonActiveEmployeeById(id);
  const updateActiveEmployee = useUpdateEmployeeById(id);
  const sendInvitation = useInvitationById();

  const methods = useForm<EmployeeUpdateFormDto>({
    defaultValues: {
      name: '',
      email: '',
      position: '',
      canCreateStudentPlans: false,
      canOrganizeTransport: false,
      canAccessStudentAbsences: false,
      phoneNumber: '',
      address: '',
      birthDate: '' as unknown as SimpleDate,
      egn: '',
      birthplace: '',
      pks: '',
      computerSkills: '',
      foreignLanguages: '',
      yearsOfExperience: 0,
      education: '',
      levelOfEducation: '',
      diplomaSeries: '',
      diplomaNumber: '',
      diplomaIssueDate: '' as unknown as SimpleDate,
      files: [],
    },
    values: user,
  });

  const { isDirty } = methods.formState;

  const { append: appendFileToTable, remove: removeFileFromTable } =
    useFieldArray({
      control: methods.control,
      name: 'files',
      keyName: 'files',
    });

  const files = methods.watch('files');

  const onSubmit = async (
    data: EmployeeUpdateFormDto,
    shouldInvite: boolean
  ) => {
    setIsInviting(shouldInvite);
    if (id) {
      updateEmployee(data);
    } else {
      createEmployee(data, shouldInvite);
    }
  };

  const createEmployee = async (
    data: EmployeeCreateDto,
    shouldInvite: boolean
  ) => {
    await createNewEmployee
      .mutateAsync({ data, shouldInvite })
      .then(() => {
        notification.success(t('USERS.DRAWER.MESSAGES.EMPLOYEE_CREATED'));
        queryClient.invalidateQueries({ queryKey: ['users'] });
        closeModal();
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  const updateEmployee = async (data: EmployeeUpdateFormDto) => {
    if (isActiveUser(status)) {
      const { files, birthDate, diplomaIssueDate, ...rest } = data;
      const formData: EmployeeUpdateDto = {
        ...rest,
        birthDate: mapSimpleDateToString(birthDate),
        diplomaIssueDate: mapSimpleDateToString(diplomaIssueDate),
        fileIds: files.map((file) => file.id),
      };
      await updateActiveEmployee
        .mutateAsync(formData)
        .then(() =>
          notification.success(t('USERS.DRAWER.MESSAGES.EMPLOYEE_UPDATED'))
        )
        .catch((resError) => {
          notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
        });
    } else {
      await updateNonActiveEmployee
        .mutateAsync(data)
        .then(() =>
          notification.success(t('USERS.DRAWER.MESSAGES.EMPLOYEE_UPDATED'))
        )
        .catch((resError) => {
          notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
        });
    }
    queryClient.invalidateQueries({ queryKey: ['users'] });
    queryClient.invalidateQueries({ queryKey: ['employee', id] });
  };

  const onSendInvitation = () => {
    sendInvitation
      .mutateAsync(id!)
      .then(() => {
        notification.success(t('USERS.DRAWER.MESSAGES.INVITATION_SEND'));
        queryClient.invalidateQueries({ queryKey: ['users'] });
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  const onCloseDrawer = () => {
    if (isDirty) {
      openCancellationDialog();
    } else {
      closeModal();
    }
  };

  const isPerformingApiRequest =
    createNewEmployee.isPending ||
    updateNonActiveEmployee.isPending ||
    updateActiveEmployee.isPending;

  if (isLoading && id) {
    return <FormSkeleton />;
  }
  return (
    <FormProvider {...methods}>
      <form id="employee-form" className="drawer__users__form">
        <EmployeePermissionsSection />
        <BasicInfoSection />
        <div className="mb-6">
          <Input
            name="position"
            rules={getCommonValidators([V.MAX_LENGTH])}
            label={t('USERS.DRAWER.LABELS.POSITION') ?? ''}
            className="drawer__users__form__input"
            placeholder={t('USERS.DRAWER.PLACEHOLDERS.POSITION') ?? ''}
          />
        </div>
        {isActiveUser(status) && (
          <>
            <ActiveEmployeeSection />
            <PedagogicalSection />
            <div>
              <h3 className="my-8 drawer__users__form__students__title">
                {t('USERS.DRAWER.TITLES.DOCUMENTS')}
              </h3>
              <FilesTable data={files} removeFile={removeFileFromTable} />
              <FileUpload appendFile={appendFileToTable} />
            </div>
          </>
        )}
        {footerElement &&
          createPortal(
            <>
              <Button
                onClick={onCloseDrawer}
                typeof="button"
                className="drawer__users__form__actions__cancel"
                variant="outlined"
              >
                {t('ACTIONS.CANCEL')}
              </Button>
              <Button
                form="employee-form"
                type="button"
                onClick={methods.handleSubmit((data) => onSubmit(data, false))}
                loading={!isInviting ? isPerformingApiRequest : false}
                disabled={isPerformingApiRequest || isArchivedUser(status)}
              >
                {id ? t('ACTIONS.UPDATE') : t('ACTIONS.SAVE')}
              </Button>
              {id ? (
                isNonActiveUser(status) && (
                  <Button
                    type="button"
                    onClick={onSendInvitation}
                    loading={isInviting ? isPerformingApiRequest : false}
                    disabled={
                      isPerformingApiRequest || methods.formState.isDirty
                    }
                  >
                    {isNewUser(status)
                      ? t('ACTIONS.SEND_INVITATION')
                      : t('ACTIONS.SEND_INVITATION_AGAIN')}
                  </Button>
                )
              ) : (
                <Button
                  type="button"
                  onClick={methods.handleSubmit((data) => onSubmit(data, true))}
                  loading={isInviting ? isPerformingApiRequest : false}
                  disabled={isArchivedUser(status) || isPerformingApiRequest}
                >
                  {t('ACTIONS.SAVE_AND_INVITE')}
                </Button>
              )}
            </>,
            footerElement
          )}
      </form>
    </FormProvider>
  );
}
