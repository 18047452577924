import { Car, Timer } from '@carbon/icons-react';
import type { SelectOption } from '@ph-react-ui/core';
import { Button, Select } from '@ph-react-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useGetAuthenticatedParentProfile } from '../../../hooks/profile/parent/useGetAuthenticatedParentProfile';
import { useGetBusStopsPerStudent } from '../../../hooks/transportation/parent/useGetBusStopsPerStudent';
import { useGetBusRoutesMap } from '../../../hooks/transportation/routes/useGetBusRoutesMap';
import { useModal } from '../../../hooks/useModal';
import { useGetStudentsForParent } from '../../../hooks/users/parents/useGetStudentsForParent';
// import { formatTimeWithoutSeconds } from '../../../services/mappingsSimpleTime';
import { TRANSPORTATION_REQUEST } from '../../../utils/constants/transportation/modals';
import { realTimeUrl } from '../../../utils/constants/transportation/transportation-realtime-data';

export function TransportationPlan() {
  const { t } = useTranslation();
  const { open: openTransportationRequest } = useModal(TRANSPORTATION_REQUEST);
  const [studentOptions, setStudentOptions] = useState<SelectOption<string>[]>(
    []
  );
  const [searchParams, setSearchParams] = useSearchParams();

  const studentIdParam =
    searchParams.get('studentId') ?? studentOptions?.[0]?.value;

  const { data: parent } = useGetAuthenticatedParentProfile();
  const { data: students } = useGetStudentsForParent({
    enabled: Boolean(parent),
  });
  const { data: busRouteDetailsPerStudent } = useGetBusStopsPerStudent(
    studentIdParam,
    Boolean(studentIdParam)
  );
  const { data: busRoutesMap, isSuccess } = useGetBusRoutesMap();

  useEffect(() => {
    const studentOptions = (students || []).map((student) => ({
      label: student.name,
      value: student.id,
    }));

    setStudentOptions(studentOptions);
  }, [students, searchParams, setSearchParams]);

  const onSelectStudent = (
    value: string | number | (string | number)[] | null
  ) => {
    if (value) {
      searchParams.set('studentId', value.toString());
      setSearchParams(searchParams);
    }
  };

  const handleOpenRealtime = () => {
    window.open(realTimeUrl);
  };

  const onOpenTransportationRequestModal = () => {
    openTransportationRequest({
      studentId: studentIdParam,
    });
  };

  return (
    <div className="transportation-plan">
      <div className="transportation-plan__actions">
        <div className="transportation-plan__actions__watch">
          <Select
            placeholder={
              t('TRANSPORTATION_MANAGEMENT.MESSAGES.CHOOSE_STUDENT') ?? ''
            }
            label={t('TRANSPORTATION_MANAGEMENT.MESSAGES.CHOOSE_STUDENT') ?? ''}
            compact
            options={studentOptions}
            value={studentIdParam}
            onChange={(value) => onSelectStudent(value)}
          />
          <Button
            compact
            variant="outlined"
            color="primary"
            onClick={handleOpenRealtime}
          >
            <Timer />
            {t('TRANSPORTATION_MANAGEMENT.MESSAGES.MAP_WATCH')}
          </Button>
        </div>
        <Button
          compact
          color="primary"
          onClick={onOpenTransportationRequestModal}
          disabled
        >
          <Car />
          {t('TRANSPORTATION_MANAGEMENT.ACTIONS.TRANSPORTATION_USAGE')}
        </Button>
      </div>
      {busRouteDetailsPerStudent && busRouteDetailsPerStudent.busRouteId ? (
        <div className="transportation-plan__view">
          <h4>{t('TRANSPORTATION_MANAGEMENT.MESSAGES.ASSIGNED_MESSAGE')}</h4>
        </div>
      ) : (
        // <div className="transportation-plan__view">
        //   <div className="transportation-plan__view__route-details">
        //     <div className="transportation-plan__view__route-details__name">
        //       <h4>{`Route ${busRouteDetailsPerStudent.name}`}</h4>
        //     </div>
        //     <div className="transportation-plan__view__route-details__info">
        //       <h4>
        //         {`${t('TRANSPORTATION_MANAGEMENT.MESSAGES.BUS_NUMBER')}: ${
        //           busRouteDetailsPerStudent.busNumber
        //         }`}
        //       </h4>
        //       <h4>
        //         {`${t('TRANSPORTATION_MANAGEMENT.MESSAGES.CONTACT_NUMBER')}:
        //         ${busRouteDetailsPerStudent.busContactPhoneNumber}`}
        //       </h4>
        //     </div>
        //   </div>
        //   <div className="transportation-plan__view__route-plan">
        //     {busRouteDetailsPerStudent.pickUpStop && (
        //       <h2>
        //         {`${t(
        //           'TRANSPORTATION_MANAGEMENT.MESSAGES.PICKUP'
        //         )}: ${formatTimeWithoutSeconds(
        //           busRouteDetailsPerStudent.pickUpStop.time
        //         )} ${busRouteDetailsPerStudent.pickUpStop.address}`}
        //       </h2>
        //     )}
        //     {busRouteDetailsPerStudent.dropOffStop && (
        //       <h2>
        //         {`${t(
        //           'TRANSPORTATION_MANAGEMENT.MESSAGES.DROPOFF'
        //         )}: ${formatTimeWithoutSeconds(
        //           busRouteDetailsPerStudent.dropOffStop.time
        //         )} ${busRouteDetailsPerStudent.dropOffStop.address}`}
        //       </h2>
        //     )}
        //   </div>
        // </div>
        <div className="transportation-plan__not-assigned">
          <h4>
            {t('TRANSPORTATION_MANAGEMENT.MESSAGES.NOT_ASSIGNED_MESSAGE')}
          </h4>
        </div>
      )}
      {isSuccess && busRoutesMap.uri && (
        <div className="transportation-plan__map">
          <iframe title="transportation-plan" src={busRoutesMap.uri}></iframe>
        </div>
      )}
    </div>
  );
}
