import { Tabs, Tab } from '@ph-react-ui/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { StudentsTransportationInUsage } from './StudentsTransportationInUsage';
import { StudentsTransportationPendingRequests } from './StudentsTransportationPendingRequests';

export const StudentsTransportationManagement = () => {
  const { t } = useTranslation();
  const [, setSearchParams] = useSearchParams();
  const [tabSelected, setTabSelected] = useState<string | number>('in-usage');

  return (
    <div className="term-reports">
      <Tabs
        selected={tabSelected}
        onSelectedChange={(selected) => {
          setTabSelected(selected);
          setSearchParams('');
        }}
      >
        <Tab id="in-usage" title={t('TRANSPORTATION_MANAGEMENT.TABS.IN_USAGE')}>
          <StudentsTransportationInUsage />
        </Tab>
        <Tab id="pending" title={t('TRANSPORTATION_MANAGEMENT.TABS.PENDING')}>
          <StudentsTransportationPendingRequests />
        </Tab>
      </Tabs>
    </div>
  );
};
