import { useQuery } from '@tanstack/react-query';
import type { StudentMealMenuDetailsDto } from '../../../models/meal-menus/student/student-meal-menu-details.dto';
import apiRequest from '../../../services/apiWrapper';
import { MEAL_MENUS_URL } from '../../../utils/constants/urlConstants';

async function getStudentMonthlyMealMenu(
  studentId: string,
  year: string | null,
  month: string | null
) {
  const response = await apiRequest.get<StudentMealMenuDetailsDto>(
    `${MEAL_MENUS_URL}/students/${studentId}?year=${year}&month=${month}`
  );

  return response.data;
}

export function useGetStudentMonthlyMealMenu({
  studentId,
  month,
  year,
}: {
  studentId: string;
  month: string | null;
  year: string | null;
}) {
  return useQuery({
    queryKey: ['student-meal-menu', studentId, month, year],
    queryFn: () => getStudentMonthlyMealMenu(studentId, year, month),
    enabled: Boolean(studentId),
    throwOnError: false,
    retry: false,
  });
}
