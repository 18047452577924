import { Add, TrashCan } from '@carbon/icons-react';
import { Button, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGetAdminSettings } from '../../../hooks/settings/admin/useGetAdminSettings';
import { useUpdateAdminSettings } from '../../../hooks/settings/admin/useUpdateAdminSettings';
import type { AdminSettingsFormDto } from '../../../models/settings/admin/admin-settings-form.dto';
import type { AdminSettingsDto } from '../../../models/settings/admin/admin-settings.dto';
import { Input } from '../../../utils/components/hoc-components';
import {
  getCommonValidators,
  getRequiredValidator,
  VALIDATION as V,
} from '../../../utils/inputValidators';

export function AdminSettings() {
  const { t } = useTranslation();
  const { data: settings } = useGetAdminSettings();
  const queryClient = useQueryClient();
  const notification = useNotifications();
  const updateSettings = useUpdateAdminSettings();

  const methods = useForm<AdminSettingsFormDto>({
    values: settings,
  });

  const { append, remove } = useFieldArray({
    control: methods.control,
    name: 'transportSettings.additionalReceivers',
  });

  const additionalReceiversWatch = methods.watch(
    'transportSettings.additionalReceivers'
  );

  const yearlySchoolTax = methods.watch('schoolTaxesSettings.yearlySchoolTax');

  const firstDownPayment = methods.watch(
    'schoolTaxesSettings.firstDownPayment'
  );
  const secondDownPayment = methods.watch(
    'schoolTaxesSettings.secondDownPayment'
  );

  const onAddNewReceiver = (index: number) => {
    append({ name: '', id: index });
  };

  const onDeleteReceiver = (index: number) => {
    remove(index);
  };

  const onSubmit = async (data: AdminSettingsFormDto) => {
    const filteredReceivers =
      data.transportSettings?.additionalReceivers?.filter(
        (receiver) => receiver.name
      );
    const formData: AdminSettingsDto = {
      ...data,
      transportSettings: {
        ...data.transportSettings,
        additionalReceivers: filteredReceivers.length
          ? filteredReceivers.map((receiver) => receiver.name)
          : null,
      },
    } as {} as AdminSettingsDto;
    updateSettings
      .mutateAsync(formData)
      .then(() => {
        notification.success(t('SETTINGS.MESSAGES.SETTINGS_UPDATED'));
        queryClient.invalidateQueries({ queryKey: ['admin-settings'] });
      })
      .catch((error) =>
        notification.danger(t(`NETWORK_ERRORS.${error.errors[0]}`))
      );
  };

  const settingBaseRules = getCommonValidators([
    V.REQUIRED,
    V.NUMBER_PATTERN,
    V.MAX_LENGTH,
  ]);

  return (
    <FormProvider {...methods}>
      <form className="settings" onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="settings__section">
          <h2 className="settings__section__title">
            {t('SETTINGS.TITLES.GENERAL_SETTINGS')}
          </h2>
          <div className="settings__section__item settings__section__item-1 settings__section__item-left">
            <span className="settings__section__item__label settings__section__item__label-extend">
              {t('SETTINGS.LABELS.NAME')}
            </span>
            <Input
              name="generalSettings.recipientName"
              className="settings__section__item__input settings__section__item__input-expand"
              rules={getCommonValidators([V.REQUIRED, V.MAX_LENGTH])}
            />
          </div>
          <div className="settings__section__item settings__section__item-2">
            <span className="settings__section__item__label settings__section__item__label-extend">
              {t('SETTINGS.LABELS.PROCREDIT_ACCOUNTANCY')}
            </span>
            <Input
              name="generalSettings.proCreditAccountancyEmail"
              className="settings__section__item__input settings__section__item__input-expand"
              rules={getCommonValidators([
                V.REQUIRED,
                V.EMAIL_PATTERN,
                V.MAX_LENGTH,
              ])}
            />
          </div>
          <div className="settings__section__item settings__section__item-3 settings__section__item-left">
            <span className="settings__section__item__label settings__section__item__label-extend">
              {t('SETTINGS.LABELS.IBAN')}
            </span>
            <Input
              name="generalSettings.iban"
              className="settings__section__item__input settings__section__item__input-expand"
              rules={getCommonValidators([V.REQUIRED, V.IBAN_PATTERN])}
            />
          </div>
          <div className="settings__section__item settings__section__item-4">
            <span className="settings__section__item__label settings__section__item__label-extend">
              {t('SETTINGS.LABELS.BANK_NAME')}
            </span>
            <Input
              name="generalSettings.bankName"
              className="settings__section__item__input settings__section__item__input-expand"
              rules={getCommonValidators([V.REQUIRED, V.MAX_LENGTH])}
            />
          </div>
          <div className="settings__section__item settings__section__item-5 settings__section__item-left">
            <span className="settings__section__item__label settings__section__item__label-extend">
              {t('SETTINGS.LABELS.BIC')}
            </span>
            <Input
              name="generalSettings.bic"
              className="settings__section__item__input settings__section__item__input-expand"
              rules={getCommonValidators([V.REQUIRED, V.MAX_LENGTH])}
            />
          </div>
        </div>
        <div className="settings__section">
          <h2 className="settings__section__title">
            {t('SETTINGS.TITLES.SCHOOL_TAXES')}
          </h2>

          <div className="settings__section__item settings__section__item-1 settings__section__item-left">
            <span className="settings__section__item__label">
              {t('SETTINGS.LABELS.TAX_PER_YEAR')}
            </span>
            <Input
              name="schoolTaxesSettings.yearlySchoolTax"
              className="settings__section__item__input"
              rules={{
                ...settingBaseRules,
                required: getRequiredValidator(),
              }}
            />
          </div>
          <div className="settings__section__item settings__section__item-3 settings__section__item-left">
            <span className="settings__section__item__label">
              {t('SETTINGS.LABELS.SCHOOL_TAX_PRICE_FOR_SECOND_CHILD')}
            </span>
            <Input
              name="schoolTaxesSettings.secondChildTax"
              className="settings__section__item__input"
              rules={{
                ...settingBaseRules,
                ...getCommonValidators([V.MIN_0]),
                required: getRequiredValidator(),
              }}
            />
          </div>

          <div className="settings__section__item settings__section__item-5 settings__section__item-left">
            <span className="settings__section__item__label">
              {t('SETTINGS.LABELS.FIRST_DOWN_PAYMENT')}
            </span>
            <Input
              name="schoolTaxesSettings.firstDownPayment"
              className="settings__section__item__input"
              rules={{
                ...settingBaseRules,
                validate: {
                  lessThanFullAmount: (value) => {
                    return (
                      (+value < +yearlySchoolTax ||
                        t('SETTINGS.ERRORS.GREATER_THAN_FULL_AMOUNT')) ??
                      ''
                    );
                  },
                  withSecondDownPaymentGreaterThanFullAmount: (value) => {
                    return (
                      (+value + +secondDownPayment <= +yearlySchoolTax ||
                        t(
                          'SETTINGS.ERRORS.TOTAL_AMOUNT_EXCEEDS_FULL_AMOUNT'
                        )) ??
                      ''
                    );
                  },
                },
              }}
            />
          </div>

          <div className="settings__section__item settings__section__item-7 settings__section__item-left">
            <span className="settings__section__item__label">
              {t('SETTINGS.LABELS.SECOND_DOWN_PAYMENT')}
            </span>
            <Input
              name="schoolTaxesSettings.secondDownPayment"
              className="settings__section__item__input"
              rules={{
                ...settingBaseRules,
                validate: {
                  value: (value) => {
                    return (
                      (+value < +yearlySchoolTax ||
                        t('SETTINGS.ERRORS.GREATER_THAN_FULL_AMOUNT')) ??
                      ''
                    );
                  },
                  withFirstDownPaymentGreaterThanFullAmount: (value) => {
                    return (
                      (+value + +firstDownPayment <= +yearlySchoolTax ||
                        t(
                          'SETTINGS.ERRORS.TOTAL_AMOUNT_EXCEEDS_FULL_AMOUNT'
                        )) ??
                      ''
                    );
                  },
                },
              }}
            />
          </div>
        </div>

        <div className="settings__section">
          <h2 className="settings__section__title">
            {t('SETTINGS.TITLES.FOOD_MANAGEMENT')}
          </h2>
          <div className="settings__section__item settings__section__item-1 settings__section__item-left">
            <span className="settings__section__item__label">
              {t('SETTINGS.LABELS.DAILY_PRICE_EMPLOYEES')}
            </span>
            <Input
              name="foodSettings.employeeDailyPrice"
              className="settings__section__item__input"
              rules={{
                ...settingBaseRules,
                validate: {
                  value: (value) => {
                    return (
                      (+value > 0 ||
                        t('SETTINGS.ERRORS.POSITIVE_NUMBER_ONLY')) ??
                      ''
                    );
                  },
                },
              }}
            />
          </div>

          <div className="settings__section__item settings__section__item-3 settings__section__item-left">
            <span className="settings__section__item__label">
              {t('SETTINGS.LABELS.DAILY_PRICE_STUDENTS')}
            </span>
            <Input
              name="foodSettings.studentDailyPrice"
              className="settings__section__item__input"
              rules={{
                ...settingBaseRules,
                validate: {
                  value: (value) => {
                    return (
                      (+value > 0 ||
                        t('SETTINGS.ERRORS.POSITIVE_NUMBER_ONLY')) ??
                      ''
                    );
                  },
                },
              }}
            />
          </div>

          <div className="settings__section__item settings__section__item-5 settings__section__item-left">
            <span className="settings__section__item__label">
              {t('SETTINGS.LABELS.NUMBER_OF_EMPLOYEES_SCHOOL_RESTAURANT')}
            </span>
            <Input
              name="foodSettings.numberOfEmployeesAtSchoolRestaurant"
              className="settings__section__item__input"
              rules={settingBaseRules}
            />
          </div>

          <div className="settings__section__item settings__section__item-6 settings__section__item-meat-menu settings__section__item-left">
            <span className="settings__section__item__label">
              {t('SETTINGS.LABELS.NUMBER_OF_EMPLOYEES_MEAT_MENU')}
            </span>
            <Input
              name="foodSettings.numberOfEmployeesWithMeatMenu"
              className="settings__section__item__input"
              rules={{
                ...settingBaseRules,
                ...getCommonValidators([V.MIN_0]),
                validate: {
                  value: (value) => {
                    return (
                      (Number.isInteger(+value) ||
                        t('ERROR.VALIDATION.INTEGER_ONLY_NUMBER')) ??
                      ''
                    );
                  },
                },
              }}
            />
          </div>

          <div className="settings__section__item settings__section__item-7 settings__section__item-left">
            <span className="settings__section__item__label">
              {t('SETTINGS.LABELS.NUMBER_OF_EMPLOYEES_VEGETARIAN_MENU')}
            </span>
            <Input
              name="foodSettings.numberOfEmployeesWithVegetarianMenu"
              className="settings__section__item__input"
              rules={{
                ...settingBaseRules,
                ...getCommonValidators([V.MIN_0]),
                validate: {
                  value: (value) =>
                    (Number.isInteger(+value) ||
                      t('ERROR.VALIDATION.INTEGER_ONLY_NUMBER')) ??
                    '',
                },
              }}
            />
          </div>

          <div className="settings__section__item settings__section__item-2">
            <span className="settings__section__item__label settings__section__item__label-extend">
              {t('SETTINGS.LABELS.FOOD_COMPANY_EMAIL')}
            </span>
            <Input
              name="foodSettings.foodCompanyEmail"
              className="settings__section__item__input settings__section__item__input-expand"
              rules={getCommonValidators([
                V.REQUIRED,
                V.EMAIL_PATTERN,
                V.MAX_LENGTH,
              ])}
            />
          </div>
        </div>
        <div className="settings__section">
          <h2 className="settings__section__title">
            {t('SETTINGS.TITLES.TRANSPORT')}
          </h2>
          <div className="settings__section__item settings__section__item-1">
            <span className="settings__section__item__label">
              {t('SETTINGS.LABELS.TRANSPORT_FEE_ONE_WAY')}
            </span>
            <Input
              name="transportSettings.oneWayMonthlyTax"
              className="settings__section__item__input"
              rules={{
                ...settingBaseRules,
                validate: {
                  value: (value) => {
                    return (
                      (+value > 0 ||
                        t('SETTINGS.ERRORS.POSITIVE_NUMBER_ONLY')) ??
                      ''
                    );
                  },
                },
              }}
            />
          </div>
          <div className="settings__section__item settings__section__item-2">
            <span className="settings__section__item__label settings__section__item__label-extend">
              {t('SETTINGS.LABELS.TRANSPORTATION_COMPANY')}
            </span>
            <Input
              name="transportSettings.transportationCompanyEmail"
              className="settings__section__item__input settings__section__item__input-expand"
              rules={getCommonValidators([
                V.REQUIRED,
                V.EMAIL_PATTERN,
                V.MAX_LENGTH,
              ])}
            />
          </div>
          <div className="settings__section__item settings__section__item-3">
            <span className="settings__section__item__label">
              {t('SETTINGS.LABELS.TRANSPORT_FEE_TWO_WAY')}
            </span>
            <Input
              name="transportSettings.twoWayMonthlyTax"
              className="settings__section__item__input"
              rules={{
                ...settingBaseRules,
                validate: {
                  value: (value) => {
                    return (
                      (+value > 0 ||
                        t('SETTINGS.ERRORS.POSITIVE_NUMBER_ONLY')) ??
                      ''
                    );
                  },
                },
              }}
            />
          </div>
          {additionalReceiversWatch?.length > 0 &&
            additionalReceiversWatch.map((field, index) => (
              <div
                className="settings__section__item settings__section__item-5 settings__section__item settings__section__item-right"
                key={index}
              >
                {index === 0 && (
                  <span className="settings__section__item__label settings__section__item__label-extend settings__section__item__label-not-required">
                    {t('SETTINGS.LABELS.ADDITIONAL_RECEIVERS')}
                  </span>
                )}

                <>
                  <Input
                    key={field.id}
                    name={`transportSettings.additionalReceivers.${index}.name`}
                    className="settings__section__item__input settings__section__item__input-expand"
                    rules={getCommonValidators([V.MAX_LENGTH, V.EMAIL_PATTERN])}
                  />
                  {index !== 0 && (
                    <button
                      type="button"
                      className="settings__section__item__trash"
                      onClick={() => onDeleteReceiver(index)}
                    >
                      <TrashCan className="settings__section__item__trash__icon" />
                    </button>
                  )}
                  <button
                    type="button"
                    className="settings__section__item__add"
                    onClick={() => onAddNewReceiver(index)}
                  >
                    <Add className="settings__section__item__add__icon" />
                    <span>{t('ACTIONS.ADD_MORE_RECEIVERS')}</span>
                  </button>
                </>
              </div>
            ))}
        </div>
        <div className="settings__actions">
          {Object.keys(methods.formState.errors).length
            ? JSON.stringify(methods.formState.errors)
            : ''}
          <Button
            className="settings__actions__button"
            type="submit"
            disabled={updateSettings.isPending}
          >
            {t('ACTIONS.SAVE')}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
