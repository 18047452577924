import { useMutation } from '@tanstack/react-query';
import type { CompleteParentCandidateProfileFormDTO } from '../../../features/admission/complete-profile/parent-candidate/CompleteProfileParentCandidate';
import publicApiRequest from '../../../services/publicApiWrapper';
import { PUBLIC_CANDIDATE_URL } from '../../../utils/constants/urlConstants';

async function completeCandidateRegistration(
  invitationId: string | null,
  candidateData: CompleteParentCandidateProfileFormDTO
) {
  return publicApiRequest.post(
    `${PUBLIC_CANDIDATE_URL}/complete-registration?invitationId=${invitationId}`,
    candidateData
  );
}
export function useCompleteRegistrationForCandidate(
  invitationId: string | null
) {
  return useMutation({
    mutationFn: (candidateData: CompleteParentCandidateProfileFormDTO) =>
      completeCandidateRegistration(invitationId, candidateData),
  });
}
