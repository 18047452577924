import { Tabs, Tab } from '@ph-react-ui/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../../hooks/auth/useAuth';
import { Role } from '../../utils/enums/role.enum';
import { AllReports } from './admin/AllReports';
import { MyTermReports } from './MyTermReports';

export const TermReports = () => {
  const { authState } = useAuth();
  const { t } = useTranslation();
  const [, setSearchParams] = useSearchParams();
  const [tabSelected, setTabSelected] = useState<string | number>('my-reports');

  return (
    <div className="term-reports">
      <Tabs
        selected={tabSelected}
        onSelectedChange={(selected) => {
          setTabSelected(selected);
          setSearchParams('');
        }}
      >
        <Tab id="my-reports" title={t('TERM_REPORTS.TABS.MY_REPORTS')}>
          <MyTermReports />
        </Tab>

        {authState?.role === Role.Admin ? (
          <Tab id="all-reports" title={t('TERM_REPORTS.TABS.ALL_REPORTS')}>
            <AllReports />
          </Tab>
        ) : null}
      </Tabs>
    </div>
  );
};
