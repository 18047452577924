import { useMutation } from '@tanstack/react-query';
import type { EmployeeUpdateProfileDto } from '../../../models/users/employees/employee-update-profile.dto';
import apiRequest from '../../../services/apiWrapper';
import { EMPLOYEES_URL } from '../../../utils/constants/urlConstants';

async function updateAuthenticatedEmployeeProfile(
  employee: EmployeeUpdateProfileDto
) {
  return apiRequest.put(`${EMPLOYEES_URL}/profile`, employee);
}

export function useUpdateAuthenticatedEmployeeProfile() {
  return useMutation({
    mutationFn: (employee: EmployeeUpdateProfileDto) =>
      updateAuthenticatedEmployeeProfile(employee),
  });
}
