import { Add, TrashCan } from '@carbon/icons-react';
import { Button, Tooltip, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { createPortal } from 'react-dom';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useCreateBusRoute } from '../../../../../hooks/transportation/routes/useCreateBusRoute';
import { useEditBusRoute } from '../../../../../hooks/transportation/routes/useEditBusRoute';
import { useGetBusRoute } from '../../../../../hooks/transportation/routes/useGetBusRoute';
import { useCancellationDialog } from '../../../../../hooks/useCancellationDialog';
import { useModal } from '../../../../../hooks/useModal';
import type {
  BusRouteDTO,
  BusRouteForm,
} from '../../../../../models/transportation/bus-route.dto';
import type { BusStopForm } from '../../../../../models/transportation/bus-stop.dto';
import type { CreateUserForm } from '../../../../../models/users/create-user-form';
import { mapSimpleTimeToString } from '../../../../../services/mappingsSimpleTime';
import {
  Input,
  Textarea,
  TimePicker,
} from '../../../../../utils/components/hoc-components';
import { CREATE_EDIT_ROUTE_MODAL } from '../../../../../utils/constants/transportation/modals';
import {
  getCommonValidators,
  VALIDATION as V,
} from '../../../../../utils/inputValidators';
import { FormSkeleton } from '../../../../shared/components/skeletons/FormSkeleton';

export function AddEditRouteForm({ footerElement }: CreateUserForm) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { open: openCancellationDialog } = useCancellationDialog();
  const notification = useNotifications();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('routeId') ?? null;
  const { close: closeModal } = useModal(CREATE_EDIT_ROUTE_MODAL);
  const { data: busRoute, isLoading } = useGetBusRoute(id, Boolean(id));

  const createBusRoute = useCreateBusRoute();
  const updateBusRoute = useEditBusRoute(id);

  const methods = useForm<Partial<BusRouteForm>>({
    defaultValues: {
      name: '',
      description: '',
      busNumber: '',
      busContactPhoneNumber: '',
      busStops: [
        {
          number: 1,
          address: '',
          timeOfPickUp: {
            hours: 10,
            minutes: 0,
          },
          timeOfDropOff: {
            hours: 17,
            minutes: 0,
          },
        },
        {
          number: 2,
          address: '',
          timeOfPickUp: {
            hours: 10,
            minutes: 10,
          },
          timeOfDropOff: {
            hours: 17,
            minutes: 10,
          },
        },
      ],
    },
    values: !isLoading ? busRoute : ({} as BusRouteForm),
  });

  const { append, remove } = useFieldArray({
    control: methods.control,
    name: 'busStops',
  });

  const busStopsWatch = methods.watch('busStops');

  const { isDirty } = methods.formState;

  const onSubmit = (data: any) => {
    if (id) {
      updateExistingBusRoute(data);
    } else {
      createNewBusRoute(data);
    }
  };

  const formatRouteSubmitData = (data: BusRouteForm): BusRouteDTO => {
    return {
      ...data,
      busStops: data.busStops?.map((busStop, index) => ({
        ...busStop,
        number: index + 1,
        timeOfPickUp: mapSimpleTimeToString(busStop.timeOfPickUp),
        timeOfDropOff: mapSimpleTimeToString(busStop.timeOfDropOff),
      })),
    } as BusRouteDTO;
  };

  const updateExistingBusRoute = async (data: BusRouteForm) => {
    await updateBusRoute
      .mutateAsync(formatRouteSubmitData(data))
      .then(() => {
        notification.success(
          t('TRANSPORTATION_MANAGEMENT.MESSAGES.ROUTE_EDIT_SUCCESS')
        );
        queryClient.invalidateQueries({ queryKey: ['bus-routes'] });
        queryClient.invalidateQueries({ queryKey: ['bus-route', id] });
        closeModal();
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  const createNewBusRoute = async (data: BusRouteForm) => {
    await createBusRoute
      .mutateAsync(formatRouteSubmitData(data))
      .then(() => {
        notification.success(
          t('TRANSPORTATION_MANAGEMENT.MESSAGES.ROUTE_CREATION_SUCCESS')
        );
        queryClient.invalidateQueries({ queryKey: ['bus-routes'] });
        queryClient.invalidateQueries({ queryKey: ['bus-route', id] });
        closeModal();
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  const onCloseDrawer = () => {
    if (isDirty) {
      openCancellationDialog();
    } else {
      onCloseModal();
    }
  };

  const onCloseModal = () => {
    queryClient.invalidateQueries({ queryKey: ['bus-route', id] });
    closeModal();
  };

  const addRouteStop = () => {
    let lastStopData = {} as BusStopForm;
    if (busStopsWatch) {
      lastStopData = busStopsWatch[busStopsWatch?.length - 1];
    }

    append({
      number: lastStopData.number + 1,
      address: '',
      timeOfPickUp: {
        hours: lastStopData.timeOfPickUp?.hours ?? 10,
        minutes: lastStopData.timeOfPickUp?.minutes ?? 0,
      },
      timeOfDropOff: {
        hours: lastStopData.timeOfDropOff?.hours ?? 17,
        minutes: lastStopData.timeOfDropOff?.minutes ?? 0,
      },
    });
  };

  const deleteRouteStop = (index: number) => {
    remove(index);
  };

  const renderDeleteButton = (index: number) => {
    const hasTwoOrLessStops = busStopsWatch && busStopsWatch.length <= 2;
    const hasStudentsOnBusStop =
      busRoute?.busStops &&
      (busRoute.busStops[index]?.dropOffStudentsCount > 0 ||
        busRoute?.busStops[index]?.pickUpStudentsCount > 0);
    const isDisabled = hasTwoOrLessStops || hasStudentsOnBusStop;
    const buttonNode = (
      <button
        type="button"
        className="drawer__transportation-management__form__delete-stop__button"
        onClick={() => deleteRouteStop(index)}
        disabled={isDisabled}
      >
        <TrashCan className="drawer__transportation-management__form__delete-stop__button__icon" />
      </button>
    );
    return isDisabled ? (
      <Tooltip
        position="left"
        message={
          hasTwoOrLessStops
            ? t(
                'TRANSPORTATION_MANAGEMENT.DRAWER.MESSAGES.MINIMUM_TWO_BUS_STOPS'
              )
            : t(
                'TRANSPORTATION_MANAGEMENT.DRAWER.MESSAGES.STUDENTS_ON_BUS_STOP'
              )
        }
      >
        {buttonNode}
      </Tooltip>
    ) : (
      buttonNode
    );
  };

  if (isLoading && id) {
    return <FormSkeleton />;
  }
  return (
    <FormProvider {...methods}>
      <form
        id="transportation-management-form"
        className="drawer__transportation-management__form"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <div className="drawer__transportation-management__form__details">
          <div className="drawer__transportation-management__form__details__item mb-6">
            <Input
              className="drawer__transportation-management__form__details__item__input"
              label={t('TRANSPORTATION_MANAGEMENT.DRAWER.LABELS.NAME')!}
              placeholder={
                t('TRANSPORTATION_MANAGEMENT.DRAWER.PLACEHOLDERS.NAME') ?? ''
              }
              name="name"
              rules={getCommonValidators([V.REQUIRED, V.MAX_LENGTH])}
            />
            <Input
              className="drawer__transportation-management__form__details__item__input"
              label={t('TRANSPORTATION_MANAGEMENT.DRAWER.LABELS.BUS_NUMBER')!}
              placeholder={
                t('TRANSPORTATION_MANAGEMENT.DRAWER.PLACEHOLDERS.BUS_NUMBER') ??
                ''
              }
              name="busNumber"
              rules={{
                ...getCommonValidators([V.REQUIRED]),
                maxLength: {
                  value: 20,
                  message: t('ERROR.VALIDATION.MAX_LENGTH'),
                },
              }}
            />
          </div>

          <div className="drawer__transportation-management__form__details__item mb-6">
            <Input
              className="drawer__transportation-management__form__details__item__input"
              label={
                t('TRANSPORTATION_MANAGEMENT.DRAWER.LABELS.ADDITIONAL_INFO')!
              }
              placeholder={
                t(
                  'TRANSPORTATION_MANAGEMENT.DRAWER.PLACEHOLDERS.ADDITIONAL_INFO'
                ) ?? ''
              }
              name="description"
              rules={getCommonValidators([V.MAX_LENGTH])}
            />
            <Input
              className="drawer__transportation-management__form__details__item__input"
              label={t('TRANSPORTATION_MANAGEMENT.DRAWER.LABELS.PHONE_NUMBER')!}
              placeholder={
                t(
                  'TRANSPORTATION_MANAGEMENT.DRAWER.PLACEHOLDERS.PHONE_NUMBER'
                ) ?? ''
              }
              name="busContactPhoneNumber"
              rules={getCommonValidators([V.REQUIRED, V.MAX_LENGTH])}
            />
          </div>
        </div>
        {busStopsWatch?.length &&
          busStopsWatch.map((field, index) => (
            <div
              className="drawer__transportation-management__form__route-details"
              key={field.number}
            >
              <div className="drawer__transportation-management__form__route-details__index">
                <span>{index + 1}</span>
              </div>
              <div className="drawer__transportation-management__form__route-details__time">
                <TimePicker
                  name={`busStops.${index}.timeOfPickUp`}
                  label={
                    t('TRANSPORTATION_MANAGEMENT.DRAWER.LABELS.PICKUP_TIME') ??
                    ''
                  }
                  placeholder={
                    t(
                      'TRANSPORTATION_MANAGEMENT.DRAWER.PLACEHOLDERS.PICKUP_TIME'
                    ) ?? ''
                  }
                  rules={getCommonValidators([V.REQUIRED])}
                />
                <TimePicker
                  name={`busStops.${index}.timeOfDropOff`}
                  label={
                    t('TRANSPORTATION_MANAGEMENT.DRAWER.LABELS.DROPOFF_TIME') ??
                    ''
                  }
                  placeholder={
                    t(
                      'TRANSPORTATION_MANAGEMENT.DRAWER.PLACEHOLDERS.DROPOFF_TIME'
                    ) ?? ''
                  }
                  rules={getCommonValidators([V.REQUIRED])}
                />
              </div>
              <div className="drawer__transportation-management__form__route-details__note">
                <Textarea
                  name={`busStops.${index}.address`}
                  rows={7}
                  label={
                    t(
                      'TRANSPORTATION_MANAGEMENT.DRAWER.LABELS.BUS_STOP_NAME'
                    ) ?? ''
                  }
                  className="dialog__absence__content__additional__note"
                  rules={getCommonValidators([V.REQUIRED, V.MAX_LENGTH])}
                  placeholder={
                    t(
                      'TRANSPORTATION_MANAGEMENT.DRAWER.PLACEHOLDERS.BUS_STOP_NAME'
                    ) ?? ''
                  }
                />
              </div>
              <div className="drawer__transportation-management__form__route-details__delete">
                {renderDeleteButton(index)}
              </div>
            </div>
          ))}
        <div className="drawer__transportation-management__form__add-stop">
          <button
            type="button"
            className="drawer__transportation-management__form__add-stop__button"
            onClick={addRouteStop}
          >
            <Add className="drawer__transportation-management__form__add-stop__button__icon" />
            <span className="ml-1">
              {t('TRANSPORTATION_MANAGEMENT.DRAWER.TITLES.ADD_MORE_STOPS')}
            </span>
          </button>
        </div>
        {footerElement &&
          createPortal(
            <>
              <Button
                onClick={onCloseDrawer}
                type="button"
                className="drawer__transportation-management__form__actions__cancel"
                variant="outlined"
              >
                {t('ACTIONS.CANCEL')}
              </Button>

              <Button form="transportation-management-form" type="submit">
                {t('ACTIONS.SAVE')}
              </Button>
            </>,
            footerElement
          )}
      </form>
    </FormProvider>
  );
}
