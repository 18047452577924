import { Button, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useDeleteBusRoute } from '../../../../hooks/transportation/routes/useDeleteBusRoute';
import { useModal } from '../../../../hooks/useModal';
import { DELETE_ROUTE_MODAL } from '../../../../utils/constants/transportation/modals';
import { Dialog } from '../../../shared/components/Dialog';

export const DeleteRoute = () => {
  const { t } = useTranslation();
  const notification = useNotifications();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('routeId') ?? '';
  const { close: closeModal } = useModal(DELETE_ROUTE_MODAL);

  const deleteBusRoutes = useDeleteBusRoute();

  const onDeleteRoute = async () => {
    await deleteBusRoutes
      .mutateAsync(id)
      .then(() => {
        notification.success(
          t('TRANSPORTATION_MANAGEMENT.MESSAGES.DELETE_SUCCESS')
        );
        queryClient.invalidateQueries({ queryKey: ['bus-routes'] });
        closeModal();
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  return (
    <Dialog
      onClose={() => closeModal()}
      dialogHeader={t('TRANSPORTATION_MANAGEMENT.ACTIONS.DELETE_ROUTE')}
      actionButton={
        <Button onClick={onDeleteRoute}>{t('ACTIONS.DELETE')}</Button>
      }
    >
      <h2>{t('TRANSPORTATION_MANAGEMENT.MESSAGES.ARE_YOU_SURE')}</h2>
    </Dialog>
  );
};
