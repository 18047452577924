import { AddComment, ThumbsUpFilled } from '@carbon/icons-react';
import { Button, Tooltip, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useApproveTermReportSectionById } from '../../../hooks/term-reports/admin/report-sections/useApproveTermReportSectionById';
import { useUpdateTermReportSectionById } from '../../../hooks/term-reports/admin/report-sections/useUpdateTermReportSectionById';
import { useModal } from '../../../hooks/useModal';
import type { EmployeesSectionDetailsDto } from '../../../models/term-reports/term-report.dto';
import { Input, TextEditor } from '../../../utils/components/hoc-components';
import { COMMENT_TERM_REPORT_SECTION } from '../../../utils/constants/term-reports/modals';
import { TermReportSectionStatus } from '../../../utils/enums/term-report-status.enum';

interface ProbamProps {
  section: EmployeesSectionDetailsDto;
}

export const ApproveEditTermReportSection = ({ section }: ProbamProps) => {
  const { t } = useTranslation();
  const notification = useNotifications();
  const queryClient = useQueryClient();
  const { id } = useParams();

  const { open: openCommentModal } = useModal(COMMENT_TERM_REPORT_SECTION);

  const updateTermReportSection = useUpdateTermReportSectionById(section.id!);
  const approveTermReportSection = useApproveTermReportSectionById();

  const methods = useForm({
    defaultValues: {
      content: '',
    },
    values: section,
  });

  const contentWatch = methods.watch('content');

  const onAddCommentToSection = () => {
    openCommentModal({
      sectionId: section.id!,
    });
  };

  const onApproveTermReportSection = () => {
    approveTermReportSection
      .mutateAsync(section.id!)
      .then(() => {
        notification.success(
          t('TERM_REPORTS.MESSAGES.SUCCESSFULLY_APPROVED_SECTION')
        );
        queryClient.invalidateQueries({
          queryKey: ['term-report-section', section.id],
        });
        queryClient.invalidateQueries({
          queryKey: ['term-report', id],
        });
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  const onSubmit = (data: EmployeesSectionDetailsDto) => {
    updateTermReportSection
      .mutateAsync({
        content: data.content!,
      })
      .then(() => {
        notification.success(
          t('TERM_REPORTS.MESSAGES.SUCCESSFULLY_SAVED_SECTION')
        );
        queryClient.invalidateQueries({
          queryKey: ['term-report-section', section.id],
        });
        queryClient.invalidateQueries({
          queryKey: ['term-report', id],
        });
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  const getSectionStatus = () => {
    if (section.status === TermReportSectionStatus.Approved)
      return 'approved-section';
    if (section.status === TermReportSectionStatus.Rejected)
      return 'rejected-section';
    if (section.status === TermReportSectionStatus.Submitted)
      return 'submitted-section';
    return '';
  };

  return (
    <div className={`term-reports__form__section ${getSectionStatus()}`}>
      <FormProvider {...methods}>
        <form
          className="term-reports__form__comment-content"
          onSubmit={methods.handleSubmit((data) => onSubmit(data))}
        >
          <div className="term-reports__form__comment-content__wrapper">
            <Input
              disabled
              name="name"
              label={t('TERM_REPORTS.FORM.NAME') ?? ''}
              placeholder={t('TERM_REPORTS.FORM.NAME') ?? ''}
              className="disabled-to-auto"
            />
            <Input
              disabled
              name="assignedToName"
              label={t('TERM_REPORTS.FORM.ASSIGNED_TO') ?? ''}
              placeholder={t('TERM_REPORTS.FORM.ASSIGNED_TO') ?? ''}
              className="disabled-to-auto"
            />
          </div>
          <TextEditor name="content" />
          <div className="term-reports__form__comment-content__actions">
            <Button
              variant="outlined"
              disabled={!contentWatch}
              onClick={methods.handleSubmit((data) => onSubmit(data))}
            >
              {t('ACTIONS.SAVE')}
            </Button>
          </div>
        </form>
      </FormProvider>
      <div className="term-reports__form__section__action-buttons">
        <div>
          <Tooltip
            message={t('TERM_REPORTS.TABLE.ACTIONS.DENY')}
            position="left"
          >
            <button type="button">
              <AddComment size={24} onClick={onAddCommentToSection} />
            </button>
          </Tooltip>
          <Tooltip
            message={t('TERM_REPORTS.TABLE.ACTIONS.APPROVE')}
            position="right"
          >
            <button type="button" onClick={onApproveTermReportSection}>
              <ThumbsUpFilled size={24} />
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
