import { Button, useNotifications } from '@ph-react-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useForgotPassword } from '../../../hooks/auth/useForgotPassword';
import type { UserCredentialsDto } from '../../../models/auth/login.dto';
import { Input } from '../../../utils/components/hoc-components';
import {
  getCommonValidators,
  VALIDATION as V,
} from '../../../utils/inputValidators';

export function ForgotPassword() {
  const { t } = useTranslation();
  const notification = useNotifications();
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues: {
      email: '',
    },
  });

  const forgotPassword = useForgotPassword({
    onError: () => {
      methods.reset();
    },
  });

  const onSubmit = (data: UserCredentialsDto) => {
    forgotPassword
      .mutateAsync({ email: data.email })
      .then(() => {
        notification.success(t('AUTH.ALERT.CHECK_EMAIL'));
        setTimeout(() => {
          navigate('/auth');
        }, 1000);
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <h2 className="auth__window__title">
          {t('AUTH.TITLE.FORGOT_PASSWORD')}
        </h2>

        <div className="auth__window__inputs">
          <Input
            name="email"
            rules={getCommonValidators([V.REQUIRED, V.EMAIL_PATTERN])}
            placeholder={t('AUTH.EMAIL') ?? ''}
            label={t('AUTH.EMAIL')!}
          />
        </div>
        <Button
          type="submit"
          loading={forgotPassword.isPending}
          color="success"
          className="auth__window__button"
        >
          {t('AUTH.ACTIONS.SEND')}
        </Button>
      </form>
    </FormProvider>
  );
}
