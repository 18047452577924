import { useQuery } from '@tanstack/react-query';
import type { PaginatedList } from '../../../models/common/paginated-list.model';
import type { GroupMemberDetailsDto } from '../../../models/groups/group-member-details.dto';
import apiRequest from '../../../services/apiWrapper';
import { GROUPS_URL } from '../../../utils/constants/urlConstants';

async function getAvailableGroupMembers(
  id: string,
  name: string,
  role: number | string
) {
  const params = `${name ? `&name=${name}` : ''}${role ? `&role=${role}` : ''}`;
  const response = await apiRequest.get<PaginatedList<GroupMemberDetailsDto>>(
    `${GROUPS_URL}/${id}/members/available?page=1&size=999${params}`
  );
  return response.data;
}

export function usePaginatedAvailableGroupMembers(
  id: string,
  name: string,
  role: number | string
) {
  return useQuery({
    queryKey: ['available-members', id, name, role],
    queryFn: () => getAvailableGroupMembers(id, name, role),
    enabled: Boolean(id),
  });
}
