import { useQuery } from '@tanstack/react-query';
import type { PaginatedList } from '../../models/common/paginated-list.model';
import type { StudentsToEvaluateDetailsDto } from '../../models/evaluation-plans/students-to-evaluate-details.dto';
import apiRequest from '../../services/apiWrapper';
import { createPaginatedQueryKeys } from '../../services/createPaginatedQueryKeys';
import { EVALUATION_PLANS_URL } from '../../utils/constants/urlConstants';

async function fetchStudentsToEvaluate(searchParams: URLSearchParams) {
  const response = await apiRequest.get<
    PaginatedList<StudentsToEvaluateDetailsDto>
  >(`${EVALUATION_PLANS_URL}/groups/students?${searchParams.toString()}`);
  return response.data;
}

function createQueryKey(searchParams: URLSearchParams) {
  return {
    studentName: searchParams.get('studentName'),
    groupName: searchParams.get('groupName'),
    ...createPaginatedQueryKeys(searchParams),
  };
}

export function usePaginatedStudentsToEvaluate(searchParams: URLSearchParams) {
  return useQuery({
    queryKey: ['students-to-evaluate', createQueryKey(searchParams)],
    queryFn: () => fetchStudentsToEvaluate(searchParams),
    throwOnError: false,
  });
}
