import i18n from 'i18next';
import {
  EMAIL_REGEX,
  IBAN_REGEX,
  NUMBER_ONLY_REGEX,
  PASSWORD_REGEX,
} from './constants/authContants';

export enum VALIDATION {
  REQUIRED = 'required',
  NOT_REQUIRED = 'not-required',
  MAX_LENGTH = 'maxLength',
  PASSWORD_PATTERN = 'pass-pattern',
  EMAIL_PATTERN = 'email-pattern',
  IBAN_PATTERN = 'iban-pattern',
  NUMBER_PATTERN = 'num-pattern',
  EGN_MIN = 'egn-minLength',
  EGN_MAX = 'egn-maxLength',
  MIN_0 = 'min-0',
  MAX_100 = 'max-100',
  MAX_LENGTH_2000 = 'maxLength-2000',
  MAX_LENGTH_3000 = 'maxLength-3000',
  MAX_LENGTH_600 = 'maxLength-600',
}

const predefinedRules = [
  'required',
  'maxLength',
  'minLength',
  'pattern',
  'min',
  'max',
];

const validators = {
  [VALIDATION.REQUIRED]: {
    value: true,
    message: 'ERROR.VALIDATION.REQUIRED',
  },
  [VALIDATION.NOT_REQUIRED]: {
    value: false,
    message: 'ERROR.VALIDATION.REQUIRED',
  },
  [VALIDATION.MAX_LENGTH]: {
    value: 200,
    message: 'ERROR.VALIDATION.MAX_LENGTH',
  },
  [VALIDATION.PASSWORD_PATTERN]: {
    value: PASSWORD_REGEX,
    message: 'ADMISSION.COMPLETE_PROFILE.ERRORS.PASSWORD_INVALID',
  },
  [VALIDATION.EMAIL_PATTERN]: {
    value: EMAIL_REGEX,
    message: 'AUTH.VALIDATION.EMAIL',
  },
  [VALIDATION.IBAN_PATTERN]: {
    value: IBAN_REGEX,
    message: 'USERS.DRAWER.ERRORS.IBAN_ERROR',
  },
  [VALIDATION.NUMBER_PATTERN]: {
    value: NUMBER_ONLY_REGEX,
    message: 'SETTINGS.ERRORS.ONLY_NUMBER',
  },
  [VALIDATION.EGN_MIN]: {
    value: 10,
    message: 'USERS.DRAWER.ERRORS.EGN_LENGTH_ERROR',
  },
  [VALIDATION.EGN_MAX]: {
    value: 10,
    message: 'USERS.DRAWER.ERRORS.EGN_LENGTH_ERROR',
  },
  [VALIDATION.MIN_0]: {
    value: 0,
    message: 'SETTINGS.ERRORS.MIN_VALUE',
  },
  [VALIDATION.MAX_100]: {
    value: 100,
    message: 'SETTINGS.ERRORS.MAX_VALUE',
  },
  [VALIDATION.MAX_LENGTH_2000]: {
    value: 2000,
    message: 'SETTINGS.ERRORS.MAX_VALUE_2000',
  },
  [VALIDATION.MAX_LENGTH_3000]: {
    value: 3000,
    message: 'SETTINGS.ERRORS.MAX_VALUE_3000',
  },
  [VALIDATION.MAX_LENGTH_600]: {
    value: 600,
    message: 'SETTINGS.ERRORS.MAX_VALUE_600',
  },
};

export const getCommonValidators = (rules: VALIDATION[]) => {
  return rules.reduce((acc, rule) => {
    const key = predefinedRules.find((e) => rule.includes(e)) ?? rule;
    return {
      ...acc,
      [key]: {
        value: validators[rule].value,
        message: i18n.t(validators[rule].message),
      },
    };
  }, {});
};

export const getRequiredValidator = () => {
  return {
    value: true,
    message: i18n.t('ERROR.VALIDATION.REQUIRED'),
  };
};
