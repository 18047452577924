import { useMutation } from '@tanstack/react-query';
import type { AssignEvaluationPlanToGroupsDto } from '../../models/evaluation-plans/assign-evaluation-plan-to-groups.dto';
import apiRequest from '../../services/apiWrapper';
import { EVALUATION_PLANS_URL } from '../../utils/constants/urlConstants';

async function assignEvaluationPlanToGroups(
  id: string | null,
  data: AssignEvaluationPlanToGroupsDto
) {
  return apiRequest.put(`${EVALUATION_PLANS_URL}/${id}/groups/assign`, data);
}

export function useAssignEvaluationPlanToGroups(id: string | null) {
  return useMutation({
    mutationFn: (data: AssignEvaluationPlanToGroupsDto) =>
      assignEvaluationPlanToGroups(id, data),
  });
}
